import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, FormHelperText } from '@mui/material';
import TextField from './TextField';
import { tokens } from '../../theme';


interface CustomMultipleDropDownProps {
  options: { value: string | number; label: string }[];
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string;
  variant?: "filled" | "outlined" | "standard";
  disableSearch?: boolean;
  name?: string;
  onChangeSelect?: (e: any, value: any) => any; control?: Control<any, any>
  // defaultValue?: string | number | boolean
  rules?: object;
  disabled?: boolean;
  sortOptions?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomMultipleDropDown: React.FC<CustomMultipleDropDownProps> = ({
  options,
  size = "medium",
  color,
  label,
  variant = "outlined",
  disableSearch = false,
  name,
  control,
  onChangeSelect,
  // defaultValue,
  rules,
  disabled = false,
  sortOptions = true,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fieldValueArray, setFieldValueArray] = React.useState<string[]>([]);


  const processedOptions = sortOptions
  ? [...options].sort((a, b) => a.label.localeCompare(b.label))
  : options;

  const handleChange = (event: any, field: any, newValue:{ value: string | number; label: string }[] ) => {
    const valueArray = newValue.map((newVal : { value: string | number; label: string }) => {
      return newVal.value
    });
    field.onChange(valueArray);
    if (onChangeSelect) {
      onChangeSelect(event, valueArray);
    }
  };

  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };
  
  return (
    <Controller
      name={name || ""}
      control={control}
      rules={rules ? rules : {}}
      render={({ field: { ref, value, ...field }, fieldState }) => {
        const selectedOptions = processedOptions?.filter(option => Array.isArray(value) && value.includes(option.value));
        return (
          <>
            <Autocomplete
              multiple
              options={processedOptions}
              value={selectedOptions}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              onChange={(e, newValue) => handleChange(e, field, newValue)}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const tagProps = getTagProps({ index });
                  return (
                    <Chip
                      {...tagProps} 
                      label={option.label}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={getLabel() || label}
                  variant={variant}
                  size={size}
                  color={color || (theme.palette.mode === "dark" ? "secondary" : "primary")}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  disabled={disabled} 
                />
              )}
              disabled={disabled} 
            />
          </>
        );
      }}
    />

  );
}


export default CustomMultipleDropDown;