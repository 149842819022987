import { Box, Grid, Collapse, useTheme, useMediaQuery } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { ArchiveOutlined, ArchiveRounded, ArchiveSharp, ArchiveTwoTone, AssignmentReturnedOutlined, Checklist, DownloadDoneOutlined, FileOpenOutlined, ModeOutlined, NoteAddOutlined, PictureAsPdfOutlined, SearchOutlined, TrackChanges, VisibilityOutlined} from "@mui/icons-material";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { usePracticeSlice, searchPracticeList, SearchPracticeRequest, fetchDocumentById, resetPracticeInfo, getUpdatedPracticeRegistrationByPracticeId } from "../../../redux/slice/practiceSlice";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import { fetchPracticeByIdData } from "../../../redux/slice/practice/practiceEditSlice"
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { fullName, formatDate, hideElement, sortNames } from "../../../utils/Utility";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { AgencyInfo, SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import PDFViewer from "../../common/pdf/PDFViewer";
import LegacyToolBar from "../../common/LegacyToolBar";
import CustomIconButton from "../../common/CustomIconButton";
import PracticeApprovePopup from "./PracticeApprovePopup";
import { selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AUDIT_ENTITYS, CAN_ACCESS_APPLICATION, PRACTICE_STATUS } from "../../../constants/applicationConstants";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { SearchAgency } from "../../../redux/slice/agency/AgencySlice";
import { fetchActiveAgencyExecutives } from "../../../redux/slice/practice/practiceApproveSlice";
import { usePracticeApproveSlice } from "../../../redux/slice/practice/practiceApproveSlice";
import { getAllAgenciesList } from "../../../service/CommonService";
import UploadBaaPopup from "../baa/UploadBaaPopup";
import { PracticeInfo } from "../../../redux/slice/practice/practiceRegistrationSlice";
import CustomSingleAlert from "../../common/CustomSingleAlert";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left"; // Adjust the align property type
  format?: (value: number) => string;
}

interface Row {
  contactName: string;
  practiceName: string;
  approvalStatus: string;
  contactEmail: string;
  createdDate : String
  action: JSX.Element;
  practiceId: string;
  agencyName: string;
}

type Option = {
  value: string,
  label: string
};


interface DropdownDisable {
  field: string;
  isDisabled: boolean;
}



// Define columns and rows data
const columns: Column[] = [
  { id: "practiceName", label: "Practice Name", minWidth: 100 },
  { id: "contactName", label: "Contact Name", minWidth: 100 },
  { id: "contactEmail", label: "Contact Email", minWidth: 100 },
  { id: "contactPhone", label: "Contact Phone Number", minWidth: 100 },
  { id: "approvalStatus", label: "Approval Status", minWidth: 100 },
  { id: "createdDate", label: "Created Date", minWidth: 100 },
  { id: "agencyName", label: "Agency", minWidth: 100 },
  { id: "userId", label: "Account Rep", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 50, align: "center", },
];

const ITEMS_PER_PAGE = 20;

const PracticeList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, handleSubmit, reset, setValue } = useForm<SearchPracticeRequest>();

  const [showCard, setShowCard] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showPracticesPopup, setShowPracticesPopup] = useState(false);
  const [showBaaPopup, setShowBaaPopup] = useState<{show: boolean, userEntity: "PRACTICE" | "USER", details:PracticeInfo | null}>();
  const [baaAlert, setBaaAlert] = useState<{show:boolean, obj: SingleAlertInfo}>();

  const [searchCriteria, setSearchCriteria] = useState<SearchPracticeRequest>({
    practiceName: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    approvalStatus: '',
    agencyId: '',
    userId:'',
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const { practiceInfo, document } = usePracticeSlice(); // Use the practiceInfo from the Redux store
  const { userInfo } = useAuthSlice();

  const {agencyExecutives} = usePracticeApproveSlice();

  const canAccessPracticeActionsView = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.VIEW)(state));
  const canAccessPracticeActionsEdit = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.EDIT)(state));
  const canAccessPracticeActionsPreview = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.PREVIEW)(state));
  const canAccessPracticeActionsApprove = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.APPROVE)(state));
  const canAccessPracticeActionsAchieve = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.ACHIEVE)(state));
  const canAccessPracticeActionsImportBaa = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.IMPORT_BAA)(state));
  const canAuditPractice = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessAuditAction", AUDIT_ENTITYS.PRACTICEAUDIT)(state));

  const [agencyInfoList, setAgencyInfoList] = useState<AgencyInfo>();

  const statusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: "ALL", label: "ALL" },
      { value: "SUBMITTED", label: "SUBMITTED" },
      { value: "APPROVED", label: "APPROVED" },
      { value: "REJECTED", label: "REJECTED" },
    ];
    return options;
  }, []);

  //Mount
  useEffect(() => {
    const fromBaa = location?.state?.fromBaa;
    if(fromBaa) {
      notifyBaaAlert();
    };
  }, []);

  const notifyBaaAlert = () => {
    let infoObj: SingleAlertInfo = {
      message: "Please upload the Business Association Agreement by selecting the Import BAA option in the action menu.",
      alertType: "info"
    }
    setBaaAlert({
      show: true,
      obj: infoObj
    });
  }

  //Unmount
  useEffect(() => () => {
    dispatch(resetPracticeInfo());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId);
    if ((location.state && location.state.practiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
      if (location.state && location.state.withSearchHistory) {
        //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
        navigate("/practice_list", { state: { practiceListSearchCriteria: criteria } });
        return;
      }
      let searchCriteria: SearchPracticeRequest = {
        practiceName: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        approvalStatus: '',
        agencyId: '',
        userId:'',
        currentPage: 0,
        itemsPerPage: 0
      };
      if (location.state && location.state.practiceListSearchCriteria) {
        searchCriteria = location.state.practiceListSearchCriteria;
      } else {
        searchCriteria = criteria ? criteria : searchCriteria;
      }
      setSearchCriteria(searchCriteria);
      setValuesToCriteriaFields(searchCriteria);

      searchPracticesList(searchCriteria);
    } else {
      loadInitialPracticeList(1);
    }
  }, [location.state, showBaaPopup?.show]);

  const setValuesToCriteriaFields = (searchCriteria: SearchPracticeRequest) => {
    setValue("practiceName", searchCriteria.practiceName);
    setValue("contactFirstName", searchCriteria.contactFirstName);
    setValue("contactLastName", searchCriteria.contactLastName);
    setValue("contactEmail", searchCriteria.contactEmail);
    setValue("contactPhone", searchCriteria.contactPhone);
    setValue("approvalStatus", searchCriteria.approvalStatus);
    setValue("agencyId", searchCriteria.agencyId);
    setValue("userId", searchCriteria.userId);
  }

  const loadInitialPracticeList = (pNumber: number = 1) => {
    const data : SearchPracticeRequest = {
      practiceName: '',
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      approvalStatus: "",
      agencyId: "",
      userId:"",
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);
    
    searchPracticesList(data);
  }

  const searchPracticesList = (searchCriteria: SearchPracticeRequest) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId, searchCriteria);
    
    let dataToSearch = {
      ...searchCriteria,
      approvalStatus: searchCriteria.approvalStatus === "ALL" ? "" : searchCriteria.approvalStatus,
    }

    dispatch(searchPracticeList(dataToSearch));
  }

  const onSubmit = (data: SearchPracticeRequest) => {
    const data2: SearchPracticeRequest = {
      practiceName: data.practiceName,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      approvalStatus: data.approvalStatus,
      agencyId: data.agencyId,
      userId: data.userId,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/practice_list", {state: {practiceListSearchCriteria : data2}});
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/practice_list", { state: { practiceListSearchCriteria: data } });
  }
  
  const handleActionEdit = (row: any) => {
    const { approvalStatus } = row;
    navigate("/practice_edit_view", { state: { practiceEditInfo: { approvalStatus }, practiceId: row.practiceId, documentId:row.associateAgreementDocumentId } });
  }

  const handlePracticeAchievePreview = async (row: any) => {
    if (row.associateAgreementDocumentId !== null) {
      const actionResult = await dispatch(fetchDocumentById(row.associateAgreementDocumentId));
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        setShowPDF(true);
      }
    } else {
      let errorObj: SingleAlertInfo = {
        message: "Feature currently under development. Stay tuned for updates!",
        alertType: "info"
      }
      dispatch(setSingleAlertObj(errorObj));
    }
  }

  const handleApproveAction = async (row: any) => {
    const response = await dispatch(fetchPracticeByIdData(row.practiceId));
    if (response.payload) {
      navigate('/practice_request', { state: { rowValue: response.payload?.data } })
    }
  }

  const handleActionPreview = async (row: any) => {
    const data = {
      practiceId: row.practiceId,
      updatePracticeAgreement: false,
    };
    const response = await dispatch(getUpdatedPracticeRegistrationByPracticeId(data));
    const documentData = response.payload;

    if (documentData && documentData.data) {
      setShowPDF(true);
    }
    else {
      let errorObj: SingleAlertInfo = {
        message: "Feature currently under development. Stay tuned for updates!",
        alertType: "info"
      }
      dispatch(setSingleAlertObj(errorObj));
    }
  }

  const handleClose = () => {
    setShowPDF(false);
  }

  const handleImportBaa = (row: any) => {
    setShowBaaPopup({
      show: true,
      userEntity: "PRACTICE",
      details: row
    });
  }
  const handleClickPracticeAudit = (data: any) => {
    if (data && data.practiceName) {
      const PracticeAuditData = {
        entityName: "PRACTICE",
        entityNumber: data?.practiceName
      }

      const url = `/auditlog?entityName=${PracticeAuditData.entityName}&entityNumber=${PracticeAuditData.entityNumber}`;
      window.open(url, '_blank');
      // navigate("/auditlog", '_blank',{ state: { entityName: IVRAuditData.entityName, entityNumber: IVRAuditData.entityNumber } });
    }
  }
  const actionComponent = (row: any): JSX.Element => {
    const { approvalStatus } = row;
    return (
      <>
        {((approvalStatus === "APPROVED" || approvalStatus === "REJECTED") && approvalStatus === "APPROVED") &&
          (<CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleActionPreview(row)}
            tooltipMessage="PDF" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsPreview)}` }}>
            <PictureAsPdfOutlined fontSize='small' />
          </CustomIconButton>
          )}
        {(approvalStatus === "APPROVED" || approvalStatus === "REJECTED") && (
          <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleActionEdit(row)} tooltipMessage="Edit" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsEdit)}` }}>
            <ModeOutlined fontSize='small' />
          </CustomIconButton>
        )}
        {(!(approvalStatus === "APPROVED" || approvalStatus === "REJECTED") &&
          <CustomIconButton color="success" size="small" variant="outlined" onClick={() => handleApproveAction(row)} tooltipMessage="Approve" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsApprove)}` }}>
            <Checklist fontSize="small" />
          </CustomIconButton>
        )}
        {/* <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handlePracticeInfoPreview(row)} tooltipMessage="View Practice Request"
          tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsView)}` }}>
          <NoteAddOutlined fontSize="small" />
        </CustomIconButton> */}
        {approvalStatus === "APPROVED" &&
          (<CustomIconButton color="info" size="small" variant="outlined" onClick={() => handlePracticeAchievePreview(row)} tooltipMessage="Show Archive Agreement"
            tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsAchieve)}` }}>
            <FileOpenOutlined fontSize="small" />
          </CustomIconButton>)
        }
        {approvalStatus === "APPROVED" &&
          (<CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleImportBaa(row)} tooltipMessage="Import BAA"
            tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsImportBaa || row.baaDetailId !== null)}` }}>
            <AssignmentReturnedOutlined fontSize="small" />
          </CustomIconButton>)
        }
          <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Practice Audit"
          color="warning"
          size="small"
          variant="outlined"
          style={{ display: `${hideElement(!canAuditPractice)}` }}
          onClick={() => handleClickPracticeAudit(row) }
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>
      </>
    );
  }

  const [dropDownDisabled, setDropDownDisable] = useState<DropdownDisable[]>([]); // Assuming you're managing this state

  const disableDropDowns = (fieldToDisable: DropdownDisable) => {
      setDropDownDisable((prevDisabled) => {
      
          const alreadyDisabled = prevDisabled.some(item => item.field === fieldToDisable.field);
  
          if (!alreadyDisabled) {
              return [...prevDisabled, fieldToDisable];
          }
          return prevDisabled;
      });
    }


  const [agencyData, setAgencyData] = useState<SearchAgency>({
    agencyName: undefined,
    phone: undefined,
    email: undefined,
    address: undefined,
    commType: undefined,
    agencyStatus: undefined,
    currentPage: 1,
    itemsPerPage: 100
  });

  
  useEffect(() => {

    let searchCriteria: SearchAgency = {
      agencyName: '',
      phone: '',
      email: '',
      address: '',
      commType: '',
      agencyStatus: '',
      currentPage: 0,
      itemsPerPage: 100
    };

    setAgencyData(searchCriteria)

      const fetchAgencies = async () => {
        try {
          const response = await getAllAgenciesList();
          setAgencyInfoList(response.data.data); // Adjust based on how your API returns data
        } catch (error) {
          console.error("Error fetching agencies:", error);
        }
      };

    fetchAgencies();
    dispatch(fetchActiveAgencyExecutives({ getAgencyAdmins: false, isInactive: true }))
  }, [dispatch]);

  const agencyOptions = useMemo(() => {
    let options: Option[] = [];
  
    if (agencyInfoList) {
      const sortedAgencyList = sortNames(agencyInfoList, 'agencyName');
      sortedAgencyList.forEach((agency: any) => {
        options.push({ label: agency.agencyName, value: agency.agencyId });
      });
    }
  
    return options;
  }, [agencyInfoList]);


  const accountRepOptions = useMemo(() => {
    let options: Option[] = [];
  
    if(userInfo.userRole != "ROLE_ACCOUNTEXECUTIVE"){
        if (agencyExecutives && agencyExecutives.length > 0) {
          
          const sortedRepList = sortNames(agencyExecutives, 'firstName');
          sortedRepList.forEach((rep: any) => {
            options.push({ label: `${rep.firstName} ${rep.lastName}`, value: rep.userId });
          });
        }
    }else{
        options.push({ label: `${userInfo.firstName} ${userInfo.lastName}`, value: userInfo.userId.toString() });
    }
  
    return options;
  }, [agencyExecutives]);



  useEffect(() => {
      if(userInfo.userRole === "ROLE_ACCOUNTEXECUTIVE"){
          const userId = userInfo.userId;
          setValue("userId", userId.toString());
          disableDropDowns({ field: "userId", isDisabled: true });

          if (agencyOptions.length > 0) {
            const agencyId = agencyOptions[0]?.value;
            setValue("agencyId", agencyId);
            disableDropDowns({ field: "agency", isDisabled: true });
        }

      }
    
      if (userInfo.userRole === "ROLE_AGENCYADMIN" && agencyOptions.length > 0) { 
            const agencyId = agencyOptions[0]?.value;
            console.log(typeof agencyId);

            setValue("agencyId", agencyId);
            disableDropDowns({ field: "agency", isDisabled: true });
      }
  
  },[practiceInfo, agencyOptions])



  const formattedPracticeInfo: Row[] = useMemo(() => {
    let practices: Row[] = [];
    if (practiceInfo) {
      practices = practiceInfo.content.map((practice: any) => ({
        practiceName: practice.practiceName,
        contactName: fullName(practice.title, practice.contactFirstName, practice.contactMiddleName, practice.contactLastName),
        contactEmail: practice.contactEmail,
        contactPhone: practice.contactPhone,
        approvalStatus: practice.approvalStatus,
        createdDate: formatDate(practice.requestTimestamp),
        action: actionComponent(practice),
        practiceId: practice.practiceId,
        agencyName: practice.agencyName,
        userId: practice.executivesNames.join(", ") || "N/A",
        title: practice.title,
        associateAgreementDocumentId: practice.associateAgreementDocumentId,
      }));
    };
    return practices;
  }, [practiceInfo]);

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <Checklist color="success" />, text: "Approve" },
    // { icon: <NoteAddOutlined color="info" />, text: "View Practice Request" }
    { icon: <ArchiveOutlined color="info" />, text: "View Archive Practice" }
  ];

  return (
    <>
      { showPDF &&
        <PDFViewer
          isOpen={showPDF}
          onClose={handleClose}
          fileName={document?.documentName + ""}
          title={document?.documentName + ""}
          base64String={document?.content || ""}
        />
      }
      { showBaaPopup?.show &&
          <UploadBaaPopup 
          open={showBaaPopup.show }
          onClose={() => setShowBaaPopup({show : false, userEntity: "PRACTICE", details: null})}
          userEntity= {showBaaPopup.userEntity}
          details={showBaaPopup.details}
          />

      }
       {/* { showPracticesPopup &&
      <PracticeApprovePopup onClose={clickClosePracticesPopup} open={showPracticesPopup} />
      } */}
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Practice List" subtitle="Practice Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="practiceName" label="Practice Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="contactFirstName" label="Contact First Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="contactLastName" label="Contact Last Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {/* CS-543  */}
                    <CustomTextField control={control} controllerName="contactEmail" label="Contact Email" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomPatternTextField control={control} label="Contact Phone Number" name="contactPhone" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField name="approvalStatus" control={control} label="Practice Status" options={statusOptions} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField name="agencyId" label="Agency" control={control} options={agencyOptions} disabled={dropDownDisabled.some(item => item.field === "agency" && item.isDisabled)}/>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField name="userId" control={control} label="Account Rep" options={accountRepOptions} disabled={dropDownDisabled.some(item => item.field === "userId" && item.isDisabled)} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px", }} type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
              {/* {isMobile ?<LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              rows={formattedPracticeInfo || []}
              actionComponent={(row: any) => actionComponent(row)}
              emptyText="No Practice(s) Found"
              pageNumber={handlePagination}
              totalPages={practiceInfo?.totalPages}
              totalElementText={"Records: " + (practiceInfo?.totalElements ? practiceInfo?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
              showMenu={true}
            />
          </Card>
        </Box>
      </Box>
      {
        baaAlert?.show && 
        <CustomSingleAlert
        open={baaAlert?.show}
        singleAlertObj={baaAlert.obj}
        autoHideDuration={20000}
        onClose={() => setBaaAlert({show:false, obj:{message: "", alertType: "info" }})}
      />
      }
    </>
  );

}

export default PracticeList;