import { fetchInvoiceList, invoiceInfo, SearchInvoiceInfo } from "../../redux/slice/invoice/InvoiceSlice";
import ExcelJS from 'exceljs';
import { AppDispatch } from "../../redux/store";
import { fetchOrdersList, OrderInfo, OrderItemDTO } from "../../redux/slice/order/OrderSlice";
import { EXPORTABLE_ITEMS } from "../../constants/applicationConstants";

interface invoiceTableRow extends invoiceInfo {
  checkBox: JSX.Element;
}

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  padding?: string;
  props?: ColumnProps;
}

interface ColumnProps {
  control?: any;
  controllerName?: string;
  isDisabled?: boolean;
}

const ExportToExcel = async (initData: any, formattedColumns: any, dispatch: AppDispatch, sectionName: string, hiddenColumns?: string[]) => {

  const formatSizes = (orderItems: OrderItemDTO[]) => {
    const uniqueSizes = new Set();

    orderItems.forEach((orderItem) => {
      if (orderItem.size) {
        const size = orderItem.size.trim();
        const match = size.match(/(\d+(\.\d+)?x\d+(\.\d+)?)\s*cm|(\d+mm\b.*)/gi);
        if (match) {
          uniqueSizes.add(match[0].replace(/\s*cm$/, ""));
        }
      }
    });

    return Array.from(uniqueSizes).join("\n");
  };

  const getInitials = (patientName: string, hasMiddleName: boolean) => {
    if (!patientName) return "";
    const names = patientName.trim().split(' ');

    if (!hasMiddleName) {
      return names.map(name => name.charAt(0).toUpperCase()).join('');
    } else {
      return names
        .filter((_, i, arr) => i === 0 || i < arr.length - 1)
        .map(name => name.charAt(0).toUpperCase())
        .join('');
    }
  };



  try {

    let allRecords: invoiceTableRow[] | OrderInfo[] = [];
    let response: any = null;
    let fetchedData: invoiceTableRow[] | OrderInfo[] = [];

    if (sectionName === EXPORTABLE_ITEMS.INVOICE) {
      response = await dispatch(fetchInvoiceList(initData));
      fetchedData = response.payload?.data?.downloadData || [];
      allRecords = [...(allRecords as invoiceTableRow[]), ...(fetchedData as invoiceTableRow[])];
    } else if (sectionName === EXPORTABLE_ITEMS.ORDER || sectionName === EXPORTABLE_ITEMS.ORDER_SSV) {
      response = await dispatch(fetchOrdersList(initData));
      fetchedData = response.payload?.data?.downloadData || [];
      allRecords = [...(allRecords as OrderInfo[]), ...(fetchedData as OrderInfo[])];
    }

    const headerRow = [];

    if (allRecords.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(sectionName);

      if (sectionName === EXPORTABLE_ITEMS.ORDER) {

        const headerRow = [
          'V3 Order ID', 'Order Date', 'Practice', 'PT', 'Product',
          'QTY', 'Size', 'Units', 'COGs/Unit', 'COGs', 'Provider',
          'DOS', 'Actual DOS', 'Order Type', 'Ship Status',
          'Usage Status', 'Serial Number'
        ];
        worksheet.addRow(headerRow);

        headerRow.forEach((_, index) => {
          worksheet.getColumn(index + 1).width = 20;
        });

        const headerRowCells = worksheet.getRow(1);
        headerRowCells.font = { bold: true, color: { argb: 'FF000000' }, size: 15, underline: 'single' };
        headerRowCells.alignment = { horizontal: 'center', vertical: 'middle' };

        const cogsUnitIndex = headerRow.indexOf('COGs/Unit') + 1;
        const cogsIndex = headerRow.indexOf('COGs') + 1;

        allRecords.forEach(order => {
          const orderInfo = order as OrderInfo;
          if (Array.isArray(order.orderItems)) {
            order.orderItems.forEach((orderItem: OrderItemDTO) => {
              const rowData = headerRow.map(header => {
                switch (header) {
                  case 'V3 Order ID':
                    return orderInfo.orderNumber;
                  case 'Order Date':
                    return orderInfo.orderDate;
                  case 'Practice':
                    return orderInfo.practiceName;
                  case 'PT':
                    return getInitials(orderInfo.patientName, orderInfo.hasMiddleName);
                  case 'Product':
                    return orderInfo.productName;
                  case 'QTY':
                    return orderItem.quantity;
                  case 'Size':
                    return orderItem.size;
                  case 'Units':
                    return orderItem.units;
                  case 'COGs/Unit':
                    return (orderItem.extendedPrice ? orderItem.extendedPrice : "N/A");
                  case 'COGs':
                    return (orderItem.extendedPrice ? orderItem.units * orderItem.extendedPrice : "N/A");
                  case 'Provider':
                    return orderInfo.providerName || orderInfo.orderInfo?.provider || '';
                  case 'DOS':
                    return orderInfo.dateOfService;
                  case 'Actual DOS':
                    return (orderItem.actualDateOfService ? orderItem.actualDateOfService : 'N/A');
                  case 'Order Type':
                    return orderInfo.orderType;
                  case 'Ship Status':
                    const trackingNumber = orderInfo.trackingNumber;
                    return trackingNumber
                      ? `${orderInfo.orderStatus} (${trackingNumber})`
                      : orderInfo.orderStatus;
                  case 'Usage Status':
                    if (orderItem?.tissueTrackingDocumentId) {
                      if (orderItem?.invoiceId) {
                        return "Applied (Invoiced)";
                      }
                      return "Applied";
                    } else if (orderItem?.productReturnId) {
                      if (orderItem?.invoiceId) {
                        return "Returned (Invoiced)";
                      }
                      return "Returned";
                    } else if (orderItem?.invoiceId) {
                      return "Invoiced";
                    }
                    return "Available";
                  case 'Serial Number':
                    return orderItem.serialNumber;
                  default:
                    return '';
                }
              });

              const newRow = worksheet.addRow(rowData);

              if (orderItem.extendedPrice) {
                newRow.getCell(cogsUnitIndex).numFmt = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
                newRow.getCell(cogsIndex).numFmt = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
              }
            });
          }
        });

      } else if (sectionName === EXPORTABLE_ITEMS.ORDER_SSV) {

        const headerRow = [
          'MFG', 'MFG Confirm #', 'Invoice #', 'Tracking #', 'V3 Order ID',
          'Serial Number', 'Order Date', 'Order Type', 'Order MO/YR', 'Account "Practice"',
          'Product', 'Prod Size', 'Total Units/sqcm', 'Prod Qty', 'COGs P/U',
          'COGs/Sales Orig', 'COGs/Sales Pmt', 'Provider', 'DOS', 'PT'
        ];
        worksheet.addRow(headerRow);

        headerRow.forEach((_, index) => {
          worksheet.getColumn(index + 1).width = 20;
        });

        const headerRowCells = worksheet.getRow(1);
        headerRowCells.font = { bold: true, color: { argb: 'FF000000' }, size: 15, underline: 'single' };
        headerRowCells.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };

        const cogsUnitIndex = headerRow.indexOf('COGs P/U') + 1;
        const cogsSalesOrig = headerRow.indexOf('COGs/Sales Orig') + 1;
        const cogsSalesPmt = headerRow.indexOf('COGs/Sales Pmt') + 1;

        const finalRows: any[] = [];

        allRecords.forEach((order) => {
          const orderInfo = order as OrderInfo;
          const newRowData = headerRow.map((header) => {
            switch (header) {
              case "MFG":
                return orderInfo.manufacturerName ? orderInfo.manufacturerName : '';
              case "MFG Confirm #":
                return orderInfo.mfgConfirmationNumber ? orderInfo.mfgConfirmationNumber : '';
              case "Invoice #":
                {
                  const uniqueInvoices = new Set();

                  order.orderItems.forEach(orderItem => {
                    if (orderItem.mfgInvoiceNumber) {
                      uniqueInvoices.add(orderItem.mfgInvoiceNumber.trim());
                    }
                  });

                  const formattedInvoices = Array.from(uniqueInvoices).join("\n");
                  return formattedInvoices;
                }
              case "Tracking #":
                return orderInfo.trackingNumber ? orderInfo.trackingNumber : '';
              case 'V3 Order ID':
                return orderInfo.orderNumber ? orderInfo.orderNumber : '';
              case 'Order Date':
                return orderInfo.orderDate ? orderInfo.orderDate : '';
              case 'Order MO/YR': {
                const orderDateString = orderInfo.orderDate;
                const orderDate = new Date(orderDateString);

                if (!isNaN(orderDate.getTime())) {
                  const monthShort = orderDate.toLocaleString('en-US', { month: 'short' });
                  const yearShort = orderDate.getFullYear().toString().slice(-2);
                  return `${monthShort} ${yearShort}'`;
                }

                return '';
              }
              case 'Account "Practice"':
                return orderInfo.practiceName ? orderInfo.practiceName : '';
              case 'PT':
                return getInitials(orderInfo.patientName, orderInfo.hasMiddleName);
              case 'Product':
                return orderInfo.productName ? orderInfo.productName : '';
              case 'Prod Qty': {
                let totalQty = 0

                order.orderItems.forEach(orderItem => {
                  if (orderItem.quantity) {
                    totalQty = totalQty + orderItem.quantity;
                  }
                });
                return totalQty;
              }
              case 'Prod Size':
                return formatSizes(order.orderItems);
              case 'Total Units/sqcm': {
                let totalUnits = 0;
                order.orderItems.forEach(orderItem => {
                  totalUnits = totalUnits + orderItem.units;
                });
                return totalUnits;
              }
              case 'COGs P/U':
                return orderInfo.orderItems[0].extendedPrice;
              case 'COGs/Sales Pmt':
                return "";
              case 'Provider':
                return orderInfo.providerName || orderInfo.orderInfo?.provider || '';
              case 'COGs/Sales Orig':
                {
                  let totalCogSales = 0

                  order.orderItems.forEach(orderItem => {
                    if (orderItem.extendedPrice && orderItem.units) {
                      totalCogSales = totalCogSales + (orderItem.units * orderItem.extendedPrice);
                    }
                  });
                  return totalCogSales;
                }
              case 'Order Type':
                return orderInfo.orderType ? orderInfo.orderType : "";
              case 'DOS':
                return orderInfo.dateOfService ? orderInfo.dateOfService : "";
              case 'Serial Number':
                {
                  const serialNumbers: string[] = [];
                  order.orderItems.forEach(orderItem => {
                    if (orderItem.serialNumber) {
                      serialNumbers.push(orderItem.serialNumber);
                    }
                  });

                  const formattedInvoices = serialNumbers.join("\n");
                  return formattedInvoices;
                }
              default:
                return '';
            }
          });

          const row = worksheet.addRow(newRowData);
          row.getCell(cogsUnitIndex).numFmt = '$#,##0.00';
          row.getCell(cogsSalesOrig).numFmt = '$#,##0.00';
          row.getCell(cogsSalesPmt).numFmt = '$#,##0.00';

        });

        finalRows.forEach(rowData => {
          worksheet.addRow(rowData);
        });

      }


      else {

        const headerRow = formattedColumns.filter((column: Column, index: any) =>
          !hiddenColumns?.includes(column.id) &&
          !(index === 0 &&
            (typeof column.label !== 'string' || column.label.trim() === '')
          ) &&
          !(index == formattedColumns.length - 1 && column.label == 'Action')
        ).map((column: Column) => column.label);

        worksheet.addRow(headerRow);
        
        const headerRowCells = worksheet.getRow(1);
        headerRowCells.font = { bold: true, color: { argb: 'FF000000' }, size: 15, underline: 'single' }
        headerRowCells.alignment = {wrapText: true, horizontal: 'center', vertical: 'middle' };
        headerRow.forEach((label: any, index: any) => {
          const column = worksheet.getColumn(index + 1);
          column.width = 12; 
        });
        allRecords.forEach(row => {
          const rowData = headerRow.map((label: String) => {
            const column = formattedColumns.find((col: Column) => col.label === label);

            if (column) {
              if (sectionName === EXPORTABLE_ITEMS.INVOICE) {
                return (row as invoiceTableRow)[column.id as keyof invoiceTableRow];
              }
            }

            return '';
          });
          worksheet.addRow(rowData);
        })
      }


      const columnWidth = 25;
      for (let i = 1; i <= headerRow.length; i++) {
        worksheet.getColumn(i).width = columnWidth;
      }

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber !== 1) {
          row.eachCell(cell => {
            cell.font = { size: 12 };
            cell.alignment = {wrapText: true, horizontal: 'center', vertical: 'middle' };
          });
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);

      let fileName = "";
      if (sectionName === EXPORTABLE_ITEMS.INVOICE) {
        fileName = `invoices_${new Date().toISOString().slice(0, 10)}.xlsx`;
      } else if (sectionName === EXPORTABLE_ITEMS.ORDER) {
        fileName = `Export_List_Detail_${new Date().toISOString().slice(0, 10)}.xlsx`;
      } else if (sectionName === EXPORTABLE_ITEMS.ORDER_SSV) {
        fileName = `Export_SS_Detail_${new Date().toISOString().slice(0, 10)}.xlsx`;
      }

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log('Excel file is ready for download: invoices.xlsx');

    } else {
      console.log('No order data available to export.');
    }


  } catch (error) {

    console.error('Error exporting to Excel:', error);

  }

};

export default ExportToExcel;