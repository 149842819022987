import {
  Delete,
  Preview,
  FileUploadSharp,
  FileDownloadSharp,
  UploadFile,
  DriveFolderUpload,
} from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled, Typography, useTheme } from "@mui/material";
import {
  avi,
  csv,
  doc,
  docx,
  flv,
  gif,
  html,
  jpeg,
  mp3,
  mp4,
  mpg,
  ogv,
  pdf,
  png,
  rtf,
  txt,
  webm,
  wma,
  xls,
  xlsx,
  zip,
  ts,
  mkv,
  mov,
  wmv,
  video3gp,
  video3g2,
  m4v,
  asf,
  f4v,
  rm,
  rmvb,
  mxf,
  vob,
  dv,
  amv,
} from "../../constants/applicationConstants";

import { Controller, Control } from "react-hook-form";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { SingleAlertInfo, setSingleAlertObj } from "../../redux/slice/commonSlice";
import OptionsPopup from "./OptionsPopup";
import { tokens } from "../../theme";

interface FileSizeLimit {
  size: number;
  unit: 'KB' | 'MB' | 'GB';
}

export interface ExtendedFile extends File {
  documentId?: string;
}


interface DropzoneProps {
  name: string;
  onFileChange: (uploadedFiles: ExtendedFile[]) => any;
  label?: string;
  subLabel?: {textColor : string, text : JSX.Element};
  allowedFileTypes?: { [mimeType: string]: string[] };
  maxFileSize?: number | FileSizeLimit; 
  maxFileCount?: number;
  onFileChangeTest?: (uploadedFiles: ExtendedFile[], name: string) => any;
  initialFiles?: ExtendedFile[];
  control?: Control<any, any>;
  rules?: {
      required: string;
    };
  isDisabled?: boolean;
  onDeleteFile?: (documentId: any) => any;
  holdOnChange?: boolean;
  showUploadedFiles?:boolean;
}
/**
 * allowedFileTypes - Used for custom file types. For single file type, allowedFileTypes = {png}. For multiple file types, allowedFileTypes = {{...png, ...jpeg}}.
 * 
 * maxFileSize - Used to set the maximum upload file size in bytes. If not provided, no limit will be applied. When limiting the file size, maxFileSize={{size: 1, unit: 'MB'}}.
 * 
 * maxFileCount - Used to set the maximum number of files can be uploaded. For a single file upload, maxFileCount = {1}.
 * 
 * */

const StyledPreview = styled(Preview)(({ theme }) => ({
  transition: "border-color 0.24s ease-in-out, color 0.24s ease-in-out",
  color: "#bdbdbd",
  "&:hover": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
}));

const StyledFileUploadSharp = styled(FileUploadSharp)(({ theme }) => ({
  transition: "border-color 0.24s ease-in-out, color 0.24s ease-in-out",
  color: "#bdbdbd",
  "&:hover": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
}));

const StyledDelete = styled(Delete)(({ theme }) => ({
  transition: "border-color 0.24s ease-in-out, color 0.24s ease-in-out",
  color: "#bdbdbd",
  "&:hover": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.error.main
        : theme.palette.error.main,
  },
}));

const StyledFileDownloadSharp = styled(FileDownloadSharp)(({ theme }) => ({
  transition: "border-color 0.24s ease-in-out, color 0.24s ease-in-out",
  color: "#bdbdbd",
  "&:hover": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
}));

const DropzoneArea = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderWidth: "2px",
  borderRadius: "10px",
  borderColor: theme.palette.mode === "dark" ? "#bdbdbd" : "#bdbdbd",
  borderStyle: "dashed",
  backgroundColor: "transparent",
  color: "#bdbdbd",
  transition: "border-color 0.24s ease-in-out, color 0.24s ease-in-out",
  "&:hover": {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
}));

const Dropzone: React.FC<DropzoneProps> = ({
  name,
  onFileChange,
  onFileChangeTest,
  label,
  subLabel,
  allowedFileTypes,
  maxFileSize,
  maxFileCount,
  initialFiles,
  control,
  rules,
  isDisabled,
  onDeleteFile,
  holdOnChange = false,
  showUploadedFiles = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isFileDropped, setIsFileDropped] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [fileRejections, setFileRejections] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<ExtendedFile[]>(
    initialFiles || []
  );
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [confirmedAction, setConfirmedAction] = useState<(() => void) | null>(null);
  const DEFAULT_MAX_SIZE = Infinity;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function convertFileSizeToBytes(fileSizeLimit?: number | FileSizeLimit): number {
    if (typeof fileSizeLimit === 'number') {
      return fileSizeLimit;
    } else if (fileSizeLimit) {
      const { size, unit } = fileSizeLimit;
      const unitConversions = {
        KB: 1024,
        MB: 1024 * 1024,
        GB: 1024 * 1024 * 1024,
      };
      return size * unitConversions[unit];
    } else {
      return DEFAULT_MAX_SIZE;
    }
  };

  useEffect(() => {
    if (initialFiles) {
      setUploadedFiles(initialFiles);
    }
  }, [initialFiles]);

  const updateFilesInState = (newFiles: ExtendedFile[]) => {
    setUploadedFiles(newFiles);
  };

  const isFileSizeValid = (file: ExtendedFile) => {
    if (!maxFileSize) return true;
    const maxFileSizeInBytes = convertFileSizeToBytes(maxFileSize);
    return file.size <= maxFileSizeInBytes;
  };

  const onDragEnter = () => {
    setIsFileDropped(false);
  };

  const onDragOver = () => {
    setIsFileDropped(false);
  };

  const onDragLeave = () => {
    setIsFileDropped(false);
  };

  const resetConfirmationState = () => {
    setIsConfirmationOpen(false);
    setConfirmationMessage("");
    setConfirmedAction(null);
    
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    
  };
  

  const handleRenameUpload = (file: ExtendedFile, fileName: string, fileExtension: string) => {
    let newFile = file;
    let counter = 1;
    while (uploadedFiles.some((uploadedFile) => uploadedFile.name === newFile.name)) {
      newFile = new File([file], `${fileName}(${counter})${fileExtension}`, {
        type: file.type,
      }) as ExtendedFile;
      counter++;
    }
    
    setUploadedFiles((prevFiles) => {
      let newFiles = [...prevFiles, newFile];
      onFileChange(newFiles);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      onFileChangeTest && onFileChangeTest(newFiles, name);
      return newFiles;
    });
  
    setIsConfirmationOpen(false);
  };

  const onDrop = async (acceptedFiles: ExtendedFile[], fileRejections: any) => {
    try {
      console.log(fileRejections);
      setIsDragActive(false);
      setIsFileDropped(true);
  
      const validFiles = acceptedFiles.filter(isFileSizeValid);
      const spaceAvailable = maxFileCount
        ? maxFileCount - uploadedFiles.length
        : validFiles.length;
      const filesToAdd = validFiles.slice(0, spaceAvailable);
      
      const renamedFiles: any = [];

      for (let file of filesToAdd) {
        let newFile = file;
        let fileName = file.name;
        let fileExtension = '';
        const dotIndex = fileName.lastIndexOf('.');
        
        if (dotIndex !== -1) {
          fileExtension = fileName.slice(dotIndex);
          fileName = fileName.slice(0, dotIndex);
        }
    
        while (uploadedFiles.some((uploadedFile) => uploadedFile.name === newFile.name)) {
          setConfirmationMessage(`File "${newFile.name}" already exists. Do you want to rename and upload?`);
          setConfirmedAction(() => () => {
            handleRenameUpload(file, fileName, fileExtension);
          });
          setIsConfirmationOpen(true);
          return;
        }
        renamedFiles.push(newFile);
      }
  
      if (renamedFiles.length > 0) {
        setUploadedFiles((prevFiles) => {
          let newFiles = [...prevFiles, ...renamedFiles];
          onFileChange(newFiles);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          onFileChangeTest && onFileChangeTest(newFiles, name);
          return newFiles;
        });
      }
  
      if (fileRejections.length > 0) {
        fileRejections.forEach((rejection: any) => {
          const file = rejection.file;
          const fileName = file.name;
          const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
          let errorObj: SingleAlertInfo = {
              message: '',
              alertType: 'error'
          };
          if (rejection.errors.some((e: any) => e.code === 'file-too-large')) {
            errorObj.message = `Invalid file size. Maximum size allowed is ${
              convertFileSizeToBytes(maxFileSize) / (1024 * 1024)
            } MB.`;
          } else if (rejection.errors.some((e: any) => e.code === 'file-invalid-type')) {
            if (allowedFileTypes) {
                errorObj.message = `Invalid file type "${fileExtension}". Allowed types are ${Object.keys(
                  allowedFileTypes
                ).map(key => allowedFileTypes[key].map(ext => ext)).flat().join(", ")}.`;
            } else {
                errorObj.message = `Invalid file type "${fileExtension}".`;
            }
        } else {
            errorObj.message = "File could not be uploaded due to restrictions.";
        }
          dispatch(setSingleAlertObj(errorObj));
        });
      }
    } catch (error) {
      console.error("Error handling the file drop:", error);
      setIsDragActive(false);
      setIsFileDropped(false);
    }
  };
  
  const deleteFile = (index: number, docId: any) => {
    const uploadedFileElement = document.querySelector(`.uploaded-file[data-document-id="${docId}"]`);
    const documentId = uploadedFileElement?.getAttribute("data-document-id");
    onDeleteFile && onDeleteFile(documentId);
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
    if (onFileChangeTest) onFileChangeTest(newFiles, name);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if(!holdOnChange){
      onFileChange(newFiles);
    }
  };

  const maxSizeInBytes = useMemo(() => {
    return typeof maxFileSize === 'number' ? maxFileSize : convertFileSizeToBytes(maxFileSize);
  }, [maxFileSize]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      onDrop(acceptedFiles, fileRejections),
    accept: allowedFileTypes || {
      ...pdf,
      ...doc,
      ...docx,
      ...xls,
      ...xlsx,
      ...zip,
      ...csv,
      ...txt,
      ...rtf,
      ...html,
      ...mp3,
      ...wma,
      ...mpg,
      ...flv,
      ...avi,
      ...mp4,
      ...ogv,
      ...ts,
      ...webm,
      ...mkv,
      ...mov,
      ...wmv,
      ...video3gp,
      ...video3g2,
      ...m4v,
      ...asf,
      ...f4v,
      ...rm,
      ...rmvb,
      ...mxf,
      ...vob,
      ...dv,
      ...amv,
      ...jpeg,
      ...png,
      ...gif,
    },
    maxSize: maxSizeInBytes,
    onDragEnter,
    onDragOver,
    onDragLeave,
    noClick: true,
  });

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const renderUploadedFiles = () => {
    return uploadedFiles.map((file, index) => {
      const trimmedFileName =
        file.name.length > 30 ? file.name.substring(0, 27) + "..." : file.name;
      const fileSizeDisplay =
        file.size < 1024 * 1024
          ? `${(file.size / 1024).toFixed(2)} KB`
          : `${(file.size / 1024 / 1024).toFixed(2)} MB`;
      const fileUrl = URL.createObjectURL(file);

      const downloadFile = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.download = file.name; // Here you can also customize the file name for download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      return (
        <div key={index} className="uploaded-file" data-document-id={file.documentId}>
          <span>
            {trimmedFileName} - {fileSizeDisplay}
          </span>
          <div>
            <StyledFileDownloadSharp
              className="dz-download-file"
              onClick={downloadFile}
            />
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <StyledPreview className="dz-view-file" />
            </a>
            {!isDisabled ?
              <StyledDelete
                className="dz-delete-file"
                onClick={(e) => {
                  e.preventDefault();
                  URL.revokeObjectURL(fileUrl);
                  deleteFile(index, file.documentId);
                }}
              /> : null
            }
          </div>
        </div>
      );
    });
  };

  useEffect(() => {}, [isDragActive, fileRejections]);
  useEffect(() => {
      if (showUploadedFiles) {
        uploadedFiles.map((file, index)=>{
          deleteFile(index, file.documentId);
        });
      }
    }, [showUploadedFiles]);

  return (
    <>
    <div>
      {label && <div style={{ textAlign: "left",marginBottom : "5px" }}>{label} <span style={{color : subLabel?.textColor}}>{subLabel?.text}</span></div>}
      <Controller
        name={name}
        control={control}
        rules={rules ? rules : {}}
        defaultValue={initialFiles || ""}
        render={({ field, fieldState: { error } }) => (
          <>
      <DropzoneArea
        {...getRootProps()}
        className={isFileDropped ? "file-dropped" : ""}
        style={{ borderColor: error ? '#F44336' : isDisabled ? "#5C6166" : "#bdbdbd" }}
        onClick={openFileDialog}
        sx={{backgroundColor : theme.palette.mode === "dark" ? "#2a2a2a": "#f7f5fc", cursor : isDisabled? "not-allowed":'pointer'}}
      >
        <input
          {...getInputProps()}
          ref={fileInputRef}
          style={{ display: "none" }}
          name={name}
          disabled={isDisabled}
        />
        {/* <p id="dropzone-text1">
          Drag & drop a file here, <br /> or
        </p> */}
        <DriveFolderUpload
          sx={{ fontSize: 35, mt:1 }}
          className="upload-icon"
        />
        <Typography id="dropzone-text2" mt={3} sx={{color : colors.info[300], fontWeight :'bold'}}> Click to Upload File or Capture Image</Typography>
      </DropzoneArea>
          {error && (
            <p style={{ color: '#F44336', fontSize: '12px', marginBottom: '-3px'  }}>
              {rules ? rules.required : ""}
            </p>
          )}

      <div>{uploadedFiles.length > 0 && renderUploadedFiles()}</div>
      </>
        )}
      />
    </div>
    <OptionsPopup
    open={isConfirmationOpen}
    onClose={() => resetConfirmationState()}
    variant="confirm"
    message={confirmationMessage}
    buttons={[
      { name: "Confirm", color: 'primary', onClick: () => confirmedAction && confirmedAction() },
      { name: "Cancel", color: 'secondary', onClick: () => resetConfirmationState() }
    ]}
  />
  </>
  );
};

export default Dropzone;
