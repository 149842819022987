import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomTable from "../../common/CustomTable";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { Cancel, CloudDownloadOutlined } from "@mui/icons-material";
import { AgyPayout, invoiceInfo, RecordPayoutOverrideInfo } from "../../../redux/slice/invoice/InvoiceSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { priceFormatter } from "../../../utils/Utility";
import CustomDatePicker from "../../common/CustomDatePicker";
import dayjs from "dayjs";
import { RecordPayoutInfo, savePayoutInfo } from "../../../redux/slice/payment/paymentSlice";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import CustomDateRange from "../../common/CustomDateRange";
import { DateObject } from "react-multi-date-picker";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  invoice: string;
  date: string;
  invAmount: string;
  paid: string;
  v3Comm: string;
  ovrAgencyComm: string;
  invStatus: string;
  v3PaidAmount: string;
  ovrAgencyPaidAmmount: string;
  remainingAmount: string;
}

const columns: Column[] = [
  { id: "invoice", label: "INV #", minWidth: 100 },
  { id: "date", label: "INV Date", minWidth: 100 },
  { id: "invAmount", label: "INV Amount", minWidth: 100 },
  { id: "paid", label: "INV Paid Amt", minWidth: 100 },
  { id: "invStatus", label: "Status", minWidth: 100 },
  { id: "v3Comm", label: "V3 Comm", minWidth: 100 },
  { id: "v3PaidAmount", label: "V3 Pay Status", minWidth: 100 },
  { id: "ovrAgencyComm", label: "Override Agency Comm", minWidth: 100 },
  { id: "ovrAgencyPaidAmmount", label: "Override Agency Pay Status", minWidth: 100 },
  { id: "remainingAmount", label: "Remaining Amt", minWidth: 100 },
];

const RecordPayoutOverride = () => {
  const { control, handleSubmit, setValue, getValues } = useForm<RecordPayoutInfo>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [navigatedInvList, setNavigateInvList] = useState<invoiceInfo[]>();
  const [overRideAgencyName, setOverrideAgencyName] = useState<string>("");
  const [overrideAgencyId, setOverrideAgencyId] = useState<number>(0);
  const [fullCommAmount, setFullCommAmount] = useState(0);
  const [v3CommissionReceivedAmount, setV3CommissionReceivedAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);


  useEffect(() => {
    const invList = location.state.selectedInvoices;
    setNavigateInvList(invList);

    if (invList && invList.length > 0) {
      setOverrideAgencyName(invList[0].overrideAgencyName)
      setOverrideAgencyId(invList[0].overrideAgencyId);
    }
  }, [location.state]);

  let totalRemainingAmount = 0
  const remainingOvrAgencyAmountCalculation = ( invoiceDetails: any ,navigatedInvList:any)=>{
    if(invoiceDetails && invoiceDetails?.ovrAgencyCommissionRate){
      const invPaidAmount =invoiceDetails?.v3InvoicePaidAmount;
      const ovrAgencyCommissionRate = (invoiceDetails?.ovrAgencyCommissionRate)/100;
      const overAgencyPayStatus = (invoiceDetails?.ovrAgencyCommissionPaidAmount);
      const data = (invPaidAmount * ovrAgencyCommissionRate) - overAgencyPayStatus;
      if(navigatedInvList?.length > 1){
        totalRemainingAmount += data;
        setRemainingAmount(totalRemainingAmount)
        return data;
      }else{
        setRemainingAmount(data);
        return data;
      }
    }else{
      return 0;
    }

  }
  const rows: Row[] = useMemo(() => {
    const invList: Row[] = [];
    let totalCommAmount = 0;
    let v3CommissionAmount = 0;
    navigatedInvList?.forEach(invoice => {
      const calculatedRemainingAmount = remainingOvrAgencyAmountCalculation(invoice ,navigatedInvList);
      totalCommAmount = totalCommAmount + Number(invoice.ovrAgencyCommissionAmount);
      v3CommissionAmount = v3CommissionAmount + Number(invoice.v3CommissionAmount);
      invList.push({
        invoice: invoice.mfgInvoiceNumber,
        date: invoice.mfgInvoiceDate,
        invAmount: priceFormatter(Number(invoice.invoiceAmount)),
        paid: priceFormatter(Number(invoice.v3InvoicePaidAmount)),
        invStatus: invoice.invoiceStatus,
        v3Comm: priceFormatter(Number(invoice.v3CommissionAmount)),
        v3PaidAmount: priceFormatter(Number(invoice.v3CommissionPaidAmount)),
        ovrAgencyComm: priceFormatter(Number(invoice.ovrAgencyCommissionAmount)),
        ovrAgencyPaidAmmount: priceFormatter(Number(invoice.ovrAgencyCommissionPaidAmount)),
        remainingAmount:priceFormatter(Number(calculatedRemainingAmount)) 

      })
    });
    setFullCommAmount(totalCommAmount);
    setV3CommissionReceivedAmount(v3CommissionAmount);
    return invList;
  }, [navigatedInvList]);

  const setInvoiceIds = (): number[] => {
    if (navigatedInvList) {
      return navigatedInvList.map(invoice => invoice.invoiceId);
    }
    return [];
  };

  const onSubmit = async (data: RecordPayoutInfo) => {
    data.invoiceIds = setInvoiceIds();
    data.commissionAgencyType = "OVERRIDE";
    data.agencyId = overrideAgencyId;
    data.v3CommissionReceivedAmount = v3CommissionReceivedAmount;

    const response = await dispatch(savePayoutInfo(data));
    if (
      response.payload &&
      response.payload.message === "SUCCESS"
    ) {
      const alertObj: SingleAlertInfo = {
        message: "Override agency payments recorded successfully!",
        alertType: "success",
      };
      // CS-346 changes for exporting the excel file after final submission of agency payout
      if (response?.payload &&
        response?.payload?.data) {
        const base64Data = response?.payload?.data?.base64String;
        const FileName = response?.payload?.data?.fileName;
        const FileType = response?.payload?.data?.fileType;

        // Convert base64 to binary
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byte array
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${FileName}.${FileType}`; // Specify the file name

        // Append to the body and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } else {
        console.log("No excel data available!")
      }
      //
      dispatch(setSingleAlertObj(alertObj));
      navigate(-1)
    }
    console.log(data)
  };
  const handlePayoutDate = (date: Date | null) => {
    setValue("payoutDate", dayjs(date).format("MM/DD/YYYY"));
  };
  // CS-346
  const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;
    setValue("fromDate", fromDateString ? fromDateString : "");
    setValue("toDate", toDateString ? toDateString : "");
  };
  //

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header title="Record Payout" subtitle="Record Override Agency Payout Management" />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Box sx={{ marginBottom: 2 }}>
              <Typography variant="h4">
                <strong>Override Agency:</strong> {overRideAgencyName}
              </Typography>
            </Box>

            <Box>
              <CustomTable
                columns={columns}
                rows={rows}
                disablePagination={true}
                borderBox={true}
              />
            </Box>

            <Box sx={{ textAlign: "right", marginTop: 2 }}>
              <Typography variant="body1" fontWeight="bold">
              {priceFormatter(remainingAmount)}
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              pt={1}
            >
              <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
                <Typography variant="body1">
                  <strong>Payment:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomCurrencyField
                  control={control}
                  controllerName="payment"
                  label="Payment Amount"
                  rules={{
                    required: "Payment Amount is required."
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              pt={1}
            >
              <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
                <Typography variant="body1">
                  <strong>Payout Date:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomDatePicker
                  control={control}
                  onChange={handlePayoutDate}
                  controllerName="payoutDate"
                  label="Payout Date"
                  rules={{ required: "Payout Date is required." }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              pt={1}
            >
              <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
                <Typography variant="body1">
                  <strong>Payout Comment:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  control={control}
                  controllerName="payoutComment"
                  label="Payout Comment"
                  aria-readonly
                />
              </Grid>
            </Grid>
            {/* CS:346 -> Added Expected Pay Date Range In RecordPayout   */}
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              pt={1}
            >
              <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
                <Typography variant="body1">
                  <strong>Expected Pay Date:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomDateRange
                  label="Expected Pay Date Range"
                  control={control}
                  controllerName="dateRange"
                  onChange={handleDateRangeChange}
                />
              </Grid>
            </Grid>
            {/*  */}
            <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
              <Grid item xs={12} md="auto">
                <Button
                  fullWidth
                  color="warning"
                  type="submit"
                  startIcon={<CloudDownloadOutlined />}
                >
                  Record and Download Payout
                </Button>
              </Grid>
              <Grid item xs={12} md="auto" ml={1}>
                <Button
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </form>
      </Box>
    </Box>
  );
};

export default RecordPayoutOverride;
