import * as React from 'react';
import { TextField, MenuItem, Select, InputLabel, FormControl, useTheme, Autocomplete, FormHelperText } from '@mui/material';
import { Control, Controller, FieldValues, Noop, RefCallBack, UseFormSetValue } from 'react-hook-form';
import { tokens } from '../../theme';

export type DropdownFieldProps = {
  options: { value: string | number; label: string }[];
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string;
  variant?: "filled" | "outlined" | "standard";
  disableSearch?: boolean;
  name?: string;
  onChangeSelect?: (e: any, value: any) => any;  
  control?: Control<any, any>
  // defaultValue?: string | number | boolean
  rules?: object;
  disabled?: boolean;
  showErrorMinLength?: number;
  setValues?: UseFormSetValue<FieldValues>;
  enableBlurHandler?: boolean;
  enableKeyDownHandler?: boolean;
  sortOptions?: boolean;
}; 

const CustomDropdownField: React.FC<DropdownFieldProps> = ({
  options,
  size = "medium",
  color,
  label,
  variant = "outlined",
  disableSearch  = false, 
  name,
  control,
  onChangeSelect,
  // defaultValue,
  rules,
  disabled = false,
  showErrorMinLength,
  setValues,
  enableBlurHandler = false,
  enableKeyDownHandler = false,
  sortOptions = true,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sortedOptions = sortOptions
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options;
  
  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };

  const setValue = (value: any) => {
    let fieldValue = sortedOptions.find((option) => {
      if (option.value.toString() === value?.toString())
        return value.toString();
    });
    return fieldValue ? fieldValue.value?.toString() : null;
  }

  const handleSelectChange = (
    event: any,
    field: {
      onChange: any;
      onBlur?: Noop;
      value?: any;
      disabled?: boolean | undefined;
      name?: string;
      ref?: RefCallBack;
    },
    option: string | null
  ) => {
    const matchingOption = sortedOptions.find((o) => o.label === option);
    const value = matchingOption ? matchingOption.value : "";
    field.onChange(value);
    if (onChangeSelect) {
      onChangeSelect(matchingOption, matchingOption?.value);
    }
  };

  // const handleBlurEvent = (
  //   event: any,
  //   field: {
  //     onChange: any;
  //     onBlur?: Noop;
  //     value?: any;
  //     disabled?: boolean | undefined;
  //     name?: string;
  //     ref?: RefCallBack;
  //   },
  //   option: string | null
  // ) => {
  //   if (option && options) {
  //     const matchingOption = options.find((o) => o.label === option || (o.label && o.label.toUpperCase() === option?.toUpperCase()));
  //     const value = matchingOption ? matchingOption.value : "";
  //     field.onChange(value);
  //   }
  // };

  const handleKeyDown = (event: React.KeyboardEvent, value: string) => {
    if (enableKeyDownHandler && event.key === 'Enter') {
      const filteredOptions = sortedOptions.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      const firstOption = filteredOptions[0];
      if (firstOption) {
        const value = firstOption.value;
        setValues && setValues(name || '', value);
        if (onChangeSelect) {
          onChangeSelect(null, value);
        }
      }
    }
  };

  const handleBlurEvent = (event: any) => {
    if (enableBlurHandler) {
      const filteredOptions = sortedOptions.filter((option) =>
        option.label.toLowerCase().includes(event.target.value.toLowerCase())
      );
      const firstOption = filteredOptions[0];
      if (firstOption) {
        const value = firstOption.value;
        setValues && setValues(name || "", value);
        if (onChangeSelect) {
          onChangeSelect(null, value);
        }
      }
    }
  };

  if (disableSearch ) {
    return (
      <Controller
        name={name||""}
        control={control}
        // defaultValue={defaultValue}
        rules={rules? rules: {}}
        render={({ field: { ref, ...field }, fieldState }) => (
          <>
          <FormControl fullWidth error={!!fieldState.error} disabled={disabled}>
          <InputLabel color={color || theme.palette.mode === "dark" ? "secondary" : "primary"} shrink={Boolean(field.value)}>{label}</InputLabel>
            <Select
              fullWidth
              {...field}
              label={getLabel() || ""}
              color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
              style={{ maxHeight: 200 }}
              name={name}
              value={setValue(field.value)}
              onChange={(e) => { field.onChange(e.target.value); onChangeSelect && onChangeSelect(e, e.target.value); }}              
              onBlur={(e) => { field.onBlur(); handleBlurEvent(e) }}
              onKeyDown={(e) => handleKeyDown(e, field.value)}
              inputProps={{
                shrink: Boolean(field.value),
            }}
              error={!!fieldState.error}
              disabled={disabled}
              {...props}
            >
              {sortedOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {fieldState.error ? ((showErrorMinLength && showErrorMinLength <= (fieldState.error.message ? fieldState.error.message.length : 0)) ? null : fieldState.error.message) : null}
            </FormHelperText>
            </FormControl>
          </>
        )}
      />
    );
  } else {
    return (
      <Controller
        name={name || ""}
        control={control}
        rules={rules? rules: {}}
        render={({ field: { ref, ...field }, fieldState }) => (
          <>
            <Autocomplete
              options={sortedOptions.map(option => option.label)}
              value={sortedOptions.find(option => option.value === field.value)?.label || ''}
              onChange={(event, newValue) => handleSelectChange(event, field, newValue)}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  label={getLabel() || ""}
                  
                  onBlur={handleBlurEvent}
                  variant={variant}
                  size={size}
                  name={name}
                  color={color || (theme.palette.mode === "dark" ? "secondary" : "primary")}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error ? ((showErrorMinLength && showErrorMinLength <= (fieldState.error.message ? fieldState.error.message.length : 0)) ? null : fieldState.error.message) : null
                  }
                  onKeyDown={(e) => handleKeyDown(e, field.value)}
                />
              )}
            />
          </>
        )}
      />
    );
  }
};

export default CustomDropdownField;