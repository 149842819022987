import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../types/CommonTypes";
import { useAppSelector } from "../redux-hooks";
import { deleteProductItem, fetchAllProducts, fetchProductList, fetchProductsByUserRole, fetchProductsIncludingInactiveForProductSearch, getProductDocumentsByProductId, getProductItemsByProductId, getProductList, getProductListWithLogos, getProductPricing } from "../../../service/ProductService";

export type ProductListInfo = {
    productId : number,
    productName: string,
    hcpcsCode: string,
    inactiveDate: string,
    manufacturer: any,
    productLogo: productLogo,
    productCategory: string
};

interface PaginationProductList {
    content: ProductListInfo[];
    totalElements: number;
    totalPages: number;
    size: number;
  }

export type productLogo = {
    documentId: string,
    documentName: string,
    contentPath: string,
    type: string,
    fileType: string,
    content: string,
}

export type searchParamsInfo = {
    productName: string,
    inactiveDate: string,
}

export type PracticeProductAgreementInfo = {
    productByUserRole: [];
    practiceByUserRole: string;
}

export type ProductPrice = {
    allowableUnitPrice: any,
    macId: string,
    priceType: string,
    productPriceId: string,
    effectiveDate: string,
    inactiveDate: string,
    customPrice: number
}

export type ProductInfo = {
    productId: string;
    productName: string
    productPrices: ProductPrice[]
}

export type ProductItemInfo = {
    productItemId: number;
    sku: string;
    size: string;
    units: string;
    inactiveDate: string;
}

export type ProductItemListInfo = {
    size: number;
    productItemId: number;
    productItems: ProductItemInfo[];
}

export type productDocumentListInfo = {
    entityId: string,
    entityName: string,
    type: string,
    description: string
}

export type ProductListState = {
    productName: string,
    inactiveDate: string,
    productList: ProductListInfo[],
    productPriceList: ProductInfo[],
    productPriceListByUserRole: ProductInfo[],
    manufacturerList: any,
    productItemsInfo: ProductItemListInfo[],
    status: LoadingType,
    fetchProductsByIdStatus: LoadingType,
    productsByUserRole: ProductInfo[],
    productSearchList?: PaginationProductList | null,
};

interface FetchProductItemsParams {
    productId: number;
    onlyActiveRecords: boolean;
}

export type searchParamsProdcutsList = {
    productIds: number[],
    mfgIds: number[],
    status: string[],
    productCategories: string[],
    currentPage: number,
    itemsPerPage: number,
}

const initialState = {
    productName: "",
    inactiveDate: "",
    productList: [],
    manufacturerList: [],
    productPriceList: [],
    productPriceListByUserRole: [],
    practiceProductAgreementInfo:[],
    productDocumentInfo:[],
    productsByUserRole:[],
    productItemsInfo: [],
    status: "idle" ,
    fetchProductsByIdStatus: "idle",
    productSearchList: undefined,
} as ProductListState;

export const getProductsList = createAsyncThunk<
    any,
    { searchParams: searchParamsInfo },
    { rejectValue: string }
>("getProductsList", async (data, { rejectWithValue }) => {
    const { searchParams } = data;
    try {
        const response = await getProductList(searchParams);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while fetching product list")
    }
});

export const getProductListWithLogo = createAsyncThunk("getProductListWithLogo", async () => {
    const response = await getProductListWithLogos();
    let resp = response.data;
    return resp;
})

export const fetchProductPricing = createAsyncThunk("productPricingList", async () => {
    const response = await getProductPricing();
    const v3Response = response.data;
    return v3Response.data;
});

export const deleteProduct = createAsyncThunk<
    any,
    { productId: number },
    { rejectValue: string }
>("getProductList", async (data, { rejectWithValue }) => {
    const { productId } = data;
    try {
        const response = await deleteProductItem(productId);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while fetching product list")
    }
});

export const fetchProductsByUserRoleData = createAsyncThunk("fetchProductsByUserRole", async (includeProductPrice: boolean) => {
    const response = await fetchProductsByUserRole(includeProductPrice);
    const v3Response = response.data
    return v3Response.data;
});

export const fetchProductsPriceWithUserRole = createAsyncThunk("fetchAllProducts", async (includeProductPrice: boolean) => {
    const response = await fetchAllProducts(includeProductPrice);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchProductItemsByProductId = createAsyncThunk("fetchProductItemsByProductId", async ({ productId, onlyActiveRecords }: FetchProductItemsParams) => {
    const response = await getProductItemsByProductId(productId, onlyActiveRecords);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchProductsList = createAsyncThunk<
    any,
    { searchParams: searchParamsProdcutsList },
    { rejectValue: string }
>("fetchProductsList", async (data, { rejectWithValue }) => {
    const { searchParams } = data;
    try {
        const response = await fetchProductList(searchParams);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while fetching product list")
    }
});

export const fetchProductsForProductsSearch = createAsyncThunk("fetchProductsForProductsSearch", async (includeProductPrice: boolean) => {
    const response = await fetchProductsIncludingInactiveForProductSearch(includeProductPrice);
    const v3Response = response.data
    return v3Response.data;
});

const productListSlice = createSlice({
    name: "productList",
    initialState,
    reducers: {
        resetProductItems : (state) =>{
            state.productItemsInfo = []
        },
        resetProductsByUserRole : (state) =>{
            state.productsByUserRole = []
            state.fetchProductsByIdStatus = "idle"
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductsList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getProductsList.fulfilled, (state, action) => {
                state.status = "success";
                console.log(action.payload)
                state.productList = action.payload?.data;
            })
            .addCase(getProductsList.rejected, (state, action) => {
                state.status = 'error';
            })
            .addCase(getProductListWithLogo.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getProductListWithLogo.fulfilled, (state, action) => {
                state.status = "success";
                console.log(action.payload)
                state.productList = action.payload?.data;
            })
            .addCase(getProductListWithLogo.rejected, (state, action) => {
                state.status = 'error';
            })
            .addCase(deleteProduct.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.status = "success";
                state.productList = action.payload;
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.status = 'error';
            })
            .addCase(fetchProductsByUserRoleData.pending, (state) => {
                state.fetchProductsByIdStatus = "loading";
            })
            .addCase(fetchProductsByUserRoleData.fulfilled, (state, action) => {
                state.fetchProductsByIdStatus = "success";
                state.productsByUserRole = action.payload;
            })
            .addCase(fetchProductsByUserRoleData.rejected, (state, action) => {
                state.fetchProductsByIdStatus = 'error';
            })
            .addCase(fetchProductPricing.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductPricing.fulfilled, (state, action) => {
                state.status = "success";
                state.productPriceList = action.payload;
            })
            .addCase(fetchProductPricing.rejected, (state) => {
                state.status = "error";
            })
            .addCase(fetchProductsPriceWithUserRole.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductsPriceWithUserRole.fulfilled, (state, action) => {
                state.status = "success";
                state.productPriceListByUserRole = action.payload;
            })
            .addCase(fetchProductsPriceWithUserRole.rejected, (state) => {
                state.status = "error";
            }) 
            .addCase(fetchProductItemsByProductId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductItemsByProductId.fulfilled, (state, action) => {
                state.status = "success";
                state.productItemsInfo = action.payload;
            })
            .addCase(fetchProductItemsByProductId.rejected, (state) => {
                state.status = "error";
            })
            .addCase(fetchProductsList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProductsList.fulfilled, (state, action) => {
                state.status = "success";
                state.productSearchList = action.payload.data;
            })
            .addCase(fetchProductsList.rejected, (state) => {
                state.status = "error";
            })
            .addCase(fetchProductsForProductsSearch.pending, (state) => {
                state.fetchProductsByIdStatus = "loading";
            })
            .addCase(fetchProductsForProductsSearch.fulfilled, (state, action) => {
                state.fetchProductsByIdStatus = "success";
                state.productsByUserRole = action.payload;
            })
            .addCase(fetchProductsForProductsSearch.rejected, (state, action) => {
                state.fetchProductsByIdStatus = 'error';
            });
    },
  });

export const { resetProductItems, resetProductsByUserRole } = productListSlice.actions;

export const useProductListReducer = () => useAppSelector((state) => state.productListSlice);
export default productListSlice.reducer;