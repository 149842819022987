import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  useTheme,
  IconButton,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Footer from "../../common/Footer";
import { ColorModeContext, tokens } from "../../../theme";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CloudDownload,
  DarkModeOutlined,
  LightModeOutlined,
} from "@mui/icons-material";
import Card from "../../common/Card";
import Button from "../../common/Button";
import ManufacturerNotificationIVRDownload from "./ManufacturerNotificationIVRDownload";
import { downloadDocumentById, useValidateManufacturerCredentials, validateEmailLink } from "../../../redux/slice/manufacturerNotifications/ManufacturerNotificationSlice";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import { SearchIVRListRequest } from "../../../redux/slice/ivr/createIVRSlice";
import { SearchOrder } from "../../../redux/slice/order/OrderSlice";
import { decryptAES } from "../../../utils/EncryptorUtils";

const ManufactureNotificationAccess: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { documentInfo, isLinkExpired } = useValidateManufacturerCredentials();
  const [showPopup, setShowPopup] = useState(false);
  const [ivrNumber, setIvrNumber] = useState("");
  const { isValid, userInfo } = useAuthSlice();
  const [email, setEmail] = useState("");
  const [ivrDocId, setIvrDocId] = useState("");
  const [orderDocId, setorderDocId] = useState("");
  const [orderNumber, setorderNumber] = useState("");
  const [tissueTrackingDocId, setTissueTrackingDocId] = useState("");
  const [productReturnDocId, setProductReturnDocId] = useState("");
  const [validateToken, setValidateToken] = useState("");
  const [fileName, setFileName] = useState("");

  const ITEMS_PER_PAGE = 20;

  const benefits = [
    { title: "Access all V3 IVRs & orders to date" },
    { title: "Streamline your order fulfillment" },
    { title: "Organize all your forms in one place" },
    { title: "Export Data features for reconciliation" },
    { title: "Reduce the time it takes to locate documents or forms by accessing it all in one central location" },
  ];

  const features = [
    { title: "View, Download, Review & Respond to IVRs" },
    { title: "View, Download, Review & Fulfill Orders" },
    { title: "View & Manage Return requests" },
    { title: "Manage & Execute Customer Agreements (MFR Admin)" },
    { title: "View Tissue Tracking Logs" },
    { title: "View and Manage Invoice Records (MFR Admin)" },
  ];

  useEffect(() => {
    // Parse the URL parameters
    const params = new URLSearchParams(location.search);
    const encryptedIvrNumber = params.get('ivrNumber') || null;
    const encryptedIvrDocId = params.get('ivrDocId') || null;
    const encryptedOrderDocId = params.get('orderDocId') || null;
    const encryptedOrderNumber = params.get('orderNumber') || null;
    const encryptedTissueTrackingDocId = params.get('tissueTrackingDocId') || null;
    const encryptedProductReturnDocId = params.get('productReturnDocId') || null;
    const encryptedEmail = params.get('email') || null;
    const iv = params.get('iv');
    const salt = params.get('salt');
    setValidateToken(params.get('token') || "")

    // Decrypt the parameters only if both are non-null and non-empty
    if (encryptedIvrNumber != null && encryptedIvrNumber !== '') {
      setIvrNumber(decryptAES(encryptedIvrNumber, iv, salt));
    }
    if (encryptedIvrDocId != null && encryptedIvrDocId !== '') {
      setIvrDocId(decryptAES(encryptedIvrDocId, iv, salt));
    }
    if (encryptedOrderDocId != null && encryptedOrderDocId !== '') {
      setorderDocId(decryptAES(encryptedOrderDocId, iv, salt));
    }
    if (encryptedOrderNumber != null && encryptedOrderNumber !== '') {
      setorderNumber(decryptAES(encryptedOrderNumber, iv, salt));
    }
    if (encryptedTissueTrackingDocId != null && encryptedTissueTrackingDocId !== '') {
      setTissueTrackingDocId(decryptAES(encryptedTissueTrackingDocId, iv, salt));
    }
    if (encryptedProductReturnDocId != null && encryptedProductReturnDocId !== '') {
      setProductReturnDocId(decryptAES(encryptedProductReturnDocId, iv, salt));
    }
    if (encryptedEmail != null && encryptedEmail !== '') {
      setEmail(decryptAES(encryptedEmail, iv, salt));
    }
  }, [location.search]);

  useEffect(() => {
    const validateAndNavigate = async () => {
      const res = await dispatch(validateEmailLink(validateToken));
    };
    if (validateToken !== null && validateToken !== "") {
      validateAndNavigate();
    }
  }, [validateToken]);

  useEffect(() => {
    if (isLinkExpired) {
      navigate("/signIn");
    }
  }, [isLinkExpired])

  useEffect(() => {
    let searchCriteriaOrder: SearchOrder = {
      practiceIds: [],
      manufacturerIds: [],
      patientName: "",
      productIds: [],
      providerName: "",
      orderNumber: orderNumber,
      invoiceNumber: "",
      fromDate: "",
      toDate: "",
      status: [],
      invoiceStatus: [],
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
      downloadState: false,
      fromServiceDate: '',
      toServiceDate: ''
    };
    let searchCriteriaIvr: SearchIVRListRequest = {
      productId: [],
      practiceId: [],
      patientFirstName: '',
      patientLastName: '',
      verificationStatus: [],
      ivrNumber: ivrNumber,
      fromDate: "",
      toDate: "",
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE
    };
    if (isValid && userInfo.userName === email && ivrDocId != "" && ivrNumber != "") {
      navigate("/ivr_list", { state: { ivrDocId: ivrDocId, ivrNumber: ivrNumber, ivrListSearchCriteria: searchCriteriaIvr } })
    } else if (isValid && userInfo.userName === email && orderDocId != "" && orderNumber != "") {
      navigate("/order_list", { state: { orderDocId: orderDocId, orderNumber: orderNumber, orderListSearchCriteria: searchCriteriaOrder } });
    } else if (isValid && userInfo.userName === email && tissueTrackingDocId != "" && orderNumber != "") {
      navigate("/order_list", { state: { tissueTrackingDocId: tissueTrackingDocId, orderNumber: orderNumber, orderListSearchCriteria: searchCriteriaOrder } });
    } else if (isValid && userInfo.userName === email && productReturnDocId != "" && orderNumber != "") {
      navigate("/order_list", { state: { productReturnDocId: productReturnDocId, orderNumber: orderNumber, orderListSearchCriteria: searchCriteriaOrder } });
    } else {
      setShowPopup(true);
    }
  }, [isValid, ivrDocId, ivrNumber, orderDocId, orderNumber]);

  useEffect(() => {
    if (ivrDocId) {
      setFileName("IVR.pdf");
    } else if (orderDocId) {
      setFileName("Order.pdf");
    } else if (tissueTrackingDocId) {
      setFileName("Tissue Tracking.pdf");
    } else if (productReturnDocId)
      setFileName("Product Return.pdf");
  }, [ivrDocId, orderDocId, tissueTrackingDocId, productReturnDocId]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const downloadDocument = async () => {
    const actionResult = await dispatch(downloadDocumentById(parseInt(ivrDocId || orderDocId || tissueTrackingDocId || productReturnDocId)));
    const documentData = actionResult.payload;

    if (documentData?.content && documentData?.fileType === 'pdf') {
      const byteCharacters = atob(documentData.content);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = documentData.documentName || 'download.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the Blob URL to free up memory
      URL.revokeObjectURL(blobUrl);
    }
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            borderBottom: 1,
            zIndex: 1100,
            borderColor:
              theme.palette.mode === "dark" ? "primary.dark" : "secondary.main",
          }}
        >
          <Grid
            container
            spacing={2}
            p={2}
            display="flex"
            style={{ backgroundColor: colors.grey[800] }}
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={require("../../../assets/V3_logo-gradient.png")}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <LightModeOutlined />
                ) : (
                  <DarkModeOutlined />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh", pt: { xs: 10, md: 0 } }}
        >
          <Container
            component="main"
            maxWidth="xl"
            style={{ paddingTop: "40px", paddingBottom: "50px" }}
          >
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={5} xs={12}>
                <Box
                  sx={{
                    padding: "2rem",
                    border: "1px solid",
                    borderColor: colors.grey[600],
                    borderRadius: "12px",
                    backgroundColor: colors.grey[800],
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={require("../../../assets/download_title.png")}
                      alt="Excel Icon"
                      style={{ width: 35, height: 35, marginRight: "0.5rem" }}
                    />
                    Download {ivrDocId ? `IVR (${ivrNumber})` : orderDocId ? `Order (${orderNumber})` : tissueTrackingDocId ? `Tissue Tracking` : productReturnDocId ? `Product Return` : ""}
                  </Typography>

                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                    Click the download button to proceed with downloading the
                    PDF
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "1rem",
                      backgroundColor: colors.grey[900],
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 1.5,
                        borderRadius: 2,
                        borderStyle: "dotted",
                        borderColor: colors.grey[600],
                        m: 1,
                      }}
                    >
                      <img
                        src={require("../../../assets/download_main.png")}
                        alt="IVR PDF"
                        style={{ width: 40 }}
                      />
                    </Box>

                    <Box>
                      <Typography variant="h5" fontWeight="bold">
                        {fileName}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: colors.grey[500] }}>
                        Click the button on the right to download your PDF
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      startIcon={<CloudDownload />}
                      color="warning"
                      sx={{ padding: "0.5rem 2rem" }}
                      onClick={downloadDocument}
                    >
                      Download
                    </Button>
                  </Box>
                </Box>
              </Grid>

              
              {/* <Grid item md={7} xs={12} sx={{ display: "flex" }}>
                <Card sx={{ width: "100%", height: "100%" }}>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "1.5rem",
                      }}
                    >
                      Access the Full Platform
                    </Typography>
                    <Typography variant="h6">
                      Boost your productivity by unlocking full access to our
                      platform.
                    </Typography>
                    <Typography variant="h6">
                      Enabling you to streamline & manage all of your business with V3, in one central location.
                    </Typography>
                    <Typography variant="h6" mt={3}>
                      Click 'Sign in Now' to get Started!
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ marginTop: "1.5rem", marginBottom: "1rem", minWidth: "350px" }}
                      onClick={() => navigate("/manufacturerNotificationSignUp", { state: { email: email } })}
                    >
                      Sign In Now
                    </Button>
                  </Box>

                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          padding: 3,
                          border: "1px solid",
                          borderColor: colors.grey[600],
                          borderRadius: "12px",
                          backgroundColor: colors.grey[800],
                          width: "100%",
                          height: "100%", // Make this stretch to full height
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 2,
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              height: "1px",
                              backgroundColor: colors.grey[600],
                              opacity: 0.5,
                            }}
                          />
                          <Box
                            sx={{
                              padding: "2px 10px",
                              backgroundColor: colors.grey[800],
                              borderRadius: "20px",
                              border: 1,
                              borderColor: colors.grey[700],
                            }}
                          >
                            <Typography variant="caption" fontWeight="bold">
                              AVAILABLE FEATURES
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flexGrow: 1,
                              height: "1px",
                              backgroundColor: "grey",
                              opacity: 0.5,
                            }}
                          />
                        </Box>

                        {features.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "self-start",
                              marginBottom: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: colors.grey[700],
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mr: 1,
                                p: 1
                              }}
                            >
                              <CheckCircleIcon
                                sx={{
                                  color: colors.grey[500],
                                }}
                              />
                            </Box>
                            <Typography variant="body1" sx={{ mt: "4px" }}>
                              {item.title}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          padding: 3,
                          border: "1px solid",
                          borderColor: colors.grey[600],
                          borderRadius: "12px",
                          backgroundColor: colors.grey[800],
                          width: "100%",
                          height: "100%", // Make this stretch to full height
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 2,
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              height: "1px",
                              backgroundColor: colors.grey[600],
                              opacity: 0.5,
                            }}
                          />
                          <Box
                            sx={{
                              padding: "2px 10px",
                              backgroundColor: colors.grey[800],
                              borderRadius: "20px",
                              border: 1,
                              borderColor: colors.grey[700],
                            }}
                          >
                            <Typography variant="caption" fontWeight="bold">
                              BENEFITS
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flexGrow: 1,
                              height: "1px",
                              backgroundColor: "grey",
                              opacity: 0.5,
                            }}
                          />
                        </Box>

                        {benefits.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "self-start",
                              marginBottom: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: colors.grey[700],
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mr: 1,
                                p: 1
                              }}
                            >
                              <img
                                src={require("../../../assets/trust.png")}
                                alt="trust"
                                style={{ width: 18 }}
                              />
                            </Box>
                            <Typography variant="body1" sx={{ mt: "4px" }}>
                              {item.title}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid> */}
              
            </Grid>
          </Container>
        </Grid>
        <Box
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, borderTop: 5 }}
        >
          <Footer />
        </Box>
      </Box>

      {showPopup && <ManufacturerNotificationIVRDownload onClose={handleClosePopup} />}
    </>
  );
};

export default ManufactureNotificationAccess;
