import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../common/Button";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useForm } from "react-hook-form";
import { Cancel, FileUploadOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { tokens } from "../../../theme";
import CustomDatePicker from "../../common/CustomDatePicker";
import { convertBase64, sortNames } from "../../../utils/Utility";
import { fetchManufacturersListUserSlice, useUserReducer } from "../../../redux/slice/userSlice";
import { ManufacturerInfo, setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { fetchProductDocumentsByProductId, ProductDocument, updateElementInProductSlice, updateProductObjectValues, uploadProductDocument, useProductReducer } from "../../../redux/slice/product/createProductSlice";
import dayjs from "dayjs";
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { pdf, doc, docx, jpeg, png, xls, xlsx, mov, mp4, avi, amv, asf, dv, f4v, flv, m4v, mkv, mxf, ogv, rm, rmvb, ts, video3gp, vob, webm, wmv, video3g2, PRODUCT_DOCUMENT_TYPES, PRODUCT_DOCUMENT_STATUS } from "../../../constants/applicationConstants";

interface ProductDocumentPopupProps {
  open: boolean;
  onClose: () => void;
}

interface Attachment {
  documentName: string;
  content: string;
  fileType: string;
}

type Option = {
  label: string;
  value: string | number;
}


const ProductDocumentPopup: React.FC<ProductDocumentPopupProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0);
  const { manufacturerInfoListUserSlice } = useUserReducer();
  const { uploadProductDocumentStatus } = useProductReducer();
  const dispatch = useAppDispatch();
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const [productDocumentList, setProductDocumentList] = React.useState<CodeTable[]>([]);
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setValue('manufacturer.manufacturerId', null);
  };

  const { control, handleSubmit, setValue, getValues } = useForm<ProductDocument>({
    defaultValues: {
      attachmentProductDocument: [],
    },
  });

  const statusUpdateDocumentTypes = [
    PRODUCT_DOCUMENT_TYPES.IVR_FORM,
     PRODUCT_DOCUMENT_TYPES.ORDER_FORM, 
     PRODUCT_DOCUMENT_TYPES.SERVICE_AGREEMENT, 
     PRODUCT_DOCUMENT_TYPES.TISSUE_TRACKING_FORM, 
     PRODUCT_DOCUMENT_TYPES.RETURN_FORM
    ];

  const onSubmit = async(data: ProductDocument) => {
    if(statusUpdateDocumentTypes.includes(data?.documentType)) {
      data.status = PRODUCT_DOCUMENT_STATUS.PENDING;
    }
    const response = await dispatch(uploadProductDocument({productDocument: data}));
  };

  const productDocumentOptions = useMemo(() => {
    return productDocumentList.map(item => ({
      label: item.displayValue,
      value: item.keyName
    }));
  }, [productDocumentList]);

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      const sortedDocumentList = [
        ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "ProductDocument"),
      ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      setProductDocumentList(sortedDocumentList);
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);


  useEffect(() => {
    if(uploadProductDocumentStatus === 'success') {
      let successObj: SingleAlertInfo = {
        message: "Product document(s) saved successfully.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      dispatch(updateElementInProductSlice({
        elementName : "uploadProductDocumentStatus",
        value: 'idle'
      }));
      dispatch(fetchProductDocumentsByProductId(getValues('productId')));
      onClose();
    }
  }, [uploadProductDocumentStatus]);

  //mount
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let id = urlSearchParams.get('id');
    if(id) {
      setValue('productId', parseInt(id));
    };
    // Set effectiveDate to today's date
    const today = dayjs().format("MM/DD/YYYY");
    setValue('effectiveDate', today);
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    const responseManufacturers = await dispatch(fetchManufacturersListUserSlice());
    const responseAllCodeTableValues = await dispatch(fetchAllCodeTablesValues());
  }

  const handleEffectiveDate = (date: Date | null) => {
    if (dayjs(date).isValid()) {
        setValue("effectiveDate", dayjs(date).format("MM/DD/YYYY"));
    };
};


  const handleDropzoneChange = async (uploadedFiles: ExtendedFile[]) => {
    const readFileAsBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
    const attachments: Attachment[] = await Promise.all(
      uploadedFiles.map(async (file) => {
        const base64Content = await readFileAsBase64(file);
        return {
          documentName: file.name,
          content: base64Content.split(",")[1],
          fileType: file.type,
        };
      })
    );
    const documentName = attachments[0]?.documentName || "";
    const name = documentName?.substring(0, documentName.lastIndexOf('.')) || documentName; 
    setValue('name', name);
    setValue("attachmentProductDocument", attachments);
  };

  const manufacturersList = useMemo(() => {
    let options: Option[] = [];
    if (manufacturerInfoListUserSlice && manufacturerInfoListUserSlice.length > 0) {
      const sortedInfo: ManufacturerInfo[] = sortNames(manufacturerInfoListUserSlice, 'manufacturerName');
      sortedInfo?.map(option => {
        options.push({ label: option.manufacturerName, value: option.manufacturerId });
      });
    }
    return options;
  }, [manufacturerInfoListUserSlice]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Upload Document
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper sx={{ p: 2, mb: 2, width: "100%" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={require("../../../assets/upload_document.png")}
              alt="Excel Icon"
              style={{ width: 24, height: 24 }}
            />
            <Typography variant="h6" component="div">
              Product Document
            </Typography>
          </Stack>
          <Grid container spacing={2} sx={{ pt: "5px" }}>
            <Grid item md xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                Upload your document here. Supported formats:  PDF, DOC, DOCX, JPEG, PNG, XLS, XLSX, MP4, MOV. Maximum
                file size: 15MB.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            justifyContent: "center",
            border: "1px solid #4CAF50",
            borderRadius: "24px",
            backgroundColor:colors.grey[800],
            padding: "4px",
            mb: 3,
            overflow: "hidden",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              transition: "background-color 0.5s ease", 
              minHeight: "auto",
              "& .MuiTabs-flexContainer": {
                borderRadius: "24px",
                overflow: "hidden",
              },
              "& .Mui-selected": {
                backgroundColor: colors.success[500], 
                color:colors.grey[100]
              },
              "& .MuiTab-root": {
                textTransform: "none",
                color: colors.warning[200],
                fontWeight: "bold",
                padding: "8px 16px",
                borderRadius: "24px",
                minHeight: "auto",
                transition: "background-color 0.5s ease",
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                backgroundColor: "transparent",
                color: colors.grey[100],
              },
            }}
          >
            <Tab label="Product Wise Document" />
            <Tab label="Manufacturer&nbsp;Wise&nbsp;Document" />
          </Tabs>
        </Box>

        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomDropdownField
                name="documentType"
                control={control}
                options={productDocumentOptions}
                label="Document Type"
                rules={{ required: "Document type is required." }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <CustomDatePicker
                name="effectiveDate"
                controllerName="effectiveDate"
                control={control}
                label="Effective Date"
                onChange={handleEffectiveDate}
                rules={{ required: "Effective Date is required." }}
              />
            </Grid>
            { activeTab === 1 && <Grid item md={6} xs={12}>
                <CustomDropdownField
                  name="manufactureId"
                  control={control}
                  options={manufacturersList}
                  label="Manufacturer"
                  rules={{ required: "Manufacturer is required." }}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <Dropzone
                name="attachmentProductDocument"
                label=""
                onFileChange={() => { }}
                onFileChangeTest={handleDropzoneChange}
                control={control}
                maxFileCount={1}
                rules={{ required: "Product document is required." }}
                maxFileSize={{ size: 15, unit: "MB" }}
                allowedFileTypes={{
                  ...pdf,
                  ...doc,
                  ...docx,
                  ...jpeg,
                  ...png,
                  ...xls,
                  ...xlsx,
                  ...mov,
                  ...mp4,
                  ...avi,
                  ...flv,
                  ...ogv,
                  ...webm,
                  ...ts,
                  ...mkv,
                  ...wmv,
                  ...video3gp, 
                  ...video3g2, 
                  ...m4v,
                  ...asf,
                  ...f4v,
                  ...rm,
                  ...rmvb,
                  ...mxf,
                  ...vob,
                  ...dv,
                  ...amv,
                }}
              />
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Support Format : PDF, DOC, DOCX, JPEG, PNG, XLS, XLSX, MP4, MOV.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Maximum size : 15MB
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12} textAlign="center">
              <Typography variant="h5">OR</Typography>
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                controllerName="productDocumentInfo.documentURL"
                control={control}
                label="Document URL"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="name"
                label="Name"
                rules={{ required: "Name is required." }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="comments"
                label="Comments"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="flex-end" spacing={1} p={2}>
          <Grid item xs={12} sm="auto">
            <Button type="submit" startIcon={<FileUploadOutlined />} fullWidth>
              Upload
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              color="error"
              startIcon={<Cancel />}
              onClick={onClose}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDocumentPopup;
