import React, { ChangeEvent } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { tokens } from "../../theme";

interface PhoneInputFieldProps {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string | "";
  variant?: "filled" | "outlined" | "standard" | undefined;
  name: string;
  control: Control<any, any>;
  rules?: Object;
  isDisabled?: boolean;
  isEmpty?: boolean;
}

const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  value,
  onChange,
  size,
  color,
  label,
  variant,
  name,
  control,
  rules,
  isDisabled,
  isEmpty,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };

  const formatPhoneNumber = (phone: string) => {
    if (!phone) return "";
    
    const cleaned = ("" + phone).replace(/\D/g, "");
    
    let countryCode = "";
    let mainNumber = cleaned;
    
    if (cleaned.length > 10) {
      countryCode = cleaned.substring(0, cleaned.length - 10);
      mainNumber = cleaned.slice(cleaned.length - 10);
    }
    
    const match = mainNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    const formattedMainNumber = match ? `(${match[1]}) ${match[2]}-${match[3]}` : mainNumber;
    
    return countryCode ? `+${countryCode} ${formattedMainNumber}` : formattedMainNumber;
  }
  

  return (
    <Controller
      name={name}
      control={control}
      rules={rules || {}}
      render={({ field, fieldState }) => (
        <PhoneInput
          country={"us"}
          value={isEmpty ? "" : value || field.value}
          onChange={(phone) => {
            const formattedPhone = formatPhoneNumber(phone);
            const event = {
              target: { value: formattedPhone } as HTMLInputElement,
            } as ChangeEvent<HTMLInputElement>;
            field.onChange(formattedPhone);
            onChange && onChange(event);
          }}          
          disabled={isDisabled}
          inputStyle={{
            width: "100%",
            height: "50px",
            borderRadius: "8px",
            borderWidth: "2px",
            borderColor: colors.primary[400],
            backgroundColor: "transparent",
            color: colors.grey[100],
          }}
          containerClass={
            theme.palette.mode === "dark"
              ? "phone-input-container-dark"
              : "phone-input-container-light"
          }
          buttonClass={
            theme.palette.mode === "dark"
              ? "phone-input-button-dark"
              : "phone-input-button-light"
          }
          dropdownClass={
            theme.palette.mode === "dark"
              ? "phone-input-dropdown-dark"
              : "phone-input-dropdown-light"
          }
          inputProps={{
            name,
            required: isRequired(),
          }}
        />
      )}
    />
  );
};

export default PhoneInputField;
