import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Cancel, SecurityUpdate, SystemSecurityUpdate, SystemSecurityUpdateRounded, TipsAndUpdates, VpnKey } from "@mui/icons-material";
import { useForm } from "react-hook-form";

import { Box, Grid } from "@mui/material";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import Button from "../../common/Button";
import { fetchProductDocumentsByProductId, UpdateProductDocumentStatusRequest, updateStatusOfProductDocument } from "../../../redux/slice/product/createProductSlice";

interface UpdateProductDocumentStatusProps {
    open: boolean;
    onClose: () => void;
    statusData: any;
    originalStatusValue: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateProductDocumentStatusPopup: React.FC<UpdateProductDocumentStatusProps> = ({
    open,
    onClose,
    statusData,
    originalStatusValue
}) => {
    const { handleSubmit } = useForm({});
    const dispatch = useAppDispatch();
    const [productId, setProductId] = useState<number>(0);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let id = urlSearchParams.get('id');
        if (id) {
            setProductId(parseInt(id));
        };
    }, [open]);


    const onSubmit = async () => {
        if (statusData?.productId && statusData?.status && statusData?.documentId) {
            const data: UpdateProductDocumentStatusRequest = {
                productId: statusData.productId,
                documentId: statusData.documentId,
                status: (statusData?.status).trim()
            }
            const response = await dispatch(updateStatusOfProductDocument({ updateRequest: data }));
            if (response.payload && response.payload.code === "V3OK") {
                let successObj: SingleAlertInfo = {
                    message: response.payload.message,
                    alertType: "success",
                };
                dispatch(setSingleAlertObj(successObj));
            }
            await dispatch(fetchProductDocumentsByProductId(productId))
        }
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle style={{ fontSize: "20px" }}>Update Product Document Status</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <span>The status value is currently "</span><span style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}>{originalStatusValue}</span><span>"  and will be updated to "<span style={{ color: '#66bb6a', fontSize: "15px", fontWeight: "bold" }}>{statusData.status}</span>" shortly.</span>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="flex-end" spacing={2} pt={2}>
                            <Grid item xs={12} sm="auto">
                                <Button type="submit" startIcon={<TipsAndUpdates />} fullWidth>
                                    Update
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Button color="error" startIcon={<Cancel />} onClick={() => handleClose()} fullWidth>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default UpdateProductDocumentStatusPopup;