import { ManufacturerCreateObj } from "../components/operation/manufacturerNotification/CreateManufacturer";
import { SAVE_MANUFACTURER_URL } from "./URL";
import { http } from "./http";

export const saveManufacturer = async (saveManufacturer: ManufacturerCreateObj) => {
    try{
        console.log("Type of Notification Email: ", typeof saveManufacturer.notificationEmail);
        const response = await http.post(SAVE_MANUFACTURER_URL, saveManufacturer, undefined, {skipErrorPopup: true});
        return response;
    }
    catch(error){
        throw error;
    }
};