import Header from "../../common/Header";
import { Box, Grid } from "@mui/material";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { ArrowForward, ForwardToInboxOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PracticeCreateInfo, createPractice, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useForm } from "react-hook-form";
import CustomNametField from "../../common/CustomNametField";
import CustomTextField from "../../common/CustomTextField";

export default function CreatePractice() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = usePracticeSlice();
  const { control, handleSubmit, reset, formState: { errors }} = useForm<PracticeCreateInfo>();

  // useEffect (()=>{
  //   if(status && status === 'success') {
  //     navigate("/practice_list");
  //   }else {
  //   }
  // }, [status])

  const allErrors = Object.values(errors).map(error => error.message);

  const onSubmit = (data: PracticeCreateInfo) => {
    console.log(data)
    try {
      dispatch(createPractice(data));

      /// need to change below content and execute the commented useEffect - START------------------------------------------------
      if (status && status === 'success') {
        navigate("/practice_list");
      } else {
      }
      /// need to change below content and execute the commented useEffect - END---------------------------------------------------
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header title="Practice Registration" subtitle="Invite Practice Registration" />
      <Box mb="20px">
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <CustomNametField
                  control={control}
                  context="Contact"
                  xs={12}
                  md={4}
                  isRequired={true}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <CustomTextField
                  control={control}
                  controllerName="contactEMail"
                  label="Email Address"
                  rules={{required: "Contact Email required."}}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  startIcon={<ArrowForward />}
                  style={{
                    padding: "15px",
                  }}
                  type="submit"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    </Box>

  );
};


