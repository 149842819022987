import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import Button from "../../common/Button";
import { TransitionProps } from "@mui/material/transitions";
import CustomDatePicker from "../../common/CustomDatePicker";
import { RecordPastOrder } from "../../../redux/slice/product/createProductSlice";
import { useForm } from "react-hook-form";
import { Cancel, ClassOutlined } from "@mui/icons-material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ORDERTYPE } from "../../../constants/applicationConstants";



interface RecordPastOrderPopupProps {
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RecordPastOrderPopup: React.FC<RecordPastOrderPopupProps> = ({
  open,
  onClose,
}) => {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue } = useForm<RecordPastOrder>();
  const [isDisableCreateButton, setIsDisableCreateButton] = useState(true);

  const [orderDate, setOrderDate] = useState("");

  const handleClose = () => {
    setOrderDate("");
    reset();
    setIsDisableCreateButton(true)
    onClose();
  };

  const handleDateChangeOrder = (date: Date | null) => {
    if (date) {
      const formattedDate = dayjs(date).format("MM/DD/YYYY");
      setOrderDate(formattedDate);
    } else {
      setOrderDate("")
    }
  }

  useEffect(() => {
    if(orderDate!=""){
        setIsDisableCreateButton(false)
    }    
  }, [orderDate]);

  const handleSave = async () => {
    navigate("/order", { state: { orderType: ORDERTYPE.PAST_ORDER, orderDate: orderDate } })
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={(event) => {
          event.stopPropagation();
        }}
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography>Record Past Order</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>When was the order placed ?</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <CustomDatePicker
              isDateCalendar={false}
              control={control}
              controllerName="OrderPlaceDate"
              label="Order Place Date"
              onChange={handleDateChangeOrder}
            />
          </Grid>

          <Grid container justifyContent="flex-end" sx={{ mt: 1 }} spacing={1}>
            <Grid item xs={12} sm="auto">
              <Button color="success" disabled={isDisableCreateButton} onClick={handleSave} startIcon={<ClassOutlined />} fullWidth>
                Record
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                color="error"
                onClick={handleClose}
                startIcon={<Cancel />}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordPastOrderPopup;
