import React, { useState, useRef, useEffect } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import {
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { Clear, InsertInvitation } from "@mui/icons-material";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { setSingleAlertObj, SingleAlertInfo } from "../../redux/slice/commonSlice";
import { useDispatch } from "react-redux";

interface CustomDateRangeProps {
  label?: string;
  control: any;
  controllerName: string;
  month?: number;
  dateRangeMonths?: number;
  onChange?: (fromDate: DateObject | null, toDate: DateObject | null) => void;
  onClear?: boolean; 
}

const CustomDateRange: React.FC<CustomDateRangeProps> = ({
  label,
  control,
  controllerName,
  month,
  dateRangeMonths,
  onChange,
  onClear,
}) => {
  const theme = useTheme();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (onClear) {
      handleClear();
    }
  }, [onClear]);

  const getDefaultDateRange = (): DateObject[] => {
    if (dateRangeMonths) {
      const endDate = new DateObject(); 
      const startDate = new DateObject().subtract(dateRangeMonths, "months");
      return [startDate, endDate];
    }
    return [];
  };

  const [dateRange, setDateRange] = useState<DateObject[]>(getDefaultDateRange);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (dateRangeMonths) {
      const defaultRange = getDefaultDateRange();
      setDateRange(defaultRange);
      if (onChange) {
        const [fromDate, toDate] = defaultRange;
        onChange(fromDate || null, toDate || null);
      }
    }

    if(dateRangeMonths===0){
      setInputValue("");
      setDateRange([]);
      if (onChange) {
        onChange(null, null);
    }
    }
  }, [dateRangeMonths]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const isValidDate = (dateString: string): boolean => {
    const regex = new RegExp(
      `^([0-1]?[0-9])([/-])([0-3]?[0-9])([/-])([0-9]{4})$`
    );
  
    const match = dateString.match(regex);
    if (!match) return false; 
  
    const [_, month, separator1, day, separator2, year] = match;
   
    if (separator1 !== separator2) return false;
  
    return true;
  };

  
  const handleBlur = () => {

    if (dateRange.length === 2) {
      const [start, end] = dateRange;

      const startStr = start.toString();
      const endStr = end.toString();
  
      if (!isValidDate(startStr) || !isValidDate(endStr)) {
        const errorObj: SingleAlertInfo = {
          message: "Invalid date format entered.",
          alertType: "error",
        };
        setTimeout(() => {
        dispatch(setSingleAlertObj(errorObj)); 
        }, 100);
        setDateRange([]); 
        setInputValue(""); 
        if (onChange) {
          onChange(null, null); 
        }
        return;
      }
  
      if (start > end) {
        
        const errorObj: SingleAlertInfo = {
          message: "Start date cannot be after the end date.",
          alertType: "error",
        };
        setDateRange([]); 
        setInputValue(""); 
        if (onChange) {
          onChange(null, null); 
        }
        setTimeout(() => {
          dispatch(setSingleAlertObj(errorObj)); 
        }, 100);
        return;
        
      }
    }
    setIsFocused(false); 
  };
  

  const handleChange = (dates: DateObject[]) => {
    setDateRange(dates);
    setInputValue(dates.map((date) => date.format("MM/DD/YYYY")).join(" - "));
    if (onChange) {
      const [fromDate, toDate] = dates;
      onChange(fromDate || null, toDate || null);
    }
  };

  const clearFieldAndDispatchError = (message: string) => {
    setInputValue("");
    setDateRange([]);
    if (onChange) {
      onChange(null, null);
    }
      dispatch(setSingleAlertObj({message: message, alertType:"error"})); 
  };


  const validateAndSanitizeDate = (dateStr: string): DateObject | null => {
    if (!dateStr) return null;

    const parts = dateStr.split("/").map(Number);
    if (parts.length !== 3) {
      return null;
    }

    let [month, day, year] = parts;

    // Validate month
    if (month < 1 || month > 12) {
      clearFieldAndDispatchError("Month must be between 1 and 12.");
      return null;
    }

    // Validate day
    const maxDay = new Date(year, month, 0).getDate();
    if (day < 1 || day > maxDay) {
      clearFieldAndDispatchError(`Day must be between 1 and ${maxDay} for the month.`);
      return null;
    }

    return new DateObject({ date: dateStr, format: "MM/DD/YYYY" });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    const validCharactersRegex = /^[0-9\s/-]*$/;
    if (!validCharactersRegex.test(value)) {
      clearFieldAndDispatchError("Only numbers, spaces, '-' and '/' are allowed.");
      return;
    }
  
    setInputValue(value);

    value = value.replace(/\s*-\s*/g, " - ");
  
    const [startDateStr, endDateStr] = value.split(" - ");
    const start = validateAndSanitizeDate(startDateStr);
    const end = validateAndSanitizeDate(endDateStr);
  
    if (start && end) {
      setDateRange([start, end]);
      if (onChange) {
        onChange(start, end);
      }
    } else {
      setDateRange([]);
      if (onChange) {
        onChange(null, null);
      }
    }
  };
  

  const handleClear = () => {
    setDateRange([]);
    setInputValue("");
    if (onChange) {
      onChange(null, null);
    }
  };

  return (
    <>
      <DatePicker
        numberOfMonths={month || 2}
        value={dateRange}
        onChange={handleChange}
        range
        onOpen={() => handleFocus()}
        onClose={() => handleBlur()}
        format="MM/DD/YYYY"
        className={
          isMobile
            ? theme.palette.mode === "dark"
              ? "rmdp-mobile green bg-dark"
              : "green"
            : theme.palette.mode === "dark"
            ? "green bg-dark"
            : "green"
        }
        style={{
          width: "100%",
        }}
        containerStyle={{
          width: "100%",
        }}
        mobileLabels={{
          OK: "APPLY",
          CANCEL: "CLOSE",
        }}
        render={(value, openCalendar) => (
          <CustomTextField
            control={control}
            controllerName={controllerName}
            // value={value}
            value={inputValue=="" ? value : inputValue} 
            onChange={handleInputChange}
            onClick={() => {
              openCalendar();
              handleFocus();
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={textFieldRef}
            InputLabelProps={{
              shrink: isFocused || dateRange.length > 0,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      openCalendar();
                      handleFocus();
                    }}
                  >
                    <InsertInvitation />
                  </IconButton>
                  {dateRange.length > 0 && (
                    <IconButton
                      onClick={handleClear}
                      aria-label="clear date range"
                    >
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default CustomDateRange;
