import { Box } from "@mui/material";
import Header from "../common/Header";

const Dashboard = () => {
  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="Welcome to your Dashboard" />
      </Box>
    </Box>
  );
};

export default Dashboard;
