import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from "../redux-hooks";
import {getManufacturerList} from "../../../service/ManufacturerNotificationService";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GeneralV3Response, LoadingType } from '../../../types/CommonTypes';
import { updateElementValue } from '../../../utils/updateElementValue';
import { ManufacturerCreateObj } from '../../../components/operation/manufacturerNotification/CreateManufacturer';
import { saveManufacturer } from "../../../service/ManufacturerService";

// File: src/types/ManufacturerInfo.ts
export interface ManufacturerListDTO {
    manufacturerId: number;
    manufacturerName: string;
    contactPhone: string;
    contactEmail: string;
    notificationEmail: string[];
  }
  
export interface PaginationManufacturerList {
  content: ManufacturerListDTO[];
  totalElements: number;
  totalPages: number;
  size: number;
    }

type ManufacturerListState = {
    ManufacturerListInfo?: ManufacturerListDTO[] | null;
    ManufacturerListFetchStatus?: LoadingType;
}


const initialState: ManufacturerListState = {
    ManufacturerListInfo: undefined
};

export const fetchManufacturerList = createAsyncThunk("fetchManufacturerList", async () => {
    const response = await getManufacturerList();
    const v3Response = response.data;
    console.log("V3 Response", v3Response);
    return v3Response;
});

export const saveManufacturers = createAsyncThunk("saveManufacturer", async (data: ManufacturerCreateObj, { rejectWithValue }) => {
  try {
    data.notificationEmail = data.notificationEmail.replace(/\\"/g, '"').replaceAll(/\n/g, '');
    //console.log("Notification Email: ", JSON.parse(data.notificationEmail.replace(/\\"/g, '"').replaceAll(/\n/g, '')));
    console.log(data);
    const response = await saveManufacturer(data); 
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

// Create the slice
const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    updateElementInManufacturerSlice : updateElementValue,
    resetManufacturerData: (state: ManufacturerListState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    resetManufacturerList: (state: ManufacturerListState) => {
      state.ManufacturerListInfo = undefined;
      state.ManufacturerListFetchStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManufacturerList.pending, (state) => {
        state.ManufacturerListFetchStatus = "loading";
      })
      .addCase(fetchManufacturerList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.ManufacturerListFetchStatus = "success";
        state.ManufacturerListInfo = action.payload.data;
      })
      .addCase(fetchManufacturerList.rejected, (state, action) => {
        state.ManufacturerListFetchStatus = 'error';
      })
  }
});

// Export actions for use in components
export const {
    resetManufacturerData,
    resetManufacturerList,
} = manufacturerSlice.actions;

// Export the reducer for the store configuration
export const useManufacturerListSlice = () => useAppSelector((state) => state.manufacturerSlice);
export default manufacturerSlice.reducer;
