import React, { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "../../common/Card";
import Table from "../../common/CustomTable";
import Header from "../../common/Header";
import Button from "../../common/Button";
import DifferenceIcon from '@mui/icons-material/Difference';
import {
  AddCircleOutlineOutlined, AppRegistrationOutlined, DeleteOutlineOutlined, ListAltOutlined, ModeOutlined,
  SearchOutlined, SummarizeOutlined, VisibilityOutlined, EditNote, ViewInArOutlined, ContentCopyOutlined,
  EditNoteOutlined,
  RemoveShoppingCartOutlined,
  PictureAsPdfOutlined,
  TrackChanges
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../../common/CustomTextField';
import LegacyToolBar from '../../common/LegacyToolBar';
import { useSelector } from 'react-redux';
import { ProductInfo } from '../../../redux/slice/product/productListSlice';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { PracticeInfo } from '../../../redux/slice/practiceSlice';
import { selectIsTokenAvailable, selectIsValueFoundInToken, useAuthSlice } from '../../../redux/slice/authSlice';
import { AUDIT_ENTITYS, CAN_ACCESS_APPLICATION, IVR_STATUS, IVR_STATUS_LIST, ORDERTYPE } from '../../../constants/applicationConstants';
import { formatDate, fullName, hideElement, sortNames } from '../../../utils/Utility';
import {
  fetchManufacturerList, useManufacturerListSlice
} from '../../../redux/slice/manufacturer/ManufacturerSlice';
import { RootState } from '../../../redux/store';
import OptionsPopup from '../../common/OptionsPopup';
import { fetchPracticeProductsData } from '../../../redux/slice/practice/practiceProductSlice';
// import IVRPracticeSelectionPopup from './IVRPracticeSelectionPopup';
import CustomIconButton from '../../common/CustomIconButton';
import PDFViewer from '../../common/pdf/PDFViewer';
import { fetchDocumentById, useIVRDocumentSlice } from '../../../redux/slice/ivr/ivrDocumentSlice';
import CustomMultipleDropDown from '../../common/CustomMultipleDropDown';
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from '../../../redux/slice/codetableSlice';
import { setSingleAlertObj, showHideLoadingDialog, SingleAlertInfo } from '../../../redux/slice/commonSlice';
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from '../../../utils/StorageUtils';
import CustomDateRange from '../../common/CustomDateRange';
import { DateObject } from 'react-multi-date-picker';
import CustomDropdownField from '../../common/CustomDropdownFiled';
import { SearchAuditLogRequest } from '../../../redux/slice/auditLog/auditLogSlice';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

type Option = {
  value: string,
  label: string
};

export type ManufacturerInfo = {
  manufacturer: string;
  contactPhone: string;
  // contactEmail: string;
  notificationEmail: string;
}

const ITEMS_PER_PAGE = 20;

const ManufacturerList: React.FC = () => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, handleSubmit, reset, setValue, getValues } = useForm<SearchAuditLogRequest>();

  const [showIVRSelectionPopup, setShowIVRSelectionPopup] = useState(false);
  const [showPDF, setShowPDF] = useState<{ isShow: boolean, productName: string, fileName: string }>({ isShow: false, productName: "", fileName: "" });
  const [deleteConfirm, setDeleteConfirm] = useState<{ message: string, show: boolean, row: any }>({ message: "", show: false, row: "" });
  const [cancelConfirm, setCancelConfirm] = useState<{ message: string, show: boolean, row: any }>({ message: "", show: false, row: "" });
  const [showInfoPopup, setShowInfoPopup] = useState<{ message: string, show: boolean }>({ message: "", show: false });
  const [showCard, setShowCard] = useState(false);
  const [showIvrCopyMessage, setShowIvrCopyMessage] = useState<{ message: string, show: boolean, row: any }>({ message: "", show: false, row: "" });

  // const [searchCriteria, setSearchCriteria] = useState<SearchIVRListRequest>({
  //   practiceId: [],
  //   productId: [],
  //   patientFirstName: "",
  //   patientLastName: "",
  //   verificationStatus: [],
  //   ivrNumber: "",
  //   fromDate: "",
  //   toDate: "",
  //   currentPage: 1,
  //   itemsPerPage: ITEMS_PER_PAGE,
  // });

  const { userInfo } = useAuthSlice();
  //const { IVRListInfo, practiceByUserRoleCreateIVRSlice, productsByUserRoleCreateIVRSlice } = useCreateIVRSlice();
  const { ivrDocumentInfo } = useIVRDocumentSlice();
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  // const [selectedRow, setSelectedRow] = useState<IVRInfo>();

  const canAuditManufacture = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessAuditAction", AUDIT_ENTITYS.USERAUDIT)(state));

  const { ManufacturerListInfo } = useManufacturerListSlice();

  //CS-518
  // useEffect(() => {
  // const entityName = params.get('entityName');
  // const entityNumber = params.get('entityNumber');
  //   // const entityName = location.state?.entityName;
  //   // const entityNumber = location.state?.entityNumber;
  //   setShowCard(true)
  //   setValue("entityName", entityName);
  //   setValue("entityNumber", entityNumber);
  // }, [location.state])
  //
  useEffect(() => {
    const auditedEntity = params.get('auditedEntity');
    const entityNumber = params.get('entityNumber');
    // const auditedEntity = location.state?.auditedEntity;
    // const entityNumber = location.state?.entityNumber;
    setShowCard(true)
    //setValue("auditedEntity", auditedEntity);
    setValue("entityNumber", entityNumber);
  }, [location.state])
  //
  const statusOptions = useMemo(() => {
    let statuses: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      statuses = getCodeTableValuesByTableName(allCodeTablesValuesMap, IVR_STATUS_LIST);
    }
    if (!statuses || statuses.length === 0) {
      //dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string, value: any }[] = [];
    statuses?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const formattedManufacturerInfo: ManufacturerInfo[] = useMemo(() => {
    let manufacturers: ManufacturerInfo[] = [];
    if (ManufacturerListInfo) {
      manufacturers = ManufacturerListInfo.map((manufacturer: any) => ({
        manufacturer: manufacturer.manufacturerName,
        contactPhone: manufacturer.contactPhone,
        // contactEmail: manufacturer.contactEmail,
        notificationEmail: manufacturer.notificationEmail != null ? JSON.stringify(manufacturer.notificationEmail).replaceAll(',', '\n') : ''
      }));
    };
    return manufacturers;
  }, [ManufacturerListInfo]);


  // const practiceOptions = useMemo(() => {
  //   let options: Option[] = [];
  //   if (practiceByUserRoleCreateIVRSlice && practiceByUserRoleCreateIVRSlice.length > 0) {
  //     if (practiceByUserRoleCreateIVRSlice.length === 1) {
  //       setValue('practiceId', [Number(practiceByUserRoleCreateIVRSlice[0]?.practiceId)]);
  //     }
  //     const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleCreateIVRSlice, 'practiceName');
  //     sortedPracticeInfo?.map(practice => {
  //       options.push({ value: practice.practiceId, label: practice.practiceName });
  //     });
  //   };
  //   return options;
  // }, [practiceByUserRoleCreateIVRSlice]);

  // const productOptions = useMemo(() => {
  //   let options: Option[] = [];
  //   if (productsByUserRoleCreateIVRSlice && productsByUserRoleCreateIVRSlice.length > 0) {
  //     if (productsByUserRoleCreateIVRSlice.length === 1) {
  //       setValue('productId', [Number(productsByUserRoleCreateIVRSlice[0]?.productId)]);
  //     }
  //     const sortedProductList: ProductInfo[] = sortNames(productsByUserRoleCreateIVRSlice, 'productName');
  //     sortedProductList?.map((option) => {
  //       options.push({ label: option.productName, value: option.productId })
  //     });
  //   };
  //   return options;
  // }, [productsByUserRoleCreateIVRSlice]);

  // const formattedIVRInfo: IVRInfo[] = useMemo(() => {
  //   let ivrs: IVRInfo[] = [];
  //   // if (IVRListInfo) {
  //   //   ivrs = IVRListInfo.content.map((ivr: any) => ({
  //   //     practice: ivr.practiceName,
  //   //     patient: fullName(ivr.title, ivr.patientFirstName, ivr.patientMiddleName, ivr.patientLastName),
  //   //     product: ivr.productName,
  //   //     provider: ivr.providerName,
  //   //     dateCreated: ivr.auditTimestamp,
  //   //     createdDate: ivr.createdDate,
  //   //     dateOfService: ivr.dateOfService,
  //   //     status: ivr.verificationStatus,
  //   //     locationId: ivr.locationId,
  //   //     productId: ivr.productId,
  //   //     practiceId: ivr.practiceId,
  //   //     ivrId: ivr.ivrId,
  //   //     ivrNumber: ivr.ivrNumber,
  //   //     patientId: ivr.patientId,
  //   //     patientDOB: ivr.patientDOB,
  //   //     practiceProductId: ivr.practiceProductId,
  //   //     ivrDocumentId: ivr.ivrDocumentId
  //   //   }));
  //   // };
  //   return ivrs;
  // }, [IVRListInfo]);

  //Mount
  useEffect(() => {
    fetchInitialLists();
  }, []);

  const fetchInitialLists = async () => {
    dispatch(showHideLoadingDialog(true));
    //Don't remove below unused variables
    const responseManufacturers = await dispatch(fetchManufacturerList());
    dispatch(showHideLoadingDialog(false));
  }

  //Unmount
  useEffect(() => () => {
    //dispatch(resetIVRList());
  }, []);

  // useEffect(() => {
  //   //1. When login another user pagination is remain in the history and can redirect that page
  //   if (productsByUserRoleCreateIVRSlice && productsByUserRoleCreateIVRSlice.length > 0) {
  //     let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.IVR_MANAGEMENT + "SearchCriteria" + userInfo.userId);
  //     if ((location.state && location.state.ivrListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
  //       if (location.state && location.state.withSearchHistory) {
  //         //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
  //         navigate("/ivr_list", { state: { ivrListSearchCriteria: criteria } });
  //         return;
  //       }
  //       let searchCriteria: SearchIVRListRequest = {
  //         productId: [],
  //         practiceId: [],
  //         patientFirstName: '',
  //         patientLastName: '',
  //         verificationStatus: [],
  //         ivrNumber: '',
  //         fromDate: "",
  //         toDate: "",
  //         currentPage: 0,
  //         itemsPerPage: 0
  //       };
  //       if (location.state && location.state.ivrListSearchCriteria) {
  //         searchCriteria = location.state.ivrListSearchCriteria;
  //       } else {
  //         searchCriteria = criteria ? criteria : searchCriteria;
  //       }
  //       setSearchCriteria(searchCriteria);
  //       setValuesToCriteriaFields(searchCriteria);

  //       searchIVRsList(searchCriteria);
  //     } else {
  //       loadInitialIVRList(1);
  //     }
  //   }
  // }, [location.state, productsByUserRoleCreateIVRSlice]);

  // const setValuesToCriteriaFields = (searchCriteria: SearchIVRListRequest) => {
  //   setValue("practiceId", searchCriteria.practiceId);
  //   setValue("productId", searchCriteria.productId);
  //   setValue("patientFirstName", searchCriteria.patientFirstName);
  //   setValue("patientLastName", searchCriteria.patientLastName);
  //   setValue("verificationStatus", searchCriteria.verificationStatus);
  // }

  // const loadInitialIVRList = (pNumber: number = 1) => {
  //   const practiceIdList = practiceByUserRoleCreateIVRSlice && practiceByUserRoleCreateIVRSlice.length > 0
  //     ? practiceByUserRoleCreateIVRSlice?.map(practice => Number(practice.practiceId)) : [];
  //   const productIdList = productsByUserRoleCreateIVRSlice && productsByUserRoleCreateIVRSlice.length > 0
  //     ? productsByUserRoleCreateIVRSlice?.map(product => Number(product.productId)) : [];
  //   const data: SearchIVRListRequest = {
  //     practiceId: practiceIdList && practiceIdList.length === 1 ? practiceIdList : [],
  //     productId: productIdList && productIdList.length === 1 ? productIdList : [],
  //     patientFirstName: "",
  //     patientLastName: "",
  //     verificationStatus: [],
  //     ivrNumber: "",
  //     fromDate: "",
  //     toDate: "",
  //     currentPage: pNumber,
  //     itemsPerPage: ITEMS_PER_PAGE,
  //   };
  //   setSearchCriteria(data);
  //   setValuesToCriteriaFields(data);

  //   searchIVRsList(data);
  // }

  const searchManufacturerList = () => {
    //setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.IVR_MANAGEMENT);
    dispatch(fetchManufacturerList());
  }

  const onSubmit = (data: SearchAuditLogRequest) => {
    // const data2: SearchIVRListRequest = {
    //   practiceId: data.practiceId,
    //   productId: data.productId,
    //   patientFirstName: data.patientFirstName,
    //   patientLastName: data.patientLastName,
    //   verificationStatus: data.verificationStatus,
    //   ivrNumber: data.ivrNumber,
    //   fromDate: data.fromDate,
    //   toDate: data.toDate,
    //   currentPage: 1,
    //   itemsPerPage: ITEMS_PER_PAGE,
    // }
    // setSearchCriteria(data2);

    // //Below navigate line call to useEffect[location.state, productsByUserRoleCreateIVRSlice] and search
    // navigate("/ivr_list", { state: { ivrListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      // ...searchCriteria,
      currentPage: pageNumber + 1
    }
    // setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state, productsByUserRoleCreateIVRSlice] and search
    // navigate("/ivr_list", { state: { ivrListSearchCriteria: data } });
  }

  // const confirmDeleteIVR = async () => {
  //   const response = await dispatch(deleteIVRById(deleteConfirm.row));
  //   if (response.payload.message === "SUCCESS") {
  //     const alertObj: SingleAlertInfo = {
  //       message: `IVR ${selectedRow?.ivrNumber} has been deleted successfully.`,
  //       alertType: "success",
  //     };
  //     dispatch(setSingleAlertObj(alertObj));
  //     searchIVRsList(searchCriteria);
  //   }
  //   setDeleteConfirm({ message: "", show: false, row: "" });
  // }

  // const confirmCancelIVR = async () => {
  //   const response = await dispatch(cancelIVRById(cancelConfirm.row));
  //   if (response.payload.message === "SUCCESS") {
  //     const alertObj: SingleAlertInfo = {
  //       message: `IVR  ${selectedRow?.ivrNumber} has been cancelled successfully.`,
  //       alertType: "success",
  //     };
  //     dispatch(setSingleAlertObj(alertObj));
  //     searchIVRsList(searchCriteria);
  //   }
  //   setCancelConfirm({ message: "", show: false, row: "" });
  // }

  const cancelDeleteIVR = () => {
    setDeleteConfirm({ message: "", show: false, row: "" });
  }

  const cancelCancelIVR = () => {
    setCancelConfirm({ message: "", show: false, row: "" });
  }

  // const handlePreviewAction = async (row: IVRInfo) => {
  //   const actionResult = await dispatch(fetchDocumentById(row.ivrDocumentId));
  //   const documentData = actionResult.payload;

  //   if (documentData && documentData.content) {
  //     setShowPDF({ isShow: true, productName: row.product + ` - IVR`, fileName: row.ivrNumber });
  //   };
  // }

  // const handleCreateOrder = (row: IVRInfo) => {
  //   const selectedIVR = IVRListInfo?.content?.find(ivr => ivr.ivrId === row.ivrId);
  //   if (selectedIVR) {
  //     navigate("/order", { state: { fromUI: "IVRList", orderType: ORDERTYPE.DIRECT_ORDER, orderDetails: selectedIVR } });
  //   }
  // }

  const onCreateClick = async () => {
    // if (practiceByUserRoleCreateIVRSlice && practiceByUserRoleCreateIVRSlice.length === 1) {
    //   const response = await dispatch(fetchPracticeProductsData(practiceByUserRoleCreateIVRSlice[0].practiceId));
    //   const isExecuted = response.payload.data.some((product: any) =>
    //     product.agreementStatus === 'EXECUTED' ||
    //     product.agreementStatus === 'INITIATED' ||
    //     product.agreementStatus === 'PRACTICE SIGNED'
    //   );
    //   if (!isExecuted) {
    //     setShowInfoPopup({ message: "Could not find any valid onboarding agreements. Please try again later.", show: true });
    //   } else {
    //     navigate("/ivr");
    //   }
    // }
    // else if (practiceByUserRoleCreateIVRSlice && practiceByUserRoleCreateIVRSlice.length > 1) {
    //   setShowIVRSelectionPopup(true);
    // } else {
    //   setShowInfoPopup({ message: "Could not find any valid practice(s) associated with your account. Please try again later.", show: true })
    // }
  }

  const onClickInfoPopup = () => {
    setShowInfoPopup({ message: "Could not find any valid onboarding agreements. Please try again later.", show: false })
  }

  // const onClickCopyIvrPopup = (row: IVRInfo) => {
  //   navigate("/ivr", { state: { ivrId: row.ivrId, practiceId: row.practiceId, copiedIvr: true } })
  // }

  // const isOlderThan30Days = (dateString: string) => {
  //   const givenDate = new Date(dateString);
  //   const currentDate = new Date();
  //   const thirtyDaysAgo = new Date();
  //   thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  //   return givenDate < thirtyDaysAgo;
  // };

  // const handleCopyIvr = (row: IVRInfo) => {
  //   if (isOlderThan30Days(row.dateCreated.toString())) {
  //     setShowIvrCopyMessage({ message: "Information on this IVR is 30 days or older. Please verify the information on this IVR.", show: true, row: row })
  //   } else {
  //     navigate("/ivr", { state: { ivrId: row.ivrId, practiceId: row.practiceId, copiedIvr: true } })
  //   }
  // }
  // CS-382 
  // const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
  //   const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
  //   const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;
  //   setValue("fromDate",fromDateString?fromDateString:"");
  //   setValue("toDate", toDateString?toDateString:"");
  // };
  //
  const handleClickManufactureAudit = (data: any) => {
    if (data && data.manufacturer) {
      const MFRAuditData = {
        entityName: "MANUFACTURER",
        entityNumber: data?.manufacturer
      }

      const url = `/auditlog?entityName=${MFRAuditData.entityName}&entityNumber=${MFRAuditData.entityNumber}`;
      window.open(url, '_blank');
      // navigate("/auditlog", '_blank',{ state: { entityName: IVRAuditData.entityName, entityNumber: IVRAuditData.entityNumber } });
    }
  }
  const actionComponent = (row: ManufacturerInfo): JSX.Element => {
    return (
      <>

        {/* <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Edit"
          color="info"
          size="small"
          variant="outlined"
        //style={{ display: `${hideElement(!canEditIVR || ![IVR_STATUS.DRAFTED, IVR_STATUS.NEEDS_MORE_INFO, IVR_STATUS.DENIED].includes(row.status))}` }}
        //onClick={async () => { navigate("/ivr", { state: { ivrId: row.ivrId, practiceId: row.practiceId } }) }}
        >
          <ModeOutlined fontSize="small" />
        </CustomIconButton> */}
        
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Manufacture Audit"
          color="warning"
          size="small"
          variant="outlined"
          style={{ display: `${hideElement(!canAuditManufacture)}` }}
          onClick={() => handleClickManufactureAudit(row) }
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>
      </>
    );
  };

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <AppRegistrationOutlined color="success" />, text: "Create an Order" },
    { icon: <ListAltOutlined color="info" />, text: "Detail" },
    { icon: <SummarizeOutlined color="warning" />, text: "Respond" },
    { icon: <EditNote color="info" />, text: "Note" },
    { icon: <ViewInArOutlined color="info" />, text: "View" },
  ];

  type Column = {
    label: string;
    id: string;
    minWidth?: number;
    render?: (row: ManufacturerInfo) => JSX.Element;
  };


  // Define columns and rows data
  const columns: Column[] = [
    { id: "manufacturer", label: "Manufacturer ", minWidth: 100 },
    { id: "contactPhone", label: "Contact Phone", minWidth: 100 },
    // { id: "contactEmail", label: "Contact Email", minWidth: 100 },
    {
      label: 'Notification Email',
      id: 'notificationEmail',
      render: (row: ManufacturerInfo) => (
        <pre>
          {row.notificationEmail} {/* The <pre> tag will preserve line breaks */}
        </pre>
      ),
    },
    { id: "action", label: "Action", minWidth: 50, },
  ];

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Manufacturer List" subtitle="List Of Manufacturers" />
        {/* <Collapse in={showCard}>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.5} mt={-3} mb={-1}>
                <Grid item xs={12} sm={3}>
                  <CustomDropdownField name="entityName" label="Audited Entity" options={[{ value: "IVR", label: "IVR" }, { value: "ORDER", label: "ORDER" }]} control={control} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomTextField controllerName="entityNumber" label="Entity Number" control={control} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomMultipleDropDown name="actionType" label="Action Type" options={[{ value: "Test", label: "Test" }]} control={control} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomDateRange
                    label="Updated Date"
                    control={control}
                    controllerName="dateRange"
                  // onChange={handleDateRangeChange}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Collapse> */}
        <Box pt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              {/* <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid> */}
              {/* <Grid item xs={12} md="auto">
                <Button fullWidth color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/manufacturer_create")} >
                  Create Manufacturer
                </Button>
              </Grid> */}
            </Grid>
            <Table columns={columns} rows={formattedManufacturerInfo} pageNumber={handlePagination}
              totalPages={1} totalElementText={"Records: 0"} // + (IVRListInfo?.totalElements ? IVRListInfo?.totalElements : "0")
              actionComponent={(row: ManufacturerInfo) => actionComponent(row)} emptyText="No Manufacturer(s) Found" currentPageNumber={1} showMenu={true} />
          </Card>
        </Box>
      </Box>
      {/* {deleteConfirm.show &&
        <OptionsPopup
          open={deleteConfirm.show}
          onClose={() => setDeleteConfirm({ message: "", show: false, row: "" })}
          variant="confirm"
          message={<>{deleteConfirm.message}</>}
          buttons={[{ name: "Confirm", color: 'primary', onClick: confirmDeleteIVR }, { name: "Cancel", color: 'secondary', onClick: cancelDeleteIVR }]}
        />
      }
      { cancelConfirm.show &&
      <OptionsPopup
        open={cancelConfirm.show}
        onClose={() => setCancelConfirm({ message: "", show: false, row: "" })}
        variant="confirm"
        message={<>{cancelConfirm.message}</>}
        buttons={[{ name: "Confirm", color: 'primary', onClick: confirmCancelIVR }, { name: "Cancel", color: 'secondary', onClick: cancelCancelIVR }]}
      />
      }
      { showInfoPopup.show &&
      <OptionsPopup
        open={showInfoPopup.show}
        onClose={() => setShowInfoPopup({ message: "Could not find any valid onboarding agreements. Please try again later.", show: false })}
        variant="info"
        message={<>{showInfoPopup.message}</>}
        buttons={[{ name: "Ok", color: 'primary', onClick: onClickInfoPopup }]}
      />
      }
      {showIvrCopyMessage.show &&
        <OptionsPopup
          open={showIvrCopyMessage.show}
          onClose={() => setShowIvrCopyMessage({ message: "Information on this IVR is 30 days or older. Please verify the information on this IVR.", show: false, row: "" })}
          variant="warning"
          message={<>{showIvrCopyMessage.message}</>}
          buttons={[{ name: "Ok", color: 'primary', onClick: () => onClickCopyIvrPopup(showIvrCopyMessage.row) }]}
        />
      } */}
    </>
  )
}

export default ManufacturerList;