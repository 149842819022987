import { Box, Checkbox, Grid, InputLabel } from "@mui/material";
import Header from "../../common/Header";
import { Cancel, SaveAlt } from "@mui/icons-material";
import Card from "../../common/Card";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { PatientInfo, createPatient, getPatient, updatePatientObjectReducer, usePatientReducer } from "../../../redux/slice/patient/patientSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomNametField from "../../common/CustomNametField";
import CustomTextField from "../../common/CustomTextField";
import CustomCheckbox from "../../common/CustomCheckBox";
import CustomRadioButton from "../../common/CustomRadioButton";



const CreatePatient: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { patientList, patientInfoObject, practiceList } = usePatientReducer();
    const { control, handleSubmit, reset } = useForm<PatientInfo>();
    const [textField, setTextField] = useState(1);

    const defaultValues = useCallback(() => {
        reset(
            {
                patientId: patientInfoObject?.patientId,
                name: patientInfoObject?.name,
                address: patientInfoObject?.address,
                unitNumber: patientInfoObject?.unitNumber,
                city: patientInfoObject?.city,
                state: patientInfoObject?.state,
                zip: patientInfoObject?.zip,
                primaryInsurance: patientInfoObject?.primaryInsurance,
                secondaryInsurance: patientInfoObject?.secondaryInsurance,
                practiceName: patientInfoObject?.practiceName
            })
    }, [patientInfoObject])

    useEffect(() => {
        defaultValues();
    }, [patientInfoObject])

    const onSubmit = (data: PatientInfo) => {
        console.log(data)
        let Obj: PatientInfo = {
            patientId: patientInfoObject?.patientId,
            name: data.name,
            address: data.address,
            unitNumber: data.unitNumber,
            city: data.city,
            state: data.state,
            zip: data.zip,
            primaryInsurance: data.primaryInsurance,
            secondaryInsurance: data.secondaryInsurance,
            practiceName: data.practiceName,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            nameList: [],
            birthDate: data.birthDate,
            gender: data.gender
        }
        //dispatch(createPatient({patientObj: Obj, navigate: navigate }));
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let patientId = urlSearchParams.get('id');
        patientList.map((patient: PatientInfo) => {
            if (patient.patientId === parseInt(patientId ? patientId : "0")) {
                dispatch(updatePatientObjectReducer(patient))
            }
        })
    }, []);

    const handleAddField = () => {
        setTextField(textField + 1);
    };

    return (
        <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
            <Header title="Patient Registration" subtitle="Patient Registration Form" />
            <Box mb="20px">
                <Card >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12}>
                            <CustomNametField
                              control={control}
                              context= "Patient"
                              name="name"
                              xs = {12}
                              md = {4}
                           />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="address"
                                    label="Address"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomDropdownField
                                    name="practiceName"
                                    control={control}
                                    options={[{ value: "0a", label: "0a" }, { value: "1b", label: "1b" }]}
                                    label="Practice Name"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="unitNumber"
                                    label="Unit Number"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="city"
                                    label="City"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="state"
                                    label="State"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="zip"
                                    label="Zip-Code"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="primaryInsurance"
                                    label="Primary Insurance"
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    control={control}
                                    controllerName="secondaryInsurance"
                                    label="Secondary Insurance"
                                />
 
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end' spacing={3}>
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            color="secondary"
                                            startIcon={<SaveAlt />}
                                            style={{
                                                padding: "15px",
                                            }}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            color='primary'
                                            startIcon={<Cancel />}
                                            style={{
                                                padding: "15px",
                                            }}
                                            onClick={() => navigate("/patients")}
                                            type='button'
                                        >
                                            cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Box>
        </Box>
    );
}

export default CreatePatient;