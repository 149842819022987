import { useEffect } from 'react';
import { reNewAccessToken, useAuthSlice } from '../redux/slice/authSlice';
import { useAppDispatch } from '../redux/slice/redux-hooks';

const useTokenWorker = () => {
  const dispatch = useAppDispatch();
  const { userInfo, isValid } = useAuthSlice();

  useEffect(() => {
    if (
      isValid &&
      userInfo.refreshToken !== null &&
      userInfo.refreshToken !== "" &&
      userInfo.accessTokenRenewTime &&
      userInfo.accessTokenRenewTime > 0 &&
      !userInfo.refreshTokenExpired
    ) {
      const tokenWorker = new Worker(new URL('./tokenWorker.ts', import.meta.url));
      let interval = (userInfo.accessTokenRenewTime * 1000 * 60) / 2;
      tokenWorker.postMessage({ interval });
      tokenWorker.onmessage = (event) => {
        if (event.data.type === 'fetch') {
          dispatch(reNewAccessToken(userInfo.refreshToken));
        }
      };
  
      return () => {
        tokenWorker.postMessage({ type: 'STOP' });
        tokenWorker.terminate();
      };
    }
  }, [userInfo.refreshToken, isValid]);
};

export default useTokenWorker;