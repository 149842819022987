import {
  Box,
  Grid,
  Collapse,
  Popover,
  useTheme,
  useMediaQuery,
  Link,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import {
  ModeOutlined,
  SearchOutlined,
  SettingsAccessibility,
} from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import LegacyToolBar from "../../common/LegacyToolBar";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AppConfigInfo,
  fetchAppConfigList,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { tokens } from "../../../theme";
import UpdateAppConfigPopup from "../../common/UpdateAppConfigPopup";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  padding?: string;
}

const ConfigList = () => {
  const { control } = useForm<any>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const legacyItems = [{ icon: <ModeOutlined color="info" />, text: "Edit" }];
  const [appConfigData, setAppConfigData] = useState<any>({
    appconfigId: "",
    configValue: "",
  });
  const [isEditing, setIsEditing] = useState(false); // State to control editing mode
  const { appConfigList } = useCommonReducer();
  const [showUpdateConfigPopup, setUpdateConfigPopup] = useState(false);
  const [origialConfigValue, setOrigialConfigValue] = useState<any>();

  useEffect(() => {
    dispatch(fetchAppConfigList());
  }, []);

  const configColumns: Column[] = [
    {
      id: "configName",
      label: "Config Name",
      minWidth: 10,
      padding: "30px",
      align: "center",
    },
    {
      id: "configValue",
      label: "Config Value",
      minWidth: 10,
      padding: "5px",
      align: "center",
    },
  ];

  const renderTextfieldComponent = (configValue: string) => {
    // Show the text field only if we're editing
    if (isEditing && configValue) {
      return (
        <>
          <CustomTextField
            controllerName="configValue"
            autoFocus
            control={control}
            value={appConfigData.configValue} // Use configValue from state
            onChange={(event) => {
              const inputData = event.target.value;
              // Update the state only if the new value is different
              if (inputData !== appConfigData.configValue) {
                setAppConfigData({ ...appConfigData, configValue: inputData });
              }
            }}
            onBlur={() => {
              // Close the text field when it loses focus
              setIsEditing(false);
              if (
                appConfigData?.configValue &&
                (appConfigData?.configValue).trim() !== configValue
              ) {
                setUpdateConfigPopup(true);
              }
            }}
            name="configValue"
          />
        </>
      );
    } else {
      return configValue;
    }
  };
  const configListData: any = useMemo(() => {
    let listData: any = [];
    if (appConfigList) {
      listData = appConfigList.map((data: AppConfigInfo) => ({
        appConfigId: data.appconfigId,
        configName: data.configName,
        configValue:
          appConfigData.appconfigId === data.appconfigId ? (
            renderTextfieldComponent(data.configValue)
          ) : (
            <span
              onClick={() => {
                setAppConfigData({
                  appconfigId: data.appconfigId, // Keep the current appConfigId
                  configValue: data.configValue, // Set the configValue to the clicked one
                });
                setIsEditing(true); // Enter editing mode
                setOrigialConfigValue(data.configValue);
              }}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              {data.configValue}
            </span>
          ), // Use the updated renderTextfieldComponent
      }));
    }
    return listData;
  }, [appConfigList, appConfigData, isEditing]);

  return (
    <>
      {showUpdateConfigPopup && (
        <UpdateAppConfigPopup
          open={showUpdateConfigPopup}
          appConfigData={appConfigData}
          originalConfigValue={origialConfigValue}
          onClose={() => {
            setUpdateConfigPopup(false);
            setIsEditing(false);
            setAppConfigData("");
          }}
        />
      )}
      <Box
        sx={{
          margin: {
            xs: "20px 10px",
            sm: "20px",
          },
        }}
      >
        <Header title="Configuration List" subtitle="Configuration" />
        <Box
          sx={{
            padding: "16px",
            backgroundColor: colors.grey[800],
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            margin: "auto",
            border : 2,
            borderColor: colors.error[500],
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.info[300], marginBottom: "8px" }}
          >
            Configuration Notice
          </Typography>
          <Typography variant="body1" sx={{ color: colors.error[400] }}>
            To change configuration, please click on{" "}
            <Typography
              component="span"
              sx={{ fontWeight: "bold", color: colors.error[300] }}
            >
              "Config Value"
            </Typography>
            . Please be sure while editing these values as it will affect the
            entire application instantly.
          </Typography>
        </Box>
        <Box mt={1}>
          <Card>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs />
            </Grid>
            <Table
              columns={configColumns}
              rows={configListData || []}
              emptyText="No Configuration(s) Found"
              showMenu={true}
              // totalPages={1}
              // actionComponent={(row: AppConfigInfo) => actionComponent(row)}
              // currentPageNumber={getValues("currentPage") ? getValues("currentPage") - 1 : 1}
            />
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ConfigList;
