import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  ArrowCircleRightOutlined,
  PhoneAndroid,
  EmailOutlined,
  Close,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import Header from "../common/Header";
import Card from "../common/Card";
import Button from "../common/Button";
import { tokens } from "../../theme";
import FormStepper from "../common/FormStepper";
import CustomTextField from "../common/CustomTextField";
import { twoFaSignUp } from "../../redux/slice/2FA/twoFactorSlice";

const SignUp2FA: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<"email" | "phone" | null>(null);
  const [step, setStep] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [existingOptions, setExistingOptions] = useState([
    { type: "email", value: "sample01@gmail.com" },
    { type: "phone", value: "123-456-7890" },
  ]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { control, handleSubmit, reset } = useForm<twoFaSignUp>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    "Introduction",
    "Get Verification Code",
    "Enter Verification Code",
  ];

  const handleOptionSelect = (option: "email" | "phone") => {
    setSelectedOption(option);
    setInputValue("");
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleOtpChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleRemoveExistingOption = (index: number) => {
    setExistingOptions(existingOptions.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header
        title="Two-factor authentication"
        subtitle="Two-factor authentication Management"
      />
      <Box>
        <Card sx={{ padding: isMobile ? 2 : 5, marginBottom: 5 }}>
          <FormStepper activeStep={step} setActiveStep={setStep} steps={steps}>
            <Box pt={2}>
              {step === 0 && (
                <>
                  <Box sx={{ marginTop: 4 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <ArrowCircleRightOutlined />
                      <Typography variant="h6" ml={1}>
                        Why have I reached this page?
                      </Typography>
                    </Box>
                    <Typography>
                      We take your security seriously. Please follow this brief
                      two-step process to help us verify your identity.
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <ArrowCircleRightOutlined />
                      <Typography variant="h6" ml={1}>
                        What do I need to do?
                      </Typography>
                    </Box>
                    <Typography>
                      We will send you the temporary identification code you
                      will need to enter before you can access the system. Press{" "}
                      <strong>Next</strong> to choose how you want to receive
                      your temporary identification code.
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button onClick={handleNext} startIcon={<ArrowForward />}>
                      Next
                    </Button>
                  </Box>
                </>
              )}
              {step === 1 && (
                <>
                  <Box sx={{ marginTop: 4 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <ArrowCircleRightOutlined />
                      <Typography variant="h6" ml={1}>
                        Existing Verification Options
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {existingOptions.map((option, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <Box
                            display="flex"
                            alignItems="center"
                            borderRadius={2}
                            bgcolor={colors.grey[700]}
                            p={2}
                            sx={{ cursor: "pointer", position: "relative" }}
                          >
                            {option.type === "email" ? (
                              <EmailOutlined
                                sx={{ width: 20, height: 20, marginRight: 2 }}
                              />
                            ) : (
                              <PhoneAndroid
                                sx={{ width: 20, height: 20, marginRight: 2 }}
                              />
                            )}
                            <Box>
                              <Typography variant="body1">
                                {option.value}
                              </Typography>
                            </Box>
                            <IconButton
                              sx={{
                                position: "absolute",
                                right: 3,
                                color: colors.grey[300],
                              }}
                              onClick={() => handleRemoveExistingOption(index)}
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    <Box display="flex" alignItems="center" mb={2} mt={2}>
                      <ArrowCircleRightOutlined />
                      <Typography variant="h6" ml={1}>
                        Select a Verification Method
                      </Typography>
                    </Box>
                    <Typography>
                      Select one of the following two options to receive your
                      temporary verification code.
                    </Typography>
                  </Box>

                  <Grid container spacing={2} pt={3}>
                    <Grid item xs={12} md={2}>
                      <Box
                        onClick={() => handleOptionSelect("email")}
                        sx={{
                          borderRadius: 2,
                          backgroundColor:
                            selectedOption === "email" ? colors.grey[900] : "",
                          border:
                            selectedOption === "email"
                              ? "2px solid green"
                              : "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{ padding: 20 }}
                        >
                          <EmailOutlined fontSize="large" />
                          <Typography>Email Address</Typography>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Box
                        onClick={() => handleOptionSelect("phone")}
                        sx={{
                          borderRadius: 2,
                          backgroundColor:
                            selectedOption === "phone" ? colors.grey[900] : "",
                          border:
                            selectedOption === "phone"
                              ? "2px solid green"
                              : "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{ padding: 20 }}
                        >
                          <PhoneAndroid fontSize="large" />
                          <Typography>Phone Number</Typography>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>

                  {selectedOption && (
                    <Box mt={4}>
                      <Box display="flex" alignItems="center" mb={2} mt={2}>
                        <ArrowCircleRightOutlined />
                        <Typography variant="h6" ml={1}>
                          Get Your Verification Code
                        </Typography>
                      </Box>
                      <Typography>
                        Please Enter your{" "}
                        {selectedOption === "email"
                          ? "Email Address"
                          : "Phone Number"}{" "}
                        below to receive the temporary verification code and{" "}
                        <strong>Next</strong> to proceed.
                      </Typography>
                      <Box sx={{ marginTop: 3 }}>
                        <CustomTextField
                          controllerName={
                            selectedOption === "email" ? "email" : "phone"
                          }
                          onChange={(e) => setInputValue(e.target.value)}
                          label={
                            selectedOption === "email"
                              ? "Email Address"
                              : "Phone Number"
                          }
                          control={control}
                        />
                      </Box>
                    </Box>
                  )}

                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button onClick={handleBack} startIcon={<ArrowBack />}>
                      Back
                    </Button>
                    <Button
                      onClick={handleNext}
                      disabled={!inputValue}
                      startIcon={<ArrowForward />}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              )}
              {step === 2 && (
                <Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <ArrowCircleRightOutlined />
                      <Typography variant="h6" ml={1}>
                        Enter Your Verification Code
                      </Typography>
                    </Box>
                    <Typography>
                      Please enter your verification code in the fields below.
                      This is the only time you will use this code, so there is
                      no need to save it.
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Grid container spacing={1}>
                      {otp.map((value, index) => (
                        <Grid item key={index}>
                          <CustomTextField
                            value={value}
                            onChange={(e) =>
                              handleOtpChange(index, e.target.value)
                            }
                            inputProps={{
                              maxLength: 1,
                              style: { textAlign: "center" },
                            }}
                            style={{ width: 50, marginRight: 8, minWidth :40 }}
                            control={control}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" gutterBottom>
                      <strong>My Verification Code did not arrive</strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Please allow extra time to receive your Verification Code
                      by email. We will send the code immediately, but many
                      factors (software settings, etc.) may influence how fast
                      it appears in your inbox. If you have a Spam folder,
                      please check there once.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>
                        Please make sure you have added noreply@v3biomed.com in
                        your address book.
                      </strong>
                    </Typography>
                    <Link href="#" variant="body1">
                      Request a new identification code
                    </Link>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button onClick={handleBack} startIcon={<ArrowBack />}>
                      Back
                    </Button>
                    <Button>Submit</Button>
                  </Box>
                </Box>
              )}
            </Box>
          </FormStepper>
        </Card>
      </Box>
    </Box>
  );
};

export default SignUp2FA;
