import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import {
  AttachmentBarcodeStickerInfo,
  OrderItemInfo,
  OrderInfo,
  TissueTrackingInfo,
  addTissueTracking,
  getOrderItemsByOrderId,
  updateElementInOrderSlice,
  useOrderSlice,
  AttachmentImageOfWoundsInfo,
} from "../../../redux/slice/order/OrderSlice";
import { useForm } from "react-hook-form";
import {
  Cancel,
  ClassOutlined,
} from "@mui/icons-material";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../../common/CustomCheckBox";
import CustomDatePicker from "../../common/CustomDatePicker";
import { tokens } from "../../../theme";
import { useEffect, useMemo, useState } from "react";
import { convertBase64 } from "../../../utils/Utility";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import React from "react";
import CustomHelpMessage from "../../common/CustomHelpMessage";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface TissueTrackingState extends TissueTrackingInfo {
  checkBoxes: string[],
  selectAll?: boolean
}

export interface OrderItemRow {
  select: JSX.Element;
  orderItemId: number;
  serialNumber: string;
  size: string;
  tissueTrackingId: number
  productReturnId: number;
  units: number;
}


const TissueTracking = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<TissueTrackingState>();

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderInfo>();
  const { orderItemsListInfo, addTissueTrackingStatus } = useOrderSlice();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: '', show: false });
  const [isAllAppliedOrReturned, setIsAllAppliedOrReturned] = useState(true);
  const [barcodeStickerInitialFiles, setBarcodeStickerInitialFiles] = useState<ExtendedFile[]>([]);
  const [imagesOfWoundsInitialFiles, setImagesOfWoundsInitialFiles] = useState<ExtendedFile[]>([]);
  const [selectedRow, setSelectedRow] = useState<OrderItemRow[] | []>([]); 

  useEffect(() => {
    const orderDetails: OrderInfo = location.state.selectedOrder;
    setOrder(orderDetails);
    if (orderDetails) {
      setInitialValues(orderDetails);
      dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    };
  }, []);

  const setInitialValues = (orderDetails: OrderInfo) => {
    setValue("orderId", orderDetails.orderId);
    if (orderDetails.patientId) {
      setValue('patientId', orderDetails.patientId);
    };
    setValue('productId', orderDetails.productId);
    // Restrict Date of Service as the default
    // if (dayjs(orderDetails.dateOfService).isValid()) {
    //   setValue("dateOfService", dayjs(orderDetails.dateOfService).format("MM/DD/YYYY"));
    // };
  };

  const handleDropZone = async (uploadedFiles: ExtendedFile[], fieldName: string) => {
    const attachmentBarcodeStickerArray: AttachmentBarcodeStickerInfo[] = [];
    if (uploadedFiles && uploadedFiles.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const base64File = String(await convertBase64(uploadedFiles[i]));
        const fileName = uploadedFiles[i].name;
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
        const base64Substring = base64File.substring(
          fileExtension === "pdf" ? "data:application/pdf;base64,".length : "data:image/png;base64,".length,
          base64File.length
        );
        const attachmentBarcodeStickerObj: AttachmentBarcodeStickerInfo = {
          documentName: fileName,
          content: base64Substring,
          fileType: fileExtension
        };
        attachmentBarcodeStickerArray.push(attachmentBarcodeStickerObj);
      }
    }
    setValue("attachmentBarcodeSticker", attachmentBarcodeStickerArray);
    trigger("attachmentBarcodeSticker");
  };

  const handleImageOfWounds = async (uploadedFiles: ExtendedFile[], fieldName: string) => {
    const attachmentImageOfWoundsArray: AttachmentImageOfWoundsInfo[] = [];
    if (uploadedFiles && uploadedFiles.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const base64File = String(await convertBase64(uploadedFiles[i]));
        const fileName = uploadedFiles[i].name;
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
        const base64Substring = base64File.substring(
          fileExtension === "pdf" ? "data:application/pdf;base64,".length : "data:image/png;base64,".length,
          base64File.length
        );
        const attachmentImageOfWoundsObj: AttachmentImageOfWoundsInfo = {
          documentName: fileName,
          content: base64Substring,
          fileType: fileExtension
        };
        attachmentImageOfWoundsArray.push(attachmentImageOfWoundsObj);
      }
    }
    setValue("attachmentImageOfWounds", attachmentImageOfWoundsArray);
    trigger("attachmentImageOfWounds");
  };


  const handleDateOfService = (date: Date | null) => {
    if (dayjs(date).isValid()) {
      setValue("dateOfService", dayjs(date).format("MM/DD/YYYY"));
    };
  };

  const onSubmit = () => {
    if (!isAllAppliedOrReturned) {
      if (checkedList?.length > 0) {
        clearErrors("attachmentBarcodeSticker");
        clearErrors("dateOfService");
        clearErrors("checkBoxes");
        clearErrors("selectAll");
        setShowOptionsPopup({
          message: "Do you really want to submit the the Tissue Tracking? ",
          show: true,
          variant: "confirm",
          buttons: [{ name: "Confirm", color: 'primary', onClick: () => submitTissueTracking() }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
        });
      }
    } else {
      let warningObj: SingleAlertInfo = {
        message: "Order items aren't available to Tissue Track.",
        alertType: "warning"
      };
      dispatch(setSingleAlertObj(warningObj));
    }
  };

  const submitTissueTracking = async () => {
    const { serialNumbers, checkBoxes, selectAll, ...submitObject } = getValues();
    const orderItemList: OrderItemInfo[] = [];
    checkedList?.map(itemId => {
      orderItemList.push({
        orderItemId: itemId,
        //@ts-ignore
        serialNumber: serialNumbers[`serialNumber${itemId}`]
      })
    });
    if (orderItemList?.length > 0) {
      submitObject.orderItems = orderItemList;
    };
    const response = dispatch(
      addTissueTracking({
        ...submitObject,
        isBarcodeStickerSkipped: getValues("isBarcodeStickerSkipped"),
      })
    );
    cancelOptionPopup();
  };

  const onError = (_errors: any, e: any) => {
    const { attachmentBarcodeSticker, dateOfService, attachmentImageOfWounds } = getValues();
    let hasError = false;
    
    if (checkedList?.length <= 0) {
      let errorObj: SingleAlertInfo = {
        message: "Please select at least one Order item.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errorObj));
      hasError = true;
      cancelOptionPopup();
      // clearErrors("attachmentBarcodeSticker");
    } else if (dateOfService === undefined || dateOfService === "" || dateOfService === null) {
      let errorObj: SingleAlertInfo = {
        message: "Date of Service is Required.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errorObj));
      hasError = true;
      cancelOptionPopup();
      clearErrors("checkBoxes");
      clearErrors("selectAll");

    } else if ((selectedRow.length === 0 || hasSerialNumberPresent(selectedRow)) && attachmentBarcodeSticker?.length <= 0) {
      let errorObj: SingleAlertInfo = {
        message: "Barcode Sticker Attachment is required.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errorObj));
      hasError = true;
      cancelOptionPopup();
      clearErrors("checkBoxes");
      clearErrors("selectAll");

    // } else if (attachmentImageOfWounds?.length <= 0) {
    //   let errorObj: SingleAlertInfo = {
    //     message: "Image of wounds are required.",
    //     alertType: "error"
    //   };
    //   dispatch(setSingleAlertObj(errorObj));
    //   hasError = true;
    //   cancelOptionPopup();
    //   cancelOptionPopup();
    //   clearErrors("checkBoxes");
    //   clearErrors("selectAll");
    //   clearErrors("attachmentBarcodeSticker");
    }
    else {
      const serialNumbers = getValues("serialNumbers");
      let isNotEmpty = false;
      const hasBarcodeSticker = getValues("attachmentBarcodeSticker").length > 0;

      checkedList?.map(itemId => {
        //@ts-ignore
        if (serialNumbers[`serialNumber${itemId}`]) {
          isNotEmpty = true;
        }
        //@ts-ignore
        else if (!serialNumbers[`serialNumber${itemId}`] && hasBarcodeSticker) {
          isNotEmpty = true;
        }
      });

      if (isNotEmpty) {
        clearErrors("attachmentBarcodeSticker");
        clearErrors("checkBoxes");
        clearErrors("selectAll");
      }
    }
    if (Object.keys(errors).length > 0 || hasError) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    if (addTissueTrackingStatus === 'success') {
      let successObj: SingleAlertInfo = {
        message: `Record Product Usage for Order ${order?.orderNumber} has been submitted successfully.`,
        alertType: "success"
      };
      dispatch(setSingleAlertObj(successObj));
      dispatch(updateElementInOrderSlice(
        {
          elementName: "addTissueTrackingStatus",
          value: ""
        }
      ));
      cancelOptionPopup();
      navigate(-1);
    }
  }, [addTissueTrackingStatus])

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  useEffect(() => {
    if (orderItemsListInfo) {
      const isAllItemAppliedOrReturned = orderItemsListInfo.every((item: { productReturnId: any; tissueTrackingId: any; }) => item.productReturnId || item.tissueTrackingId);
      setIsAllAppliedOrReturned(!!isAllItemAppliedOrReturned);
    };
  }, [orderItemsListInfo]);

  const orderItemRow: OrderItemRow[] = useMemo(() => {
    let orderItems: OrderItemRow[] = [];
    if (orderItemsListInfo) {
      orderItems = orderItemsListInfo.map(item => {
        //@ts-ignore
        setValue(`serialNumbers.serialNumber${item.orderItemId}`, item.serialNumber);
        return {
          select: <CustomCheckbox control={control} name="select" />,
          orderItemId: item.orderItemId,
          serialNumber: item.serialNumber,
          size: item.size,
          tissueTrackingId: item.tissueTrackingId,
          productReturnId: item.productReturnId,
          units: item.units
        }
      })
      .sort((a, b) => a.units - b.units);;
    }
    return orderItems;
  }, [orderItemsListInfo]);

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, row: OrderItemRow) => {
    const { checked } = e.target;
    if (checked) {
      row && setCheckedList(prevValues => {
        const newValues: number[] = [...prevValues];
        newValues.push(row.orderItemId);
        return newValues;
      });

      setSelectedRow((data)=>{
        let selectedOrderItemRow: OrderItemRow[]  = [];

        data?.map((itemRow)=>selectedOrderItemRow.push(itemRow));
        selectedOrderItemRow.push(row);
        return selectedOrderItemRow;
      })
    } else {
      setCheckedList(checkedList.filter(item => item !== row.orderItemId));

      setSelectedRow((data)=>{
        return data?.filter((itemRow)=> itemRow.orderItemId !== row.orderItemId) || [];
      })
      setValue("selectAll", false);
    }
  };

  const checkSerialNumberPresent = (row: OrderItemRow) => {
    if (checkedList.includes(row.orderItemId)) {
      return false;
    }
    return true;
  };

  const handleSerialNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: OrderItemRow) => {
    let existingRow = selectedRow.find(item => item.orderItemId === row.orderItemId);
    
    if (existingRow && existingRow !== null) {
      let updatedRow: OrderItemRow = {
        ...existingRow,
        serialNumber: e.target.value,
      };
  
      setSelectedRow((prevRows) => {
        let otherRows = prevRows?.filter((item) => item.orderItemId !== row.orderItemId) || [];
        return [...otherRows, updatedRow];
      });
    }
  }

  const hasSerialNumberPresent = (row: OrderItemRow[]) => {
    let hasNoSerialNumber = false;

    row?.map((element: OrderItemRow) => {
      if (element.serialNumber === "" || element.serialNumber === null) {
        hasNoSerialNumber = true;
      }
    })

    return hasNoSerialNumber;
  };

  const isCheckBoxDisable = (row: OrderItemRow) => {
    if (row?.tissueTrackingId || row?.productReturnId) {
      return true;
    };
    return false;
  };

  const orderItemStatus = (row: OrderItemRow) => {
    if (row?.tissueTrackingId) {
      return "Applied";
    } else if (row?.productReturnId) {
      return "Returned";
    }
    return "Available";
  };


  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const selectList: number[] = [];
      orderItemRow?.forEach(row => {
        if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
          selectList.push(row.orderItemId);
          //@ts-ignore
          setValue(`checkBoxes.checkbox${row.orderItemId}`, true);
  
          setSelectedRow((prevSelectedRows) => {
            if (!prevSelectedRows?.some(itemRow => itemRow.orderItemId === row.orderItemId)) {
              return [...prevSelectedRows, row];
            }
            return prevSelectedRows;
          });
        }
      });
  
      setCheckedList(selectList);

    } else {
      setCheckedList([]);
      orderItemRow?.forEach(row => {
        if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
          //@ts-ignore
          setValue(`checkBoxes.checkbox${row.orderItemId}`, false);

          setSelectedRow((prevSelectedRows) => {
            return prevSelectedRows?.filter(itemRow => itemRow.orderItemId !== row.orderItemId) || [];
          });
        }
      });
    }
  };

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      {showOptionPopup.show &&
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      }
      <Box mb={2}> 
        <Grid container spacing={2} display="flex" alignItems="center">
          <Grid item>
            <Header title="Record Product Usage" subtitle="Order Management" 
            message="The procedure was a success! Now let's document the procedure and the products used, applied, or implanted. To do so, please fill the below fields by following the prompts below."/>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Box p={1}>
              <Grid container spacing={2} mb={2}>
                <Grid item md={4} sm={6}>
                  <Typography component="label" fontWeight="bold">
                    Patient :{" "}
                  </Typography>
                  <Typography style={{ paddingLeft: "10px" }} component="label">{order?.patientName}</Typography>
                </Grid>
                <Grid item md={4} sm={6}>
                  <Typography component="label" fontWeight="bold">
                    Product :{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: "10px" }} component="label">{`${order?.productName} (${order?.manufacturerName})`}</Typography>
                </Grid>
                <Grid item md={4} sm={6}>
                  <Typography component="label" fontWeight="bold">
                    Order # :{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.orderNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2} mt={2} display="flex" alignItems="center">
                <Grid item>
                  <Typography variant="h4" fontWeight="bold">
                    Date of Service (DOS)
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid item>
                    <CustomHelpMessage
                      title="Help"
                      message="Select the correct Date of Service, the date the product was used to treat the patient listed above." />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={4}>
                  <CustomDatePicker
                    control={control}
                    onChange={handleDateOfService}
                    controllerName="dateOfService"
                    label="Date Of Service"
                    rules={!isAllAppliedOrReturned ? { required: "Date of Service is Required." } : {}}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                />
              </Grid>
              <Grid container spacing={2} mb={2} mt={2} display="flex" alignItems="center" flexDirection="row" wrap="nowrap">
                <Grid item>
                  <Typography variant="h4" fontWeight="bold">
                    Select the Products Utilized or Applied
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomHelpMessage
                    title="Help"
                    message="Check the box of each product item that was utilized or applied to this patient on the above Date of Service. Ensure the Serial # aligns with the Tissue ID# on the barcode sticker found in the product's original packaging." />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2} mt={1}>
                <Grid item md={8} mb={2}>
                  <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                    <ListItem>
                      <ListItemButton
                        onClick={() => { }}
                        sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                      >
                        <ListItemIcon sx={{ width: "15%", textAlign: 'left' }}>
                          <CustomCheckbox
                            control={control}
                            controllerName="selectAll"
                            onChange={handleSelectAllCheckbox}
                            tooltipMessage="Select All"
                            tooltipPlacement="top"
                            rules={!isAllAppliedOrReturned ? { required: true } : {}}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ width: "30%" }}>
                          <b>Ordered Size</b>
                        </ListItemText>
                        <ListItemText sx={{ width: "40%" }}>
                          <b>Serial Number</b>
                        </ListItemText>
                        <ListItemText sx={{ width: "10%" }}>
                        </ListItemText>
                        <ListItemText sx={{ width: "40%" }}>
                          <b>Status</b>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    {orderItemRow.map((row) => (
                      <ListItem key={row.orderItemId}>
                        <ListItemButton
                          onClick={() => { }}
                          sx={{ padding: 0, borderRadius: 2 }}
                        >
                          <ListItemIcon sx={{ width: "15%" }}>
                            <CustomCheckbox isDisabled={isCheckBoxDisable(row)}
                              onChange={(e) => handleCheckBox(e, row)}
                              control={control}
                              controllerName={`checkBoxes.checkbox${row.orderItemId}`}
                              rules={!isAllAppliedOrReturned ? { required: true } : {}}
                            />
                          </ListItemIcon>
                          <ListItemText sx={{ width: "30%" }}>
                            {row.size}
                          </ListItemText>
                          <CustomTextField
                            control={control}
                            //@ts-ignore
                            isDisabled={checkSerialNumberPresent(row)}
                            controllerName={`serialNumbers.serialNumber${row.orderItemId}`}
                            label="Serial Number"
                            sx={{ width: "40%" }}
                            onChange={(e) => handleSerialNumberChange(e, row)}
                          />
                          <ListItemText sx={{ width: "10%" }}>
                          </ListItemText>
                          <ListItemText sx={{ width: "40%" }}>
                            {orderItemStatus(row)}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2} mt={2} display="flex" alignItems="center" flexDirection="row" wrap="nowrap" >
                <Grid item>
                  <Typography variant="h4" fontWeight="bold">
                    Upload Image of Barcode Sticker(s) [Optional]
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomHelpMessage
                    title="Help"
                    message=" Check the box of each product item that was utilized or applied to this patient on the above Date of Service." />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={3} xs={12}>
                  <Dropzone
                    onFileChangeTest={handleDropZone}
                    name="attachmentBarcodeSticker"
                    onFileChange={() => { }}
                    control={control}
                    subLabel={{
                      textColor: colors.error[300],
                      text: (selectedRow.length === 0 || hasSerialNumberPresent(selectedRow)) && getValues("attachmentBarcodeSticker")?.length <= 0
                        ? <></>
                        : <span style={{ fontStyle: 'italic', fontSize: '14px' }}>(Not Required)</span>
                    }}
                    rules={selectedRow.length === 0 || !hasSerialNumberPresent(selectedRow) ? undefined : { required: "Barcode Sticker Attachment is required." }}
                    initialFiles={barcodeStickerInitialFiles}
                    holdOnChange={true}
                    isDisabled={getValues("isBarcodeStickerSkipped")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2} mt={6} display="flex" alignItems="center" flexDirection="row" wrap="nowrap" >
                <Grid item>
                  <Typography variant="h4" fontWeight="bold">
                    Upload Image of Wound [Optional]
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomHelpMessage
                    title="Help"
                    message=" Here can upload an image of the wound associated to this procedure and DOS. This will bolster your protection during any audit." />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={3} xs={12}>
                  <Dropzone
                    onFileChangeTest={handleImageOfWounds}
                    name="attachmentImageOfWounds"
                    onFileChange={() => { }}
                    control={control}
                    // rules={{ required: "Image of wounds are required." }}
                    initialFiles={imagesOfWoundsInitialFiles}
                    holdOnChange={true}
                  />
                </Grid>
              </Grid>         
              <Grid container spacing={2} mb={2} mt={2}>
                <Grid item md={8} xs={12}>
                  <CustomTextField
                    controllerName="comment"
                    control={control}
                    label="Any Additional Comments for this Tissue Utilization Record? List them here."
                    multiline
                    rows={4}
                    maxRows={4}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <React.Fragment>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ pt: 2 }}
                  >
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="success"
                        startIcon={<ClassOutlined />}
                        sx={{ mr: 1 }}
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        color="error"
                        startIcon={<Cancel />}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Stack>
                </React.Fragment>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default TissueTracking;
