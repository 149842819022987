import React, { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import { tokens } from "../../../theme";
import { useForm } from 'react-hook-form';
import { Cancel, ClassOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCheckbox from '../../common/CustomCheckBox';
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import CustomNametField from '../../common/CustomNametField';
import CustomDropdownField from '../../common/CustomDropdownFiled';
import CustomDatePicker from '../../common/CustomDatePicker';
import { getPatient, getPatientListByPracticeId, usePatientReducer } from '../../../redux/slice/patient/patientSlice';
import { convertBase64, fullName } from '../../../utils/Utility';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { useAuthSlice } from '../../../redux/slice/authSlice';
import { USER_ROLES } from '../../../constants/applicationConstants';
import { SingleAlertInfo, setSingleAlertObj } from '../../../redux/slice/commonSlice';
import { AttachmentBarcodeStickerInfo, OrderItemInfo, OrderInfo, TissueTrackingInfo, addTissueTracking, getOrderItemsByOrderId, updateElementInOrderSlice, useOrderSlice } from '../../../redux/slice/order/OrderSlice';
import dayjs from 'dayjs';
import { OrderItemRow } from './TissueTracking';
import OptionsPopup, { OptionPopupObjectProps } from '../../common/OptionsPopup';


type patientInfo = {
    patientFirstName: string,
    patientMiddleName: string,
    patientLastName: string,
    patientDOB: string
};

interface ExtendedTissueTrackingInfo extends TissueTrackingInfo {
    patientInfo: patientInfo
    checkBoxes: string[];
    selectAll: boolean;
};

const ConsignmentOrder = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { control, getValues, setValue, reset, handleSubmit } = useForm<ExtendedTissueTrackingInfo>();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useAppDispatch();
    const { patientList } = usePatientReducer();
    const { orderItemsListInfo, addTissueTrackingStatus } = useOrderSlice();
    const { userInfo } = useAuthSlice();
    const [ checkedList, setCheckedList ] = useState<number[]>([]);
    const [ order, setOrder ] = useState<OrderInfo>();
    const [ showOptionPopup, setShowOptionsPopup ] = useState<OptionPopupObjectProps>({message:'', show:false});
    const [ isAllAppliedOrReturned, setIsAllAppliedOrReturned ] = useState(true);
    const [ isDisablePatientFields, setIsDisablePatientFields ] = useState(false);

    //Mounting
    useEffect(() => {
        const orderDetails = location.state.selectedOrder;
        setOrder(orderDetails);
        if(orderDetails) {
            setInitialValues(orderDetails);
            dispatch(getOrderItemsByOrderId(orderDetails.orderId));
          };
        if (orderDetails?.practiceId) {
            fetchPatientsList(orderDetails.practiceId);
        }
    }, []);

    const setInitialValues = (orderDetails: OrderInfo) => {
        setValue("orderId", orderDetails.orderId);
        setValue('productId', orderDetails.productId);
        if(dayjs(orderDetails.dateOfService).isValid()){
            setValue("dateOfService", dayjs(orderDetails.dateOfService).format("MM/DD/YYYY"));
        };
      };
    
    const fetchPatientsList = async (practiceId: string) => {
        if (userInfo?.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
            await dispatch(getPatientListByPracticeId({ practiceId: practiceId }));
        } else if (userInfo?.userRole === USER_ROLES.ROLE_ADMIN) {
            // await dispatch(getPatientList());
            await dispatch(getPatientListByPracticeId({ practiceId: practiceId }));
        };
    };

    const handlePatientChange = async (e: any) => {
        const patientId = getValues(`patientId`);
        if (patientId) {
            const response = await dispatch(getPatient({ patientId: patientId.toString() }))
            if (response.payload.message === "SUCCESS") {
                const data = response.payload.data;
                setValue('patientInfo.patientFirstName', data.firstName)
                setValue('patientInfo.patientMiddleName', data.middleName ? data.middleName : "")
                setValue('patientInfo.patientLastName', data.lastName)
                setValue('patientInfo.patientDOB', data.birthDate)
                setIsDisablePatientFields(true);
            }
        }
    };

    const patientsOptions = React.useMemo(() => {
        let options: { label: string; value: string }[] = [];
        patientList?.map((option: any) => {
          options.push({ label: fullName("",option.firstName,option.middleName,option.lastName), value: option.patientId });
        });
        return options;
      }, [patientList]);

      const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, row: OrderItemRow) => {
        const { checked } = e.target;
        if (checked) {
            row && setCheckedList(prevValues => {
                const newValues : number[] = [...prevValues];
                newValues.push(row.orderItemId);
                return newValues;
            });
        } else {
            setCheckedList(checkedList.filter(item => item !== row.orderItemId));
            setValue('selectAll', false);
        }
    };

    const onSubmit = () => {
        if(isAllAppliedOrReturned) {
            let warningObj: SingleAlertInfo = {
                message: "Order items aren't available to Tissue Track.",
                alertType: "warning"
              };
              dispatch(setSingleAlertObj(warningObj));
        } else {
            if (checkedList?.length > 0) {
                setShowOptionsPopup({
                    message: "Do you really want to submit the the Tissue Tracking? ",
                    show: true,
                    variant: "confirm",
                    buttons: [{ name: "Confirm", color: 'primary', onClick: () => submitTissueTracking() }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
                });
            } else {
                let errorObj: SingleAlertInfo = {
                    message: "Please select atleast one Order item.",
                    alertType: "error"
                };
                dispatch(setSingleAlertObj(errorObj));
                cancelOptionPopup();
            };
        }

    };
    
    const submitTissueTracking = async () => {
        const { serialNumbers, selectAll, checkBoxes, ...submitObject } = getValues();
        const orderItemList: OrderItemInfo[] = [];
        checkedList?.map(itemId => {
            orderItemList.push({
                orderItemId: itemId,
                //@ts-ignore
                serialNumber: serialNumbers[`serialNumber${itemId}`]
            })
        });
        if (orderItemList?.length > 0) {
            submitObject.orderItems = orderItemList;
        };
        const response = await dispatch(addTissueTracking(submitObject));
        cancelOptionPopup();
    };

    useEffect(() => {
        if(addTissueTrackingStatus === 'success') {
          let successObj: SingleAlertInfo = {
            message: "Tissue Tracking has been submitted successfully.",
            alertType: "success"
          };
          dispatch(setSingleAlertObj(successObj));
          dispatch(updateElementInOrderSlice(
           { elementName : "addTissueTrackingStatus",
            value: ""}
          ));
          cancelOptionPopup();
          navigate(-1);
        }
      }, [addTissueTrackingStatus])

    const cancelOptionPopup = () => {
        setShowOptionsPopup({ message: "", show: false });
    };

    useEffect(() => {
        if(orderItemsListInfo) {
            const isAllItemAppliedOrReturned = orderItemsListInfo.every(item => item.productReturnId || item.tissueTrackingId);
            setIsAllAppliedOrReturned(!!isAllItemAppliedOrReturned);
        };
    }, [orderItemsListInfo]);

    const orderItemRow: OrderItemRow[] = useMemo(() => {
        let orderItems: OrderItemRow[] = [];
        if(orderItemsListInfo) {
          orderItems = orderItemsListInfo.map(item => {
            //@ts-ignore
            setValue(`serialNumbers.serialNumber${item.orderItemId}`, item.serialNumber);
            return {
              select: <CustomCheckbox control={control} name="select" />,
              orderItemId: item.orderItemId,
              serialNumber: item.serialNumber,
              size: item.size,
              tissueTrackingId: item.tissueTrackingId,
              productReturnId: item.productReturnId,
              units: item.units
            }
          })
          .sort((a, b) => a.units - b.units);
        }
        return orderItems;
      }, [orderItemsListInfo]);

      const checkSerialNumberPresent = (row: OrderItemRow) => {
        if(checkedList.includes(row.orderItemId)) {
            return false;
          } 
          return true;
      };
      
      const isCheckBoxDisable = (row: OrderItemRow) => {
        if(row?.tissueTrackingId || row?.productReturnId){
          return true;
        };
        return false;
      };

      const orderItemStatus = (row: OrderItemRow) => {
        if(row?.tissueTrackingId){
          return "Applied";
        } else if(row?.productReturnId) {
          return "Returned";
        }
        return "Available";
      };
      
      const handleDropZone = async (uploadedFiles: ExtendedFile[], fieldName: string) => {
        const attachmentBarcodeStickerArray: AttachmentBarcodeStickerInfo[] = [];
        if (uploadedFiles && uploadedFiles.length > 0) {
          for (let i=0; i < uploadedFiles.length; i++) {
            const base64File = String(await convertBase64(uploadedFiles[i]));
            const fileName = uploadedFiles[i].name;
            const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
            const base64Substring = base64File.substring(
              fileExtension === "pdf" ? "data:application/pdf;base64,".length  : "data:image/png;base64,".length, 
              base64File.length);
              const attachmentBarcodeStickerObj:AttachmentBarcodeStickerInfo = {
                documentName: fileName,
                content: base64Substring,
                fileType: fileExtension
              };
            attachmentBarcodeStickerArray.push(attachmentBarcodeStickerObj);
          };
          setValue("attachmentBarcodeSticker", attachmentBarcodeStickerArray);
        }
      };

    const handleDateOfService = (date: Date | null) => {
        if (dayjs(date).isValid()) {
            setValue("dateOfService", dayjs(date).format("MM/DD/YYYY"));
        };
    };


    const handlePatientDOB = (date: Date | null) => {
        if(dayjs(date).isValid()) {
            setValue('patientInfo.patientDOB', dayjs(date).format("MM/DD/YYYY"));
        };
    };

    const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
          const selectList: number[] = [];
          orderItemRow?.forEach(row => {
            if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
                selectList.push(row.orderItemId);
                //@ts-ignore
                setValue(`checkBoxes.checkbox${row.orderItemId}`, true);
            };
          });
          setCheckedList(selectList)
        } else {
          setCheckedList([]);
          orderItemRow?.forEach(row => {
            if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
              //@ts-ignore
              setValue(`checkBoxes.checkbox${row.orderItemId}`, false);
            };
          });
        };
      };

    return (
        <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
            {showOptionPopup.show &&
                <OptionsPopup
                    open={showOptionPopup.show}
                    onClose={() => setShowOptionsPopup({ message: "", show: false })}
                    variant={showOptionPopup.variant}
                    message={<>{showOptionPopup.message}</>}
                    buttons={showOptionPopup.buttons}
                    title={showOptionPopup.title}
                    obj={showOptionPopup.obj}
                />
            }
            <Header title="Consignment Order Tissue Tracking" subtitle="Order Management" />
            <Box>
                <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <CustomDropdownField
                                    name="patientId"
                                    control={control}
                                    options={patientsOptions}
                                    label="Patient"
                                    onChangeSelect={(e) => handlePatientChange(e)}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CustomNametField
                                    control={control}
                                    name="patientInfo"
                                    context="Patient"
                                    xs={12}
                                    isRequired={!isAllAppliedOrReturned}
                                    isDisabled={isDisablePatientFields}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <CustomDatePicker
                                    control={control}
                                    controllerName={`patientInfo.${'patientDOB'}`}
                                    label="Patient DOB"
                                    onChange={handlePatientDOB}
                                    disableFuture={true}
                                    rules={ !isAllAppliedOrReturned ? { required: "Patient DOB is required" } : {} }
                                    disabled={isDisablePatientFields}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2} mt={2}>
                            <Grid item md={6}>
                                <Typography component="label" fontWeight="bold">
                                    Product :{" "}
                                </Typography>
                                <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.productName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item md={6}>
                                <Typography component="label" fontWeight="bold">
                                    Order # :{" "}
                                </Typography>
                                <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.orderNumber}</Typography>
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={3}>
                                <Typography component="label" fontWeight="bold">
                                    PO # :{" "}
                                </Typography>
                                <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.poNumber}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item md={4}>
                                <CustomDatePicker
                                    control={control}
                                    controllerName="dateOfService"
                                    onChange={handleDateOfService}
                                    label="Date of Service"
                                    rules={ !isAllAppliedOrReturned ? { required: "Date of Service is Required. " } : {}}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} mb={2} mt={1}>
                            <Grid item md={8} mb={2}>
                                    <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                                        <ListItem>
                                            <ListItemButton
                                                onClick={() => { }}
                                                sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                                            >
                                                <ListItemIcon sx={{ width: "15%", textAlign: 'left' }}>
                                                    <CustomCheckbox
                                                        control={control}
                                                        controllerName="selectAll"
                                                        onChange={handleSelectAllCheckbox}
                                                        tooltipMessage="Select All"
                                                        tooltipPlacement="top"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText sx={{ width: "30%" }}>
                                                    <b>Ordered Size</b>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "40%" }}>
                                                    <b>Serial Number</b>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "10%" }}>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "40%" }}>
                                                    <b>Status</b>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                        {orderItemRow.map((row) => (
                                            <ListItem key={row.orderItemId}>
                                                <ListItemButton
                                                    onClick={() => { }}
                                                    sx={{ padding: 0, borderRadius: 2 }}
                                                >
                                                    <ListItemIcon sx={{ width: "15%" }}>
                                                        <CustomCheckbox 
                                                        isDisabled={isCheckBoxDisable(row)} 
                                                        onChange={(e) => 
                                                        handleCheckBox(e, row)} 
                                                        control={control} 
                                                        controllerName={`checkBoxes.checkbox${row.orderItemId}`}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ width: "30%" }}>
                                                        {row.size}
                                                    </ListItemText>
                                                    <CustomTextField
                                                        control={control}
                                                        //@ts-ignore
                                                        isDisabled={checkSerialNumberPresent(row)}
                                                        controllerName={`serialNumbers.serialNumber${row.orderItemId}`}
                                                        label="Serial Number"
                                                        sx={{ width: "40%" }}
                                                    />
                                                    <ListItemText sx={{ width: "10%" }}>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "40%" }}>
                                                        {orderItemStatus(row)}
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item md={4} xs={12}>
                                    <Dropzone
                                        onFileChangeTest={handleDropZone}
                                        name=""
                                        onFileChange={() => { }}
                                        control={control}
                                        label="Attachment Barcode Sticker"
                                    />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                                type="submit"
                                color="success"
                                startIcon={<ClassOutlined />}
                                sx={{ mr: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                color="error"
                                startIcon={<Cancel />}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>

                    </form>
                </Card>
            </Box>
        </Box>
    );
}

export default ConsignmentOrder