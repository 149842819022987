import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Payment } from "@mui/icons-material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomTable from "../../common/CustomTable";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  size: string;
  units: number;
  qty: number;
  cogPU: string;
  cogSale: string;
}

const columns: Column[] = [
  { id: "size", label: "Size", minWidth: 100 },
  { id: "units", label: "Units", minWidth: 100 },
  { id: "qty", label: "QTY", minWidth: 100 },
  { id: "cogPU", label: "COGs P/U", minWidth: 100 },
  { id: "cogSale", label: "COGs/Sales", minWidth: 100 },
];

const rows: Row[] = [
  { size: "4×6 cm", units: 24, qty: 1, cogPU: "$315.25", cogSale: "$7,566.00" },
  { size: "4×4 cm", units: 16, qty: 1, cogPU: "$315.25", cogSale: "$5,044.00" },
  { size: "3×8 cm", units: 24, qty: 1, cogPU: "$315.25", cogSale: "$7,566.00" },
];

const Invoice = () => {
  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header title="Invoice" subtitle="Invoice Management" />
      <Box>
        <Card>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6" fontWeight="bold">
              Invoice# 44664
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Mfg:</strong> Parametrics
              </Typography>
              <Typography variant="body1">
                <strong>Product:</strong> Restorigin
              </Typography>
              <Typography variant="body1">
                <strong>Order #:</strong> O2408900231
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Practice:</strong> NV Health Solutions
              </Typography>
              <Typography variant="body1">
                <strong>Agency:</strong> Wound Biologics
              </Typography>
              <Typography variant="body1">
                <strong>Agent:</strong> Mark Smith
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ marginTop: 2 }}>
            <CustomTable
              columns={columns}
              rows={rows}
              disablePagination={true}
              borderBox={true}
            />
          </Box>

          <Box sx={{ textAlign: "right", marginTop: 2 }}>
            <Typography variant="body1" fontWeight="bold">
              $20,176.00
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Total Comm %:</strong> 42.5%
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="body1">
                <strong>$8,574.80</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Agency Comm:</strong> 32.5% Wound Biologics
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="body1">
                <strong>$6,557.20</strong>
              </Typography>
              <IconButton color="warning">
                <Payment />
              </IconButton>
              <Typography variant="body1" component="span">
                Mark Paid
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Override Comm:</strong> 1.0% Bill Cochrane
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="body1">
                <strong>$201.76</strong>
              </Typography>
              <IconButton color="warning">
                <Payment />
              </IconButton>
              <Typography variant="body1" component="span">
                Mark Paid
              </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="body1">
              <strong>V3 Net Comm:</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ textAlign: "right", marginTop: 2 }}>
              <Typography variant="body1" fontWeight="bold">
                $1,815.84
              </Typography>
            </Box>
          </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default Invoice;
