import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "./Button";
import { Cancel, VpnKey } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { Box, Grid, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { priceFormatter } from "../../utils/Utility";
import { tokens } from "../../theme";

interface CreditMemoPopupProps {
    open: boolean;
    onClose: () => void;
    recordCreditMemo: any
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CreditMemoTable: React.FC<CreditMemoPopupProps> = ({
    open,
    onClose,
    recordCreditMemo
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        onClose();
        // defaultValues();
    };

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                onClick={(event) => event.stopPropagation()}
                maxWidth="lg"  // Make the dialog wider (large size)
                fullWidth
            >
                <DialogTitle variant="h4"
                    sx={{ bgcolor: colors.grey[900] }}
                >Credit Memo History</DialogTitle>
                <DialogContent
                    sx={{ bgcolor: colors.grey[900] }}
                >
                    <Box>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <List>
                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <ListItemText sx={{
                                            width: '10%', color: theme.palette.mode === "dark"
                                                ? colors.info[300]
                                                : colors.grey[500]
                                        }}>
                                            <b>Date Of Credit</b>
                                        </ListItemText>
                                        <ListItemText sx={{
                                            width: '10%', color: theme.palette.mode === "dark"
                                                ? colors.info[300]
                                                : colors.grey[500]
                                        }}>
                                            <b>Credit Amount</b>
                                        </ListItemText>
                                        <ListItemText sx={{
                                            width: '20%', color: theme.palette.mode === "dark"
                                                ? colors.info[300]
                                                : colors.grey[500]
                                        }}>
                                            <b>User</b>
                                        </ListItemText>
                                        <ListItemText sx={{
                                            width: '20%', color: theme.palette.mode === "dark"
                                                ? colors.info[300]
                                                : colors.grey[500]
                                        }}>
                                            <b>Comments</b>
                                        </ListItemText>
                                    </ListItem>

                                    {recordCreditMemo?.creditMemoDTOList?.length > 0 && (
                                        <>
                                            {recordCreditMemo?.creditMemoDTOList?.map((row: any) => (
                                                <ListItem key={row?.productReturnId} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <ListItemText sx={{ width: '10%' }}>
                                                        {row.dateOfCredit}
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: '10%' }}>
                                                        {priceFormatter(row.creditAmount)}
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: '20%' }}>
                                                        {row.auditUserName}
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: '20%' }}>
                                                        {row.comments}
                                                    </ListItemText>
                                                </ListItem>
                                            ))}
                                        </>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ bgcolor: colors.grey[900] }}
                >
                    <Grid container justifyContent="flex-end" >
                        <Grid item xs={12} sm="auto">
                            <Button color="error" startIcon={<Cancel />} onClick={() => handleClose()} fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreditMemoTable;
