
import { http } from "./http";
import { SEARCH_AUDIT_LOGS_URL } from "./URL";

export const getAuditLogList = async (data: any) => {
  try {
    const response = await http.post(SEARCH_AUDIT_LOGS_URL, data);
    return response;
  } catch (error) {
    throw error;
  }
};
