import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { orderCreate, getOrderItemsById, returnOrder, searchOrder, tissueTracking, orderUpdate, submitClaim, submitReimbursedClaim, getOrderDocumentByDocumentId, getOrderDocumentsByDocumentIds, orderCancel } from "../../../service/OrderService";
import { LoadingType } from "../../../types/CommonTypes";
import { updateElementValue } from "../../../utils/updateElementValue";
import { fetchProductsByUserRole, fetchProductsIncludingInactiveByUserRole } from "../../../service/ProductService";
import { fetchPracticesByUserRole } from "../../../service/PracticeService";
import { PracticeInfo } from "../practiceSlice";
import { ProductInfo } from "../product/productListSlice";

export type OrderDocumentInfo = {
  ivrId: any;
  documentId?: number;
  documentName: string;
  content: string;
  documentType?: string;
  type?: string;
  fileType: string;
  auditTimestamp?: string;
};

export type SearchOrder = {
  practiceIds: number[];
  manufacturerIds: number[];
  patientName: string;
  productIds: number[];
  providerName: string;
  orderNumber: string;
  invoiceNumber: string;
  //cs-382
  // dateOfOrder: string;
  fromDate: string | undefined;
  toDate: string | undefined;
  //
  // dateOfService: string;
  status: string[];
  invoiceStatus: string[];
  currentPage: number;
  itemsPerPage: number;
  downloadState: boolean;

  fromServiceDate: string | undefined;
  toServiceDate: string | undefined;
  withoutIvr?: string;
  dateRange?: {
    fromDate: string;
    toDate: string;
  }; 
};

export type CreateOrder = {
  practice: string;
  patient: string;
  product: string;
  provider: string;
  dateOrdered: string;
  dateOfService: string;
  status: string;
};

export type TissueTrackingDetail = {
  patient: string;
  product: string;
  order: string;
  po: string;
  dateOfService: string;
  shippingCarrier?: string;
  trackingNumber?: string;
  orderStatus?: string;
  providerName?: string;
  practiceName?: string;
};

export type GraftQty = {
  productItemId: number,
  quantity: number
}

export type PatientDetails = {
  firstName: string,
  middleName?: string,
  lastName: string,
  birthDate: string,
  gender: string
}

export type AddOrderInfo = {
  practiceId: number | string,
  ivrId?: number,
  productId: number | string | undefined,
  practiceProductId: string | number | undefined,
  patientId?: number,
  orderType: string,
  // orderDate: string,
  requestedDeliveryDate: string,
  requestedShippingMethod: string,
  dateOfService: string,
  poNumber?: string | undefined,
  orderInfo: {
    practice: string,
    patient?: string,
    provider?: string,
    facilityAddress: string,
    providerId?:string | number
    manufacturerEmailEnable?: boolean
  },
  orderItems: GraftQty[] | undefined,
  orderId?: number,
  patient?: PatientDetails,
  orderDate?:string,
  manufacturerEmailEnable?: boolean
}

export type OrderReturn = {
  orderId: number;
  productId: number;
  returnReason: string;
  returnDate: string;
  otherReturnReason: string;
  signature: string;
  name: string;
  productNotDamaged: boolean,
  productNotTransfer: boolean,
  productStoredInRecommendedEnv: boolean,
  isShippingLabel: boolean,
  orderItems: {
    orderItemId: number;
    serialNumber?: string;
  }[];
 
  returnTrackingInfo: {
    returnCarrier: string;
    returnTracking: string;
  };
  attachmentBarcodeSticker: {
    documentName: string;
    content: string;
    fileType: string;
  }[];
  attachmentProductBoxPictures: {
    documentName: string;
    content: string;
    fileType: string;
  }[];
  additionalContext: string;
 
};

export type TissueTracking = {
  orderId: number;
  patientId: number;
  productId: number;
  dateOfService: string;
  orderItems: {
    orderItemId: number;
    serialNumber: string;
  }[];
  attachmentBarcodeSticker: {
    documentName: string;
    content: string;
    fileType: string;
  }[];
};

export type OrderDetails = {
  practiceIds: number[];
  productIds: number[];
  patientName: string;
  providerName: string;
  orderNumber: string;
  // dateOfOrder: string;
  //cs-387
  fromDate: string | undefined;
  toDate: string | undefined;
  //
  // dateOfService: string;
  status: string[];
  fromServiceDate: string | undefined;
  toServiceDate: string | undefined;
};

export interface OrderItemDTO {
  orderItemId: number;
  orderId: number;
  productItemId: number;
  size: string;
  productPriceId: number;
  quantity: number;
  tissueTrackingId: number;
  tissueTrackingDocumentId: number;
  productReturnId: number;
  returnDocumentId: number;
  serialNumber: string;
  auditUser: string;
  auditTimestamp: string;
  claim: orderItemClaim,
  invoice: orderItemInvoice,
  tissueTrackingInfo: TissueTrackingInfo,
  practiceProductId: string
  invoiceId: number;
  returnTrackingInfo: {
    returnCarrier?: string;
    returnTracking?: string;
    additionalContext?: string;
    productBoxPictures?: number[]
  };
  units: number;
  extendedPrice: number;
  actualDateOfService: string
  appliedDate: string;
  returnedDate: string;
  invoicedDate: string;
  manufacturerName: string;
  mfgInvoiceNumber: string;
}

export interface PracticeProductAgreementDTO {
  practiceProductId: number;
  practiceName: string;
  productName: string;
  extendedPrice: number;
  discountType: string; 
  effectiveDate: string; 
  inactiveDate: string | null; 
  agreementStatus: string;
  practiceSignedDate: string | null; 
  manufacturerSignedDate: string | null; 
  productLogo: string | null; 
}


export interface OrderInfo {
  orderId: number;
  practiceId: number;
  productId: number;
  ivrId: number;
  patientId: number;
  practiceName: string;
  hasMiddleName: boolean;
  patientName: string;
  provider?: string;
  manufacturerName: string;
  productName: string;
  providerName: string;
  orderNumber: string;
  orderDate: string;
  dateOfService?: string;
  orderType: string;
  orderStatus: string;
  mfgConfirmationNumber: string;
  shippedDate: string;
  deliveredDate: string;
  shippingCarrier: string;
  trackingNumber: string;
  poNumber: string;
  orderTotalUnit: number;
  orderTotalPrice: number;
  orderDocumentId: number;
  invoiceId?: number;
  invoiceStatus?: string;
  orderInfo?:{
    practice: string,
    patient?: string,
    provider?: string,
    facilityAddress: string,
  };
  orderItems: OrderItemDTO[];
};

interface PaginationOrderList {
  content: OrderInfo[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type OrderItemInfo = {
  orderItemId: number,
  serialNumber: string
}

export interface OrderFulfillmentInfo {
  orderId: number;
  practiceId: number;
  ivrId: number;
  patientId: number;
  practiceName: string;
  manufacturerName: string;
  patientName: string;
  productName: string;
  providerName: string;
  orderNumber: string;
  orderDate: string;
  orderType: string;
  orderStatus: string;
  mfgConfirmationNumber: string;
  shippedDate: string;
  deliveredDate: string;
  shippingCarrier: string;
  trackingNumber: string;
  poNumber: string;
  dateOfService: string;
  orderStatusToUpdate: string;
  updateStatusDate: string;
  orderItems: OrderItemDTO[];
}

export interface claimInfo {
  orderId: number;
  productId: number;
  claimNumber: string;
  claimStatus: 'SUBMITTED' | 'REIMBURSED'
  submittedDate: string;
  orderItems: { orderItemId: number; }[]
}

export interface orderItemClaim {
  claimId: number,
  claimNumber: string,
  claimAmount?: number,
  claimStatus?: string,
  submittedDate: string,
  reimbursedDate?: string
}

export interface orderItemInvoice {
  invoiceId: number,
  manufacturerId: number,
  agencyId: number,
  mfgInvoiceNumber: string,
  mfgInvoiceStatus?: string,
  cogsAmount: number,
  practicePaidDate: string,
  v3CommReceivedDate: string,
  v3ProductCommissionId: number,
  v3CommStatus: string,
  agencyCommStatus: string,
  agencyCommPaidDate: string,
  agencyProductCommissionId: number
};

export interface reimburseClaimInfo {

  claimId: number;
  claimAmount: number;
  claimStatus: 'SUBMITTED' | 'REIMBURSED';
  reimbursedDate: string;

}

export type AttachmentBarcodeStickerInfo = {
  documentName: string,
  content: string,
  fileType: string
}

export type AttachmentImageOfWoundsInfo = {
  documentName: string,
  content: string,
  fileType: string
}

export const initialValuesOfOrderFulfillment = {
  orderId: 0,
  practiceId: 0,
  ivrId: 0,
  patientId: 0,
  practiceName: "",
  patientName: "",
  productName: "",
  manufacturerName: "",
  providerName: "",
  orderNumber: "",
  orderDate: "",
  orderType: "",
  orderStatus: "",
  mfgConfirmationNumber: "",
  shippedDate: "",
  deliveredDate: "",
  shippingCarrier: "",
  trackingNumber: "",
  poNumber: "",
  dateOfService: "",
  orderStatusToUpdate: "",
  updateStatusDate: "",
  orderItems: [{}]
} as OrderFulfillmentInfo;

export interface TissueTrackingInfo {
  barcodes: number[];
  imagesOfWounds: number[];
  orderId: number,
  productId: number,
  patientId?: number,
  dateOfService: string,
  orderItems: OrderItemInfo[],
  attachmentBarcodeSticker: AttachmentBarcodeStickerInfo[],
  attachmentImageOfWounds: AttachmentImageOfWoundsInfo[],
  serialNumbers?: string[],
  comment?: string,
  isBarcodeStickerSkipped: boolean,

}

type OrderState = {
  orderItemsListInfo?: OrderItemDTO[] | null;
  addTissueTrackingStatus: LoadingType;
  status: LoadingType;
  cancelStatus: LoadingType;
  orderListInfo?: PaginationOrderList | null;
  orderDocumentInfo?: OrderDocumentInfo | null;
  fetchProductsByRoleStatusOrderSlice: LoadingType,
  productsByUserRoleOrderSlice: ProductInfo[],
  getPracticesByRoleStatusOrderSlice: LoadingType;
  practiceByUserRoleOrderSlice?: PracticeInfo[] | null;
}

const initialState: OrderState = {
  orderItemsListInfo: undefined,
  addTissueTrackingStatus: 'idle',
  status: "idle",
  cancelStatus: 'idle',
  orderListInfo: undefined,
  orderDocumentInfo: undefined,
  fetchProductsByRoleStatusOrderSlice: "idle",
  productsByUserRoleOrderSlice: [],
  getPracticesByRoleStatusOrderSlice: "idle",
  practiceByUserRoleOrderSlice: []
};

export const addOrder = createAsyncThunk("addOrder", async (data: AddOrderInfo | null) => {
  const response = await orderCreate(data);
  const v3Response = response.data;
  return v3Response.data;
});

export const updateOrder = createAsyncThunk("updateOrder", async (data: OrderFulfillmentInfo | null) => {
  try {
    const response = await orderUpdate(data);
    const resData = response.data;
    return resData;
  } catch (error) {
    throw error;
  }
});

export const getOrderItemsByOrderId = createAsyncThunk("getOrderItemsById", async (id: number) => {
  const response = await getOrderItemsById(id);
  const v3Response = response.data;
  return v3Response;
});

export const addProductReturn = createAsyncThunk("OrderReturn", async (data: OrderReturn | null) => {
  const response = await returnOrder(data)
  const v3Response = response.data;
  return v3Response;
});

export const fetchOrdersList = createAsyncThunk("fetchOrdersList", async (data: OrderDetails) => {
  const response = await searchOrder(data);
  const v3Response = response.data;
  return v3Response;
});

export const addTissueTracking = createAsyncThunk("addTissueTracking", async (data: TissueTrackingInfo) => {
  const response = await tissueTracking(data);
  const v3Response = response.data;
  return v3Response;
});

export const fetchOrderDocumentById = createAsyncThunk('fetchOrderDocumentById', async (documentId: number) => {
  const response = await getOrderDocumentByDocumentId(documentId);
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchOrderDocumentsByIds = createAsyncThunk('fetchOrderDocumentsByIds', async (documentIds: number[]) => {
  const response = await getOrderDocumentsByDocumentIds(documentIds);
  const v3Response = response.data;
  return v3Response.data;
});

export const addClaim = createAsyncThunk("addClaim", async (data: claimInfo) => {
  const response = await submitClaim(data);
  return response;
})

export const addReimbursedClaim = createAsyncThunk("addReimbursedClaim", async (data: reimburseClaimInfo) => {
  const response = await submitReimbursedClaim(data);

  return response;
})

export const fetchProductListByUserRoleOrderSlice = createAsyncThunk("fetchProductListByUserRoleOrderSlice", async (includeProductPrice: boolean) => {
  const response = await fetchProductsByUserRole(includeProductPrice);
  const v3Response = response.data
  return v3Response.data;
});

export const fetchProductListIncludingInactiveByUserRoleOrderSlice = createAsyncThunk("fetchProductListIncludingInactiveByUserRoleOrderSlice", async (includeProductPrice: boolean) => {
  const response = await fetchProductsIncludingInactiveByUserRole(includeProductPrice);
  const v3Response = response.data
  return v3Response.data;
});

export const getPracticeListByUserRoleOrderSlice = createAsyncThunk('getPracticeListByUserRoleOrderSlice', async (isActivePracticeOnly?: boolean) => {
  const response = await fetchPracticesByUserRole(isActivePracticeOnly);
  const v3Response = response.data;
  return v3Response.data;
});

export const cancelOrder = createAsyncThunk("cancelOrder", async(orderId: number) => {
  const response = await orderCancel(orderId);
  const v3Response = response.data;
  return v3Response.data;
});

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateElementInOrderSlice: updateElementValue,
    resetOrderListInfo: (state: OrderState) => {
      state.orderListInfo = undefined;
      state.fetchProductsByRoleStatusOrderSlice = "idle";
      state.productsByUserRoleOrderSlice = [];
      state.getPracticesByRoleStatusOrderSlice = "idle";
      state.practiceByUserRoleOrderSlice = [];
    },
    resetCancelStatus: (state: OrderState) => {
      state.cancelStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrder.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.status = "success"
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(getOrderItemsByOrderId.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getOrderItemsByOrderId.fulfilled, (state, action) => {
        state.status = "success"
        state.orderItemsListInfo = action.payload.data
      })
      .addCase(getOrderItemsByOrderId.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(addProductReturn.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addProductReturn.fulfilled, (state, action) => {
        state.status = "success"
      })
      .addCase(addProductReturn.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(fetchOrdersList.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {
        state.status = "success";
        state.orderListInfo = action.payload.data;
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(addTissueTracking.pending, (state, action) => {
        state.addTissueTrackingStatus = "loading"
      })
      .addCase(addTissueTracking.fulfilled, (state, action) => {
        state.addTissueTrackingStatus = "success"
      })
      .addCase(addTissueTracking.rejected, (state, action) => {
        state.addTissueTrackingStatus = "error"
      })
      .addCase(addClaim.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addClaim.fulfilled, (state, action) => {
        state.status = "success"
      })
      .addCase(addClaim.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(addReimbursedClaim.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addReimbursedClaim.fulfilled, (state, action) => {
        state.status = "success"
      })
      .addCase(addReimbursedClaim.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(fetchOrderDocumentById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderDocumentById.fulfilled, (state, action) => {
        state.status = "success";
        state.orderDocumentInfo = action.payload;
      }
    )
    .addCase(fetchOrderDocumentById.rejected, (state, action) => {
      state.status = "error";
    })
    .addCase(fetchOrderDocumentsByIds.pending, (state) => {
      state.status = "loading";
    })
    .addCase(fetchOrderDocumentsByIds.fulfilled, (state, action) => {
      state.status = "success";
      state.orderDocumentInfo = action.payload;
    }
    )
    .addCase(fetchOrderDocumentsByIds.rejected, (state, action) => {
      state.status = "error";
    })
    .addCase(fetchProductListByUserRoleOrderSlice.pending, (state) => {
      state.fetchProductsByRoleStatusOrderSlice = "loading";
    })
    .addCase(fetchProductListByUserRoleOrderSlice.fulfilled, (state, action) => {
      state.fetchProductsByRoleStatusOrderSlice = "success";
      state.productsByUserRoleOrderSlice = action.payload;
    })
    .addCase(fetchProductListByUserRoleOrderSlice.rejected, (state, action) => {
      state.fetchProductsByRoleStatusOrderSlice = 'error';
    })
    .addCase(fetchProductListIncludingInactiveByUserRoleOrderSlice.pending, (state) => {
      state.fetchProductsByRoleStatusOrderSlice = "loading";
    })
    .addCase(fetchProductListIncludingInactiveByUserRoleOrderSlice.fulfilled, (state, action) => {
      state.fetchProductsByRoleStatusOrderSlice = "success";
      state.productsByUserRoleOrderSlice = action.payload;
    })
    .addCase(fetchProductListIncludingInactiveByUserRoleOrderSlice.rejected, (state, action) => {
      state.fetchProductsByRoleStatusOrderSlice = 'error';
    })
    .addCase(getPracticeListByUserRoleOrderSlice.pending, (state, action) => {
      state.getPracticesByRoleStatusOrderSlice = 'loading';
    })
    .addCase(getPracticeListByUserRoleOrderSlice.fulfilled, (state, action) => {
      state.getPracticesByRoleStatusOrderSlice = 'success';
      state.practiceByUserRoleOrderSlice = action.payload;
    })
    .addCase(getPracticeListByUserRoleOrderSlice.rejected, (state, action) => {
      state.getPracticesByRoleStatusOrderSlice = 'error';
    })
    .addCase(cancelOrder.pending, (state, action) => {
      state.cancelStatus = "loading"
    })
    .addCase(cancelOrder.fulfilled, (state, action) => {
      state.cancelStatus = "success"
    })
    .addCase(cancelOrder.rejected, (state, action) => {
      state.cancelStatus = "error"
    })  },
});

export const { updateElementInOrderSlice, resetOrderListInfo, resetCancelStatus } = orderSlice.actions;
export const useOrderSlice = () => useAppSelector((state) => state.orderSlice);
export default orderSlice.reducer;

