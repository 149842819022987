import { Box, DialogActions, FormControl, Grid, List, ListItem, ListItemText } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import React, { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomDatePicker from "../../common/CustomDatePicker";
import dayjs from "dayjs";
import Button from "../../common/Button";
import {
  SingleAlertInfo,
  fetchMac,
  setSingleAlertObj,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import Table from "../../common/CustomTable";
import {
  PracticeInfo,
  getPracticesByUserRole,
  usePracticeSlice,
} from "../../../redux/slice/practiceSlice";
import {
  AddCircleOutlineOutlined,
  Cancel,
  DeleteOutlineOutlined,
  Details,
  Save,
} from "@mui/icons-material";
import {
  calculateValue,
  priceFormatter,
  sortNames,
} from "../../../utils/Utility";
import {
  createProductAgreement,
  PracticeProductInfo,
} from "../../../redux/slice/productAgreement/createAgreementSlice";
import moment from "moment";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useNavigate } from "react-router-dom";
import PracticeDetailsPopup from "./PracticeDetailsPopup";
import {
  ProductInfo,
  ProductPrice,
  fetchProductsByUserRoleData,
  useProductListReducer,
} from "../../../redux/slice/product/productListSlice";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import {
  fetchAllUserRoles,
} from "../../../redux/slice/userSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomTextField from "../../common/CustomTextField";
import CustomRadioButton from "../../common/CustomRadioButton";
import { MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND, RESPONSE_CODES } from "../../../constants/applicationConstants";
import CustomCurrencyField from "../../common/CustomCurrencyField";

interface CreateNewAgreementProps {}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "left" | "center" | "right";
  format?: (value: number) => string;
}

interface Product {
  productId?: string;
  name?: string;
  discount: JSX.Element;
  price: string | null;
  extendedPrice: any;
  productPriceId: string | null;
  close: JSX.Element;
}

type NewAgreementState = {
  practiceId: string | null;
  product: string | null;
  practiceName: string | null;
  effectiveDate: string | null;
  // inactiveDate: string | null;
  macId: string | null;
  practiceProducts: PracticeProductInfo[];
  extendedValues: string[] | null;
  discount: string[] | null;
  radio: string[] | null;
  customPrice: string[] | null;
};

const initialAgreement: NewAgreementState = {
  practiceId: null,
  product: null,
  practiceName: null,
  effectiveDate: null,
  // inactiveDate: null,
  macId: null,
  practiceProducts: [],
  extendedValues: [],
  discount: [],
  radio: [],
  customPrice: []
};

const productColumns: Column[] = [
  {
    id: "name",
    label: "Product",
    minWidth: 20,
    align: "center",
  },
  {
    id: "price",
    label: "Allowable Price",
    minWidth: 100,
    align: "center",
  },
  {
    id: "discount",
    label: "Discount",
    minWidth: 100,
    align: "center",
  },
  {
    id: "extendedPrice",
    label: "Extended Price",
    minWidth: 100,
    align: "center",
  },
  { id: "action", 
    label: "Action", 
    minWidth: 50, 
    align: "center",},

];

type Option = {
  value: string | number;
  label: string;
};

const CreateNewAgreement: React.FC<CreateNewAgreementProps> = () => {
  const { control, handleSubmit, reset, watch, getValues, setValue } =
    useForm<NewAgreementState>({ defaultValues: initialAgreement });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { macInfoList } = useCommonReducer();
  const { productsByUserRole } = useProductListReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const [productRows, setProductRows] = React.useState<any[]>([]);
  const [showPracticeDetails, setShowPracticeDetails] = React.useState(false);
  const [isPracticeDisable, setIsPracticeDisable] = React.useState(false);
  const [showOptionPopup, setShowOptionsPopup] =
    React.useState<OptionPopupObjectProps>({ message: "", show: false });
  const [isEfctDateDisable, setIsEfctDateDisable] = React.useState(false);
  const [ maxInactiveDate, setMaxInactiveDate ] = React.useState(dayjs().add(20, 'year'));
  const [ minInactiveDate, setMinInactiveDate ] = React.useState(dayjs());
  const [ enabledDiscountList, setEnabledDiscountList ] = React.useState([]);
  const [ enabledCustomPrice, setEnabledCustomPrice ] = React.useState([]);

  // Mounting
  useEffect(() => {
    const date = new Date();
    setValue("effectiveDate", moment(date).format("MM/DD/YYYY"));
    dispatch(fetchMac());
    dispatch(fetchAllUserRoles());
    dispatch(fetchProductsByUserRoleData(true));
    dispatch(getPracticesByUserRole(true));
  }, []);

  const discountComponent = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    customPrice: any,
    disableUnit?: string
  ): JSX.Element => {
    
    return (
      <>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
              <CustomRadioButton
                control={control}
                controllerName={`radio.discount${prod?.productId}`}
                options={radioButtonOptions(prod, allowableUnitPrice, customPrice, disableUnit)}
                onChange={(e) => handleDiscount(prod, allowableUnitPrice, customPrice, e.target.value)}
              />
          </Grid>
        </Grid>
      </>
    );
  }

  const radioButtonOptions = (prod: ProductInfo | undefined, allowableUnitPrice: any, customPrice: number | null, disableUnit?:string) => {
    const options: { value: string | number; label: string | JSX.Element }[] = [
      {
        label: <CustomTextField
          control={control}
          controllerName={`discount.discount${prod?.productId}`}
          label="Discount %"
          onChange={(e) =>
            setDiscountOnFieldChange(prod, allowableUnitPrice, e.target.value)}
          type="number"
          isDisabled={!!(!disableUnit || disableUnit === "customDiscount")}
          rules={{
            min: { value: 1, message: "Minimum value allowed is 1" },
            max: { value: 100, message: "Maximum value allowed is 100" }
          }}
          size='small'
          style={{width: '150px'}}
          />,
        value: `customDiscount`
      }, 
      {
        label: <CustomCurrencyField
        control={control}
        controllerName={`customPrice.customPrice${prod?.productId}`}
        label="Custom Price"
        onChange={(e) =>
          e?.target?.value && setCustomPriceChange(prod, allowableUnitPrice, e?.target?.value)}
        isDisabled={!!(!disableUnit || disableUnit === "customPrice")}
        size='small'
        style={{width: '150px'}}
        />,
        value: 'customPrice'
      }
    ]
    return options;
  }

  const closeComponent = (productId: any): JSX.Element => {
    return (
      <>
        <FormControl>
          <Button 
            color="error"
            size="small"
            variant="outlined"
            onClick={() => handleClose(productId)}
          >
            <DeleteOutlineOutlined fontSize="small" />
          </Button>
        </FormControl>
      </>
    );
  };

  const handleClose = (productId: any) => {
    let products: PracticeProductInfo[] | null = getValues("practiceProducts");
    const practiceId = getValues("practiceId");
    const effectiveDate = dayjs(getValues("effectiveDate"));
    products = products?.filter(
      (product) => productId !== product.productId
    );
    deleteDiscountComponentRow(productId);
    setProductRows(prevRows => {
      const newRows = prevRows.filter((row) => row.productId !== productId);
      return newRows;
    });
    setValue("practiceProducts", products);
    if (products?.length > 0) {
      setIsPracticeDisable(!!practiceId);
      setIsEfctDateDisable(effectiveDate.isValid() ?? false);
    } else {
      setIsPracticeDisable(false);
      setIsEfctDateDisable(false);
    }
  };

  const setDiscount = (    
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    discount: string,
    products: PracticeProductInfo[]) => {
      products?.map((product) => {
        if (product.productId === prod?.productId) {
          product.discountType = discount;
          product.extendedPrice = allowableUnitPrice
            ? calculateValue(discount, allowableUnitPrice)
            : null;
        }
      }); 
      setProductRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.productId === prod?.productId) {
            return {
              ...row,
              extendedPrice: allowableUnitPrice
                ? priceFormatter(calculateValue(discount, allowableUnitPrice))
                : null,
            };
          }
          return row;
        });
      });
      return products;
  };

  const setCustomPrice = (    
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    value: number,
    products: PracticeProductInfo[]) => {
      products?.map((product) => {
        if (product.productId === prod?.productId) {
          product.extendedPrice = allowableUnitPrice
            ? value
            : null;
        }
      }); 
      setProductRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.productId === prod?.productId) {
            return {
              ...row,
              extendedPrice: allowableUnitPrice
                ? priceFormatter(value)
                : null,
            };
          }
          return row;
        });
      });
      return products;
  };

  const setDiscountOnFieldChange = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    discount: string
  ) => {
    setEnabledDiscountList(prev => {
    //@ts-ignore
    if (prev.includes(`discount.discount${prod?.productId}`)) {
      let products: PracticeProductInfo[] = getValues("practiceProducts");
      products = setDiscount(prod, allowableUnitPrice, discount, products)
      setValue("practiceProducts", products);
    }
      return prev;
    });
  };

  const convertToFloat = (str: string) => {
    const cleanedStr = str.replace(/,/g, "");
    return parseFloat(cleanedStr);
  };

  const setCustomPriceChange = (
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    value: string
  ) => {
    setEnabledCustomPrice(prev => {
    //@ts-ignore
    if (prev.includes(`customPrice.customPrice${prod?.productId}`)) {
      let products: PracticeProductInfo[] = getValues("practiceProducts");
      products = setCustomPrice(prod, allowableUnitPrice, convertToFloat(value), products)
      setValue("practiceProducts", products);
    }
      return prev;
    });
  };

  const deleteDiscountComponentRow = (productId?: string) => {
    let discounts = getValues("discount");
    let customPriceOpt = getValues('customPrice');
    let radio = getValues('radio');
    // @ts-ignore
    delete discounts[`discount${productId}`];
    // @ts-ignore
    delete radio[`discount${productId}`];
    // @ts-ignore
    delete customPriceOpt[`customPrice${productId}`];
    setValue("discount", discounts);
    setValue("radio", radio);
    setValue("customPrice", customPriceOpt);
  };

  const handleDiscount = (    
    prod: ProductInfo | undefined,
    allowableUnitPrice: any,
    customPrice: number | null,
    type: string) => {
      let products: PracticeProductInfo[] | null = getValues("practiceProducts");
      //@ts-ignore
      const discount: number | string = getValues(`discount.discount${prod?.productId}`) ? getValues(`discount.discount${prod?.productId}`) : "";
      if(type === 'customDiscount') {
        products = setDiscount(prod, allowableUnitPrice, discount.toString() , products);
        //@ts-ignore
        setEnabledDiscountList(prev => { 
          const newVal = [...prev, `discount.discount${prod?.productId}`];
          return newVal;
        });
        setEnabledCustomPrice(prev => {
          const newVal = prev.filter(discount => discount !== `customPrice.customPrice${prod?.productId}`);
          return newVal;
        });
        //@ts-ignore
        setValue(`customPrice.customPrice${prod?.productId}`, "");
        setProductRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.productId === prod?.productId) {
              return {
                ...row,
                discount: discountComponent(prod, allowableUnitPrice, customPrice, "customPrice")
              };
            }
            return row;
          });
        });
      } else {
        //@ts-ignore
        setValue(`customPrice.customPrice${prod?.productId}`, customPrice)
        setEnabledDiscountList(prev => {
          const newVal = prev.filter(customPrice => customPrice !== `discount.discount${prod?.productId}`);
          return newVal;
        });
        //@ts-ignore
        setEnabledCustomPrice(prev => {
          const newVal = [...prev, `customPrice.customPrice${prod?.productId}`]
          return newVal;
        });
        products?.map((product) => {
          if (product.productId === prod?.productId) {
            //@ts-ignore
            product.extendedPrice = getValues(`customPrice.customPrice${prod?.productId}`);
            product.discountType = "CUSTOM";
          }
        });
        setProductRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.productId === prod?.productId) {
              return {
                ...row,
                //@ts-ignore
                extendedPrice: priceFormatter(getValues(`customPrice.customPrice${prod?.productId}`)),
                discount: discountComponent(prod, allowableUnitPrice, customPrice, "customDiscount")
              };
            }
            return row;
          });
        });
        //@ts-ignore
        setValue(`discount.discount${prod?.productId}`, "")
      };
      setValue("practiceProducts", products);
  } 

  const handleChangePracticeMacDropDown = (event: any, value: any) => {
    let products: PracticeProductInfo[] = getValues("practiceProducts");
    setValue("macId", value);
    // @ts-ignore
    setProductRows((prevRows) => {
      let newRows = prevRows.map((row) => {
        let productRow = productsByUserRole?.find(
          (prod) => prod.productId === row.productId
        );
        let product = products?.find(
          (prod) => prod.productId === row.productId
        );
        let validProductPrice: ProductPrice | null = null;
        if (product) {
          validProductPrice = getEffectivePracticeProduct(product.productId);
        }
        let productPriceMAC =
          validProductPrice?.priceType === "MAC"
            ? validProductPrice
            : undefined;
        let productPriceNational =
          validProductPrice?.priceType === "NATIONAL"
            ? validProductPrice
            : undefined;
        if (value === "" || productPriceMAC?.macId !== value) {
          productPriceMAC = undefined;
        }
        if (productPriceMAC || productPriceNational) {
          let productPrice = productPriceNational
            ? productPriceNational
            : productPriceMAC
            ? productPriceMAC
            : null;
          let allowableUnitPrice = productPrice
            ? productPrice.allowableUnitPrice
            : null;
          let productPriceId = productPrice
            ? productPrice.productPriceId
            : null;
            //@ts-ignore
          let customPrice = getValues(`customPrice.customPrice${product?.productId}`) ? getValues(`customPrice.customPrice${product?.productId}`) : productPrice ? productPrice.customPrice : null;

          let productDiscountedPrice =
            product &&
            (product.discountType === "CUSTOM"
              ? customPrice && priceFormatter(parseInt(customPrice.toString()))
              : allowableUnitPrice && product.discountType
              ? priceFormatter(
                  calculateValue((product.discountType).toString(), allowableUnitPrice)
                )
              : null);
          return {
            ...row,
            price:
              productPriceNational && productPriceNational.allowableUnitPrice
                ? priceFormatter(allowableUnitPrice) + " (National)"
                : productPriceMAC && productPriceMAC.allowableUnitPrice
                ? priceFormatter(allowableUnitPrice) + " (Local Fee Schedule)"
                : "No Local or National Fee",
            productPriceId: productPriceId,
            extendedPrice: productDiscountedPrice,
            discount: discountComponent(
              productRow,
              allowableUnitPrice,
              customPrice,
              customPrice ? "customDiscount" : "customPrice"
            ),
          };
        } else {
          let practiceId = getValues("practiceId");
          products = products?.filter(
            (prod) => product?.productId !== prod.productId
          );
          product && deleteDiscountComponentRow(product.productId)
          if (products?.length === 0) {
            if (practiceId) {
              setIsPracticeDisable(false);
            }
            if (dayjs(getValues("effectiveDate")).isValid()) {
              setIsEfctDateDisable(false);
            }
          }
          setValue("practiceProducts", products);
          return {};
        }
      });
      return newRows.filter((newRow) => Object.keys(newRow).length !== 0);
    });
  }

  const handleChangePracticeName = (event: any, value: any) => {
    let products: PracticeProductInfo[] = getValues("practiceProducts");
    setValue("practiceId", value);
    if (products.length > 0) {
      setIsPracticeDisable(true);
    }
  };

  let errorObj: SingleAlertInfo = {
    message: ``,
    alertType: "error",
  };

  const getEffectivePracticeProduct = (id: string) => {
    const selectedProduct = productsByUserRole.find(
      (prod) => prod.productId === id
    );
    const productPrices = selectedProduct?.productPrices;
    const effectiveDate = dayjs(getValues("effectiveDate"));
    let validProductPrice: ProductPrice | null = null;
    if (productPrices && effectiveDate.isValid()) {
      for (let i = 0; i < productPrices.length; i++) {
        const productPriceEffective = dayjs(productPrices[i].effectiveDate);
        // const productPriceInactive = dayjs(productPrices[i].inactiveDate);
        if (
          // (!productPriceInactive.isValid() ||
          //  (effectiveDate.isBefore(productPriceInactive)) || (effectiveDate.isSame(productPriceInactive)) ) &&
          ( effectiveDate.isAfter(productPriceEffective) || effectiveDate.isSame(productPriceEffective) )
        ) {
          validProductPrice = productPrices[i];
        }
      }
    }
    return validProductPrice;
  };

  const addProduct = () => {
    let products: PracticeProductInfo[] = getValues("practiceProducts") || [];
    const selectedProductId = getValues("product");
    const addingProduct = productRows.find(
      (prod) => prod.productId === selectedProductId
    );
    const effectiveDate = dayjs(getValues("effectiveDate"));
    if (!selectedProductId) {
      errorObj.message = "Please select a Product";
      dispatch(setSingleAlertObj(errorObj));
    } else if (addingProduct) {
      errorObj.message = "The product has already been added.";
      dispatch(setSingleAlertObj(errorObj));
    } else if (!effectiveDate || !effectiveDate.isValid()) {
      errorObj.message = "Please select a valid date.";
      dispatch(setSingleAlertObj(errorObj));
    } else {
      let selectedMacId = getValues("macId");
      if (!addingProduct) {
        let selectedProduct = productsByUserRole?.find(
          (prod) => prod.productId === selectedProductId
        );
        if (selectedProduct) {
          let validProductPrice: ProductPrice | null =
            getEffectivePracticeProduct(selectedProduct.productId);
          if (validProductPrice !== null) {
            let productPriceMAC =
              validProductPrice.priceType === "MAC"
                ? validProductPrice
                : undefined;
            let productPriceMACId = productPriceMAC && productPriceMAC.macId;
            let productPriceNational =
              validProductPrice.priceType === "NATIONAL"
                ? validProductPrice
                : undefined;
            if (!productPriceNational && productPriceMACId !== selectedMacId) {
              errorObj.message =
                "Please select a MAC to retrieve product price.";
              dispatch(setSingleAlertObj(errorObj));
            } else if (
              selectedMacId &&
              !(productPriceNational || productPriceMAC)
            ) {
              errorObj.message =
                "No Local or National Fee for the selected product";
              dispatch(setSingleAlertObj(errorObj));
            } else {
              let productPrice = productPriceNational
                ? productPriceNational
                : productPriceMAC
                ? productPriceMAC
                : null;
              let allowableUnitPrice = productPrice
                ? productPrice.allowableUnitPrice
                : null;
              let productPriceId = productPrice
                ? productPrice.productPriceId
                : null;
              let customPrice = productPrice ? productPrice.customPrice : null;
              if (selectedProduct) {
                products.push({
                  productId: selectedProduct.productId,
                  extendedPrice: null,
                  productPriceId: productPriceId,
                  macId: productPrice?.macId
                });

                setProductRows((prevRows) => {
                  let newRows = [...prevRows];
                  newRows.push({
                    productId: selectedProduct?.productId,
                    name: selectedProduct?.productName,
                    discount: discountComponent(
                      selectedProduct,
                      allowableUnitPrice,
                      customPrice
                    ),
                    price:
                      productPriceNational &&
                      productPriceNational.allowableUnitPrice
                        ? priceFormatter(allowableUnitPrice) + " (National)"
                        : productPriceMAC && productPriceMAC.allowableUnitPrice
                        ? priceFormatter(allowableUnitPrice) +
                          " (Local Fee Schedule)"
                        : "No Local or National Fee",
                    extendedPrice: null,
                    productPriceId: productPriceId,
                    uniqueId: selectedProduct?.productId
                  });
                  return newRows;
                });
                setValue("practiceProducts", products);
              }
            }
          } else {
            errorObj.message =
              "No Local or National Fee for the selected Effective date";
            dispatch(setSingleAlertObj(errorObj));
          }
        }
      }
    }
    const practiceId = getValues("practiceId");
    if (products.length > 0) {
      if (practiceId) {
        setIsPracticeDisable(true);
      }
      if (dayjs(getValues("effectiveDate")).isValid()) {
        setIsEfctDateDisable(true);
      }
    }
  };

  const onSubmit = (data: any) => {
    const {
      discount,
      product,
      practiceMacDropDown,
      close,
      practiceName,
      extendedValues,
      customPrice,
      radio,
      ...agreementObj
    } = data;
  
      if( dayjs(data.effectiveDate).isValid() && dayjs(data.inactiveDate).isValid() 
        && dayjs(data.effectiveDate).isAfter(dayjs(data.inactiveDate))) {
        let errorObj: SingleAlertInfo = {
          message: "Please select valid effective or inactive date.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        if (agreementObj.practiceProducts?.length === 0) {
          let errorObj: SingleAlertInfo = {
            message: "Please select atleast one product.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
        } else if (
          agreementObj.practiceProducts.find(
            (prod: any) => prod.discountType === undefined 
            || prod.discountType === '' 
            || (prod.discountType === 'CUSTOM' && (prod.extendedPrice === null || isNaN(prod.extendedPrice)))
          )
        ) {
          let errorObj: SingleAlertInfo = {
            message: "Please select a discount or enter a custom price for each added product.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
        } else {
          setShowOptionsPopup({
            message: "Do you really want to create the onboarding agreement? ",
            show: true,
            variant: "confirm",
            buttons: [
              {
                name: "Confirm",
                color: "primary",
                onClick: () => createAgreement(agreementObj),
              },
              { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
            ],
          });
        };
      };
  };

  const extractIndex = (str: string) => {
    const match = str.match(/\[(\d+)\]/);
    return match ? Number(match[1]) : null;
  };

  const createAgreement = async (agreementObj: any) => {
    const response = await dispatch(createProductAgreement( { agreementData : agreementObj, interceptorOption: { skipErrorPopup: true } } ));
    if(response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
      let successObj: SingleAlertInfo = {
        message: "Product Agreement(s) have been created.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      navigate(-1);
    } else if( response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
      if(response?.payload?.message === MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND && response?.payload?.errors) {
        let productNames: string[] = [];
        response?.payload?.errors.map((error: any) => {
          const index = extractIndex(error.field);
          if(index !== null) {
            productNames.push(productRows[index].name)
            let errObj: SingleAlertInfo = {
              message: `Agreement(s) already available for ( ${productNames} )`,
              alertType: "error",
            };
            dispatch(setSingleAlertObj(errObj));
          }
        })
      } else {
        let errObj: SingleAlertInfo = {
          message: response?.payload?.message || "Something went wrong.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errObj));
      }
    };
    cancelOptionPopup();
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const handleDateChangeEffective = (date: Date | null) => {
    setValue("effectiveDate", dayjs(date).format("MM/DD/YYYY"));
    let products: PracticeProductInfo[] = getValues("practiceProducts");
    if (products.length > 0) {
      setIsEfctDateDisable(true);
    }
  };

  // const handleDateChangeInactive = (date: Date | null) => {
  //   setValue("inactiveDate", dayjs(date).format("MM/DD/YYYY"));
  // };

  const handlePracticeDetailsShow = () => {
    const selectedPracticeId = getValues("practiceId");

    if (selectedPracticeId) {
      setShowPracticeDetails(true);
    } else {
      errorObj.message = "Please select a Practice,";
      dispatch(setSingleAlertObj(errorObj));
    }
  };

  const MACOptions: Option[] = useMemo(() => {
    const options: Option[] = [{ label: "Select", value: "" }];
    macInfoList?.map((mac) => {
      options.push({
        value: mac.macId,
        label: mac.macName
      })
    });
    return options
  }, [macInfoList]);

  const productOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    const sortedProductList: ProductInfo[] = sortNames(productsByUserRole, "productName")
    sortedProductList?.map((prod) => {
      options.push({ value: prod.productId, label: prod.productName });
    });
    return options;
  }, [productsByUserRole]);

  const practiceOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    if(practiceByUserRole) {
      const approvedPracticeList = practiceByUserRole.filter(
        (practice) => practice.approvalStatus === "APPROVED"
      );
      const sortedPracticeInfo: PracticeInfo[] = sortNames(
        approvedPracticeList,
        "practiceName"
      );
      sortedPracticeInfo?.map((practice) => {
        options.push({
          value: practice.practiceId,
          label: practice.practiceName,
        });
      });
    }
    return options;
  }, [practiceByUserRole]);

  const effectiveDate = useWatch({
    control,
    name: 'effectiveDate'
  });

  useEffect(() => {
    if(dayjs(effectiveDate).isValid()) {
      setMaxInactiveDate(dayjs(effectiveDate).add(20, 'year'));
      setMinInactiveDate(dayjs(effectiveDate));
    }
  }, [effectiveDate])

  return (
    <>
      <PracticeDetailsPopup
        open={showPracticeDetails}
        onClose={() => setShowPracticeDetails(false)}
        practiceId={getValues("practiceId")}
      />
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
        <Header
          title="Manufacturer Onboarding Agreement"
          subtitle="Create Onboarding Agreement"
        />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    label="Practice"
                    name="practiceName"
                    options={practiceOptions}
                    control={control}
                    onChangeSelect={handleChangePracticeName}
                    rules={{ required: "practice is Required." }}
                    disabled={isPracticeDisable}
                  />
                </Grid>
                <Grid item xs={12} md="auto">
                  <Button
                    type="button"
                    color="info"
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    onClick={() => handlePracticeDetailsShow()}
                    startIcon={<Details />}
                    fullWidth
                  >
                    Practice Details
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} />

                <Grid item xs={12} md={4}>
                  <CustomDatePicker
                    label="Effective Date"
                    openTo="day"
                    controllerName="effectiveDate"
                    control={control}
                    onChange={handleDateChangeEffective}
                    disabled={isEfctDateDisable}
                    rules={{
                      required: "Effective Date is required.",
                      validate: {
                        isValidDate: (
                          value:
                            | string
                            | number
                            | Date
                            | dayjs.Dayjs
                            | null
                            | undefined
                        ) =>
                          dayjs(value, "MM/DD/YYYY", true).isValid() ||
                          "Invalid date format.",
                      },
                    }}
                  />
                </Grid>
                {/* CS-559  */}
                {/* <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                <CustomDatePicker
                    label="Inactive Date"
                    openTo="day"
                    controllerName="inactiveDate"
                    control={control}
                    onChange={handleDateChangeInactive}
                    //@ts-ignore
                    maxDate={maxInactiveDate}
                    //@ts-ignore
                    minDate={minInactiveDate}
                  />
                </Grid> */}
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    control={control}
                    name="practiceMacDropDown"
                    options={MACOptions}
                    label="MAC"
                    onChangeSelect={handleChangePracticeMacDropDown}
                  />
                </Grid>
                <Grid item xs={12} md={8} />

                <Grid item xs={12} md={4}>
                  <CustomDropdownField
                    control={control}
                    name="product"
                    options={productOptions}
                    label="Product"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="info"
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    onClick={() => addProduct()}
                    startIcon={<AddCircleOutlineOutlined />}
                    fullWidth
                  >
                    Add Product
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} />

                <Grid item xs={12}>
                  <Table
                    columns={productColumns}
                    rows={productRows}
                    disablePagination={true}
                    emptyText="No Product(s) Found"
                    actionComponent={(row: any) => closeComponent(row.productId )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ mt: 1, flexWrap: "wrap" }}
                spacing={1}
              >
                <Grid item xs={12} sm="auto">
                  <Button type="submit" startIcon={<Save />} fullWidth>
                    Create Agreement
                  </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Button
                    color="error"
                    onClick={() => navigate(-1)}
                    startIcon={<Cancel />}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Card>
      </Box>
    </>
  );
};

export default CreateNewAgreement;
