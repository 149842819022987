import React from "react";
import { Grid } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import CustomTextField from "./CustomTextField";

export type NameTextFieldProps = {
  control: Control<any, any>
  context?: string;
  name?: string;
  xs?: number;
  md?: number;
  lg?: number;
  label?: string;
  isRequired?: boolean,
  isDisabled?:boolean,
  IsEmpty?:boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomNametField: React.FC<NameTextFieldProps> = ({
  control,
  context = "",
  name,
  xs,
  md,
  lg,
  label,
  isRequired,
  isDisabled,
  IsEmpty,
  onChange,
}) => {
  const firstNameLabel = `${label?label:context} First Name`;
  const middleNameLabel = `${label?label:context} Middle Name`;
  const lastNameLabel = `${label?label:context} Last Name`;

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  }

  if (name) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Grid container spacing={2}>
            <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
              <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}FirstName`} control={control}
                label={firstNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange}
                rules={{
                  ...(isRequired && {
                    required: `${label || context} First Name is required.`,
                    validate: {
                      customValidation: (value: string) => {
                        if (value?.trim() === "") {
                          return `${label || context} First Name is required.`;
                        }
                      }
                    }
                  })
                }}
              />
            </Grid>
            <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
              <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}MiddleName`} control={control}
                label={middleNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange} />
            </Grid>
            <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
              <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}LastName`} control={control}
                label={lastNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange}
                rules={{
                  ...(isRequired && {
                    required: `${label || context} Last Name is required.`,
                    validate: {
                      customValidation: (value: string) => {
                        if (value?.trim() === "") {
                          return `${label || context} Last Name is required.`;
                        }
                      }
                    }
                  })
                }}
              />
            </Grid>
          </Grid>
        )}
      />
    )
  }
  else {
    return (
      <Grid container spacing={2}>
          <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
            <CustomTextField controllerName={context !== "" ? `${context.toLowerCase()}FirstName` : 'firstName'} control={control}
              label={firstNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange}
              rules={{
                ...(isRequired && {
                  required: `${label || context} First Name is required.`,
                  validate: {
                    customValidation: (value: string) => {
                      if (value?.trim() === "") {
                        return `${label || context} First Name is required.`;
                      }
                    }
                  }
                })
              }}
             />
          </Grid>
          <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
            <CustomTextField controllerName={context !== "" ? `${context.toLowerCase()}MiddleName` : 'middleName'} control={control}
              label={middleNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange} />
          </Grid>
          <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
            <CustomTextField controllerName={context !== "" ? `${context.toLowerCase()}LastName` :  'lastName'} control={control}
              label={lastNameLabel} isDisabled={isDisabled} isEmpty={IsEmpty} onChange={handleFieldChange}
              rules={{
                ...(isRequired && {
                  required: `${label || context} Last Name is required.`,
                  validate: {
                    customValidation: (value: string) => {
                      if (value?.trim() === "") {
                        return `${label || context} Last Name is required.`;
                      }
                    }
                  }
                })
              }}
            />
          </Grid>
      </Grid>
    );
  }
};

export default CustomNametField;