import * as React from "react";
import {
  DateCalendar,
  DatePicker as DatePickerField,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { tokens } from "../../theme";

export type DatePickerFieldProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string | "";
  variant?: "filled" | "outlined" | "standard" | undefined;
  fullWidth?: true | false;
  name?: string;
  control: Control<any, any>;
  controllerName?: string;
  onChange?: (date: Date | null) => void;
  rules?: object;
  disabled?: boolean;
  showErrorMinLength?: number;
  isDisablePast?: boolean;
  isDisableFuture?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isDateCalendar?: boolean;
};

const CustomDatePicker: React.FC<
  DatePickerFieldProps & DatePickerProps<Date>
> = ({
  size,
  color,
  label,
  variant,
  fullWidth,
  isDisablePast,
  isDisableFuture,
  name,
  control,
  controllerName,
  onChange,
  rules,
  disabled,
  showErrorMinLength,
  maxDate,
  minDate,
  onBlur,
  isDateCalendar=true,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };
  const parseDate = (e: any, fieldOnChange: any) => {
    let dateStr: string = e.target.value;

    const lastDigit: string = dateStr.substring(9, 10);
    if (isNaN(Number(lastDigit))) {
      return;
    }

    if (dateStr.length === 10) {
      const month = dateStr.substring(0, 2);
      const day = dateStr.substring(3, 5);
      if (dateStr.substring(6, 8) === "00") {
        const currentCentury = Math.floor(dayjs().year() / 100);
        const expectedCentury =
          Number(dateStr.substring(8, 10)) > 50
            ? currentCentury - 1
            : currentCentury;
        const year = expectedCentury + dateStr.substring(8, 10);
        dateStr = `${month}/${day}/${year}`;
      }
    }

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        value: dateStr,
      },
    };

    fieldOnChange(customEvent);
  };

  return (
    <Controller
      name={controllerName || ""}
      control={control}
      rules={rules ? rules : {}}
      render={({ field: { ref, ...field }, fieldState }) => (
        <>
          {isDateCalendar ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerField
              {...field}
              orientation="portrait"
              name={name ? name : controllerName}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              label={getLabel() || ""}
              disablePast={isDisablePast}
              disableFuture={isDisableFuture}
              //@ts-ignore
              maxDate={maxDate}
              //@ts-ignore
              minDate={minDate}
              // @ts-ignore
              value={field.value ? dayjs(field.value) : null}
              disabled={disabled}
              slotProps={{
                textField: {
                  onBlur: (e) => {
                    parseDate(e, field.onChange);
                    onBlur && onBlur(e);
                  },
                  size: size || "medium",
                  color:
                    color ||
                    (theme.palette.mode === "dark" ? "secondary" : "primary"),
                  fullWidth: fullWidth !== undefined ? fullWidth : true,
                  error: !!fieldState.error,
                  helperText: fieldState.error
                    ? showErrorMinLength &&
                      showErrorMinLength <=
                        (fieldState.error.message
                          ? fieldState.error.message.length
                          : 0)
                      ? null
                      : fieldState.error.message
                    : null,
                  sx: {
                    ...(fieldState.error && {
                      "& .MuiInputBase-input": {
                        color: theme.palette.error.main,
                      },
                      "& .MuiFormLabel-root": {
                        color: theme.palette.error.main,
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: theme.palette.error.dark,
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.error.main,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.error.dark,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.error.dark,
                        },
                        "&.Mui-focused .MuiFormLabel-root": {
                          color: theme.palette.error.dark,
                        },
                      },
                    }),
                  },
                },
              }}
              {...props}
            />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                {...field}
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                  field.onChange(date);
                  onChange && onChange(date?.toDate() || null);
                }}
                disablePast={isDisablePast}
                disableFuture={isDisableFuture}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
                minDate={minDate ? dayjs(minDate) : undefined}
                sx={{
                  borderRadius: "8px",
                  padding: "8px",
                  boxShadow: 10,
                }}
              />
            </LocalizationProvider>
          )}
        </>
      )}
    />
  );
};

export default CustomDatePicker;
