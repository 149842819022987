import { http } from "./http";
import { DOWNLOAD_DOCUMENT_URL, VALIDATE_MANUFACTURER_LINK, GET_MANUFACTURER_LIST_URL } from "./URL";

export const downloadDocumentByDocumentId = async (ivrDocumentId: number) => {
  try {
    const response = await http.get(`${DOWNLOAD_DOCUMENT_URL}/${ivrDocumentId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const validateEmailLinkByToken = async (token: string) => {
  try {
    const response = await http.get(VALIDATE_MANUFACTURER_LINK, {
      params: {
        token
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getManufacturerList = async () => {
  try {
    const response = await http.get(GET_MANUFACTURER_LIST_URL);
    return response;
  } catch (error) {
    throw error;
  }
};