import {
    Box,
    Typography,
    Grid,
} from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomTable from "../../common/CustomTable";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";

interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
}

interface Row {
    invoice: string;
    date: string;
    invoicePaidAmt: string;
    V3Commission: string;
    remainingAmt: string;
}

const columns: Column[] = [
    { id: "invoice", label: "Invoice #", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "invoicePaidAmt", label: "Invoice Paid Amt", minWidth: 100 },
    { id: "V3Commission", label: "V3 Commission", minWidth: 100 },
    { id: "remainingAmt", label: "Remaining Amt", minWidth: 100 },
];

const rows: Row[] = [
    { invoice: "44575", date: "04/07/2024", invoicePaidAmt: "$250000.00", V3Commission: "$25000.00 (50%)", remainingAmt: "$7,566.00" },
    { invoice: "44575", date: "04/07/2024", invoicePaidAmt: "$250000.00", V3Commission: "$25000.00 (50%)", remainingAmt: "$7,566.00" },
];

const CommPayment = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
            <Header title="Commission Payment" subtitle="Invoice Management" />
            <Box>
                <Card>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="h6" fontWeight="bold">
                            Manufacturer : {"Parametrics"}
                        </Typography>
                    </Box>

                    <Box sx={{ marginTop: 2 }}>
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            disablePagination={true}
                            borderBox={true}
                        />
                    </Box>

                    <Box sx={{ textAlign: "right", marginTop: 2 }}>
                        <Typography variant="body1" fontWeight="bold">
                            $20,176.00
                        </Typography>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                <strong style={{ marginRight: '20px' }}>Payment Received Amount: </strong> 42.5%
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>

                        <Grid item xs={6}>
                            <Typography variant="body1">
                                <strong style={{ marginRight: '20px' }}>Payment Received Amount:</strong> 32.5% Wound Biologics
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                <strong style={{ marginRight: '20px' }}>Payment Month (MM/YY):</strong> 1.0% Bill Cochrane
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button onClick={() => navigate("/invoice_list")} color="success">Record Comm Payment</Button>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default CommPayment;
