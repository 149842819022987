import React, { ReactNode, FunctionComponent } from "react";
import "./common.css";
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";

interface BorderedSectionProps {
    icon?: JSX.Element;
    title?: string;
    children: ReactNode;
    subTitle?: string;
}

const BorderedSection: FunctionComponent<BorderedSectionProps> = ({ icon, title, children, subTitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className="mainContainer">
            <div className="header">
                <div className="headerBorderBefore"></div>
                {(icon || title) && (
                    <div className="headerTitle">
                        {icon || ""}
                        {title && <span className="title">{title}</span>}
                        {subTitle && (
                            <Chip
                                sx={{
                                    backgroundColor: colors.primary[500],
                                    color: colors.success[300],
                                    fontWeight: "bold",
                                }}
                                label={
                                    <div className={isMobile ? "slidingContainer" : ""}>
                                        <span className={isMobile ? "slidingText" : ""}>{subTitle}</span>
                                    </div>
                                }
                            />
                        )}
                    </div>
                )}
                <div className="headerBorderAfter"></div>
            </div>
            <div className="childrenContainer">{children}</div>
        </div>
    );
};

export default BorderedSection;
