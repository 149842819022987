import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import Button from "../../common/Button";
import { Cancel, Download } from "@mui/icons-material";
import CustomTable from "../../common/CustomTable";
import { Column, setSingleAlertObj, SingleAlertInfo, useCommonReducer } from "../../../redux/slice/commonSlice";
import { activateProductDocument, fetchProductDocumentsByProductId, getInactiveProductDocuments, getProductPricingHistory, updateElementInProductSlice, UpdateProductDocumentStatusRequest, useProductReducer } from "../../../redux/slice/product/createProductSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import CustomCommentPreview from "../../common/CustomCommentPreview";
import { ProductDocumentListInfo } from "./Product";
import CustomIconButton from "../../common/CustomIconButton";
import { tokens } from "../../../theme";
import { PRODUCT_DOCUMENT_STATUS, PRODUCT_DOCUMENT_TYPES, PRODUCT_DOCUMENT_TYPES_TITLE_CASE } from "../../../constants/applicationConstants";
import { getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";

interface ProductDocumentHistoryProps {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ProductDocumentHistory: React.FC<ProductDocumentHistoryProps> = ({
    open,
    onClose
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useAppDispatch();
    const { productObject, inactivatedProductDocuments, activateProductDocumentStatus } = useProductReducer();
    const [productId, setProductId] = useState<number | null>(null);
    const [productDocumentTypeMap, setProductDocumentTypeMap] = useState<Record<string, string>>({});
    const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();

    const columnsDocuments: Column[] = useMemo(() => {
        return [
            { id: "documentType", label: "Type", minWidth: 100 },
            { id: "docName", label: "Name", minWidth: 100 },
            { id: "manufacturer", label: "Manufacturer", minWidth: 100 },
            { id: "status", label: "Status", minWidth: 100 },
            { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
            { id: "comments", label: "Comment", minWidth: 100 },
            { id: "documentURL", label: "Document URL", minWidth: 100, },
            { id: "action", label: "Action", minWidth: 80 },
        ]
    }, [open]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let id = urlSearchParams.get('id');
        if(id) {
            setProductId(parseInt(id));
        };
        if (open) {
            dispatch(getInactiveProductDocuments({ productId: productObject.productId }))
        }
    }, [open]);

    useEffect(() => {
        if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
          const sortedDocumentList = [
            ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "ProductDocument"),
          ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
          const productDocumentTypeMap = Object.fromEntries(
            sortedDocumentList.map(item => [item.keyName, item.displayValue])
          );
          setProductDocumentTypeMap(productDocumentTypeMap)
        }
      }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

    const commentComponent = useCallback((row: any) => {
        return (<>
            <Grid item>
                {
                    row.comments ?
                        <CustomCommentPreview
                            title={`Comment`}
                            commentBody={row.comments}
                        /> : "N/A"}
            </Grid>
        </>)
    }, [inactivatedProductDocuments]);

    const URLComponent = useCallback((row: any) => {
        const url = typeof row.documentURL === "string" ? row.documentURL : null;
      
        if (!url) {
          return null;
        }
      
        return (
          <>
            <Grid>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: colors.info[300] }}
              >
                {url.length > 30 ? `${url.substring(0, 30)}...` : url}
              </a>
            </Grid>
          </>
        );
      }, [colors.info]);

    const productDocumentRows: ProductDocumentListInfo[] = useMemo(() => {
        const rows: ProductDocumentListInfo[] = []
        inactivatedProductDocuments?.forEach(pd => {
            if (pd.entityName === 'Document' && pd.docName !== null) {
                const row = {
                    ...pd,
                    documentType: productDocumentTypeMap[pd.documentType],
                    documentURL: URLComponent(pd),
                    comments: commentComponent(pd),
                    manufacturer: pd.manufacturer?.manufacturerName || "",
                }
                rows.push(row);
            }
        })
        return rows;
    }, [inactivatedProductDocuments]);

    const statusUpdateDocumentTypes = [
        PRODUCT_DOCUMENT_TYPES_TITLE_CASE.IVR_FORM,
        PRODUCT_DOCUMENT_TYPES_TITLE_CASE.ORDER_FORM, 
        PRODUCT_DOCUMENT_TYPES_TITLE_CASE.SERVICE_AGREEMENT, 
        PRODUCT_DOCUMENT_TYPES_TITLE_CASE.TISSUE_TRACKING_FORM, 
        PRODUCT_DOCUMENT_TYPES_TITLE_CASE.RETURN_FORM
        ];

    const handleProductDocumentActivate = useCallback((row: ProductDocumentListInfo) => {
        let activateRequest: UpdateProductDocumentStatusRequest = {
            productId: row.productId,
            documentId: parseInt(row.entityId) 
        }
        if(statusUpdateDocumentTypes.includes(row.documentType)) {
            activateRequest.status = PRODUCT_DOCUMENT_STATUS.PENDING
        } 
        dispatch(activateProductDocument({activateRequest: activateRequest}));
    }, [open]);

    useEffect(() => {
        if(activateProductDocumentStatus === 'success') {
            let successObj: SingleAlertInfo = {
                message: 'Product Document successfully activated.',
                alertType: "success",
              }
              dispatch(setSingleAlertObj(successObj));
              dispatch(updateElementInProductSlice({
                elementName: "activateProductDocumentStatus",
                value: "idle"
              }));
              productId && dispatch(fetchProductDocumentsByProductId(productId));
              onClose();
        }
     }, [activateProductDocumentStatus]);

    const productDocActionComponent = useCallback((row: ProductDocumentListInfo, rowIndex: number): JSX.Element => {
        return (
          <>
            <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleProductDocumentActivate(row)}
              tooltipMessage="Activate" tooltipPlacement='bottom'>
              <Download />
            </CustomIconButton>
          </>
        );
      }, [open]);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
            >
                <DialogTitle>Product Document History</DialogTitle>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <DialogContent>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <CustomTable
                                columns={columnsDocuments}
                                rows={productDocumentRows}
                                disablePagination={true}
                                borderBox={true}
                                actionComponent={(row: any, rowIndex: number) => productDocActionComponent(row, rowIndex)}
                                showMenu={true}
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={onClose} startIcon={<Cancel />}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProductDocumentHistory;
