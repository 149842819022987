import { Box, Grid, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { Cancel, PictureAsPdfOutlined, SaveAlt } from "@mui/icons-material";

const PurchaseAgreement = () => {
  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header
        title="Purchase Agreement"
        subtitle="Purchase Agreement Management"
      />
      <Card>
        <Typography component="div">
          <Box sx={{ fontSize: "h4.fontSize" }}>Background</Box>
          <Box mt={2}>
            The Customer wishes to purchase, and Legacy Medical Consultants has
            agreed to sell to Customer human cell and tissue The Customer wishes
            to purchase, and Legacy Medical Consultants has agreed to sell to
            Customer human cell and tissue The Customer wishes to purchase, and
            Legacy Medical Consultants has agreed to sell to Customer human cell
            and tissue
          </Box>
          <Box mt={2}>Now, therefore, the parties agree as follows:</Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>01. Product Prices :</Box>
            <Box>
              Product mean the human cell and tissue products offered by Legacy
              Medical Consultants as described in Schedule A, as such Schedule A
              may be modified from time to time. The Invoice Price for each
              Product is the price stated for that Product in Schedule A.
            </Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>02.Insurance Verification :</Box>
            <Box>
              Customer agrees to utilize Legacy Medical Consultants’ Insurance
              Verification Request form (IVR) prior to ordering and using
              Products.
            </Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>03.Order Fulfillment :</Box>
            <Box>
              After Customer submits an IVR and receives confirmation of
              patient’s benefits, the Customer places an order and Legacy
              Medical Consultants accepts the order and generates an Invoice,
              which will reflect that the Customer has agreed to purchase the
              Products identified on the Invoice and the terms of the purchase.
              Legacy Medical Consultants shall, on Customer's behalf, promptly
              pack and ship the Products identified on the Invoice for delivery
              to the Customer using second-day delivery. Legacy Medical
              Consultants shall provide delivery status information from the
              carrier to the Customer for shipment.
            </Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>04.Product Usage :</Box>
            <Box>
              After receiving Product(s), Customer will treat the patient as
              medically necessary. Customer and Legacy Medical Consultants
              acknowledge that use of any Product is at the sole discretion of
              the treating provider, pursuant to his or her professional medical
              judgement.
            </Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>05.Purchase Price :</Box>
            <Box>See Schedule A</Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>06.Invoices & Payment :</Box>
            <Box>
              Legacy Medical Consultants will develop and deliver an Invoice to
              Customer that identifies the Products ordered are shipped.
              Customer agrees to pay Legacy Medical Consultants the balance due
              amount stated in each Invoice within sixty (60) days after product
              shipment. Customer will access Invoices and make payments through
              the LMC Payments Portal
              (https://app02.us.bill.com/p/legacymedicalconsultants). Customer
              will input their banking and credit card information in the LMC
              Payments Portal.
            </Box>
          </Box>
          <Box mt={2}>
            <Box sx={{ fontWeight: "bold" }}>07.Miscellaneous :</Box>
            <Box>
              This Agreement contains the entire agreement between the Parties
              concerning the subject matter hereof and is governed by Texas law.
              This agreement may be amended or modified only by a written
              agreement signed by both parties.
              <br /> Executed as of the Effective Date.
            </Box>
          </Box>
        </Typography>

        <Grid textAlign={"center"}>
          <Typography>
            <Typography variant="h2">Schedule A</Typography>
            <Typography>Effective 1/1/2024</Typography>
          </Typography>
        </Grid>
        <Box>{/* Table content */}</Box>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Button color="error" startIcon={<Cancel />}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button color="info" startIcon={<PictureAsPdfOutlined />}>
              Preview PDF
            </Button>{" "}
            <Button color="success" startIcon={<SaveAlt />}>Submit</Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default PurchaseAgreement;
