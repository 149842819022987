
import { SaveInvoiceInfo, SearchInvoiceInfo, uploadInvoiceRequest } from "../redux/slice/invoice/InvoiceSlice";
import { InterceptorOption } from "../types/CommonTypes";
import { ADD_CREDIT_MEMO, ADD_INVOICE_URL, GET_CREDIT_MEMO_DETAIL_BY_INVOICE_ID, GET_INVOICE_BY_ID_URL, SEARCH_INVOICE_URL, UPLOAD_INVOICE_URL } from "./URL";
import { http } from "./http";

export const getInvoiceList = async (data: SearchInvoiceInfo) => {
  try {
    const response = await http.post(SEARCH_INVOICE_URL, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveInvoice = async (data: SaveInvoiceInfo, interceptorOption: InterceptorOption) => {
  try {
    const response = await http.post(ADD_INVOICE_URL, data, undefined, interceptorOption);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInvoiceFromId = async (id: number) => {
  try {
    const response = await http.get(GET_INVOICE_BY_ID_URL + `/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadInvoice = async (data: PaymentRequest, interceptorOption?: InterceptorOption) => {
  try {
    const response = await http.post(UPLOAD_INVOICE_URL, data, undefined, interceptorOption);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCreditMemoDetailsByInvId = async (invId: number) => {
  try {
      const response = await http.get(GET_CREDIT_MEMO_DETAIL_BY_INVOICE_ID +`/${invId}`);
      return response
  } catch (error) {
      throw error;
  }
}
export const addCreditMemoData = async (data: any) => {
  try {
      const response = await http.post(ADD_CREDIT_MEMO,data);
      return response
  } catch (error) {
      throw error;
  }
}