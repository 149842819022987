import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import userSlice, {
    fetchAllUserRoles,
    saveUsersProfile,
    updateUserInfoObject,
    useUserReducer,
    clearUserObject,
    UserRole,
    UserNotificationPreference,
    searchUserNotificationPreference,
} from "../../../redux/slice/userSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import CustomNametField from "../../common/CustomNametField";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
    MainErrorInfo,
    SingleAlertInfo,
    fetchAgenciesList,
    fetchManufacturersList,
    setMainErrorObj,
    setSingleAlertObj,
    showHideLoadingDialog,
    useCommonReducer,
} from "../../../redux/slice/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { PracticeInfo, getPracticesByUserRole, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { sortNames } from "../../../utils/Utility";
import { selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { USER_ROLES, accountExecutiveRoleId, agencyAdminRoleId, billerRoleId, manufacturerAdminRoleId, manufacturerStaffRoleId, manufacturerIvrRoleId, manufacturerServiceId, practiceAdminRoleId, practiceStaffRoleId, NOTIFICATION_TYPE_LIST, NOTIFICATION_TYPE } from "../../../constants/applicationConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchActiveBillers, usePracticeApproveSlice } from "../../../redux/slice/practice/practiceApproveSlice";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { saveManufacturers } from "../../../redux/slice/manufacturer/ManufacturerSlice";

type Option = {
    value: string | number;
    label: string;
};

export type ManufacturerCreateObj = {
    manufacturerId: string;
    manufacturerName: string;
    contactPhone: string;
    contactEmail: string;
    notificationEmail: string;
    customConfig: string;
}

const CreateMaufacturer = () => {
    const location = useLocation();
    const userData = location.state?.user;
    const isEditing = Boolean(userData);
    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm<ManufacturerCreateObj>();
    const { userRoles, userObject, userNotificationPreferenceData } = useUserReducer();
    const { manufacturerInfoList, agencyInfoList } =
        useCommonReducer();
    const { practiceByUserRole } = usePracticeSlice();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const selectedUserType = watch("userRoleId");
    const { userInfo } = useAuthSlice();
    const { allCodeTablesValuesMap } = useCodetablesSlice();

    const { billerList } = usePracticeApproveSlice();
    //cs-442

    const notificationOptions = useMemo(() => {
        let notificationTypes: CodeTable[] = [];
        if (allCodeTablesValuesMap) {
            notificationTypes = getCodeTableValuesByTableName(allCodeTablesValuesMap, NOTIFICATION_TYPE_LIST);
        }
        let options: { label: string, value: any }[] = [];
        notificationTypes?.map((option) => {
            options.push({ label: option.displayValue, value: option.displayValue });
        });
        return options;
    }, [allCodeTablesValuesMap]);

    useEffect(() => {
        const data: UserNotificationPreference = {
            userId: userData?.userId,
            notificationType: NOTIFICATION_TYPE,
            notificationSubType: null
        }
        dispatch(searchUserNotificationPreference(data))
    }, [])
    //

    const canManageUserProfile = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));

    // const defaultValues = useCallback(() => {
    //     if (userObject) {
    //         reset(userObject);
    //     } else {
    //         reset({
    //             manufacturerId: undefined,
    //             email: '',
    //             phone: '',
    //             notificationEmail: '',
    //             customConfig: '',
    //         });
    //     }
    // }, [userObject, reset]);

    const onSubmit = async (data: ManufacturerCreateObj) => {
        if (isEditing) {
            dispatch(updateUserInfoObject({ ...data, id: userData.id }));
            await handleSaveManufacturer(data);
        } else {
            await handleSaveManufacturer({ ...data });
        }
    };

    const onClose = () => {
        navigate(-1);
    }

    const userRoleList = useMemo(() => {
        let allUserRoles: Option[] = [];
        let availableUserRoles: UserRole[] | undefined = undefined;

        if (canManageUserProfile && canManageUserProfile.length > 0) {
            availableUserRoles = userRoles?.filter((role: UserRole) => canManageUserProfile.includes(role.roleName));
        }
        availableUserRoles?.forEach((option: UserRole) => {
            allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });
        });
        return allUserRoles;
    }, [canManageUserProfile, userRoles]);

    const manufacturersList = useMemo(() => {
        let options: Option[] = [];
        manufacturerInfoList?.map((option) => {
            options.push({
                label: option.manufacturerName,
                value: option.manufacturerId,
            });
        });
        return options;
    }, [manufacturerInfoList]);

    const agencyList = useMemo(() => {
        let options: Option[] = [];
        agencyInfoList?.map((option) => {
            options.push({ label: option.agencyName, value: option.agencyId });
        });
        return options;
    }, [agencyInfoList]);

    const billerUsersList = useMemo(() => {
        let options: Option[] = [];
        billerList?.map((option) => {
            options.push({ label: option.billerName, value: option.billerId });
        });
        return options;
    }, [billerList]);

    const handleSaveManufacturer = async (data: ManufacturerCreateObj) => {
        let errorObj: MainErrorInfo = {
            title: "",
        };

        try {
            const response = await dispatch(saveManufacturers(data));
            if (response.payload && response.payload.message === 'SUCCESS') {
                navigate(-1);

                let alertMessage = isEditing ?
                    "Manufacturer Profile Updated Successfully!" :
                    "Manufacturer Profile Created Successfully!";

                const alertObj: SingleAlertInfo = {
                    message: alertMessage,
                    alertType: "success",
                };

                dispatch(setSingleAlertObj(alertObj));
            } else {
                let errorSentence = '';
                if (response.payload.errors.length <= 0) {
                    errorSentence = response.payload.message || "An unknown error occurred.";
                } else {
                    response.payload.errors.forEach((error: any, index: any) => {
                        if (error.field) {
                            errorSentence += `${error.field}: ${error.errorMessage}\n`;
                            if (error.field === 'contactEmail') {
                                setError('contactEmail', {
                                    type: 'manual',
                                    message: error.errorMessage,
                                });
                            }
                        } else {
                            errorSentence += `${error.reason}\n`;
                        }
                    });
                }

                errorObj.title = "Manufacturer Creation Failed!";
                errorObj.errorMessageStream = errorSentence;
                dispatch(setMainErrorObj(errorObj));
            }
        } catch (error) {
            console.log("Error creating Manufacturer:", error);
            errorObj.title = "Error creating Manufacturer";
            errorObj.errorMessageStream = "" + error;
            dispatch(setMainErrorObj(errorObj));
        }
    };

    const getAllList = async () => {
        try {
            dispatch(showHideLoadingDialog(true));
            const [
                resUserCall,
            ] = await Promise.all([
                dispatch(fetchAllUserRoles()),
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(showHideLoadingDialog(false));
        }
    };

    useEffect(() => {
        getAllList();
    }, [dispatch]);

    // useEffect(() => {
    //     defaultValues();
    // }, [defaultValues]);

    useEffect(() => {
        return () => {
            dispatch(clearUserObject());
        };
    }, [dispatch]);


    return (
        <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
            <Header
                title="Manufacturer Profile Management"
                subtitle={isEditing ? "Manufacturer Profile Update" : "Manufacturer Profile Create"}
            />
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                control={control}
                                controllerName="manufacturerName"
                                label="Manufacturer Name"
                                rules={{
                                    required: "Manufacturer Name is required.",
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomPatternTextField
                                control={control}
                                name="contactPhone"
                                label="Phone"
                                rules={{
                                    required: "Phone is required.",
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                control={control}
                                controllerName="contactEmail"
                                label="Email"
                                rules={{
                                    required: "Email is required.",
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                controllerName="notificationEmail"
                                control={control}
                                label="Notification Emails"
                                multiline
                                rows={6}
                                rules={{
                                    validate: (value: string) => {
                                        if (!value) return true;
                                        try {
                                            JSON.parse(value);
                                            return true;
                                        } catch (e) {
                                            return "Invalid JSON format.";
                                        }
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                controllerName="customConfig"
                                control={control}
                                label="Custom_Config"
                                multiline
                                rows={6}
                                rules={{
                                    validate: (value: string) => {
                                        if (!value) return true;
                                        try {
                                            JSON.parse(value);
                                            return true;
                                        } catch (e) {
                                            return "Invalid JSON format.";
                                        }
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Divider
                                orientation="horizontal"
                                variant="middle"
                                flexItem
                                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                            />
                        </Grid>

                        <Grid
                            mt={2}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xs={12} md="auto">
                                <Button
                                    type="submit"
                                    color="info"
                                    startIcon={<SaveOutlined />}
                                    fullWidth
                                >
                                    {isEditing ? "Update Manufacturer" : "Create Manufacturer"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button
                                    color="error"
                                    startIcon={<CancelOutlined />}
                                    onClick={() => onClose()}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </Box>
    );
};

export default CreateMaufacturer;