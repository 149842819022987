import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

const Card: React.FC<BoxProps> = ({ sx, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      className={theme.palette.mode === "dark" ? "card-dark" : "card-light"}
      sx={{
        padding: {
          xs: "20px 10px",
          sm: "20px", 
        },
        ...sx, 
      }}
    >
      {props.children}
    </Box>
  );
};

export default Card;
