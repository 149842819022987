import React from 'react';
import { Box, IconButton, Tooltip, Typography, Divider, useTheme, useMediaQuery } from '@mui/material';
import { CommentOutlined, QuestionAnswer } from '@mui/icons-material';

interface CustomCommentPreviewProps {
    title?: string;
    commentBody?: string;
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    size?: "small" | "medium" | "large";
}

const CustomCommentPreview: React.FC<CustomCommentPreviewProps> = ({ title, commentBody, color, size, ...props }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Tooltip
            title={
                <Box
                    sx={{
                        padding: 2,
                        maxWidth: 400,
                        backgroundColor: isDarkMode ? '#333' : '#fff',
                        borderRadius: 2,
                        boxShadow: isDarkMode
                            ? '0px 4px 8px rgba(0, 0, 0, 0.5)'
                            : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: isDarkMode ? '#fff' : '#333',
                            fontWeight: 700,
                            mb: 1,
                            textShadow: isDarkMode
                                ? '0px 1px 2px rgba(0, 0, 0, 0.5)'
                                : '0px 1px 2px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        {title}
                    </Typography>
                    <Divider
                        sx={{
                            bgcolor: isDarkMode ? '#555' : '#ddd',
                            opacity: 0.8,
                            my: 1,
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={{
                            color: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : '#322f2c',
                            lineHeight: 1.5,
                            letterSpacing: '0.02em',
                        }}
                    >
                        {commentBody}
                    </Typography>
                </Box>
            }
            placement={isMobile ? "bottom" : "right"}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: isDarkMode ? '#333' : '#fff',
                        color: isDarkMode ? '#fff' : '#333',
                        boxShadow: isDarkMode
                            ? '0px 3px 6px rgba(0, 0, 0, 0.4)'
                            : '0px 3px 6px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        fontSize: '14px',
                        padding: 0,
                        margin: isMobile ? 2 : 0,
                    },
                },
            }}
        >
            <IconButton
                size={size || "small"}
                color={color || "info"}
                {...props}
                sx={{ borderRadius: 1, border: 1, m: '2px' }}
            >
                <CommentOutlined fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default CustomCommentPreview;
