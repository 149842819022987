import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  useCodetablesSlice,
  getCodeTableValuesByTableName,
  CodeTable,
  fetchAllCodeTablesValues,
} from "../../../redux/slice/codetableSlice";
import { Controller, useForm } from "react-hook-form";
import {
  AddCircleOutlineOutlined,
  CloseOutlined,
  DeleteOutlineOutlined,
  ExitToApp,
  ModeOutlined,
  SaveOutlined,
  ClearOutlined
} from "@mui/icons-material";
import CustomRadioButton from "../../common/CustomRadioButton";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import Button from "../../common/Button";
import CustomNametField from "../../common/CustomNametField";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomAddressTextField from "../../common/CustomAddressTextField";
import CustomTextField from "../../common/CustomTextField";
import CustomTable from "../../common/CustomTable";
import IVRFormWoundInfoPopup from "./IVRFormWoundInfoPopup";
import CustomAlert from "../../common/CustomAlert";
import BorderedSection from "../../common/BorderedSection";
import CustomCheckbox from "../../common/CustomCheckBox";
import {
  SingleAlertInfo,
  setSingleAlertObj,
  showHideLoadingDialog,
} from "../../../redux/slice/commonSlice";
import {
  fetchProductItemsByProductId,
  resetProductItems
} from "../../../redux/slice/product/productListSlice";
import StickTopStepper from "../../common/StickTopStepper";
import Header from "../../common/Header";
import Card from "../../common/Card";
import LegacyToolBar from "../../common/LegacyToolBar";
import {
  getPatient,
  getPatientListByPracticeId,
  usePatientReducer,
} from "../../../redux/slice/patient/patientSlice";
import { fullName } from "../../../utils/Utility";
import { fetchPracticeProductsDataUsingDateOfServiceAndProvider, FetchPracticeProductsParams } from "../../../redux/slice/practice/practiceProductSlice";
import {
  fetchActivePracticeProvidersData,
  usePracticeProvidersSlice,
} from "../../../redux/slice/practice/practiceProvidersSlice";
import {
  fetchActivePracticeLocationsData,
  usePracticeLocationsSlice,
} from "../../../redux/slice/practice/practiceLocationSlice";
import {
  fetchDocumentById,
  getPracticesByUserRole,
  usePracticeSlice,
} from "../../../redux/slice/practiceSlice";
import {
  IVRFetchObjectInfo,
  IVRFetchObjectInfoWithInfoTypeData,
  IVRObjectInfo,
  Product,
  ProductListWithItems,
  fetchIVRById,
  resetIVRData,
  saveIVRData,
  submitIVR,
  useCreateIVRSlice,
} from "../../../redux/slice/ivr/createIVRSlice";
import moment from "moment";
import {
  IVRDocumentInfo,
  removeIVRDocument,
  uploadIVRDocument,
} from "../../../redux/slice/ivr/ivrDocumentSlice";
import { useLocation, useNavigate } from "react-router-dom";
import SignaturePad, {
  SignatureData,
} from "../../common/signature/SignaturePad";
import dayjs from "dayjs";
import OptionsPopup from "../../common/OptionsPopup";
import { fetchPracticeByIdData } from "../../../redux/slice/practice/practiceEditSlice";
import { PracticeInfo } from "../../../redux/slice/practice/practiceRegistrationSlice";
import { getWoundCodeValuesByWoundCodeType, useWoundCodesSlice } from "../../../redux/slice/ivr/woundCodeSlice";
import CustomIconButton from "../../common/CustomIconButton";

interface WoundInfo {
  woundType: string;
  noOfApplications: string;
  cpt: string;
  cptIds: number[];
  eCode: string;
  eCodeIds: number[];
  iCode: string;
  iCodeIds: number[];
  lCode: string;
  lCodeIds: number[];
  otherDxCodes: string;
  length: number;
  width: number;
  depth: number;
  totalSize: number;
  displayTotalSize: string;
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface ProviderData {
  providerName: string;
  npi: string;
  ptan: string;
  taxId: string;
}

interface IVRInfo {
  typeOfIVR: string;
  patientInfo: {
    patientId: number;
    patientFirstName: string;
    patientMiddleName: string;
    patientLastName: string;
    patientDOB: string;
    gender: string;
    patientStreetAddress: string;
    patientUnitNumber: string;
    patientCity: string;
    patientState: string;
    patientZipcode: string;
    faceSheet: number[];
    patientCode: string;
    isFaceSheetSkipped: boolean;
  };
  insuranceInfo: {
    insuranceName: string;
    policy: string;
    isPolicyUnderDifferentName: boolean;
    policyholderFirstName: string;
    policyholderMiddleName: string;
    policyholderLastName: string;
    policyHolderDOB: string;
    insuranceType: string;
    insuranceCards: number[];
    isInsuranceCardsSkipped: boolean;
    isSecondaryInsurance: boolean;
    secondaryInsuranceName: string;
    secondaryPolicy: string;
    isSecondaryPolicyUnderDifferentName: boolean;
    secondarypolicyholderFirstName: string;
    secondarypolicyholderMiddleName: string;
    secondarypolicyholderLastName: string;
    secondaryPolicyHolderDOB: string;
    secondaryInsuranceType: string;
    secondaryInsuranceCards: number[];
    isSecondaryInsuranceCardsSkipped: boolean;
  };
  supportingDocumentation: {
    supportingDocuments: number[];
    isSupportingDocumentsSkipped: boolean;
    imageOfWounds: number[];
    isImageOfWoundsSkipped: boolean;
  };
  patientConsentInfomation: {
    isPatientConsentSigned: string;
    patientSignDate: string;
  };
  treatmentInformation: {
    currentlyInNursingFacility: boolean;
    daysAdmittedToFacility: string;
    currentlyUnderSurgicalGlobalPeriod: boolean;
    surgicalProcedureDate: string;
    surgicalProcedureCodes: string;
    dateOfService: string;
    placesOfService: string;
    product: number;
    practiceProductId: number;
    productSizes: string[];
    products: Product[];
    currentlyCoveredUnderPartAStay: string;
  };
  woundInfo: {
    woundData: {
      woundType: string;
      cpt: string;
      lCode: string;
      eCode: string;
      iCode: string;
      otherDxCodes: string;
      noOfApplications: string;
      totalSize: string;
    }[];
    totalSizeOfAllWounds: string;
  };
  providerInformation: {
    practice: string;
    provider: string;
    practiceLocation: string;
    macId: string;
    providerSignedDate: Date;
    signature: string;
    name: string;
  };
  checkProviderSignAuth: boolean;
  comments: string;
}

interface PatientDetailsInfo {
  patientId: string;
  patientCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  patientInfo: {
    infoType: string;
    patientinfoId: number | null;
    infoTypeData: {
      patientStreetAddress?: string;
      patientUnitNumber?: string;
      patientCity?: string;
      patientState?: string;
      patientZip?: string;
      insuranceName?: string;
      policy?: string;
      isPolicyUnderDifferentName?: boolean;
      policyHolderFirstName?: string;
      policyHolderMiddleName?: string;
      policyHolderLastName?: string;
      policyHolderDOB?: string;
      insuranceType?: string;
      secondaryInsuranceName?: string;
      secondaryPolicy?: string;
      isSecondaryPolicyUnderDifferentName?: boolean;
      secondaryPolicyHolderFirstName?: string;
      secondaryPolicyHolderMiddleName?: string;
      secondaryPolicyHolderLastName?: string;
      secondaryPolicyHolderDOB?: string;
      secondaryInsuranceType?: string;
      documentIds?: number[];
    };
  }[];
}

const woundInfoTableColums: Column[] = [
  { id: "woundType", label: "Wound Type", minWidth: 200 },
  { id: "cpt", label: "CPT", minWidth: 100 },
  { id: "lCode", label: "L Code", minWidth: 100 },
  { id: "eCode", label: "E Code ", minWidth: 100 },
  { id: "iCode", label: "I Code", minWidth: 100 },
  { id: "otherDxCodes", label: "Other Dx Code(s)", minWidth: 100 },
  { id: "noOfApplications", label: "# of App ", minWidth: 100 },
  { id: "displayTotalSize", label: "Total Size(cm²)", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 100, align: "center" },
];

const IVRForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    watch,
    trigger,
    clearErrors,
    setError
  } = useForm<IVRInfo>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showWoundInfoPopup, setShowWoundInfoPopup] = useState(false);
  const [currentWoundInfo, setCurrentWoundInfo] = useState(null);
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const [signatureData, setSignatureData] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isSecondaryInsurance, setIsSecondaryInsurance] = useState(false);
  const [woundInfoRows, setWoundInfoRows] = useState<WoundInfo[]>([]);
  const [selectedProviderData, setSelectedProviderData] = useState<ProviderData[]>([]);
  const [patientInfoValid, setPatientInfoValid] = useState(false);
  const [productInfoValid, setProductInfoValid] = useState(false);
  const [insuranceInfoValid, setInsuranceInfoValid] = useState(false);
  const [ivRequestValid, setIVRequestValid] = useState(false);
  const [supportingDocumentationValid, setSupportingDocumentationValid] = useState(false);
  const [patientConsentInfoValid, setPatientConsentInfoValid] = useState(false);
  const [treatmentInfoValid, setTreatmentInfoValid] = useState(false);
  const [signatureInfoValid, setSignatureInfoValid] = useState(false);
  const [woundInfoValid, setWoundInfoValid] = useState(false);
  const { patientList, patientInfoObject } = usePatientReducer();
  const { practiceProvidersInfo } = usePracticeProvidersSlice();
  const { practiceLocationsInfo } = usePracticeLocationsSlice();
  const [isPatientInfoDisabled, setIsPatientInfoDisabled] = useState(false);
  const faceSheetDocumentIdsRef = useRef<number[]>([]);
  const insuranceCardsDocumentIdsRef = useRef<number[]>([]);
  const secondaryInsuranceCardsDocumentIdsRef = useRef<number[]>([]);
  const supportingDocsDocumentIdsRef = useRef<number[]>([]);
  const imageOfWoundsDocumentIdsRef = useRef<number[]>([]);
  const [isPatientEdited, setIsPatientEdited] = useState(false);
  const [isPatientInfoEdited, setIsPatientInfoEdited] = useState(false);
  const [isFaceSheetEdited, setIsFaceSheetEdited] = useState(false);
  const [isPrimaryInsuranceInfoEdited, setIsPrimaryInsuranceInfoEdited] = useState(false);
  const [isSecondaryInsuranceInfoEdited, setIsSecondaryInsuranceInfoEdited] = useState(false);
  const [isSupportingDocumentsEdited, setIsSupportingDocumentsEdited] = useState(false);
  const [isImageOfWoundsEdited, setIsImageOfWoundsEdited] = useState(false);
  const [patientCompleteData, setPatientCompleteData] = useState<PatientDetailsInfo>();
  const [faceSheetInitialFiles, setFaceSheetInitialFiles] = useState<ExtendedFile[]>([]);
  const [primaryInsCardsInitialFiles, setPrimaryInsCardsInitialFiles] = useState<ExtendedFile[]>([]);
  const [secondaryInsCardsInitialFiles, setSecondaryInsCardsInitialFiles] = useState<ExtendedFile[]>([]);
  const [supportingDocsInitialFiles, setSupportingDocsInitialFiles] = useState<ExtendedFile[]>([]);
  const [imagesOfWoundsInitialFiles, setImagesOfWoundsInitialFiles] = useState<ExtendedFile[]>([]);
  const [submitAction, setSubmitAction] = useState<string | null>(null);
  const { IVRFetchStatus } = useCreateIVRSlice();
  const { patientFetchStatus } = usePatientReducer();
  const [ivrId, setIvrId] = useState<number | undefined>(undefined);
  const [showEditButton, setShowEditButton] = useState(false);
  const [duplicateIvr, setDuplicateIvr] = useState(false);
  const [isPolicyUnderDifferentNameCheck, setIsPolicyUnderDifferentNameCheck] = useState(false);
  const [isPolicyUnderDifferentNameCheckSecondary, setIsPolicyUnderDifferentNameCheckSecondary] = useState(false);
  const location = useLocation();
  const [practiceID, setPracticeID] = useState("");
  const [isUnderSurgicalGlobalPeriod, setIsUnderSurgicalGlobalPeriod] = useState(false);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [disableProductField, setDisableProductField] = useState(true);
  const [ivrNumber, setIvrNumber] = useState("");
  const [patientInfoIdAddress, setPatientInfoIdAddress] = useState<number | null>(null);
  const [patientInfoIdFaceSheet, setPatientInfoIdFaceSheet] = useState<number | null>(null);
  const [patientInfoIdPrimaryInsurance, setPatientInfoIdPrimaryInsurance] = useState<number | null>(null);
  const [patientInfoIdSecondaryInsurance, setPatientInfoIdSecondaryInsurance] = useState<number | null>(null);
  const [patientInfoIdSupportingDocs, setPatientInfoIdSupportingDocs] = useState<number | null>(null);
  const [patientInfoIdWoundImages, setPatientInfoIdWoundImages] = useState<number | null>(null);
  const [isPatientCurrentlyInNursingFacility, setIsPatientCurrentlyInNursingFacility] = useState(false);
  const [signOutSideName, setSignOutSideName] = useState("");
  const [productListWithItems, setProductListWithItems] = useState<ProductListWithItems[] | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [isPracticeIndividual, setIsPracticeIndividual] = useState(false);
  const [optionPopupShow, setOptionPopupShow] = React.useState<{ message: string; show: boolean }>({ message: "", show: false });
  const [loadingProdcuts, setLoadingProdcuts] = useState<boolean>(false);
  const [showPatientSignDate, setShowPatientSignDate] = useState<boolean>(false);
  const [practiceObject, setPracticeObject] = useState<PracticeInfo>();
  const { allWoundCodesValuesMap, woundCodeFetchStatus } = useWoundCodesSlice();
  const [resetComplete, setResetComplete] = useState(false);

  // Autosave IVR - START
  const requiredFieldsFilled = useCallback(() => {
    const values = getValues();
    const hasPatientInfo = !!values.patientInfo.patientFirstName && !!values.patientInfo.patientLastName;
    const hasValidProducts = Array.isArray(values.treatmentInformation.products) && values.treatmentInformation.products.length > 0 && values.treatmentInformation.products.every(product => Array.isArray(product.productSizes) && product.productSizes.length > 0);

    return hasPatientInfo && hasValidProducts;
  }, [getValues("treatmentInformation.products"), getValues("patientInfo.patientFirstName"), getValues("patientInfo.patientLastName")]);

  useEffect(() => {
    const autosaveInterval = Number(process.env.REACT_APP_IVR_AUTOSAVE_INTERVAL) || 10000;
    const interval = setInterval(() => {
      if (requiredFieldsFilled() && !location?.state?.viewOnly) {
        saveIVRdataInBackground();
      }
    }, autosaveInterval);

    return () => clearInterval(interval);
  }, [requiredFieldsFilled, ivrNumber, ivrId, woundInfoRows, isPatientEdited, isPatientInfoEdited, isFaceSheetEdited, isPrimaryInsuranceInfoEdited, isSecondaryInsuranceInfoEdited, isSupportingDocumentsEdited, isImageOfWoundsEdited]);

  const saveIVRdataInBackground = async () => {
    const updatedObject = mapIVRInfoToIVRObjectInfo(getValues());
    const response = await dispatch(saveIVRData({ dataObj: updatedObject, interceptorOption: { skipErrorPopup: true } }));
    if (response.payload && response.payload.message === "SUCCESS") {
      let successObj: SingleAlertInfo = {
        message: "Your changes have been auto-saved.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      setIvrNumber(response.payload.data.ivrNumber);
      setIvrId(response.payload.data.ivrId);

      setValue("patientInfo.patientId", response.payload.data.patient.patientId);
      const patientDetailsInfo = transformIVRFetchObjectInfo(response.payload.data);
      addPatientInfoIds(response.payload.data);
      setPatientCompleteData(patientDetailsInfo);
    } else {
      let errorObj: SingleAlertInfo = {
        message: "An error occurred while auto saving the IVR.",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errorObj));
    }
  }
  // Autosave IVR - END

  //Unmount
  useEffect(
    () => () => {
      dispatch(resetProductItems());
      dispatch(resetIVRData());
      setDisableAllFields(false);
    },
    []
  );

  //Mount
  useEffect(() => {
    const fetchData = async () => {
      await fetchPractice();
      const ivrId = location?.state?.ivrId;
      const isOnViewOnly = location?.state?.viewOnly;
      const isDuplicateIVR = location?.state?.duplicateIVR;
      if (ivrId) {
        fetchIVRData(ivrId.toString()).then((res) => {
          if (res.payload && res.payload.code === "V3OK" && res.payload.message === "SUCCESS") {
            setDisableAllFields(isOnViewOnly);
            if (res.payload.data) {
              if(!isDuplicateIVR) {
                setIvrId(res.payload.data?.ivrId);
                setIvrNumber(res.payload.data?.ivrNumber);
              }
              const patientDetailsInfo = transformIVRFetchObjectInfo(res.payload.data);
              setPatientCompleteData(patientDetailsInfo);
              if (!isOnViewOnly) {
                setShowEditButton(true);
                setIsPatientInfoDisabled(true);
              }
              if(isDuplicateIVR) {
                setDuplicateIvr(true);
              }
            }
            const transformedData = transformData(res.payload.data);
            reset(transformedData);
            if (!location?.state?.copiedIvr) {
              checkSectionValidity();
            }
            setResetComplete(true);
          } else {
            reset();
            setResetComplete(true);
          }
        });
      }
    };

    fetchData();

    return () => {
      setFirstLoad(true);
    };
  }, []);

  useEffect(() => {
    if (resetComplete) {
      const copiedIvr = location?.state?.copiedIvr;
      if (copiedIvr) {
        setValue("typeOfIVR", "");
        setValue("treatmentInformation.dateOfService", "");
        setValue("treatmentInformation.products", []);
        setValue("treatmentInformation.practiceProductId", 0);
        setValue("treatmentInformation.productSizes", []);
        setValue("providerInformation.signature", "");
        setValue("providerInformation.name", "");
        setValue("checkProviderSignAuth", false);
        setIvrNumber("");
        setIvrId(undefined);
        setSignatureData("");
        setSignOutSideName("");
        setSelectedProducts([]);
        checkSectionValidity();
      }
    }
  }, [isPracticeIndividual, resetComplete]);

  const addPatientInfoIds = async (data: IVRFetchObjectInfo) => {
    const patientInfo = data.patient.patientInfo.reduce((acc: any, info: any) => {

      switch (info.infoType) {
        case "ADDRESS":
          setPatientInfoIdAddress(info.patientinfoId);
          break;
        case "FACESHEET":
          setPatientInfoIdFaceSheet(info.patientinfoId);
          break;
        case "PRIMARY_INSURANCE":
          setPatientInfoIdPrimaryInsurance(info.patientinfoId);
          break;
        case "SECONDARY_INSURANCE":
          setPatientInfoIdSecondaryInsurance(info.patientinfoId);
          break;
        case "SUPPORTING_DOCUMENT":
          setPatientInfoIdSupportingDocs(info.patientinfoId);
          break;
        case "IMAGEOF_WOUNDS":
          setPatientInfoIdWoundImages(info.patientinfoId);
          break;
        default:
          break;
      }

      return acc;
    }, []);
  };

  const fetchPractice = async () => {
    if (location?.state?.practiceId) {
      dispatch(fetchPracticeByIdData(location?.state?.practiceId)).then((res) => {
        if (res.payload && res.payload.code === "V3OK" && res.payload.message === "SUCCESS") {
          setPracticeObject(res.payload.data);
          if (res.payload.data.billingType === "INDIVIDUAL") {
            setIsPracticeIndividual(true)
          } else {
            setIsPracticeIndividual(false)
          }
        }
      })
      setPracticeID(location.state.practiceId);
    } else {
      dispatch((getPracticesByUserRole(false))).then((res) => {
        if (res.payload != undefined && res.payload?.length > 0) {
          if (res.payload.length === 1 && res.payload[0].billingType === "INDIVIDUAL") {
            setIsPracticeIndividual(true)
          } else {
            setIsPracticeIndividual(false)
          }
          setPracticeID(res.payload[0].practiceId);
          setPracticeObject(res.payload[0]);
        }
      })
    }
  }

  const fetchIVRData = async (ivrId: string) => {
    const response = await dispatch(fetchIVRById(ivrId))
    return response;
  }

  const checkSectionValidity = () => {
    checkIVRequestValidity(getValues());
    checkProductInfoValidity(getValues());
    checkPatientInfoValidity(getValues());
    checkInsuranceInfoValidity(getValues());
    checkSupportingDocumentationValidity(getValues());
    checkTreatmentInfoValidity(getValues());
    checkPatientConsentInfoValidity(getValues());
  }

  const transformIVRFetchObjectInfo = (
    data: IVRFetchObjectInfoWithInfoTypeData
  ): PatientDetailsInfo => {
    return {
      patientId: data.patient.patientId.toString(),
      patientCode: data.patient.patientCode,
      firstName: data.patient.firstName,
      middleName: data.patient.middleName,
      lastName: data.patient.lastName,
      birthDate: data.patient.birthDate,
      gender: data.patient.gender,
      patientInfo: data.patient.patientInfo.map((info) => ({
        infoType: info.infoType,
        patientinfoId: info.patientinfoId,
        infoTypeData: {
          patientStreetAddress: info.infoTypeData?.patientStreetAddress,
          patientUnitNumber: info.infoTypeData?.patientUnitNumber,
          patientCity: info.infoTypeData?.patientCity,
          patientState: info.infoTypeData?.patientState,
          patientZip: info.infoTypeData?.patientZip,
          insuranceName: info.infoTypeData?.insuranceName,
          policy: info.infoTypeData?.policy,
          isPolicyUnderDifferentName:
            typeof info.infoTypeData?.isPolicyUnderDifferentName === "string"
              ? info.infoTypeData?.isPolicyUnderDifferentName === "true"
              : info.infoTypeData?.isPolicyUnderDifferentName,
          policyHolderFirstName: info.infoTypeData?.policyHolderFirstName,
          policyHolderMiddleName: info.infoTypeData?.policyHolderMiddleName,
          policyHolderLastName: info.infoTypeData?.policyHolderLastName,
          policyHolderDOB: info.infoTypeData?.policyHolderDOB,
          insuranceType: info.infoTypeData?.insuranceType
            ? info.infoTypeData.insuranceType.join(", ")
            : undefined,
          documentIds: info.infoTypeData?.ivrDocumentListDTOs?.map(
            (doc) => doc.documentId
          ),
        },
      })),
    };
  };

  // Fetch and process documents and set initial files
  const processDocuments = async (documentList: any, setterFunction: any) => {
    if (documentList.length > 0) {
      try {
        const processedDocumentList = await fetchAndProcessDocuments(
          documentList,
          dispatch
        );
        setterFunction(processedDocumentList);
      } catch (error) {
        console.error("Error fetching and processing documents:", error);
      }
    }
  };

  //Transform the fetched object type to form object type
  function transformData(data: any): IVRInfo {
    const patientInfo = data.patient.patientInfo.reduce(
      (acc: any, info: any) => {
        if (info.infoType === "ADDRESS") {
          acc.patientStreetAddress = info?.patientinfoData.patientStreetAddress;
          acc.patientUnitNumber = info?.patientinfoData.patientUnitNumber;
          acc.patientCity = info?.patientinfoData.patientCity;
          acc.patientState = info?.patientinfoData.patientState;
          acc.patientZipcode = info?.patientinfoData.patientZip;
          setPatientInfoIdAddress(info.patientinfoId)
        } else if (info.infoType === "FACESHEET") {
          if (info?.patientinfoData?.ivrDocumentListDTOs) {
            acc.faceSheet.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
            faceSheetDocumentIdsRef.current.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
          }
          setPatientInfoIdFaceSheet(info.patientinfoId)
          if (info?.patientinfoData?.isSkipped) {
            acc.isFaceSheetSkipped = true
          }
        }
        return acc;
      },
      {
        patientStreetAddress: "",
        patientUnitNumber: "",
        patientCity: "",
        patientState: "",
        patientZipcode: "",
        faceSheet: [],
      }
    );

    // Process faceSheet documents
    if (patientInfo.faceSheet.length > 0) {
      processDocuments(patientInfo.faceSheet, setFaceSheetInitialFiles);
    }

    const insuranceInfo = data.patient.patientInfo.reduce(
      (acc: any, info: any) => {
        if (info.infoType === "PRIMARY_INSURANCE") {
          if (info?.patientinfoData.isPolicyUnderDifferentName === "yes") {
            setIsPolicyUnderDifferentNameCheck(true);
          }
          acc.insuranceName = info?.patientinfoData.insuranceName;
          acc.policy = info?.patientinfoData.policy;
          acc.isPolicyUnderDifferentName =
            info?.patientinfoData.isPolicyUnderDifferentName;
          acc.policyholderFirstName =
            info?.patientinfoData.policyHolderFirstName;
          acc.policyholderMiddleName =
            info?.patientinfoData.policyHolderMiddleName;
          acc.policyholderLastName = info?.patientinfoData.policyHolderLastName;
          acc.policyHolderDOB = info?.patientinfoData.policyHolderDOB;
          acc.insuranceType = info?.patientinfoData.insuranceType;
          if (info?.patientinfoData?.ivrDocumentListDTOs) {
            acc.insuranceCards.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
            insuranceCardsDocumentIdsRef.current.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
          }
          if (info?.patientinfoData?.isSkipped) {
            acc.isInsuranceCardsSkipped = true
          }
          setPatientInfoIdPrimaryInsurance(info.patientinfoId)
        } else if (
          info.infoType === "SECONDARY_INSURANCE" &&
          info?.patientinfoData.insuranceName !== undefined &&
          info?.patientinfoData.insuranceName !== ""
        ) {
          acc.secondaryInsurance = true;
          if (info?.patientinfoData.isPolicyUnderDifferentName === "yes") {
            setIsPolicyUnderDifferentNameCheckSecondary(true);
          }
          acc.secondaryInsuranceName = info?.patientinfoData.insuranceName;
          acc.secondaryPolicy = info?.patientinfoData.policy;
          acc.isSecondaryPolicyUnderDifferentName =
            info?.patientinfoData.isPolicyUnderDifferentName;
          acc.secondarypolicyholderFirstName =
            info?.patientinfoData.policyHolderFirstName;
          acc.secondarypolicyholderMiddleName =
            info?.patientinfoData.policyHolderMiddleName;
          acc.secondarypolicyholderLastName =
            info?.patientinfoData.policyHolderLastName;
          acc.secondaryPolicyHolderDOB = info?.patientinfoData.policyHolderDOB;
          acc.secondaryInsuranceType = info?.patientinfoData.insuranceType;
          if (info?.patientinfoData?.ivrDocumentListDTOs) {
            acc.secondaryInsuranceCards.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
            setIsSecondaryInsurance(true);
            secondaryInsuranceCardsDocumentIdsRef.current.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
          }
          if (info?.patientinfoData?.isSkipped) {
            acc.isSecondaryInsuranceCardsSkipped = true
          }
          setPatientInfoIdSecondaryInsurance(info.patientinfoId)
        }
        return acc;
      },
      {
        insuranceName: "",
        policy: "",
        isPolicyUnderDifferentName: false,
        policyholderFirstName: "",
        policyholderMiddleName: "",
        policyholderLastName: "",
        policyHolderDOB: "",
        insuranceType: "",
        insuranceCards: [],
        isSecondaryInsurance: false,
        secondaryInsuranceName: "",
        secondaryPolicy: "",
        isSecondaryPolicyUnderDifferentName: false,
        secondarypolicyholderFirstName: "",
        secondarypolicyholderMiddleName: "",
        secondarypolicyholderLastName: "",
        secondaryPolicyHolderDOB: "",
        secondaryInsuranceType: "",
        secondaryInsuranceCards: [],
      }
    );

    // Process primary insurance documents
    if (insuranceInfo.insuranceCards.length > 0) {
      processDocuments(
        insuranceInfo.insuranceCards,
        setPrimaryInsCardsInitialFiles
      );
    }

    // Process secondary insurance documents
    if (insuranceInfo.secondaryInsuranceCards.length > 0) {
      processDocuments(
        insuranceInfo.secondaryInsuranceCards,
        setSecondaryInsCardsInitialFiles
      );
    }

    const supportingDocumentation = data.patient.patientInfo.reduce(
      (acc: any, info: any) => {
        if (info.infoType === "SUPPORTING_DOCUMENT") {
          if (info?.patientinfoData?.ivrDocumentListDTOs) {
            acc.supportingDocuments.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
            supportingDocsDocumentIdsRef.current.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
          }
          if (info?.patientinfoData?.isSkipped) {
            acc.isSupportingDocumentsSkipped = true
          }
          setPatientInfoIdSupportingDocs(info.patientinfoId)
        } else if (info.infoType === "IMAGEOF_WOUNDS") {
          if (info?.patientinfoData?.ivrDocumentListDTOs) {
            acc.imageOfWounds.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
            imageOfWoundsDocumentIdsRef.current.push(
              ...info?.patientinfoData.ivrDocumentListDTOs.map(
                (doc: any) => doc.documentId
              )
            );
          }
          if (info?.patientinfoData?.isSkipped) {
            acc.isImageOfWoundsSkipped = true
          }
          setPatientInfoIdWoundImages(info.patientinfoId)
        }
        return acc;
      },
      {
        supportingDocuments: [],
        imageOfWounds: [],
      }
    );

    // Process supporting documentations
    if (supportingDocumentation.supportingDocuments.length > 0) {
      processDocuments(
        supportingDocumentation.supportingDocuments,
        setSupportingDocsInitialFiles
      );
    }
    // Process images of wounds
    if (supportingDocumentation.imageOfWounds.length > 0) {
      processDocuments(
        supportingDocumentation.imageOfWounds,
        setImagesOfWoundsInitialFiles
      );
    }

    const woundDataList = data.ivrInfo.woundInfo.woundData;
    let processedWoundData = woundDataList.map((woundData: WoundInfo) => {
      const totalSize = parseFloat(woundData.totalSize as unknown as string);
      const totalSizeFormatted = isNaN(totalSize) ? '0.00' : totalSize.toFixed(2);
      const cptFormatted = Array.isArray(woundData.cpt) ? woundData.cpt.join(', ') : woundData.cpt;
      return {
        woundType: woundData.woundType,
        cpt: cptFormatted,
        cptIds: woundData.cptIds,
        lCode: woundData.lCode,
        lCodeIds: woundData.lCodeIds,
        eCode: woundData.eCode,
        eCodeIds: woundData.eCodeIds,
        iCode: woundData.iCode,
        iCodeIds: woundData.iCodeIds,
        otherDxCodes: woundData.otherDxCodes,
        noOfApplications: woundData.noOfApplications,
        length: woundData.length,
        width: woundData.width,
        depth: woundData.depth,
        totalSize: totalSizeFormatted,
        displayTotalSize: `${totalSizeFormatted}cm² (${woundData.length} X ${woundData.width} X ${woundData.depth})`
      };
    });
    setWoundInfoRows(processedWoundData);

    if (data.ivrInfo.treatmentInfo.productId) {
      const onlyActiveRecords = true;
      dispatch(fetchProductItemsByProductId({ productId: data.ivrInfo.treatmentInfo.productId, onlyActiveRecords }));
    }

    const providerData = data.ivrInfo.providerInfo.provider;
    setSelectedProviderData([providerData]);
    if (!location?.state?.copiedIvr) {
      setSignatureData(data.ivrInfo.providerInfo.signature);
    }

    if (data.ivrInfo.treatmentInfo.currentlyUnderSurgicalGlobalPeriod === "true") {
      setIsUnderSurgicalGlobalPeriod(true);
    }

    if (data.ivrInfo.treatmentInfo.currentlyInNursingFacility === "true") {
      setIsPatientCurrentlyInNursingFacility(true)
    }

    //Load products list
    setDisableProductField(false);
    // handleFetchPracticeProducts(data?.dateOfService, data.practiceId, data.providerId);
    setSelectedProducts(data.ivrInfo.treatmentInfo.products);

    if (data.ivrInfo?.patientConsentInfo?.isPatientConsentSigned === "yes") {
      setShowPatientSignDate(true);
    }

    return {
      typeOfIVR: data.benefitVerificationType,
      patientInfo: {
        patientId: data.patient.patientId,
        patientFirstName: data.patient.firstName,
        patientMiddleName: data.patient.middleName,
        patientLastName: data.patient.lastName,
        patientDOB: data.patient.birthDate,
        gender: data.patient.gender,
        ...patientInfo,
      },
      insuranceInfo: {
        ...insuranceInfo,
      },
      supportingDocumentation: {
        ...supportingDocumentation,
      },
      patientConsentInfomation: {
        isPatientConsentSigned: data.ivrInfo.patientConsentInfo?.isPatientConsentSigned,
        patientSignDate: data.ivrInfo.patientConsentInfo?.patientSignDate,
      },
      treatmentInformation: {
        currentlyInNursingFacility:
          data.ivrInfo.treatmentInfo.currentlyInNursingFacility,
        daysAdmittedToFacility: data.ivrInfo.treatmentInfo.daysAdmittedToFacility,
        currentlyUnderSurgicalGlobalPeriod:
          data.ivrInfo.treatmentInfo.currentlyUnderSurgicalGlobalPeriod,
        surgicalProcedureDate: data.ivrInfo.treatmentInfo.surgicalProcedureDate,
        surgicalProcedureCodes:
          data.ivrInfo.treatmentInfo.surgicalProcedureCodes,
        dateOfService: data.dateOfService,
        placesOfService: data.ivrInfo.treatmentInfo.placesOfService[0],
        product: data.ivrInfo.treatmentInfo.productId,
        practiceProductId: data.ivrInfo.treatmentInfo.practiceProductId,
        productSizes: data.ivrInfo.treatmentInfo.productSizes,
        products: data.ivrInfo.treatmentInfo.products,
        currentlyCoveredUnderPartAStay: data.ivrInfo.treatmentInfo.currentlyCoveredUnderPartAStay
      },
      woundInfo: {
        woundData: processedWoundData,
        totalSizeOfAllWounds: data.ivrInfo.woundInfo.totalSizeOfAllWounds,
      },
      providerInformation: {
        practice: data.practiceId,
        provider: data.providerId,
        practiceLocation: data.locationId,
        macId: data.macId,
        providerSignedDate: new Date(data.ivrInfo.providerInfo.date),
        signature: data.ivrInfo.providerInfo.signature,
        name: location?.state?.copiedIvr ? "" : data.ivrInfo.providerInfo.name,
      },
      checkProviderSignAuth: data.ivrInfo.providerInfo.signature ? true : false,
      comments: data.ivrInfo?.additionalNotes?.content
    };
  }

  const placesOfService = useMemo(() => {
    let placeOfService: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      placeOfService = getCodeTableValuesByTableName(allCodeTablesValuesMap, "PlaceOfService");
    }
    if (!placeOfService || placeOfService.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string, value: any }[] = [];
    placeOfService?.map((option) => {
      options.push({ label: option.displayValue, value: option.keyName });

    });
    return options;
  }, [allCodeTablesValuesMap]);


  const practiceProvidersOptions = useMemo(() => {
    let options: { label: string; value: number }[] = [];
    practiceProvidersInfo?.map((option: any) => {
      options.push({
        label: `${option.firstName ? option.firstName : ""} ${
          option.middleName ? option.middleName + " " : ""
        }${option.lastName ? option.lastName : ""}`,
        value: option.providerId,
      });
    });
    return options;
    return [];
  }, [practiceProvidersInfo]);

  const practiceLocationsOptions = useMemo(() => {
    let options: { label: string; value: number }[] = [];
    practiceLocationsInfo?.map((option: any) => {
      const locationParts = [option.streetAddress, option.unitNumber, option.city, option.state, option.zipCode];
      const filteredLocationParts = locationParts.filter(part => part !== undefined && part !== null && part.trim() !== "");
      const locationName = filteredLocationParts?.join(", ")
      options.push({
        // label: `${option.streetAddress} ${option.unitNumber} ${option.city} ${option.state} ${option.zipCode}`,
        label: locationName,
        value: option.locationId,
      });
    });
    return options;
    return [];
  }, [practiceLocationsInfo]);

  const patientsOptions = React.useMemo(() => {
    let options: { label: string; value: string; patientCode: string }[] = [];
    patientList?.map((option: any) => {
      options.push({
        label: fullName(
          "",
          option.firstName,
          option.middleName,
          option.lastName
        ) + " - " + option.birthDate,
        value: option.patientId,
        patientCode: option.patientCode,
      });
    });
    return options;
  }, [patientList]);

  const practiceOptions = React.useMemo(() => {
    let options: { label: string; value: string }[] = [];
    if (parseInt(practiceID) > 0) {
      setValue("providerInformation.practice", practiceID);
      if (practiceObject !== undefined) {
        options.push({ label: practiceObject.practiceName, value: practiceObject.practiceId });
      }
    }
    return options;
  }, [practiceID, practiceObject]);

  useEffect(() => {
    if (practiceID) {
      dispatch(fetchActivePracticeProvidersData({ productId: practiceID, onlyActiveRecords: true }));
      dispatch(fetchActivePracticeLocationsData(practiceID));
      fetchPatientsList();
    }
  }, [practiceID]);

  const fetchPatientsList = async () => {
    await dispatch(getPatientListByPracticeId({ practiceId: practiceID }));
  };

  useEffect(() => {
    const calculateTotalSize = () => {
      let totalValid = 0;
      let foundValidNumber = false;

      const woundData = woundInfoRows.map((current) => {
        const size = Number(current.totalSize);
        if (!isNaN(size)) {
          totalValid += size;
          foundValidNumber = true;
        }

        return {
          woundType: current.woundType,
          noOfApplications: current.noOfApplications,
          cpt: current.cpt,
          eCode: current.eCode,
          iCode: current.iCode,
          lCode: current.lCode,
          otherDxCodes: current.otherDxCodes,
          totalSize: current.totalSize.toString(),
        };
      });

      setValue("woundInfo.woundData", woundData);
      if (foundValidNumber) {
        setValue("woundInfo.totalSizeOfAllWounds", totalValid.toString());
      } else {
        setValue("woundInfo.totalSizeOfAllWounds", "");
      }
      trigger("woundInfo.totalSizeOfAllWounds");
      trigger("woundInfo.woundData");
    };

    calculateTotalSize();
  }, [woundInfoRows, setValue]);

  const typeOfIVROptions = [
    { value: "newApplication", label: "New Application" },
    { value: "additionalApplication", label: "Additional Application" },
    { value: "reVerification", label: "Re-Verification" },
    { value: "newInsurance", label: "New Insurance" },
  ];

  const patientGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const policyOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const consentOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: "n/a", label: "N/A" },
  ];

  const patientInNursingFacilityOrHomeOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const patientUnderSurgicalOrGlobalPeriodOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleProviderChange = () => {
    const providerId = getValues("providerInformation.provider");
    const selectedProvider =
      practiceProvidersInfo &&
      practiceProvidersInfo.find(
        (provider) => provider.providerId === parseInt(providerId)
      );
    if (selectedProvider != undefined) {
      const data = [
        {
          providerName: fullName(
            "",
            selectedProvider?.firstName,
            selectedProvider?.middleName,
            selectedProvider?.lastName
          ),
          npi: selectedProvider?.npi,
          ptan: selectedProvider?.ptan,
          taxId: selectedProvider?.taxId,
        },
      ];
      setSelectedProviderData(data);
    } else {
      setSelectedProviderData([]);
    }
  };

  const handleAddWoundInfo = (woundInfoData: WoundInfo) => {
    const displayTotalSize = `${woundInfoData.totalSize}cm² (${woundInfoData.length} X ${woundInfoData.width} X ${woundInfoData.depth})`
    const updatedWoundInfoData = { ...woundInfoData, displayTotalSize };
    let updatedWoundInfoData2 = { ...updatedWoundInfoData };
    if (woundCodeFetchStatus === "success" && allWoundCodesValuesMap) {
      const cptCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "CPTCODE")
      const matchedCodes = cptCodes.filter(code => code.woundKey !== undefined && woundInfoData.cpt.includes(code.woundKey));

      const eCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ECODE")
      const matchedECodes = eCodes.filter(code => code.woundKey !== undefined && woundInfoData.eCode.includes(code.woundKey));

      const iCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ICODE")
      const  matchedICodes = iCodes.filter(code => code.woundKey !== undefined && woundInfoData.eCode.includes(code.woundKey));

      const lCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "LCODE")
      const matchedLCodes = lCodes.filter(code => code.woundKey !== undefined && woundInfoData.eCode.includes(code.woundKey));

      const cptFormatted = Array.isArray(updatedWoundInfoData2.cpt) ? updatedWoundInfoData2.cpt.join(', ') : updatedWoundInfoData2.cpt;
      const eCodesFormatted = Array.isArray(updatedWoundInfoData2.eCode) ? updatedWoundInfoData2.eCode.join(', ') : updatedWoundInfoData2.eCode;
      const iCodesFormatted = Array.isArray(updatedWoundInfoData2.iCode) ? updatedWoundInfoData2.iCode.join(', ') : updatedWoundInfoData2.iCode;
      const lCodesFormatted = Array.isArray(updatedWoundInfoData2.lCode) ? updatedWoundInfoData2.lCode.join(', ') : updatedWoundInfoData2.lCode;

      updatedWoundInfoData2 = {
        ...updatedWoundInfoData2,
        cptIds: matchedCodes.map(code => Number(code.woundcodeId)).filter(id => !isNaN(id)),
        eCodeIds: matchedECodes.map(code => Number(code.woundcodeId)).filter(id => !isNaN(id)),
        iCodeIds: matchedICodes.map(code => Number(code.woundcodeId)).filter(id => !isNaN(id)),
        lCodeIds: matchedLCodes.map(code => Number(code.woundcodeId)).filter(id => !isNaN(id)),
        cpt: cptFormatted,
        eCode: eCodesFormatted,
        iCode: iCodesFormatted,
        lCode: lCodesFormatted,

      };
      if (currentWoundInfo) {
        const updatedWoundInfo = woundInfoRows.map((item) =>
          item === currentWoundInfo ? updatedWoundInfoData2 : item
        );
        setWoundInfoRows(updatedWoundInfo);
      } else {
        setWoundInfoRows([...woundInfoRows, updatedWoundInfoData2]);
      }
      setShowWoundInfoPopup(false);
      setCurrentWoundInfo(null);
    }
  };

  useEffect(() => {
    checkWoundInfoValidity({ ...watch() });
  }, [woundInfoRows]);

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
  ];

  const woundInfoActionComponent = (
    row: any,
    rowIndex: number
  ): JSX.Element => {
    return (
      <>
        <CustomIconButton
          color="info"
          size="small"
          variant="outlined"
          tooltipMessage="Edit"
          tooltipPlacement="top"
          onClick={() => onEditClick(row, rowIndex, "woundInfo")}
          disabled={disableAllFields}
        >
          <ModeOutlined fontSize="small" />
        </CustomIconButton>{" "}
        {!row.productItemId || row.productItemId === 0 ? (
          <CustomIconButton
            color="error"
            size="small"
            variant="outlined"
            tooltipMessage="Delete"
            tooltipPlacement="top"
            onClick={() => onDeleteClick(rowIndex, "woundInfo")}
            disabled={disableAllFields}
          >
            <DeleteOutlineOutlined fontSize="small" />
          </CustomIconButton>
        ) : null}
      </>
    );
  };

  const onEditClick = React.useCallback(
    (row: any, rowIndex: number, fieldName: string) => {
      setCurrentWoundInfo(row);
      setShowWoundInfoPopup(true);
    },
    []
  );

  const onDeleteClick = React.useCallback(
    (rowIndex: number, fieldName: string) => {
      setWoundInfoRows((currentRows) =>
        currentRows.filter((_, index) => index !== rowIndex)
      );
      checkWoundInfoValidity({ ...watch() });
      setShowErrorAlert(false);
    },
    []
  );

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };

  const removeDataUriPrefix = (dataUri: string): string => {
    const base64String = dataUri.split(",")[1];
    return base64String || "";
  };

  const handleFaceSheetChange = async (uploadedFiles: ExtendedFile[]) => {
    const newFiles = uploadedFiles.filter((file) => !file.documentId);

    if (newFiles.length > 0) {
      for (const fileData of newFiles) {
        let base64File = String(await convertBase64(fileData));
        const FaceSheetObject: IVRDocumentInfo = {
          ivrId: ivrId,
          documentName: fileData.name,
          content: removeDataUriPrefix(base64File),
          type: "IVR",
          fileType: fileData.type,
        };

        const response = await dispatch(uploadIVRDocument(FaceSheetObject));
        let documentId = response.payload.data.documentId;
        fileData.documentId = documentId;
        faceSheetDocumentIdsRef.current.push(documentId);
      }

      setValue("patientInfo.faceSheet", faceSheetDocumentIdsRef.current);
      checkPatientInfoValidity({ ...watch() });
      trigger("patientInfo.faceSheet");
    }
  };

  const handleDeleteFacesheetDocument = async (documentId: number) => {
    const response = await dispatch(removeIVRDocument(documentId.toString()));
    if (response.payload.code === "V3OK") {
      faceSheetDocumentIdsRef.current = faceSheetDocumentIdsRef.current.filter(
        (id: number) => id != documentId
      );
      setValue("patientInfo.faceSheet", faceSheetDocumentIdsRef.current);
      checkPatientInfoValidity({ ...watch() });
    }
  };

  const handleInsuranceCardChange = async (uploadedFiles: ExtendedFile[]) => {
    const newFiles = uploadedFiles.filter((file) => !file.documentId);

    if (newFiles.length > 0) {
      for (const fileData of newFiles) {
        let base64File = String(await convertBase64(fileData));
        const FaceSheetObject: IVRDocumentInfo = {
          ivrId: ivrId,
          documentName: fileData.name,
          content: removeDataUriPrefix(base64File),
          type: "IVR",
          fileType: fileData.type,
        };

        const response = await dispatch(uploadIVRDocument(FaceSheetObject));
        let documentId = response.payload.data.documentId;
        fileData.documentId = documentId;
        insuranceCardsDocumentIdsRef.current.push(documentId);
      }

      setValue(
        "insuranceInfo.insuranceCards",
        insuranceCardsDocumentIdsRef.current
      );
      checkInsuranceInfoValidity({ ...watch() });
      trigger("insuranceInfo.insuranceCards");
    }
  };

  const handleDeleteInsuranceDocument = async (documentId: number) => {
    const response = await dispatch(removeIVRDocument(documentId.toString()));
    if (response.payload.code === "V3OK") {
      insuranceCardsDocumentIdsRef.current =
        insuranceCardsDocumentIdsRef.current.filter((id) => id != documentId);
      setValue(
        "insuranceInfo.insuranceCards",
        insuranceCardsDocumentIdsRef.current
      );
    }
  };

  const handleSupportingDocsChange = async (uploadedFiles: ExtendedFile[]) => {
    const newFiles = uploadedFiles.filter((file) => !file.documentId);

    if (newFiles.length > 0) {
      for (const fileData of newFiles) {
        let base64File = String(await convertBase64(fileData));
        const FaceSheetObject: IVRDocumentInfo = {
          ivrId: ivrId,
          documentName: fileData.name,
          content: removeDataUriPrefix(base64File),
          type: "IVR",
          fileType: fileData.type,
        };

        const response = await dispatch(uploadIVRDocument(FaceSheetObject));
        let documentId = response.payload.data.documentId;
        fileData.documentId = documentId;
        supportingDocsDocumentIdsRef.current.push(documentId);
      }

      setValue(
        "supportingDocumentation.supportingDocuments",
        supportingDocsDocumentIdsRef.current
      );
      checkSupportingDocumentationValidity({ ...watch() });
      trigger("supportingDocumentation.supportingDocuments");
    }
  };

  const handleDeleteSupportingDocument = async (documentId: number) => {
    const response = await dispatch(removeIVRDocument(documentId.toString()));
    if (response.payload.code === "V3OK") {
      supportingDocsDocumentIdsRef.current =
        supportingDocsDocumentIdsRef.current.filter((id: number) => id != documentId);
      setValue(
        "supportingDocumentation.supportingDocuments",
        supportingDocsDocumentIdsRef.current
      );
      checkSupportingDocumentationValidity({ ...watch() });
    }
  };

  const handleImageOfWoundsChange = async (uploadedFiles: ExtendedFile[]) => {
    const newFiles = uploadedFiles.filter((file) => !file.documentId);

    if (newFiles.length > 0) {
      for (const fileData of newFiles) {
        let base64File = String(await convertBase64(fileData));
        const FaceSheetObject: IVRDocumentInfo = {
          ivrId: ivrId,
          documentName: fileData.name,
          content: removeDataUriPrefix(base64File),
          type: "IVR",
          fileType: fileData.type,
        };

        const response = await dispatch(uploadIVRDocument(FaceSheetObject));
        let documentId = response.payload.data.documentId;
        fileData.documentId = documentId;
        imageOfWoundsDocumentIdsRef.current.push(documentId);
      }

      setValue(
        "supportingDocumentation.imageOfWounds",
        imageOfWoundsDocumentIdsRef.current
      );
      checkSupportingDocumentationValidity({ ...watch() });
      trigger("supportingDocumentation.imageOfWounds");
    }
  };

  const handleDeleteImageOfWoundsDocument = async (documentId: number) => {
    const response = await dispatch(removeIVRDocument(documentId.toString()));
    if (response.payload.code === "V3OK") {
      imageOfWoundsDocumentIdsRef.current =
        imageOfWoundsDocumentIdsRef.current.filter((id: number) => id != documentId);
      setValue(
        "supportingDocumentation.imageOfWounds",
        imageOfWoundsDocumentIdsRef.current
      );
      checkSupportingDocumentationValidity({ ...watch() });
    }
  };

  const handleSecondaryInsuranceCardChange = async (
    uploadedFiles: ExtendedFile[]
  ) => {
    const newFiles = uploadedFiles.filter((file) => !file.documentId);

    if (newFiles.length > 0) {
      for (const fileData of newFiles) {
        let base64File = String(await convertBase64(fileData));
        const FaceSheetObject: IVRDocumentInfo = {
          ivrId: ivrId,
          documentName: fileData.name,
          content: removeDataUriPrefix(base64File),
          type: "IVR",
          fileType: fileData.type,
        };

        const response = await dispatch(uploadIVRDocument(FaceSheetObject));
        let documentId = response.payload.data.documentId;
        fileData.documentId = documentId;
        secondaryInsuranceCardsDocumentIdsRef.current.push(documentId);
      }

      setValue(
        "insuranceInfo.secondaryInsuranceCards",
        secondaryInsuranceCardsDocumentIdsRef.current
      );
      trigger("insuranceInfo.secondaryInsuranceCards");
    }
  };

  const handleDeleteSecondaryInsuranceDocument = async (documentId: number) => {
    const response = await dispatch(removeIVRDocument(documentId.toString()));
    if (response.payload.code === "V3OK") {
      secondaryInsuranceCardsDocumentIdsRef.current =
        secondaryInsuranceCardsDocumentIdsRef.current.filter(
          (id) => id != documentId
        );
      setValue(
        "insuranceInfo.secondaryInsuranceCards",
        secondaryInsuranceCardsDocumentIdsRef.current
      );
    }
  };

  const handleSignatureChange = (data: SignatureData) => {
    setSignatureData(data.signature);
    setValue("providerInformation.signature", data.signature);
    setValue("providerInformation.name", data.typedText);
    if (data.signature && data.typedText) {
      setValue("checkProviderSignAuth", true);
    }
    // trigger("providerInformation.signature");
  };

  const clickShowWoundInfoPopup = () => {
    setShowWoundInfoPopup(true);
    setShowErrorAlert(false);
  };

  const onCancelClick = async () => {
    if (IVRFetchStatus !== "success" && patientFetchStatus != "success") {
      const faceSheetDocumentIds = getValues("patientInfo.faceSheet");
      const insuranceCardsDocumentIds = getValues(
        "insuranceInfo.insuranceCards"
      );
      const secondaryInsuranceCardsDocumentIds = getValues(
        "insuranceInfo.secondaryInsuranceCards"
      );
      const supportingDocsDocumentIds = getValues(
        "supportingDocumentation.supportingDocuments"
      );
      const imagesOfWOundsDocumentIds = getValues(
        "supportingDocumentation.imageOfWounds"
      );

      // Combine all IDs into a single array
      const allDocumentIds = [
        ...faceSheetDocumentIds,
        ...insuranceCardsDocumentIds,
        ...supportingDocsDocumentIds,
        ...imagesOfWOundsDocumentIds,
      ];

      // removeIVRDocument for each ID
      const removePromises = allDocumentIds.map((id) =>
        dispatch(removeIVRDocument(id.toString()))
      );
      // Wait for all removeIVRDocument dispatches to complete
      // await Promise.all(removePromises);
    }
    navigate("/ivr_list", {state: {withSearchHistory: true}});
  };

  const checkIVRequestValidity = (data: any) => {
    const { typeOfIVR } = data;
    const isValid = !!typeOfIVR;
    setIVRequestValid(isValid);
  };

  const checkProductInfoValidity = (data: any) => {
    const { treatmentInformation, providerInformation } = data;

    if (treatmentInformation && providerInformation) {
      const { products, dateOfService, placesOfService } = treatmentInformation;
      const { provider, practiceLocation } = providerInformation;

      const isTreatmentValid =
        !!dateOfService &&
        (Array.isArray(placesOfService)
          ? placesOfService.length > 0
          : !!placesOfService) &&
        Array.isArray(products) &&
        products.length > 0 &&
        products.every(product => Array.isArray(product.productSizes) && product.productSizes.length > 0);

      const isProviderValid =
        !!provider &&
        !!practiceLocation;

      const isValid = isTreatmentValid && isProviderValid;
      setProductInfoValid(isValid);
    } else {
      setProductInfoValid(false);
    }
  };

  useEffect(() => {
    if (isPracticeIndividual) {
      const isEnableProducts = !!getValues("treatmentInformation.dateOfService") && !!getValues("providerInformation.provider");
      if (isEnableProducts && dayjs(getValues("treatmentInformation.dateOfService"), "MM/DD/YYYY", true).isValid()) {
        if (practiceID !== "") {
          handleFetchPracticeProducts(getValues("treatmentInformation.dateOfService"), parseInt(practiceID), parseInt(getValues("providerInformation.provider")));
        }
        setDisableProductField(false);
      } else {
        setDisableProductField(true);
      }
    } else {
      if (practiceID !== "" && getValues("treatmentInformation.dateOfService") !== undefined && dayjs(getValues("treatmentInformation.dateOfService"), "MM/DD/YYYY", true).isValid()) {
        handleFetchPracticeProducts(getValues("treatmentInformation.dateOfService"), parseInt(practiceID));
        setDisableProductField(false);
      }
    }
  }, [getValues("treatmentInformation.dateOfService"), getValues("providerInformation.provider"), isPracticeIndividual])

  const checkPatientInfoValidity = (data: any) => {
    const { patientInfo } = data;
    if (patientInfo) {
      const {
        patientId,
        patientFirstName,
        patientMiddleName,
        patientLastName,
        patientDOB,
        gender,
        patientStreetAddress,
        patientUnitNumber,
        patientCity,
        patientState,
        patientZipcode,
        faceSheet,
      } = patientInfo;
      const isValid =
        !!patientFirstName &&
        !!patientLastName &&
        !!patientDOB &&
        !!gender &&
        !!patientStreetAddress &&
        !!patientCity &&
        !!patientState &&
        !!patientZipcode &&
        (Array.isArray(faceSheet) ? faceSheet.length > 0 ? true : getValues("patientInfo.isFaceSheetSkipped") : !!faceSheet);
      setPatientInfoValid(isValid);

      // Check if patient data fields have been edited
      if (patientCompleteData != undefined) {
        const isPatientDataEdited =
          patientFirstName !== patientCompleteData?.firstName ||
          patientMiddleName !== patientCompleteData?.middleName ||
          patientLastName !== patientCompleteData?.lastName ||
          patientDOB !== patientCompleteData?.birthDate ||
          gender !== patientCompleteData?.gender;

        setIsPatientEdited(isPatientDataEdited);
      }

      const addressInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "ADDRESS"
      );

      // Check if patient info data fields have been edited
      if (addressInfo != undefined) {
        const isPatientInfoEdited =
          patientStreetAddress !==
          addressInfo?.infoTypeData?.patientStreetAddress ||
          patientUnitNumber !==
          addressInfo?.infoTypeData.patientUnitNumber ||
          patientCity !== addressInfo?.infoTypeData.patientCity ||
          patientState !== addressInfo?.infoTypeData.patientState ||
          patientZipcode !== addressInfo?.infoTypeData.patientZip;

        setIsPatientInfoEdited(isPatientInfoEdited);
      }

      const faceSheetInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "FACESHEET"
      );

      // Check if faceSheet has been edited
      if (faceSheetInfo != undefined) {
        const isFaceSheetEdited =
          JSON.stringify(faceSheet) !==
          JSON.stringify(faceSheetInfo?.infoTypeData?.documentIds);
        setIsFaceSheetEdited(isFaceSheetEdited);
      }
    } else {
      setPatientInfoValid(false);
    }
  };

  const checkInsuranceInfoValidity = (data: any) => {
    const { patientInfo } = data;
    const { insuranceInfo } = data;
    let patientID = undefined;
    if (insuranceInfo) {
      const {
        insuranceName,
        policy,
        isPolicyUnderDifferentName,
        policyholderFirstName,
        policyholderMiddleName,
        policyholderLastName,
        policyHolderDOB,
        insuranceType,
        insuranceCards,
        secondaryInsuranceName,
        secondaryPolicy,
        isSecondaryPolicyUnderDifferentName,
        secondaryPolicyHolderFirstName,
        secondaryPolicyHolderMiddleName,
        secondaryPolicyHolderLastName,
        secondaryPolicyHolderDOB,
        secondaryInsuranceType,
        secondaryInsuranceCards,
      } = insuranceInfo;
      let isValid = false;
      if (isPolicyUnderDifferentName === "yes") {
        isValid =
          !!insuranceName &&
          !!policy &&
          !!isPolicyUnderDifferentName &&
          !!policyholderFirstName &&
          !!policyholderLastName &&
          !!policyHolderDOB &&
          (Array.isArray(insuranceCards)
            ? insuranceCards.length > 0 ? true : getValues("insuranceInfo.isInsuranceCardsSkipped")
            : !!insuranceCards);
      } else {
        isValid =
          !!insuranceName &&
          !!policy &&
          !!isPolicyUnderDifferentName &&
          (Array.isArray(insuranceCards)
            ? insuranceCards.length > 0 ? true : getValues("insuranceInfo.isInsuranceCardsSkipped")
            : !!insuranceCards);
      }
      setInsuranceInfoValid(isValid);

      if (patientInfo) {
        const { patientId } = patientInfo;
        patientID = patientId;
      }

      const primaryInsuranceInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "PRIMARY_INSURANCE"
      );

      // Check if any of the primary insurance fields have been edited
      if (primaryInsuranceInfo != undefined) {
        const isPrimaryInsuranceInfoEdited =
          insuranceName !==
          primaryInsuranceInfo?.infoTypeData?.insuranceName ||
          policy !== primaryInsuranceInfo?.infoTypeData.policy ||
          isPolicyUnderDifferentName !==
          primaryInsuranceInfo?.infoTypeData.isPolicyUnderDifferentName ||
          policyholderFirstName !==
          primaryInsuranceInfo?.infoTypeData.policyHolderFirstName ||
          policyholderMiddleName !==
          primaryInsuranceInfo?.infoTypeData.policyHolderMiddleName ||
          policyholderLastName !==
          primaryInsuranceInfo?.infoTypeData.policyHolderLastName ||
          policyHolderDOB !==
          primaryInsuranceInfo?.infoTypeData.policyHolderDOB ||
          insuranceType !==
          primaryInsuranceInfo?.infoTypeData.insuranceType ||
          JSON.stringify(insuranceCards) !==
          JSON.stringify(primaryInsuranceInfo?.infoTypeData.documentIds);

        setIsPrimaryInsuranceInfoEdited(isPrimaryInsuranceInfoEdited);
      }

      const secondaryInsuranceInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "SECONDARY_INSURANCE"
      );

      // Check if any of the secondary insurance fields have been edited
      if (secondaryInsuranceInfo != undefined) {
        const isSecondaryInsuranceInfoEdited =
          secondaryInsuranceName !==
          secondaryInsuranceInfo?.infoTypeData?.secondaryInsuranceName ||
          secondaryPolicy !==
          secondaryInsuranceInfo?.infoTypeData?.secondaryPolicy ||
          isSecondaryPolicyUnderDifferentName !==
          secondaryInsuranceInfo?.infoTypeData
            ?.isSecondaryPolicyUnderDifferentName ||
          secondaryPolicyHolderFirstName !==
          secondaryInsuranceInfo?.infoTypeData
            ?.secondaryPolicyHolderFirstName ||
          secondaryPolicyHolderMiddleName !==
          secondaryInsuranceInfo?.infoTypeData
            ?.secondaryPolicyHolderMiddleName ||
          secondaryPolicyHolderLastName !==
          secondaryInsuranceInfo?.infoTypeData
            ?.secondaryPolicyHolderLastName ||
          secondaryPolicyHolderDOB !==
          secondaryInsuranceInfo?.infoTypeData?.secondaryPolicyHolderDOB ||
          secondaryInsuranceType !==
          secondaryInsuranceInfo?.infoTypeData?.secondaryInsuranceType ||
          JSON.stringify(secondaryInsuranceCards) !==
          JSON.stringify(
            secondaryInsuranceInfo?.infoTypeData?.documentIds
          );

        setIsSecondaryInsuranceInfoEdited(isSecondaryInsuranceInfoEdited);
      }
    } else {
      setInsuranceInfoValid(false);
    }
  };

  const checkSupportingDocumentationValidity = (data: any) => {
    const { patientInfo } = data;
    const { supportingDocumentation } = data;
    let patientID = undefined;
    if (supportingDocumentation) {
      const { supportingDocuments, imageOfWounds } = supportingDocumentation;
      const isValid =
        (Array.isArray(supportingDocuments)
          ? supportingDocuments.length > 0 ? true : getValues("supportingDocumentation.isSupportingDocumentsSkipped")
          : !!supportingDocuments) &&
        (Array.isArray(imageOfWounds)
          ? imageOfWounds.length > 0 ? true : getValues("supportingDocumentation.isImageOfWoundsSkipped")
          : !!imageOfWounds);
      setSupportingDocumentationValid(isValid);

      if (patientInfo) {
        const { patientId } = patientInfo;
        patientID = patientId;
      }

      const supportingDocumentsInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "SUPPORTING_DOCUMENT"
      );

      // Check if supporting docs has been edited
      if (supportingDocumentsInfo != undefined) {
        const IsSupportingDocumentsEdited =
          JSON.stringify(supportingDocuments) !==
          JSON.stringify(supportingDocumentsInfo?.infoTypeData?.documentIds);
        setIsSupportingDocumentsEdited(IsSupportingDocumentsEdited);
      }

      const imagesOfWoundsInfo = patientCompleteData?.patientInfo?.find(
        (info) => info.infoType === "IMAGEOF_WOUNDS"
      );

      // Check if image of wounds has been edited
      if (imagesOfWoundsInfo != undefined) {
        const IsImageOfWoundsEdited =
          JSON.stringify(imageOfWounds) !==
          JSON.stringify(imagesOfWoundsInfo?.infoTypeData?.documentIds);
        setIsImageOfWoundsEdited(IsImageOfWoundsEdited);
      }
    } else {
      setSupportingDocumentationValid(false);
    }
  };

  const checkPatientConsentInfoValidity = (data: any) => {
    const { patientConsentInfomation } = data;
    if (patientConsentInfomation) {
      let isValid = false
      isValid = !!patientConsentInfomation.isPatientConsentSigned
      setPatientConsentInfoValid(isValid);
    } else {
      setPatientConsentInfoValid(false);
    }
  }

  const checkTreatmentInfoValidity = (data: any) => {
    const { treatmentInformation } = data;
    if (treatmentInformation) {
      const {
        currentlyInNursingFacility,
        daysAdmittedToFacility,
        currentlyUnderSurgicalGlobalPeriod,
        surgicalProcedureDate,
        surgicalProcedureCodes,
      } = treatmentInformation;
      let isValid = false
      if (currentlyInNursingFacility === "true") {
        if (currentlyUnderSurgicalGlobalPeriod === "true") {
          isValid =
            !!currentlyInNursingFacility &&
            !!daysAdmittedToFacility &&
            !!currentlyUnderSurgicalGlobalPeriod &&
            !!surgicalProcedureDate &&
            !!surgicalProcedureCodes;
        } else {
          isValid =
            !!currentlyInNursingFacility &&
            !!daysAdmittedToFacility &&
            !!currentlyUnderSurgicalGlobalPeriod;
        }
      } else {
        if (currentlyUnderSurgicalGlobalPeriod === "true") {
          isValid =
            !!currentlyInNursingFacility?.toString() &&
            !!currentlyUnderSurgicalGlobalPeriod &&
            !!surgicalProcedureDate &&
            !!surgicalProcedureCodes;
        } else {
          isValid =
            !!currentlyInNursingFacility?.toString() &&
            !!currentlyUnderSurgicalGlobalPeriod;
        }
      }
      setTreatmentInfoValid(isValid);
    } else {
      setTreatmentInfoValid(false);
    }
  };

  const checkWoundInfoValidity = (data: any) => {
    const { woundInfo } = data;
    if (woundInfo) {
      const { totalSizeOfAllWounds } = woundInfo;
      const isValid =
        !!totalSizeOfAllWounds &&
        Array.isArray(woundInfoRows) &&
        woundInfoRows.length > 0;
      setWoundInfoValid(isValid);
    } else {
      setWoundInfoValid(false);
    }
  };

  useEffect(() => {
    const isValid = !!getValues("providerInformation.name") && !!getValues("providerInformation.signature");
    if (isValid) {
      setSignatureInfoValid(true);
    } else {
      setSignatureInfoValid(false);
    }
  }, [getValues("providerInformation.name"), getValues("providerInformation.signature")])

  const onSubmit = async (data: IVRInfo) => {
    const updatedObject = mapIVRInfoToIVRObjectInfo(data);

    if (submitAction === "saveAction") {
      const response = await dispatch(saveIVRData({ dataObj: updatedObject, interceptorOption: { skipErrorPopup: true } }));
      if (response.payload && response.payload.message === "SUCCESS") {
        // cs-347 (13/9/2024) -Display Order Number or IVR Number in Success Messages
        const ivrNumber = response.payload?.data?.ivrNumber;
        let successObj: SingleAlertInfo = {
          message: "IVR " + ivrNumber + " Saved Successfully",
          alertType: "success",
        };
        //
        dispatch(setSingleAlertObj(successObj));
        navigate("/ivr_list", {state: {withSearchHistory: true}});
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "An error occurred while saving the IVR.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    } else if (submitAction === "submitAction") {
      const response = await dispatch(submitIVR(updatedObject));
      if (response.payload && response.payload.message === "SUCCESS") {
        // cs-347 (13/9/2024) - Display Order Number or IVR Number in Success Messages
        // (Here if we are creating multiple IVR also onFinalSubmit, we get only one IVRNumber in response!)
        const ivrNumber = response.payload?.data?.ivrNumber;
        let successObj: SingleAlertInfo = {
          message: "IVR " + ivrNumber + " Saved Successfully",
          alertType: "success",
        };
        //
        dispatch(setSingleAlertObj(successObj));
        navigate("/ivr_list", {state: {withSearchHistory: true}});
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "IVR Submission Failed.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    }

    setSubmitAction(null);
    setIvrId(0);
    setIvrNumber("");
  };

  const hasNestedErrors = (obj: any) => {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (hasNestedErrors(obj[key])) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const onError = (_errors: any, e: any) => {
    if (submitAction === "saveAction") {
      clearErrors();
      let hasError = false;

      if (!getValues("treatmentInformation.products") || getValues("treatmentInformation.products")?.length === 0) {
        setError("treatmentInformation.products", { type: "required", message: "At least one product should be selected." });
        hasError = true;
      } else {
        for (let product of getValues("treatmentInformation.products")) {
          if (product.productSizes.length === 0) {
            setError("treatmentInformation.products", { type: "required", message: "At least one product item should be selected." });
            hasError = true;
          }
        }
      }

      if (!getValues("treatmentInformation.dateOfService")) {
        setError("treatmentInformation.dateOfService", { type: "required", message: "Date of Service is required." });
        hasError = true;
      } else if (!dayjs(getValues("treatmentInformation.dateOfService"), "MM/DD/YYYY", true).isValid()) {
        setError("treatmentInformation.dateOfService", { type: "required", message: "Invalid Date." });
        hasError = true;
      }

      if (!getValues("patientInfo.patientFirstName")) {
        setError("patientInfo.patientFirstName", { type: "required", message: "Patient First Name is required." });
        hasError = true;
      }

      if (!getValues("patientInfo.patientLastName")) {
        setError("patientInfo.patientLastName", { type: "required", message: "Patient Last Name is required." });
        hasError = true;
      }

      if (isPracticeIndividual) {
        if (!getValues("providerInformation.provider")) {
          setError("providerInformation.provider", { type: "required", message: "Provider is required." });
          hasError = true;
        }
      }

      if (!hasError) {
        onSubmit(getValues());
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return;
    } else if (submitAction === "submitAction") {
      let hasError = true;
      let faceSheetUploaded = false;
      let supportingDocsUploaded = false;
      let imageOfWoundsUploaded = false;
      let insuranceCardsUploaded = false;
      let secondatyInsuranceCardsUploaded = false;

      if (getValues("patientInfo.faceSheet").length !== 0) {
        faceSheetUploaded = true;
        clearErrors("patientInfo.faceSheet");
      }

      if (getValues("supportingDocumentation.imageOfWounds").length !== 0) {
        imageOfWoundsUploaded = true;
        clearErrors("supportingDocumentation.imageOfWounds");
      }

      if (getValues("supportingDocumentation.supportingDocuments").length !== 0) {
        supportingDocsUploaded = true;
        clearErrors("supportingDocumentation.supportingDocuments");
      }

      if (getValues("insuranceInfo.insuranceCards").length !== 0) {
        insuranceCardsUploaded = true;
        clearErrors("insuranceInfo.insuranceCards");
      }

      if (getValues("insuranceInfo.secondaryInsuranceCards") && getValues("insuranceInfo.secondaryInsuranceCards").length !== 0) {
        secondatyInsuranceCardsUploaded = true;
        clearErrors("insuranceInfo.secondaryInsuranceCards");
      }

      if (getValues("patientInfo.isFaceSheetSkipped")) {
        clearErrors("patientInfo.faceSheet");
      }

      if (getValues("supportingDocumentation.isSupportingDocumentsSkipped")) {
        clearErrors("supportingDocumentation.supportingDocuments");
      }

      if (getValues("supportingDocumentation.isImageOfWoundsSkipped")) {
        clearErrors("supportingDocumentation.imageOfWounds");
      }

      if (getValues("insuranceInfo.isInsuranceCardsSkipped")) {
        clearErrors("insuranceInfo.insuranceCards");
      }

      if (getValues("insuranceInfo.isSecondaryInsuranceCardsSkipped")) {
        clearErrors("insuranceInfo.secondaryInsuranceCards");
      }

      if (hasNestedErrors(_errors)) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      if ((faceSheetUploaded || getValues("patientInfo.isFaceSheetSkipped")) && (supportingDocsUploaded || getValues("supportingDocumentation.isSupportingDocumentsSkipped")) && (imageOfWoundsUploaded || getValues("supportingDocumentation.isImageOfWoundsSkipped")) && (insuranceCardsUploaded || getValues("insuranceInfo.isInsuranceCardsSkipped"))) {
        hasError = false;
      } else {
        hasError = true;
      }

      if (!hasError) {
        onSubmit(getValues());
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return;
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const insuranceVerificationRequestRef = useRef<HTMLDivElement>(null);
  const productInformationRef = useRef<HTMLDivElement>(null);
  const patientInformationRef = useRef<HTMLDivElement>(null);
  const insuranceInformationRef = useRef<HTMLDivElement>(null);
  const supportingDocumentationRef = useRef<HTMLDivElement>(null);
  const patientConsentInformationRef = useRef<HTMLDivElement>(null);
  const treatmentInformationRef = useRef<HTMLDivElement>(null);
  const woundDataRef = useRef<HTMLDivElement>(null);
  const providerInformationRef = useRef<HTMLDivElement>(null);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const steps = [
    { stepName: "Insurance Verification Request", isCompleted: ivRequestValid },
    { stepName: "Product Information", isCompleted: productInfoValid },
    { stepName: "Patient Information", isCompleted: patientInfoValid },
    { stepName: "Insurance Information", isCompleted: insuranceInfoValid },
    {
      stepName: "Supporting Documentation",
      isCompleted: supportingDocumentationValid,
    },
    { stepName: "Patient Consent Information", isCompleted: patientConsentInfoValid },
    { stepName: "Treatment Information", isCompleted: treatmentInfoValid },
    { stepName: "Wound Data", isCompleted: woundInfoValid },
    { stepName: "Signature Authorization", isCompleted: signatureInfoValid },
  ];

  useEffect(() => {
    if (!firstLoad) {
      const scrollToElement = (elementRef: any) => {
        if (elementRef && elementRef.current) {
          elementRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };
      switch (activeStep) {
        case 0:
          scrollToElement(insuranceVerificationRequestRef);
          break;
        case 1:
          scrollToElement(productInformationRef);
          break;
        case 2:
          scrollToElement(patientInformationRef);
          break;
        case 3:
          scrollToElement(insuranceInformationRef);
          break;
        case 4:
          scrollToElement(supportingDocumentationRef);
          break;
        case 5:
          scrollToElement(patientConsentInformationRef);
          break;
        case 6:
          scrollToElement(treatmentInformationRef);
          break;
        case 7:
          scrollToElement(woundDataRef);
          break;
        case 8:
          scrollToElement(providerInformationRef);
          break;
        default:
          break;
      }
    } else {
      setFirstLoad(false);
    }
  }, [activeStep]);

  // Helper function to convert document content to ExtendedFile
  const convertDocumentContentToExtendedFile = (
    documentData: any,
    doc: string
  ): ExtendedFile => {
    const byteCharacters = atob(documentData.content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: documentData.fileType });

    const extendedFile: ExtendedFile = new File(
      [blob],
      documentData.documentName,
      {
        type: documentData.fileType,
      }
    );
    extendedFile.documentId = doc;
    return extendedFile;
  };

  // Helper function to fetch and process documents
  const fetchAndProcessDocuments = async (
    documentIds: string[],
    dispatch: any
  ): Promise<ExtendedFile[]> => {
    const documentList: ExtendedFile[] = [];
    for (const doc of documentIds) {
      if (doc !== null) {
        try {
          const actionResult = await dispatch(fetchDocumentById(doc));
          const documentData = actionResult.payload;
          if (documentData && documentData.content) {
            const extendedFile = convertDocumentContentToExtendedFile(
              documentData,
              doc
            );
            documentList.push(extendedFile);
          }
        } catch (error) {
          console.error(`Failed to fetch document with ID ${doc}`, error);
        }
      }
    }
    return documentList;
  };

  // Main function to handle different types of documents
  const handleDocuments = async (
    type: string,
    dataKey: any,
    stateSetter: (files: ExtendedFile[]) => void,
    dispatch: any,
    data: any
  ) => {
    const docInfo = data.patientInfo.find(
      (info: { infoType: string }) => info.infoType === type
    );
    if (docInfo) {
      setValue(dataKey, docInfo?.patientinfoData.documentIds);
      if (docInfo?.patientinfoData.documentIds.length > 0) {
        const documentList = await fetchAndProcessDocuments(
          docInfo?.patientinfoData.documentIds,
          dispatch
        );
        stateSetter(documentList);
      }
    }
  };

  const handlePatientChange = async (e: any) => {
    dispatch(showHideLoadingDialog(true));
    setIsPatientInfoDisabled(true);
    setShowEditButton(true);
    const patientId = getValues(`patientInfo.${"patientId"}`);
    if (patientId) {
      const response = await dispatch(
        getPatient({ patientId: patientId.toString() })
      );
      if (response.payload.message === "SUCCESS") {
        const data = response.payload.data;
        setPatientCompleteData(data);
        setValue("patientInfo.patientFirstName", data.firstName);
        setValue(
          "patientInfo.patientMiddleName",
          data.middleName ? data.middleName : ""
        );
        setValue("patientInfo.patientLastName", data.lastName);
        setValue("patientInfo.patientDOB", data.birthDate);
        setValue("patientInfo.gender", data.gender);

        // Find the address object
        const addressInfo = data.patientInfo.find(
          (info: { infoType: string }) => info.infoType === "ADDRESS"
        );

        // If address information exists, set the address values
        if (addressInfo) {
          const addressData = addressInfo?.patientinfoData;
          setValue(
            "patientInfo.patientStreetAddress",
            addressData.patientStreetAddress
          );
          setValue(
            "patientInfo.patientUnitNumber",
            addressData.patientUnitNumber
          );
          setValue("patientInfo.patientCity", addressData.patientCity);
          setValue("patientInfo.patientState", addressData.patientState);
          setValue("patientInfo.patientZipcode", addressData.patientZip);
          setPatientInfoIdAddress(addressInfo.patientinfoId);
        }

        // Find the Insurance object
        const primaryInsuranceInfo = data.patientInfo.find(
          (info: { infoType: string }) => info.infoType === "PRIMARY_INSURANCE"
        );

        if (primaryInsuranceInfo) {
          const InsuranceData = primaryInsuranceInfo?.patientinfoData;
          setValue("insuranceInfo.insuranceName", InsuranceData.insuranceName);
          setValue("insuranceInfo.policy", InsuranceData.policy);
          setValue(
            "insuranceInfo.isPolicyUnderDifferentName",
            InsuranceData.isPolicyUnderDifferentName
          );
          if (InsuranceData.isPolicyUnderDifferentName === "yes") {
            setIsPolicyUnderDifferentNameCheck(true);
            setValue(
              "insuranceInfo.policyholderFirstName",
              InsuranceData.policyHolderFirstName
            );
            setValue(
              "insuranceInfo.policyholderMiddleName",
              InsuranceData.policyHolderMiddleName
            );
            setValue(
              "insuranceInfo.policyholderLastName",
              InsuranceData.policyHolderLastName
            );
            setValue(
              "insuranceInfo.policyHolderDOB",
              InsuranceData.policyHolderDOB
            );
          }
          setValue("insuranceInfo.insuranceType", InsuranceData.insuranceType);
          setValue(
            "insuranceInfo.insuranceCards",
            InsuranceData.insuranceCards
          );
          if (primaryInsuranceInfo?.patientinfoData.isSkipped === true) {
            setValue("insuranceInfo.isInsuranceCardsSkipped", true)
          } else {
            setPatientInfoIdPrimaryInsurance(primaryInsuranceInfo.patientinfoId);
            insuranceCardsDocumentIdsRef.current =
              primaryInsuranceInfo?.patientinfoData.documentIds.map(
                (doc: any) => doc
              )
          }
        }

        // Find the Insurance object
        const secondaryInsuranceInfo = data.patientInfo.find(
          (info: { infoType: string }) =>
            info.infoType === "SECONDARY_INSURANCE"
        );

        if (
          secondaryInsuranceInfo &&
          secondaryInsuranceInfo?.patientinfoData.insuranceName !== undefined
        ) {
          const SecondaryInsuranceData = secondaryInsuranceInfo?.patientinfoData;
          setValue(
            "insuranceInfo.secondaryInsuranceName",
            SecondaryInsuranceData.insuranceName
          );
          setValue(
            "insuranceInfo.secondaryPolicy",
            SecondaryInsuranceData.policy
          );
          setValue(
            "insuranceInfo.isSecondaryPolicyUnderDifferentName",
            SecondaryInsuranceData.isPolicyUnderDifferentName
          );
          if (SecondaryInsuranceData.isPolicyUnderDifferentName === "yes") {
            setIsPolicyUnderDifferentNameCheckSecondary(true);
            setValue(
              "insuranceInfo.secondarypolicyholderFirstName",
              SecondaryInsuranceData.policyHolderFirstName
            );
            setValue(
              "insuranceInfo.secondarypolicyholderMiddleName",
              SecondaryInsuranceData.policyHolderMiddleName
            );
            setValue(
              "insuranceInfo.secondarypolicyholderLastName",
              SecondaryInsuranceData.policyHolderLastName
            );
            setValue(
              "insuranceInfo.secondaryPolicyHolderDOB",
              SecondaryInsuranceData.policyHolderDOB
            );
          }
          setValue(
            "insuranceInfo.secondaryInsuranceType",
            SecondaryInsuranceData.insuranceType
          );
          setValue(
            "insuranceInfo.secondaryInsuranceCards",
            SecondaryInsuranceData.insuranceCards
          );
          setValue("insuranceInfo.isSecondaryInsurance", true);
          setIsSecondaryInsurance(true);
          if (secondaryInsuranceInfo?.patientinfoData.isSkipped === true) {
            setValue("insuranceInfo.isSecondaryInsuranceCardsSkipped", true)
          } else {
            setPatientInfoIdSecondaryInsurance(secondaryInsuranceInfo.patientinfoId);
            secondaryInsuranceCardsDocumentIdsRef.current =
              secondaryInsuranceInfo?.patientinfoData.documentIds.map(
                (doc: any) => doc
              )
          }
        }

        const faceSheetInfo = data.patientInfo.find(
          (info: { infoType: string }) => info.infoType === "FACESHEET"
        );
        if (faceSheetInfo) {
          if (faceSheetInfo?.patientinfoData.isSkipped === true) {
            setValue("patientInfo.isFaceSheetSkipped", true)
          } else {
            setPatientInfoIdFaceSheet(faceSheetInfo.patientinfoId);
            faceSheetDocumentIdsRef.current =
              faceSheetInfo?.patientinfoData.documentIds.map(
                (doc: any) => doc
              )
          }
        }

        const supportingDocsInfo = data.patientInfo.find(
          (info: { infoType: string }) => info.infoType === "SUPPORTING_DOCUMENT"
        );

        if (supportingDocsInfo) {
          if (supportingDocsInfo?.patientinfoData.isSkipped === true) {
            setValue("supportingDocumentation.isSupportingDocumentsSkipped", true)
          } else {
            setPatientInfoIdSupportingDocs(supportingDocsInfo.patientinfoId);
            supportingDocsDocumentIdsRef.current =
              supportingDocsInfo?.patientinfoData.documentIds.map(
                (doc: any) => doc
              )
          }
        }

        const woundImagesInfo = data.patientInfo.find(
          (info: { infoType: string }) => info.infoType === "IMAGEOF_WOUNDS"
        );

        if (woundImagesInfo) {
          if (woundImagesInfo?.patientinfoData.isSkipped === true) {
            setValue("supportingDocumentation.isImageOfWoundsSkipped", true)
          } else {
            setPatientInfoIdWoundImages(woundImagesInfo.patientinfoId);
            imageOfWoundsDocumentIdsRef.current =
              woundImagesInfo?.patientinfoData.documentIds.map(
                (doc: any) => doc
              )
          }
        }

        await handleDocuments(
          "FACESHEET",
          "patientInfo.faceSheet",
          setFaceSheetInitialFiles,
          dispatch,
          data
        );
        await handleDocuments(
          "PRIMARY_INSURANCE",
          "insuranceInfo.insuranceCards",
          setPrimaryInsCardsInitialFiles,
          dispatch,
          data
        );

        if (secondaryInsuranceInfo?.patientinfoData.insuranceName) {
          await handleDocuments(
            "SECONDARY_INSURANCE",
            "insuranceInfo.secondaryInsuranceCards",
            setSecondaryInsCardsInitialFiles,
            dispatch,
            data
          );
        }

        await handleDocuments(
          "SUPPORTING_DOCUMENT",
          "supportingDocumentation.supportingDocuments",
          setSupportingDocsInitialFiles,
          dispatch,
          data
        );
        await handleDocuments(
          "IMAGEOF_WOUNDS",
          "supportingDocumentation.imageOfWounds",
          setImagesOfWoundsInitialFiles,
          dispatch,
          data
        );

        checkPatientInfoValidity(getValues());
        checkInsuranceInfoValidity(getValues());
        checkSupportingDocumentationValidity(getValues());
      }
    } else {
      clearPatientData();
    }
    dispatch(showHideLoadingDialog(false));
  };

  const clearPatientData = () => {
    setIsPatientInfoDisabled(false);
    setShowEditButton(false);
    setValue("patientInfo.patientFirstName", "");
    setValue("patientInfo.patientMiddleName", "");
    setValue("patientInfo.patientLastName", "");
    setValue("patientInfo.patientDOB", "");
    setValue("patientInfo.gender", "");
    setValue("patientInfo.patientStreetAddress", "");
    setValue("patientInfo.patientUnitNumber", "");
    setValue("patientInfo.patientCity", "");
    setValue("patientInfo.patientState", "");
    setValue("patientInfo.patientZipcode", "");
    setValue("patientInfo.faceSheet", []);
    setFaceSheetInitialFiles([]);

    setValue("insuranceInfo.insuranceName", "");
    setValue("insuranceInfo.policy", "");
    setValue("insuranceInfo.isPolicyUnderDifferentName", false);
    setValue("insuranceInfo.policyholderFirstName", "");
    setValue("insuranceInfo.policyholderMiddleName", "");
    setValue("insuranceInfo.policyholderLastName", "");
    setValue("insuranceInfo.policyHolderDOB", "");
    setValue("insuranceInfo.insuranceType", "");
    setValue("insuranceInfo.insuranceCards", []);
    setPrimaryInsCardsInitialFiles([]);
    setValue("insuranceInfo.isSecondaryInsurance", false);
    setValue("insuranceInfo.secondaryInsuranceName", "");
    setValue("insuranceInfo.secondaryPolicy", "");
    setValue("insuranceInfo.isSecondaryPolicyUnderDifferentName", false);
    setValue("insuranceInfo.secondarypolicyholderFirstName", "");
    setValue("insuranceInfo.secondarypolicyholderMiddleName", "");
    setValue("insuranceInfo.secondarypolicyholderLastName", "");
    setValue("insuranceInfo.secondaryPolicyHolderDOB", "");
    setValue("insuranceInfo.secondaryInsuranceType", "");
    setValue("insuranceInfo.secondaryInsuranceCards", []);
    setSecondaryInsCardsInitialFiles([]);

    setValue("supportingDocumentation.supportingDocuments", []);
    setSupportingDocsInitialFiles([]);
    setValue("supportingDocumentation.imageOfWounds", []);
    setImagesOfWoundsInitialFiles([]);
    setValue("supportingDocumentation.isImageOfWoundsSkipped", false);
    setValue("supportingDocumentation.isSupportingDocumentsSkipped", false);
    setValue("insuranceInfo.isInsuranceCardsSkipped", false);
    setValue("patientInfo.isFaceSheetSkipped", false);

    checkPatientInfoValidity(getValues());
    checkInsuranceInfoValidity(getValues());
    checkSupportingDocumentationValidity(getValues());
  }

  // const fetchProductItems = (event : any) => {
  //   const practiceProductId = getValues(`treatmentInformation.${"practiceProductId"}`);
  //   if (practiceProductId) {
  //     setValue('providerInformation.macId', event.macId);
  //     setValue('treatmentInformation.product', event.productId);
  //     const onlyActiveRecords = true;
  //     dispatch(fetchProductItemsByProductId({ productId: event.productId, onlyActiveRecords }));
  //   } else {
  //     dispatch(resetProductItems());
  //   }
  // };

  const mapIVRInfoToIVRObjectInfo = (ivrInfo: IVRInfo): IVRObjectInfo => {
    const patientInfo = ivrInfo.patientInfo;
    const insuranceInfo = ivrInfo.insuranceInfo;
    const supportingDocs = ivrInfo.supportingDocumentation;
    const mappedPatientInfo = {
      patientCode: patientInfo.patientCode,
      patientId: patientInfo.patientId,
      isPatientEdited: isPatientEdited,
      firstName: patientInfo.patientFirstName,
      middleName: patientInfo.patientMiddleName,
      lastName: patientInfo.patientLastName,
      birthDate: patientInfo.patientDOB
        ? moment(patientInfo.patientDOB?.toString())?.format("MM/DD/YYYY")
        : "",
      gender: patientInfo.gender,
      patientInfo: [
        {
          infoType: "ADDRESS",
          isEdited: isPatientInfoEdited,
          patientinfoId: patientInfoIdAddress,
          infoTypeData: {
            patientStreetAddress: patientInfo.patientStreetAddress,
            patientUnitNumber: patientInfo.patientUnitNumber,
            patientCity: patientInfo.patientCity,
            patientState: patientInfo.patientState,
            patientZip: patientInfo.patientZipcode,
          },
        },
        {
          infoType: "FACESHEET",
          isEdited: isFaceSheetEdited,
          patientinfoId: patientInfoIdFaceSheet,
          infoTypeData: {
            documentIds: patientInfo.faceSheet,
            isSkipped: patientInfo.isFaceSheetSkipped,
          },
        },
        {
          infoType: "PRIMARY_INSURANCE",
          isEdited: isPrimaryInsuranceInfoEdited,
          patientinfoId: patientInfoIdPrimaryInsurance,
          infoTypeData: {
            insuranceName: insuranceInfo.insuranceName,
            policy: insuranceInfo.policy,
            isPolicyUnderDifferentName:
              insuranceInfo.isPolicyUnderDifferentName,
            policyHolderFirstName: insuranceInfo.policyholderFirstName,
            policyHolderMiddleName: insuranceInfo.policyholderMiddleName,
            policyHolderLastName: insuranceInfo.policyholderLastName,
            policyHolderDOB: insuranceInfo.policyHolderDOB
              ? moment(insuranceInfo.policyHolderDOB?.toString())?.format(
                "MM/DD/YYYY"
              )
              : "",
            insuranceType: insuranceInfo.insuranceType,
            documentIds: insuranceInfo.insuranceCards,
            isSkipped: insuranceInfo.isInsuranceCardsSkipped,
          },
        },
        {
          infoType: "SECONDARY_INSURANCE",
          isEdited: isSecondaryInsuranceInfoEdited,
          patientinfoId: patientInfoIdSecondaryInsurance,
          infoTypeData: {
            insuranceName: insuranceInfo.secondaryInsuranceName,
            policy: insuranceInfo.secondaryPolicy,
            isPolicyUnderDifferentName:
              insuranceInfo.isSecondaryPolicyUnderDifferentName,
            policyHolderFirstName: insuranceInfo.secondarypolicyholderFirstName,
            policyHolderMiddleName:
              insuranceInfo.secondarypolicyholderMiddleName,
            policyHolderLastName: insuranceInfo.secondarypolicyholderLastName,
            policyHolderDOB: insuranceInfo.secondaryPolicyHolderDOB
              ? moment(
                insuranceInfo.secondaryPolicyHolderDOB?.toString()
              )?.format("MM/DD/YYYY")
              : "",
            insuranceType: insuranceInfo.secondaryInsuranceType,
            documentIds: insuranceInfo.secondaryInsuranceCards,
            isSkipped: insuranceInfo.isSecondaryInsuranceCardsSkipped,
          },
        },
        {
          infoType: "SUPPORTING_DOCUMENT",
          isEdited: isSupportingDocumentsEdited,
          patientinfoId: patientInfoIdSupportingDocs,
          infoTypeData: {
            documentIds: supportingDocs.supportingDocuments,
            isSkipped: supportingDocs.isSupportingDocumentsSkipped,
          },
        },
        {
          infoType: "IMAGEOF_WOUNDS",
          isEdited: isImageOfWoundsEdited,
          patientinfoId: patientInfoIdWoundImages,
          infoTypeData: {
            documentIds: supportingDocs.imageOfWounds,
            isSkipped: supportingDocs.isImageOfWoundsSkipped,
          },
        },
      ],
    };

    const updatedProducts = selectedProducts.map(product => ({
      ...product,
      providerId: parseInt(getValues("providerInformation.provider")),
    }));
    setSelectedProducts(updatedProducts);

    const treatmentInfo = ivrInfo.treatmentInformation;
    const mappedTreatmentInfo = {
      treatmentInfo: {
        currentlyInNursingFacility: treatmentInfo.currentlyInNursingFacility,
        daysAdmittedToFacility: treatmentInfo.daysAdmittedToFacility,
        currentlyUnderSurgicalGlobalPeriod:
          treatmentInfo.currentlyUnderSurgicalGlobalPeriod,
        surgicalProcedureDate: treatmentInfo.surgicalProcedureDate
          ? moment(treatmentInfo.surgicalProcedureDate?.toString())?.format(
            "MM/DD/YYYY"
          )
          : "",
        surgicalProcedureCodes: treatmentInfo.surgicalProcedureCodes,
        placesOfService: [treatmentInfo.placesOfService],
        products: isPracticeIndividual ? selectedProducts : updatedProducts,
        currentlyCoveredUnderPartAStay: treatmentInfo.currentlyCoveredUnderPartAStay
      },
    };

    const woundData = woundInfoRows.map((row) => ({
      woundType: row.woundType,
      cpt: row.cpt,
      cptIds: row.cptIds,
      lCode: row.lCode,
      lCodeIds: row.lCodeIds,
      eCode: row.eCode,
      eCodeIds: row.eCodeIds,
      iCode: row.iCode,
      iCodeIds: row.iCodeIds,
      otherDxCodes: row.otherDxCodes,
      noOfApplications: row.noOfApplications,
      length: row.length,
      width: row.width,
      depth: row.depth,
      totalSize: row.totalSize.toString(),
    }));

    const woundInfo = {
      woundData: woundData,
      totalSizeOfAllWounds: parseFloat(ivrInfo.woundInfo.totalSizeOfAllWounds),
    };

    const providerInfo = {
      practiceId: parseInt(getValues("providerInformation.practice")),
      practiceName:
        practiceObject != undefined
          ? practiceObject.practiceName
          : "",
      date: ivrInfo.providerInformation.providerSignedDate
        ? moment(
          ivrInfo.providerInformation.providerSignedDate?.toString()
        )?.format("MM/DD/YYYY")
        : "",
      signature: ivrInfo.providerInformation.signature,
      name: ivrInfo.providerInformation.name,
      provider: {
        providerName: selectedProviderData[0]?.providerName,
        npi: selectedProviderData[0]?.npi,
        ptan: selectedProviderData[0]?.ptan,
        taxId: selectedProviderData[0]?.taxId,
      },
    };

    const patientConsentInfo = {
      isPatientConsentSigned: ivrInfo.patientConsentInfomation.isPatientConsentSigned,
      patientSignDate: ivrInfo.patientConsentInfomation.patientSignDate ? moment(ivrInfo.patientConsentInfomation.patientSignDate?.toString())?.format("MM/DD/YYYY") : "",
    }

    const additionalNotes = {
      xrefTable: "IVR",
      xrefId: ivrId,
      noteType: "IVR_ADDITIONAL_NOTE",
      content: ivrInfo.comments,
    }

    const mappedData: IVRObjectInfo = {
      ivrId: ivrId,
      ivrNumber: ivrNumber,
      practiceId: providerInfo.practiceId,
      productId: treatmentInfo.product,
      practiceProductId: treatmentInfo.practiceProductId,
      providerId: parseInt(getValues("providerInformation.provider")),
      locationId: parseInt(getValues("providerInformation.practiceLocation")),
      macId: parseInt(ivrInfo.providerInformation.macId),
      dateOfService: getValues("treatmentInformation.dateOfService")
        ? moment(
          getValues("treatmentInformation.dateOfService")?.toString()
        )?.format("MM/DD/YYYY")
        : "",
      benefitVerificationType: ivrInfo.typeOfIVR,
      patient: mappedPatientInfo,
      ivrInfo: {
        patientConsentInfo: patientConsentInfo,
        treatmentInfo: mappedTreatmentInfo.treatmentInfo,
        woundInfo: woundInfo,
        providerInfo: providerInfo,
        additionalNotes: additionalNotes
      },
    };

    return mappedData;
  };

  const onPoliceUnderDifferentNameChanage = (e: any) => {
    if (e.target.value === "yes") {
      setIsPolicyUnderDifferentNameCheck(true);
      if (getValues(`patientInfo.${"patientId"}`)) {
        setValue(
          "insuranceInfo.policyholderFirstName",
          patientInfoObject.firstName
        );
        setValue(
          "insuranceInfo.policyholderMiddleName",
          patientInfoObject.middleName
        );
        setValue(
          "insuranceInfo.policyholderLastName",
          patientInfoObject.lastName
        );
        setValue("insuranceInfo.policyHolderDOB", patientInfoObject.birthDate);
      }
    } else {
      setIsPolicyUnderDifferentNameCheck(false);
      setValue("insuranceInfo.policyholderFirstName", "");
      setValue("insuranceInfo.policyholderMiddleName", "");
      setValue("insuranceInfo.policyholderLastName", "");
      setValue("insuranceInfo.policyHolderDOB", "");
    }
  };

  const onPoliceUnderDifferentNameChanageSecondary = (e: any) => {
    if (e.target.value === "yes") {
      setIsPolicyUnderDifferentNameCheckSecondary(true);
      if (getValues(`patientInfo.${"patientId"}`)) {
        setValue(
          "insuranceInfo.secondarypolicyholderFirstName",
          patientInfoObject.firstName
        );
        setValue(
          "insuranceInfo.secondarypolicyholderMiddleName",
          patientInfoObject.middleName
        );
        setValue(
          "insuranceInfo.secondarypolicyholderLastName",
          patientInfoObject.lastName
        );
        setValue(
          "insuranceInfo.secondaryPolicyHolderDOB",
          patientInfoObject.birthDate
        );
      }
    } else {
      setIsPolicyUnderDifferentNameCheckSecondary(false);
      setValue("insuranceInfo.secondarypolicyholderFirstName", "");
      setValue("insuranceInfo.secondarypolicyholderMiddleName", "");
      setValue("insuranceInfo.secondarypolicyholderLastName", "");
      setValue("insuranceInfo.secondaryPolicyHolderDOB", "");
    }
  };

  const onSurgicalOrGlobalPeriodChange = (e: any) => {
    if (e.target.value === "true") {
      setIsUnderSurgicalGlobalPeriod(true);
    } else {
      setIsUnderSurgicalGlobalPeriod(false);
      setValue("treatmentInformation.surgicalProcedureDate", "")
      setValue("treatmentInformation.surgicalProcedureCodes", "")
    }
  }

  const signaturePadValidations = {
    name: {
      required: "Name is required.",
    },
    signature: {
      required: "Signature is required.",
    },
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLElement;
      if (!target.getAttribute('data-allow-submit')) {
        event.preventDefault();
      }
    }
  };

  const handleFetchPracticeProducts = async (date: string, practiceId: number, providerId?: number) => {
    setLoadingProdcuts(true);
    const formattedDate = moment(date?.toString()).format("MM/DD/YYYY");
    if (moment(formattedDate, "MM/DD/YYYY", true).isValid()) {
      let data: FetchPracticeProductsParams;
      if (isPracticeIndividual) {
        data = {
          practiceId: practiceId,
          dateOfService: formattedDate,
          providerId: providerId
        };
      } else {
        data = {
          practiceId: practiceId,
          dateOfService: formattedDate,
          providerId: 0
        };
      }
      const response = await dispatch(fetchPracticeProductsDataUsingDateOfServiceAndProvider(data));
      if (response) {
        const productPromises = response.payload.data.map(async (product: any) => {
          const productItems = await dispatch(fetchProductItemsByProductId({ productId: product.productId, onlyActiveRecords: true }));
          const macName = product.macId && product.macName ? " - " + product.macName : '';
          const providerName = product.billingType === "INDIVIDUAL" ? ` (${product.providerName}) ` : ""
          return {
            productId: product.productId,
            macId: product.macId,
            practiceProductId: product.practiceProductId,
            providerId: product.practiceProviderId,
            label: `${product.productName}${providerName}${macName}`,
            sizes: productItems.payload.map((item: any) => item),
          };
        });

        const products: ProductListWithItems[] = await Promise.all(productPromises);
        setProductListWithItems(products);
        setLoadingProdcuts(false);
      }
    }
  }

  const onNursingFacilityChange = (e: any) => {
    if (e.target.value === "true") {
      setIsPatientCurrentlyInNursingFacility(true);
    } else {
      setIsPatientCurrentlyInNursingFacility(false);
      setValue("treatmentInformation.daysAdmittedToFacility", "");
      setValue("treatmentInformation.currentlyCoveredUnderPartAStay", "");
    }
  }

  const handleProductChange = (product: any, size: string) => {
    setSelectedProducts(prevState => {
      const productIndex = prevState.findIndex(p => p.practiceProductId === product.practiceProductId);
      let updatedProducts = [...prevState];

      if (productIndex !== -1) {
        let updatedProduct = { ...updatedProducts[productIndex] };

        if (size !== '') {
          if (updatedProduct.productSizes.includes(size)) {
            updatedProduct.productSizes = updatedProduct.productSizes.filter(s => s !== size);
          } else {
            updatedProduct.productSizes = [...updatedProduct.productSizes, size];
          }

          if (updatedProduct.productSizes.length === 0) {
            updatedProduct.productSizes = [];
          }

          updatedProducts[productIndex] = updatedProduct;
        } else {
          updatedProducts = updatedProducts.filter(p => p.practiceProductId !== product.practiceProductId);
        }
      } else if (size !== '') {
        const newProduct: Product = {
          productId: product.productId,
          macId: product.macId,
          practiceProductId: product.practiceProductId,
          providerId: product.providerId,
          productSizes: [size],
        };
        updatedProducts = [...updatedProducts, newProduct];
      } else {
        const newProduct: Product = {
          productId: product.productId,
          macId: product.macId,
          practiceProductId: product.practiceProductId,
          providerId: product.providerId,
          productSizes: [],
        };
        updatedProducts = [...updatedProducts, newProduct];
      }

      setValue("treatmentInformation.products", updatedProducts);
      return updatedProducts;
    });
  };

  const isSizeSelected = (product: any, size: string) => {
    const selectedProduct = selectedProducts?.find(p => p.practiceProductId === product.practiceProductId);
    return selectedProduct ? selectedProduct.productSizes.includes(size) : false;
  };

  const confirmOptionPopup = () => {
    setIsPatientInfoDisabled(false);
    setOptionPopupShow({ message: "", show: false });
  };

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: `Do you want to change the information of ${fullName("", getValues("patientInfo.patientFirstName"), getValues("patientInfo.patientMiddleName"), getValues("patientInfo.patientLastName"))} ? `, show: false });
  };

  return (
    <>
      {showWoundInfoPopup &&
        <IVRFormWoundInfoPopup
          open={showWoundInfoPopup}
          onClose={() => {
            setShowWoundInfoPopup(false);
            setCurrentWoundInfo(null);
          }}
          currentWoundInfo={currentWoundInfo}
          onAddWoundInfo={handleAddWoundInfo}
        />}

      <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
        <Header title="Insurance Verification Request" subtitle="IVR Form" />
        <Card>
          <StickTopStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            isViewMobile={false}
          />
          <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={handleKeyPress}>
            <Box>
              <Box mt={2} ref={insuranceVerificationRequestRef}>
                <BorderedSection title="Insurance Verification Request">
                  <FormControl>
                    <CustomRadioButton
                      control={control}
                      options={typeOfIVROptions}
                      controllerName="typeOfIVR"
                      onChange={(e) => {
                        checkIVRequestValidity({ ...watch() });
                      }}
                      rules={{ required: "IVR type is required." }}
                      isDisabled={disableAllFields}
                    />
                  </FormControl>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>

              <Box mt={4} ref={productInformationRef}>
                <BorderedSection title="Product Information">
                  <Grid container spacing={2}>
                    {isPracticeIndividual ?
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography fontWeight="bold">Please select the "Date of Service" and the "Provider" to choose the product.</Typography>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography fontWeight="bold">Please select the "Date of Service" to choose the product.</Typography>
                        </Grid>
                      </>
                    }
                    <Grid item md={2} xs={12}>
                      <CustomDatePicker
                        controllerName={`treatmentInformation.${"dateOfService"}`}
                        control={control}
                        label="Date of Service"
                        rules={{
                          required: "Date of Service is required.",
                          validate: {
                            isValidDate: (
                              value:
                                | string
                                | number
                                | Date
                                | dayjs.Dayjs
                                | null
                                | undefined
                            ) =>
                              dayjs(value, "MM/DD/YYYY", true).isValid() ||
                              "Invalid date format.",
                          },
                        }}
                        onChange={(e) => {
                          checkProductInfoValidity({ ...watch() });
                          setValue("treatmentInformation.practiceProductId", 0)
                          setValue("treatmentInformation.productSizes", [])
                          dispatch(resetProductItems());
                        }}
                        // onBlur={(e) => {
                        //   if (moment(e.target.value, "MM/DD/YYYY", true).isValid()) {
                        //     handleFetchPracticeProducts(e.target.value, parseInt(practiceID));
                        //   } else {
                        //     setDisableProductField(true);
                        //   }
                        // }}
                        // onClose={() => {
                        //   const date = getValues("treatmentInformation.dateOfService");
                        //   const providerId = getValues("providerInformation.provider");
                        //   handleFetchPracticeProducts(date, parseInt(practiceID), parseInt(providerId));
                        // }}
                        disabled={disableAllFields}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CustomDropdownField
                        name={`providerInformation.${"provider"}`}
                        control={control}
                        options={practiceProvidersOptions}
                        label="Provider"
                        rules={{ required: "Provider is required." }}
                        onChangeSelect={(e) => {
                          handleProviderChange();
                          checkProductInfoValidity({ ...watch() });
                        }}
                        disabled={disableAllFields}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <CustomDropdownField
                        control={control}
                        name={`treatmentInformation.${"placesOfService"}`}
                        options={placesOfService}
                        label="Place(s) of Service"
                        rules={{ required: "Place(s) of Service is required." }}
                        onChangeSelect={(e) => {
                          checkProductInfoValidity({ ...watch() });
                        }}
                        disabled={disableAllFields}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <CustomDropdownField
                        name={`providerInformation.${"practiceLocation"}`}
                        control={control}
                        options={practiceLocationsOptions}
                        label="Practice Location"
                        rules={{ required: "Practice Location is required." }}
                        onChangeSelect={(e) => {
                          checkProductInfoValidity({ ...watch() });
                        }}
                        disabled={disableAllFields}
                      />
                    </Grid>
                  </Grid>
                  <Controller
                    name={`treatmentInformation.products`}
                    control={control}
                    rules={{
                      validate: (value: any) => {
                        if (value !== undefined) {
                          if (submitAction === "submitAction" && value.length === 0) {
                            return "At least one product should be selected.";
                          }
                          for (let product of value) {
                            if (product.productSizes.length === 0) {
                              return "At least one product item should be selected.";
                            }
                          }
                        } else {
                          if (submitAction === "submitAction") {
                            return "At least one product should be selected.";
                          }
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          {!disableProductField ?
                            <>
                              <Typography mt={2} fontWeight="bold" fontSize={17}>Products</Typography>
                              <Typography mb={2}>Please Note: Each product you select will generate a separate IVR submission. ie. Selecting “Product X” and “Product Y” will result in two IVR submissions: one IVR for “Product X” and one IVR for “Product Y”</Typography>
                              {fieldState.error && (
                                <Typography
                                  color="error"
                                  variant="body2"
                                  style={{ marginTop: "2px" }}
                                >
                                  {fieldState && fieldState?.error?.message}
                                </Typography>
                              )}

                              {productListWithItems && productListWithItems.length > 0 ? (
                                productListWithItems.map(product => (
                                  <Grid container key={product.practiceProductId} alignItems="center" justifyContent="flex-start" spacing={2}>
                                    <Grid item xs={12} md sm>
                                      <CustomCheckbox
                                        control={control}
                                        label={product.label}
                                        name={`treatmentInformation.${"practiceProductId"}`}
                                        id={product.practiceProductId.toString()}
                                        checked={selectedProducts?.some(p => p.practiceProductId === product.practiceProductId)}
                                        onChange={() => {
                                          handleProductChange(product, '');
                                          checkProductInfoValidity({ ...watch() });
                                          // trigger("treatmentInformation.products")
                                        }}
                                        rules={{ required: "Product is required." }}
                                        isDisabled={disableAllFields}
                                      />
                                    </Grid>
                                    {selectedProducts?.some(p => p.practiceProductId === product.practiceProductId) && (
                                      <Grid xs={6} md={9}>
                                        <Box sx={{ pl: isMobile ? 5 : 0 }}>
                                          {product.sizes
                                            .slice()
                                            .sort((a, b) => a.units - b.units)
                                            .map(size => (
                                              <CustomCheckbox
                                                control={control}
                                                label={size.size}
                                                name={`treatmentInformation.${"productSizes"}`}
                                                id={`${product.practiceProductId}-${size.size}`}
                                                checked={isSizeSelected(product, size.size)}
                                                onChange={() => {
                                                  handleProductChange(product, size.size);
                                                  checkProductInfoValidity({ ...watch() });
                                                  // trigger("treatmentInformation.products")
                                                }}
                                                isDisabled={!selectedProducts?.find(p => p.practiceProductId === product.practiceProductId) || disableAllFields || !size.isSizeActive}
                                                strikeThrough={!size.isSizeActive}
                                              />
                                            ))}
                                        </Box>
                                      </Grid>
                                    )}
                                  </Grid>
                                ))
                              ) : (
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {
                                      loadingProdcuts ?
                                        <Typography>Loading...</Typography> :
                                        <Typography sx={{ color: 'red' }}>Couldn't find any products for the selected date of service and provider</Typography>
                                    }
                                  </Grid>
                                </Grid>
                              )}
                            </>
                            : ""
                          }
                        </>
                      );
                    }}
                  />
                </BorderedSection>
              </Box>
              <Box mt={4} ref={patientInformationRef}>
                <BorderedSection title="Patient&nbsp;Information" subTitle={getValues("patientInfo.patientId") !== undefined && getValues("patientInfo.patientId") !== 0? "Click the 'Edit' button to update Patient Information" : ""}>
                  <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={12} md={3}>
                        <CustomDropdownField
                          name={`patientInfo.${"patientId"}`}
                          control={control}
                          options={patientsOptions}
                          label="Patients"
                          onChangeSelect={(e) => {
                            setValue("patientInfo.patientCode", e?.patientCode);
                            handlePatientChange(e);
                          }}
                          disabled={disableAllFields}
                        />
                    </Grid>
                    <Grid item xs={12} md>
                      {getValues("patientInfo.patientId") !== undefined && getValues("patientInfo.patientId") !== 0 && !location?.state?.viewOnly ?
                          <Button
                            startIcon={<ClearOutlined />}
                            onClick={() => {
                              setValue("patientInfo.patientId", 0)
                              clearPatientData();
                            }}
                            sx={{ 
                              mr: { xs: 0, md: 1 },
                              width: { xs: "100%", md: "auto" }
                            }}
                          >
                            Clear
                          </Button>
                          : ""}
                    </Grid>
                    <Grid item xs={12} md="auto">
                    {showEditButton && (
                      <Button
                      sx={{ mr: 1 }}
                      startIcon={<ModeOutlined />}
                      color="info"
                      onClick={() => {
                        setOptionPopupShow({
                          message: `Do you want to change the information of ${fullName("", getValues("patientInfo.patientFirstName"), getValues("patientInfo.patientMiddleName"), getValues("patientInfo.patientLastName"))} ? `,
                          show: true,
                        });
                      }}
                      fullWidth
                    >
                      Edit
                    </Button>
                    )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={10} xs={12}>
                      <CustomNametField
                        control={control}
                        name="patientInfo"
                        context="Patient"
                        xs={12}
                        isRequired={true}
                        onChange={(e) => {
                          checkPatientInfoValidity({ ...watch() });
                        }}
                        isDisabled={isPatientInfoDisabled || disableAllFields}
                      />
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <CustomDatePicker
                        controllerName={`patientInfo.${"patientDOB"}`}
                        control={control}
                        label="Patient's DOB"
                        rules={{ required: "Patient's DOB is required." }}
                        onChange={(e) => {
                          checkPatientInfoValidity({ ...watch() });
                        }}
                        disabled={isPatientInfoDisabled || disableAllFields}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <CustomRadioButton
                        controllerName={`patientInfo.${"gender"}`}
                        control={control}
                        options={patientGenderOptions}
                        rules={{ required: "Gender is required." }}
                        onChange={(e) => {
                          checkPatientInfoValidity({ ...watch() });
                        }}
                        isDisabled={isPatientInfoDisabled || disableAllFields}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <CustomAddressTextField
                        control={control}
                        name="patientInfo"
                        context="Patient"
                        isRequired={true}
                        onChange={(e) => {
                          checkPatientInfoValidity({ ...watch() });
                        }}
                        isDisabled={isPatientInfoDisabled || disableAllFields}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>                        
                    <Grid item md={3} xs={12} mb={1}>
                          <Dropzone
                            control={control}
                            rules={{ required: "FaceSheet is required." }}
                            name={`patientInfo.${"faceSheet"}`}
                            onFileChange={handleFaceSheetChange}
                            label="FaceSheet"
                            isDisabled={isPatientInfoDisabled || disableAllFields || getValues("patientInfo.isFaceSheetSkipped")}
                            onDeleteFile={handleDeleteFacesheetDocument}
                            initialFiles={faceSheetInitialFiles}
                            holdOnChange={true}
                          />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>  
                      {getValues("patientInfo.faceSheet") && getValues("patientInfo.faceSheet").length === 0 ?
                        <Grid item md={5} xs={12}>
                          <CustomCheckbox
                            control={control}
                            label="I acknowledge the information is on file, and not available for upload at this time."
                            controllerName={`patientInfo.${"isFaceSheetSkipped"}`}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                clearErrors('patientInfo.faceSheet');
                                const faceSheetDocumentIds = getValues("patientInfo.faceSheet");
                                const removePromises = faceSheetDocumentIds.map((id) =>
                                  dispatch(removeIVRDocument(id.toString()))
                                );
                                setValue("patientInfo.faceSheet", []);
                                setFaceSheetInitialFiles([]);
                              } else {
                                trigger('patientInfo.faceSheet');
                              }
                              checkPatientInfoValidity({ ...watch() });
                            }}
                            isDisabled={isPatientInfoDisabled || disableAllFields}
                          />
                        </Grid>
                        : ""}
                  </Grid>
                  <Grid item md={12} xs={12} mt={2}>
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      flexItem
                      sx={{ padding: 0, margin: 0}}
                    />
                  </Grid>

                  <Box mt={4} ref={insuranceInformationRef}>
                    {/* <BorderedSection title="Insurance Information"> */}

                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <div style={{ fontWeight: "bold" }}>
                          Insurance Information
                        </div>
                        <Typography mt={2}>Primary Insurance</Typography>
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <CustomTextField
                          controllerName={`insuranceInfo.${"insuranceName"}`}
                          control={control}
                          label="Insurance Name"
                          rules={{ required: "Insurance Name is required." }}
                          onChange={(e) => {
                            checkInsuranceInfoValidity({ ...watch() });
                          }}
                          disabled={isPatientInfoDisabled || disableAllFields}
                        />
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <CustomTextField
                          controllerName={`insuranceInfo.${"policy"}`}
                          control={control}
                          label="Policy #"
                          rules={{ required: "Policy # is required." }}
                          onChange={(e) => {
                            checkInsuranceInfoValidity({ ...watch() });
                          }}
                          disabled={isPatientInfoDisabled || disableAllFields}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sx={{
                          display: {
                            xs: "block",
                            md: "flex",
                          },
                          alignItems: {
                            md: "center",
                          },
                        }}
                      >
                        <Typography mr={4}>
                          Is the policy under a different name?
                        </Typography>
                        <CustomRadioButton
                          control={control}
                          options={policyOptions}
                          controllerName={`insuranceInfo.${"isPolicyUnderDifferentName"}`}
                          onChange={(e) => {
                            onPoliceUnderDifferentNameChanage(e);
                            checkInsuranceInfoValidity({ ...watch() });
                          }}
                          rules={{
                            required:
                              "Is the policy under a different name is required.",
                          }}
                          isDisabled={isPatientInfoDisabled || disableAllFields}
                        />
                      </Grid>
                      {isPolicyUnderDifferentNameCheck ? (
                        <>
                          <Grid item md={12} xs={12}>
                            <CustomNametField
                              control={control}
                              name="insuranceInfo"
                              context="policyHolder"
                              label="Policy Holder"
                              isRequired={true}
                              onChange={(e) => {
                                checkInsuranceInfoValidity({ ...watch() });
                              }}
                              isDisabled={isPatientInfoDisabled || disableAllFields}
                            />
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <CustomDatePicker
                              control={control}
                              controllerName={`insuranceInfo.${"policyHolderDOB"}`}
                              label="Policy Holder DOB"
                              rules={{
                                required: "Policy Holder DOB is required.",
                              }}
                              onChange={(e) => {
                                checkInsuranceInfoValidity({ ...watch() });
                              }}
                              disabled={isPatientInfoDisabled || disableAllFields}
                            />
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      </Grid>
                      <Grid container spacing={2} mt={1}>
                        <Grid item md={3} xs={12} mb={2}>
                          <Dropzone
                            control={control}
                            rules={{ required: "Insurance Cards are required." }}
                            name={`insuranceInfo.${"insuranceCards"}`}
                            onFileChange={handleInsuranceCardChange}
                            label="Insurance Cards"
                            isDisabled={isPatientInfoDisabled || disableAllFields || getValues("insuranceInfo.isInsuranceCardsSkipped")}
                            onDeleteFile={handleDeleteInsuranceDocument}
                            initialFiles={primaryInsCardsInitialFiles}
                            holdOnChange={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        {getValues("insuranceInfo.insuranceCards") && getValues("insuranceInfo.insuranceCards").length === 0 ?
                          <Grid item md={5} xs={12}>
                            <CustomCheckbox
                              control={control}
                              label="I acknowledge the information is on file, and not available for upload at this time."
                              controllerName={`insuranceInfo.${"isInsuranceCardsSkipped"}`}
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  clearErrors('insuranceInfo.insuranceCards');
                                  const insuranceCardsIds = getValues("insuranceInfo.insuranceCards");
                                  const removePromises = insuranceCardsIds.map((id) =>
                                    dispatch(removeIVRDocument(id.toString()))
                                  );
                                  setValue("insuranceInfo.insuranceCards", []);
                                  setPrimaryInsCardsInitialFiles([]);
                                } else {
                                  trigger('insuranceInfo.insuranceCards');
                                }
                                checkInsuranceInfoValidity({ ...watch() });
                              }}
                              isDisabled={isPatientInfoDisabled || disableAllFields}
                            />
                          </Grid>
                          : ""}
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography mr={4}>Secondary Insurance</Typography>
                          <CustomCheckbox
                            control={control}
                            label="Yes"
                            name="secondaryInsurance"
                            id="secondaryInsurance"
                            controllerName={`insuranceInfo.${"isSecondaryInsurance"}`}
                            checked={isSecondaryInsurance}
                            onChange={() => {
                              setIsSecondaryInsurance(!isSecondaryInsurance);
                            }}
                            isDisabled={isPatientInfoDisabled || disableAllFields}
                          />
                        </Grid>

                        {isSecondaryInsurance && (
                          <>
                            <Grid item md={2} xs={12}>
                              <CustomTextField
                                controllerName={`insuranceInfo.${"secondaryInsuranceName"}`}
                                control={control}
                                label="Insurance Name"
                                rules={{
                                  required: "Insurance Name is required.",
                                }}
                                onChange={(e) => {
                                  checkInsuranceInfoValidity({ ...watch() });
                                }}
                                isDisabled={isPatientInfoDisabled || disableAllFields}
                              />
                            </Grid>

                            <Grid item md={2} xs={12}>
                              <CustomTextField
                                controllerName={`insuranceInfo.${"secondaryPolicy"}`}
                                control={control}
                                label="Policy #"
                                rules={{ required: "Policy # is required." }}
                                onChange={(e) => {
                                  checkInsuranceInfoValidity({ ...watch() });
                                }}
                                isDisabled={isPatientInfoDisabled || disableAllFields}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography mr={4}>
                                Is the policy under a different name?
                              </Typography>
                              <CustomRadioButton
                                control={control}
                                options={policyOptions}
                                controllerName={`insuranceInfo.${"isSecondaryPolicyUnderDifferentName"}`}
                                onChange={(e) => {
                                  onPoliceUnderDifferentNameChanageSecondary(e);
                                  checkInsuranceInfoValidity({ ...watch() });
                                }}
                                isDisabled={isPatientInfoDisabled || disableAllFields}
                              />
                            </Grid>
                            {isPolicyUnderDifferentNameCheckSecondary ? (
                              <>
                                <Grid item md={12} xs={12}>
                                  <CustomNametField
                                    control={control}
                                    context="secondaryPolicyHolder"
                                    label="Policy Holder"
                                    name="insuranceInfo"
                                    isRequired={true}
                                    onChange={(e) => {
                                      checkInsuranceInfoValidity({ ...watch() });
                                    }}
                                    isDisabled={isPatientInfoDisabled || disableAllFields}
                                  />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                  <CustomDatePicker
                                    control={control}
                                    controllerName={`insuranceInfo.${"secondaryPolicyHolderDOB"}`}
                                    label="Policy Holder DOB"
                                    rules={{
                                      required: "Policy Holder DOB is required.",
                                    }}
                                    onChange={(e) => {
                                      checkInsuranceInfoValidity({ ...watch() });
                                    }}
                                    disabled={isPatientInfoDisabled || disableAllFields}
                                  />
                                </Grid>
                                <Grid item md={12} xs={12}></Grid>
                              </>
                            ) : (
                              ""
                            )}
                            <Grid item md={12} xs={12}>
                              <Grid item md={3} xs={12}>
                                <Dropzone
                                  control={control}
                                  name={`insuranceInfo.${"secondaryInsuranceCards"}`}
                                  onFileChange={handleSecondaryInsuranceCardChange}
                                  label="Insurance Cards"
                                  onDeleteFile={
                                    handleDeleteSecondaryInsuranceDocument
                                  }
                                  initialFiles={secondaryInsCardsInitialFiles}
                                  isDisabled={isPatientInfoDisabled || disableAllFields || getValues("insuranceInfo.isSecondaryInsuranceCardsSkipped")}
                                  holdOnChange={true}
                                  rules={{ required: "Secondary Insurance Cards are required." }}
                                />
                              </Grid>
                              {getValues("insuranceInfo.secondaryInsuranceCards") && getValues("insuranceInfo.secondaryInsuranceCards").length === 0 ?
                                <Grid item md={5} xs={12}>
                                  <CustomCheckbox
                                    control={control}
                                    label="I acknowledge the information is on file, and not available for upload at this time."
                                    controllerName={`insuranceInfo.${"isSecondaryInsuranceCardsSkipped"}`}
                                    onChange={(e) => {
                                      if (e.target.checked === true) {
                                        clearErrors('insuranceInfo.secondaryInsuranceCards');
                                        const insuranceCardsIds = getValues("insuranceInfo.secondaryInsuranceCards");
                                        const removePromises = insuranceCardsIds.map((id) =>
                                          dispatch(removeIVRDocument(id.toString()))
                                        );
                                        setValue("insuranceInfo.secondaryInsuranceCards", []);
                                        setSecondaryInsCardsInitialFiles([]);
                                      } else {
                                        trigger('insuranceInfo.secondaryInsuranceCards');
                                      }
                                      checkInsuranceInfoValidity({ ...watch() });
                                    }}
                                    isDisabled={isPatientInfoDisabled || disableAllFields}
                                  />
                                </Grid>
                                : ""}
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} md="auto">
                          {showEditButton && (
                            <Button
                            sx={{ 
                              mr: 1,
                              width: { xs: "100%", md: "auto" }
                            }}
                            startIcon={<ModeOutlined />}
                            color="info"
                            onClick={() => {
                              setOptionPopupShow({
                                message: `Do you want to change the information of ${fullName("", getValues("patientInfo.patientFirstName"), getValues("patientInfo.patientMiddleName"), getValues("patientInfo.patientLastName"))} ? `,
                                show: true,
                              });
                            }}
                          >
                            Edit
                          </Button>
                          )}
                        </Grid>
                    </Grid>
                  </Box>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>

              <Box mt={4} ref={supportingDocumentationRef}>
                <BorderedSection title="Supporting Documentation">
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item md={6} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Dropzone
                            control={control}
                            name={`supportingDocumentation.${"supportingDocuments"}`}
                            rules={{
                              required: "Supporting Documents are required.",
                            }}
                            onFileChange={handleSupportingDocsChange}
                            label="Medical Notes"
                            onDeleteFile={handleDeleteSupportingDocument}
                            initialFiles={supportingDocsInitialFiles}
                            holdOnChange={true}
                            isDisabled={disableAllFields || getValues("supportingDocumentation.isSupportingDocumentsSkipped")}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          {getValues("supportingDocumentation.supportingDocuments") && getValues("supportingDocumentation.supportingDocuments").length === 0 ?
                            <CustomCheckbox
                              control={control}
                              label="I acknowledge the information is on file, and not available for upload at this time."
                              controllerName={`supportingDocumentation.${"isSupportingDocumentsSkipped"}`}
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  clearErrors("supportingDocumentation.supportingDocuments");
                                  const supportingDocsIds = getValues("supportingDocumentation.supportingDocuments");
                                  const removePromises = supportingDocsIds.map((id) =>
                                    dispatch(removeIVRDocument(id.toString()))
                                  );
                                  setValue("supportingDocumentation.supportingDocuments", []);
                                  setSupportingDocsInitialFiles([]);
                                } else {
                                  trigger("supportingDocumentation.supportingDocuments");
                                }
                                checkSupportingDocumentationValidity({ ...watch() });
                              }}
                              isDisabled={disableAllFields}
                            />
                            : ""}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Dropzone
                            control={control}
                            name={`supportingDocumentation.${"imageOfWounds"}`}
                            rules={{ required: "Image of Wounds are required." }}
                            onFileChange={handleImageOfWoundsChange}
                            label="Images of Wounds"
                            onDeleteFile={handleDeleteImageOfWoundsDocument}
                            initialFiles={imagesOfWoundsInitialFiles}
                            holdOnChange={true}
                            isDisabled={disableAllFields || getValues("supportingDocumentation.isImageOfWoundsSkipped")}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                        {getValues("supportingDocumentation.imageOfWounds") && getValues("supportingDocumentation.imageOfWounds").length === 0 ?
                          <CustomCheckbox
                            control={control}
                            label="I acknowledge the information is on file, and not available for upload at this time."
                            controllerName={`supportingDocumentation.${"isImageOfWoundsSkipped"}`}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                clearErrors("supportingDocumentation.imageOfWounds");
                                const imageOfWoundsIds = getValues("supportingDocumentation.imageOfWounds");
                                const removePromises = imageOfWoundsIds.map((id) =>
                                  dispatch(removeIVRDocument(id.toString()))
                                );
                                setValue("supportingDocumentation.imageOfWounds", []);
                                setImagesOfWoundsInitialFiles([]);
                              } else {
                                trigger("supportingDocumentation.imageOfWounds");
                              }
                              checkSupportingDocumentationValidity({ ...watch() });
                            }}
                            isDisabled={disableAllFields}
                          />
                          : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>

              <Box mt={4} ref={patientConsentInformationRef}>
                <BorderedSection title="Patient Consent Information">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          md: "flex",
                        },
                        alignItems: {
                          md: "center",
                        },
                      }}
                    >
                      <Typography mr={4}>
                        Patient Consent signed and on file
                      </Typography>
                      <CustomRadioButton
                        control={control}
                        options={consentOptions}
                        controllerName={`patientConsentInfomation.${"isPatientConsentSigned"}`}
                        onChange={(e) => {
                          if (e.target.value === "yes") {
                            setShowPatientSignDate(true);
                          } else {
                            setShowPatientSignDate(false);
                            setValue("patientConsentInfomation.patientSignDate", "");
                          }
                          checkPatientConsentInfoValidity({ ...watch() });
                        }}
                        isDisabled={disableAllFields}
                        rules={{
                          required:
                            "Patient Consent signed and on file option is required.",
                        }}
                      />
                    </Grid>
                    {showPatientSignDate ?
                      <Grid item md={3} xs={12}>
                        <CustomDatePicker
                          controllerName={`patientConsentInfomation.${"patientSignDate"}`}
                          control={control}
                          label="Patient's Signed Date"
                          rules={{ required: "Patient's Signed Date is required." }}
                          onChange={(e) => {
                            checkPatientConsentInfoValidity({ ...watch() });
                          }}
                          disabled={disableAllFields}
                        />
                      </Grid>
                      : ""}
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>

              <Box mt={4} ref={treatmentInformationRef}>
                <BorderedSection title="Treatment Information">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          md: "flex",
                        },
                        alignItems: {
                          md: "center",
                        },
                      }}
                    >
                      <Typography mr={4}>
                        Is this patient currently in a skilled nursing facility
                        or nursing home?
                      </Typography>
                      <CustomRadioButton
                        control={control}
                        options={patientInNursingFacilityOrHomeOptions}
                        controllerName={`treatmentInformation.${"currentlyInNursingFacility"}`}
                        rules={{
                          required:
                            "Skilled nursing facility option is required.",
                        }}
                        onChange={(e) => {
                          onNursingFacilityChange(e);
                          checkTreatmentInfoValidity({ ...watch() });
                        }}
                        isDisabled={disableAllFields}
                      />
                    </Grid>
                    {isPatientCurrentlyInNursingFacility ? (
                      <>
                        <Grid item md={6} xs={12}>
                          <Typography mr={4}>
                            How many days has the patient been admitted to the skilled nursing facility or nursing home?
                          </Typography>
                          <Grid item md={12} xs={12} mt={2}></Grid>
                          <Grid item md={4} xs={12}>
                            <CustomTextField
                              controllerName={`treatmentInformation.${"daysAdmittedToFacility"}`}
                              control={control}
                              label="Number of days"
                              rules={{
                                required: "Number of days admitted in skilled nursing facility is required",
                              }}
                              onChange={(e) => {
                                checkTreatmentInfoValidity({ ...watch() });
                              }}
                              isDisabled={disableAllFields}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: {
                              xs: "block",
                              md: "flex",
                            },
                            alignItems: {
                              md: "center",
                            },
                          }}
                        >
                          <Typography mr={4}>
                            Is the patient covered under a part A stay?
                          </Typography>
                          <CustomRadioButton
                            control={control}
                            options={policyOptions}
                            controllerName={`treatmentInformation.${"currentlyCoveredUnderPartAStay"}`}
                            rules={{
                              required:
                                "Please select whether the patient is covered under a Part A stay. This information is required.",
                            }}
                            onChange={(e) => {
                              checkTreatmentInfoValidity({ ...watch() });
                            }}
                            isDisabled={disableAllFields}
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                    {/* <Grid item md={12} xs={12}></Grid> */}

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          md: "flex",
                        },
                        alignItems: {
                          md: "center",
                        },
                      }}
                    >
                      <Typography mr={4}>
                        Is this patient currently under a surgical global period?
                      </Typography>
                      <CustomRadioButton
                        control={control}
                        options={patientUnderSurgicalOrGlobalPeriodOptions}
                        controllerName={`treatmentInformation.${"currentlyUnderSurgicalGlobalPeriod"}`}
                        rules={{
                          required:
                            "Under surgical global period option is required.",
                        }}
                        onChange={(e) => {
                          onSurgicalOrGlobalPeriodChange(e);
                          checkTreatmentInfoValidity({ ...watch() });
                        }}
                        isDisabled={disableAllFields}
                      />
                    </Grid>
                    {isUnderSurgicalGlobalPeriod ? (
                      <>
                        <Grid item md={12} xs={12}></Grid>
                        <Grid item md={6} xs={12}>
                          <CustomDatePicker
                            controllerName={`treatmentInformation.${"surgicalProcedureDate"}`}
                            control={control}
                            label="Date of Surgical Procedure*"
                            rules={{
                              required: "Surgical Procedure date is required.",
                            }}
                            onChange={(e) => {
                              checkTreatmentInfoValidity({ ...watch() });
                            }}
                            disabled={disableAllFields}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <CustomTextField
                            controllerName={`treatmentInformation.${"surgicalProcedureCodes"}`}
                            control={control}
                            label="Surgical Procedure Codes*"
                            rules={{
                              required: "Surgical Procedure codes is required.",
                            }}
                            onChange={(e) => {
                              checkTreatmentInfoValidity({ ...watch() });
                            }}
                            isDisabled={disableAllFields}
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>

              <Box mt={4} ref={woundDataRef}>
                <BorderedSection title="Wound Data">
                  <Grid container spacing={2}>
                    <Controller
                      name={`woundInfo.woundData`}
                      control={control}
                      rules={{
                        validate: (value: any) => {
                          if (submitAction === "submitAction" && value.length === 0) {
                            return "At least 1 wound information is required.";
                          }
                          return true;
                        }
                      }}
                      render={({ field, fieldState }) => {
                        const hasWoundInformation =
                          getValues("woundInfo.woundData")?.length > 0;
                        return (
                          <>
                            <Grid
                              container
                              justifyContent="flex-end"
                              sx={{ flexWrap: "wrap" }}
                              spacing={1}
                            >
                              <Grid item xs={12} sm="auto">
                                <Button
                                  sx={{ mb: 1, ml:1 }}
                                  color={
                                    fieldState.error && !hasWoundInformation
                                      ? "error"
                                      : "info"
                                  }
                                  startIcon={<AddCircleOutlineOutlined />}
                                  onClick={() => clickShowWoundInfoPopup()}
                                  fullWidth
                                  disabled={disableAllFields}
                                >
                                  Add Wound Information
                                </Button>
                              </Grid>
                            </Grid>

                            {fieldState.error && (
                              <Typography
                                color="error"
                                variant="body2"
                                style={{ marginTop: "1px", marginLeft: "15px" }}
                              >
                                {fieldState.error.message}
                              </Typography>
                            )}

                            <Grid item md={12} xs={12}>
                              {/* <LegacyToolBar items={legacyItems}/> */}
                              <CustomTable
                                columns={woundInfoTableColums}
                                rows={woundInfoRows}
                                actionComponent={(row: any, rowIndex: number) =>
                                  woundInfoActionComponent(row, rowIndex)
                                }
                                disablePagination={true}
                                borderBox={true}
                                showMenu = {true}
                              />
                            </Grid>
                          </>
                        );
                      }}
                    />
                    <Grid item md={2} xs={12}>
                      <CustomTextField
                        controllerName={`woundInfo.${"totalSizeOfAllWounds"}`}
                        control={control}
                        label="Total Size of All Wounds"
                        rules={{
                          validate: (value: string) => {
                            if (submitAction === "submitAction" && !value) {
                              return "Total Size of all wounds is required.";
                            }
                            return true;
                          }
                        }}
                        onChange={(e) => {
                          checkWoundInfoValidity({ ...watch() });
                        }}
                        isDisabled={disableAllFields}
                      />
                    </Grid>
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>
             

              <Box mt={4}>
                <BorderedSection title="Additional Notes">
                  <Grid container spacing={2} mb={2}>
                    <Grid item md={12} xs={12}>
                      <CustomTextField
                        controllerName="comments"
                        control={control}
                        label="Additional notes/comments"
                        multiline
                        rows={4}
                        maxRows={4}
                        isDisabled={disableAllFields}
                      />
                    </Grid>
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12} mt={2}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 2 }}
                />
              </Grid>
              <Grid item md={12} xs={12}></Grid>

              <Box mt={4} ref={providerInformationRef}>
                <BorderedSection title="Signature Authorization">
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <CustomDropdownField
                        name={`providerInformation.${"practice"}`}
                        control={control}
                        options={practiceOptions}
                        label="Practice"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                      <CustomCheckbox
                        control={control}
                        controllerName={`checkProviderSignAuth`}
                        label={`I am authorized to sign this IVR form on behalf of the ${selectedProviderData[0]?.providerName ?? "<Provider Name>"
                          }`}
                        isDisabled={disableAllFields}
                        rules={{
                          required:
                            "Please confirm that you are authorized to sign this IVR form on behalf of the provider.",
                        }}
                        onChange={(e) => {
                          if (e.target.checked === true && selectedProviderData.length > 0) {
                            setSignOutSideName(selectedProviderData[0].providerName);
                            setSignatureData("");
                          } else {
                            setSignatureData("");
                            setSignOutSideName("");
                          }
                        }}
                        checked={signatureData ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={4} xs={12} sx={{minWidth: !isMobile ? '550px' : ""}} >
                      <SignaturePad
                        onSignatureChange={handleSignatureChange}
                        name={`providerInformation.${"signature"}`}
                        textName={`providerInformation.${"name"}`}
                        control={control}
                        validationRules={signaturePadValidations}
                        setSignOutside={signOutSideName}
                        disabled={disableAllFields}
                        signature={signatureData}
                        signerName={getValues("providerInformation.name")}
                      />
                    </Grid>
                  </Grid>
                </BorderedSection>
              </Box>

              <Grid item md={12} xs={12}>
                <React.Fragment>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ pt: 2 }}
                  >
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        startIcon={<SaveOutlined />}
                        type="submit"
                        onClick={() => setSubmitAction("saveAction")}
                        color="warning"
                        disabled={disableAllFields}
                        data-allow-submit="true"
                      >
                        Save As Draft
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        type="submit"
                        onClick={() => setSubmitAction("submitAction")}
                        startIcon={<ExitToApp />}
                        disabled={disableAllFields}
                        data-allow-submit="true"
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={onCancelClick}
                        startIcon={<CloseOutlined />}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Stack>
                </React.Fragment>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
      <CustomAlert
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        severity="info"
        message="Feature currently under development. Stay tuned for updates!"
      />
      <OptionsPopup
        open={optionPopupShow.show}
        onClose={() => setOptionPopupShow({ message: "", show: false })}
        variant="confirm"
        message={<>{optionPopupShow.message}</>}
        buttons={[
          { name: "Confirm", color: "primary", onClick: confirmOptionPopup },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ]}
      />
    </>
  );
};

export default IVRForm;
