import { Box, Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { AddCircleOutlineOutlined, DoDisturbAltOutlined, ModeOutlined, SearchOutlined, VpnKey, CheckCircleOutline, AssignmentReturnedOutlined, TrackChanges } from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import {
  SearchUsers, fetchAllUserRoles, fetchUserProfileById, searchUserList, useUserReducer, UserRole, resetUserState,
  fetchAgenciesListUserSlice, fetchManufacturersListUserSlice, getPracticesByUserRoleUserSlice, fetchActiveBillersUserSlice,
  UserDTO
} from "../../../redux/slice/userSlice";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import LegacyToolBar from "../../common/LegacyToolBar";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { AgencyInfo, ManufacturerInfo, setSingleAlertObj, showHideLoadingDialog, SingleAlertInfo, } from "../../../redux/slice/commonSlice";
import PasswordResetPopup from "../../common/PasswordResetPopup";
import { formatDate, hideElement, sortNames } from "../../../utils/Utility";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import InactiveUserPopup from "./InactiveUserPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsValueFoundInToken, selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import CustomIconButton from "../../common/CustomIconButton";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { AGENCY_USER, AUDIT_ENTITYS, CAN_ACCESS_APPLICATION, MANUFACTURE_USER, PRACTICE_USER, USER_ROLES } from "../../../constants/applicationConstants";
import { PracticeInfo } from "../../../redux/slice/practiceSlice";
import { BillerInfo } from "../../../redux/slice/practice/practiceApproveSlice";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import UploadBaaPopup from "../baa/UploadBaaPopup";
import CustomSingleAlert from "../../common/CustomSingleAlert";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "email", label: "Email", minWidth: 100 },
  { id: "userRole", label: "User Type", minWidth: 100 },
  { id: "userName", label: "Name", minWidth: 100 },
  { id: "phone", label: "Phone", minWidth: 100 },
  { id: "userStatus", label: "User Status", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 100, },
];



interface Row {
  email: string;
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userRole: string;
  phone: string;
  createDate: String;
  userStatus: string;
  action: JSX.Element;
  userId: string;
}

type Option = {
  label: string;
  value: string | number;
}

type TypeOption = {
  label: string;
  value: string;
}

const practiceUsers = [
  USER_ROLES.ROLE_PRACTICEADMIN,
  USER_ROLES.ROLE_PRACTICESTAFF,
];

const agencyUsers = [
  USER_ROLES.ROLE_AGENCYADMIN,
  USER_ROLES.ROLE_ACCOUNTEXECUTIVE
];

const manufacturerUsers = [
  USER_ROLES.ROLE_MANUFACTURERADMIN,
  USER_ROLES.ROLE_MANUFACTURERSTAFF,
  USER_ROLES.ROLE_MANUFACTURERIVR,
  USER_ROLES.ROLE_MANUFACTURERSERVICE
];

const billerUsers = [
  USER_ROLES.ROLE_BILLER
];

const PRACTICE_ENTITY: string = "Practice";
const AGENCY_ENTITY: string = "Agency";
const MANUFACTURER_ENTITY: string = "Manufacturer";
const BILLER_ENTITY: string = "Biller";

const NONE_ENTITY: string = "None";

const ITEMS_PER_PAGE = 20;

const UserList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, handleSubmit, watch, reset, setValue, getValues } = useForm<SearchUsers>();

  const [loggedInRoleEntity, setLoggedInRoleEntity] = useState<string>("");
  const [selectedRoleEntity, setSelectedRoleEntity] = useState<string>("");
  const [extraUserRoleIdsLocal, setExtraUserRoleIdsLocal] = useState<number[]>([]);
  const [onlyExtraRoleEntity, setOnlyExtraRoleEntity] = useState<string>("");
  const [allExtraRoleEntityLoaded, setAllExtraRoleEntityLoaded] = useState(false);
  const [showPasswordResetPopup, setShowPasswordResetPopup] = useState(false);
  const [showInactivePopup, setShowInactivePopup] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState("");
  const [userID, setUserID] = useState(0);
  const [userProfileStatus, setUserProfileStatus] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [showBaaPopup, setShowBaaPopup] = useState<{show: boolean, userEntity: "PRACTICE" | "USER", details:PracticeInfo | null}>();
  const [baaAlert, setBaaAlert] = useState<{show:boolean, obj: SingleAlertInfo}>();

  const [searchCriteria, setSearchCriteria] = useState<SearchUsers>({
    firstName: "",
    lastName: "",
    userRoleIdsForm: "",
    userName: "",
    extraEntityId: undefined,
    extraRoleIds: [],
    entityId: undefined,
    phone: "",
    userStatus: [],
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const { searchUsers, userRoles, agencyInfoListUserSlice, manufacturerInfoListUserSlice,
    practiceByUserRoleUserSlice, billerListUserSlice } = useUserReducer();
  const { userInfo } = useAuthSlice();
  const loggedInUserRole = userInfo.userRole;

  const managingUserRoles = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));
  const canAuditUser = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessAuditAction", AUDIT_ENTITYS.USERAUDIT)(state));

  //If Only Create Role is present then remove action column
  const filteredColumns = managingUserRoles.includes(USER_ROLES.ROLE_ONLY_CREATE)
    ? columns.filter(column => column.id !== 'action') // Remove 'action' column
    : columns;

  const statusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
    ];
    setValue("userStatus", ["Active"]);
    return options;
  }, []);

  const userTypesList = useMemo(() => {
    let allUserRoles: TypeOption[] = [];
    let loggedInUserEntityRelatedRoles: number[] = [];
    let extraUserRoleIds: number[] = [];
    let allEntityRelatedRoles: string[] = [];
    let entityRelatedLoggedInUser: boolean = false;
    if (userRoles && userRoles.length > 0 && managingUserRoles && managingUserRoles.length > 0) {
      let availableUserRoles: UserRole[] | undefined = undefined;

      availableUserRoles = userRoles?.filter((role: UserRole) => managingUserRoles.includes(role.roleName));

      let practiceAdminRoleId: string | number = 0;
      let practiceStaffRoleId: string | number = 0;
      let manufacturerAdminRoleId: string | number = 0;
      let manufacturerStaffRoleId: string | number = 0;
      let manufacturerServiceRoleId: string | number = 0;
      let manufacturerIvrRoleId: string | number = 0;
      let agencyAdminRoleId: string | number = 0;
      let accountRepresentativeRoleId: string | number = 0;

      availableUserRoles?.forEach((option: UserRole) => {
        allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });

        if (option.roleName === USER_ROLES.ROLE_PRACTICEADMIN) {
          practiceAdminRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_PRACTICESTAFF) {
          practiceStaffRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_MANUFACTURERADMIN) {
          manufacturerAdminRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_MANUFACTURERSTAFF) {
          manufacturerStaffRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_MANUFACTURERIVR) {
          manufacturerIvrRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_MANUFACTURERSERVICE) {
          manufacturerServiceRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_AGENCYADMIN) {
          agencyAdminRoleId = option.userRoleId;
        }
        if (option.roleName === USER_ROLES.ROLE_ACCOUNTEXECUTIVE) {
          accountRepresentativeRoleId = option.userRoleId;
        }

        if (agencyUsers.includes(loggedInUserRole)) {
          entityRelatedLoggedInUser = true;
          if (agencyUsers.includes(option.roleName)) {
            loggedInUserEntityRelatedRoles.push(Number(option.userRoleId));
          }
        } else if (manufacturerUsers.includes(loggedInUserRole)) {
          entityRelatedLoggedInUser = true;
          if (manufacturerUsers.includes(option.roleName)) {
            loggedInUserEntityRelatedRoles.push(Number(option.userRoleId));
          }
        } else if (practiceUsers.includes(loggedInUserRole)) {
          entityRelatedLoggedInUser = true;
          if (practiceUsers.includes(option.roleName)) {
            loggedInUserEntityRelatedRoles.push(Number(option.userRoleId));
          }
        } else if (billerUsers.includes(loggedInUserRole)) {
          entityRelatedLoggedInUser = true;
          if (billerUsers.includes(option.roleName)) {
            loggedInUserEntityRelatedRoles.push(Number(option.userRoleId));
          }
        }

        if (agencyUsers.includes(option.roleName)) {
          if (!allEntityRelatedRoles.includes(AGENCY_ENTITY)) {
            allEntityRelatedRoles.push(AGENCY_ENTITY);
          }
        } else if (manufacturerUsers.includes(option.roleName)) {
          if (!allEntityRelatedRoles.includes(MANUFACTURER_ENTITY)) {
            allEntityRelatedRoles.push(MANUFACTURER_ENTITY);
          }
        } else if (practiceUsers.includes(option.roleName)) {
          if (!allEntityRelatedRoles.includes(PRACTICE_ENTITY)) {
            allEntityRelatedRoles.push(PRACTICE_ENTITY);
          }
        } else if (billerUsers.includes(option.roleName)) {
          if (!allEntityRelatedRoles.includes(BILLER_ENTITY)) {
            allEntityRelatedRoles.push(BILLER_ENTITY);
          }
        }

      });

      if ((managingUserRoles.includes(USER_ROLES.ROLE_PRACTICEADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_PRACTICESTAFF))
        && managingUserRoles.length > 2) {
        allUserRoles.push({ label: PRACTICE_USER, value: practiceAdminRoleId + "-" + practiceStaffRoleId });
      }

      if ((managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERSTAFF)
        && managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERIVR) && managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERSERVICE))
        && managingUserRoles.length > 4) {
        allUserRoles.push({ label: MANUFACTURE_USER, value: manufacturerAdminRoleId + "-" + manufacturerStaffRoleId + "-" + manufacturerIvrRoleId + "-" + manufacturerServiceRoleId });
      }

      if ((managingUserRoles.includes(USER_ROLES.ROLE_AGENCYADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_ACCOUNTEXECUTIVE))
        && managingUserRoles.length > 2) {
        allUserRoles.push({ label: AGENCY_USER, value: agencyAdminRoleId + "-" + accountRepresentativeRoleId });
      }

      if (entityRelatedLoggedInUser) {
        allUserRoles?.forEach((option: TypeOption) => {
          if (Number(option.value) && !loggedInUserEntityRelatedRoles.includes(Number(option.value))) {
            extraUserRoleIds.push(Number(option.value));
          }
        });
        if (extraUserRoleIds.length > 1) {
          setValue('extraRoleIds', extraUserRoleIds);
          setExtraUserRoleIdsLocal(extraUserRoleIds);
        }
      }

      if (loggedInUserEntityRelatedRoles.length === 0 && allEntityRelatedRoles.length === 1) {
        setSelectedRoleEntity(allEntityRelatedRoles[0]);
        setOnlyExtraRoleEntity(allEntityRelatedRoles[0]);
      } else {
        setOnlyExtraRoleEntity(NONE_ENTITY);
      }

      allUserRoles = sortNames(allUserRoles, 'label');

      if (allUserRoles.length === 1) {
        setValue('userRoleIdsForm', allUserRoles[0]?.value);
      }
    }
    return allUserRoles;
  }, [userRoles]);

  const manufacturersList = useMemo(() => {
    let options: Option[] = [];
    if (manufacturerInfoListUserSlice && manufacturerInfoListUserSlice.length > 0) {
      const sortedInfo: ManufacturerInfo[] = sortNames(manufacturerInfoListUserSlice, 'manufacturerName');
      sortedInfo?.map(option => {
        options.push({ label: option.manufacturerName, value: option.manufacturerId });
      });
    }
    return options;
  }, [manufacturerInfoListUserSlice]);

  const agencyList = useMemo(() => {
    let options: Option[] = [];
    if (agencyInfoListUserSlice && agencyInfoListUserSlice.length > 0) {
      const sortedInfo: AgencyInfo[] = sortNames(agencyInfoListUserSlice, 'agencyName');
      sortedInfo?.map(option => {
        options.push({ label: option.agencyName, value: option.agencyId });
      });
    }
    return options;
  }, [agencyInfoListUserSlice]);

  const billerUsersList = useMemo(() => {
    let options: Option[] = [];
    if (billerListUserSlice && billerListUserSlice.length > 0) {
      const sortedInfo: BillerInfo[] = sortNames(billerListUserSlice, 'billerName');
      sortedInfo?.map(option => {
        options.push({ label: option.billerName, value: option.billerId });
      });
    }
    return options;
  }, [billerListUserSlice]);

  const practiceList = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRoleUserSlice && practiceByUserRoleUserSlice.length > 0) {
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleUserSlice, 'practiceName');
      sortedPracticeInfo?.map(practice => {
        options.push({ value: practice.practiceId, label: practice.practiceName });
      });
    }
    return options;
  }, [practiceByUserRoleUserSlice]);

  useEffect(() => {
    if (manufacturerInfoListUserSlice && manufacturerInfoListUserSlice.length > 0) {
      if (manufacturerInfoListUserSlice.length === 1) {
        if (((loggedInRoleEntity === MANUFACTURER_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === MANUFACTURER_ENTITY && !loggedInRoleEntity)
          || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === MANUFACTURER_ENTITY))) {
          setValue('entityId', Number(manufacturerInfoListUserSlice[0]?.manufacturerId));
        }
        if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === MANUFACTURER_ENTITY) {
          setValue('extraEntityId', Number(manufacturerInfoListUserSlice[0]?.manufacturerId));
        }
      }
    }
  }, [manufacturerInfoListUserSlice, loggedInRoleEntity, selectedRoleEntity]);

  useEffect(() => {
    if (agencyInfoListUserSlice && agencyInfoListUserSlice.length > 0) {
      if (agencyInfoListUserSlice.length === 1) {
        if (((loggedInRoleEntity === AGENCY_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === AGENCY_ENTITY && !loggedInRoleEntity)
          || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === AGENCY_ENTITY))) {
          setValue('entityId', Number(agencyInfoListUserSlice[0]?.agencyId));
        }
        if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === AGENCY_ENTITY) {
          setValue('extraEntityId', Number(agencyInfoListUserSlice[0]?.agencyId));
        }
      }
    }
  }, [agencyInfoListUserSlice, loggedInRoleEntity, selectedRoleEntity]);

  useEffect(() => {
    if (billerListUserSlice && billerListUserSlice.length > 0) {
      if (billerListUserSlice.length === 1) {
        if (((loggedInRoleEntity === BILLER_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === BILLER_ENTITY && !loggedInRoleEntity)
          || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === BILLER_ENTITY))) {
          setValue('entityId', Number(billerListUserSlice[0]?.billerId));
        }
        if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === BILLER_ENTITY) {
          setValue('extraEntityId', Number(billerListUserSlice[0]?.billerId));
        }
      }
    }
  }, [billerListUserSlice, loggedInRoleEntity, selectedRoleEntity]);

  useEffect(() => {
    if (practiceByUserRoleUserSlice && practiceByUserRoleUserSlice.length > 0) {
      if (practiceByUserRoleUserSlice.length === 1) {
        if (((loggedInRoleEntity === PRACTICE_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === PRACTICE_ENTITY && !loggedInRoleEntity)
          || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === PRACTICE_ENTITY))) {
          setValue('entityId', Number(practiceByUserRoleUserSlice[0]?.practiceId));
        }
        if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === PRACTICE_ENTITY) {
          setValue('extraEntityId', Number(practiceByUserRoleUserSlice[0]?.practiceId));
        }
      }
    }
  }, [practiceByUserRoleUserSlice, loggedInRoleEntity, selectedRoleEntity]);

  useEffect(() => {
    if (onlyExtraRoleEntity && onlyExtraRoleEntity !== NONE_ENTITY) {
      fetchExtraRoleEntityLists();
    }
  }, [onlyExtraRoleEntity]);

  const fetchExtraRoleEntityLists = async () => {
    if (onlyExtraRoleEntity === AGENCY_ENTITY) {
      const responseAgencies = await dispatch(fetchAgenciesListUserSlice());
    } else if (onlyExtraRoleEntity === MANUFACTURER_ENTITY) {
      const responseManufacturers = await dispatch(fetchManufacturersListUserSlice());
    } else if (onlyExtraRoleEntity === PRACTICE_ENTITY) {
      const responsePractices = await dispatch(getPracticesByUserRoleUserSlice(false));
    } else if (onlyExtraRoleEntity === BILLER_ENTITY) {
      const responseBillers = await dispatch(fetchActiveBillersUserSlice());
    }
    setAllExtraRoleEntityLoaded(true);
  }

  useEffect(() => {
    const userRoleIdsFormLocal: string = getValues("userRoleIdsForm");
    if (!userRoleIdsFormLocal) {
      if (onlyExtraRoleEntity && onlyExtraRoleEntity !== NONE_ENTITY) {
        setSelectedRoleEntity(onlyExtraRoleEntity);
      } else {
        setSelectedRoleEntity("");
      }
      setValue('extraRoleIds', extraUserRoleIdsLocal);
      return;
    }
    let oneOfRoleId: number = 0;
    let extraUserRoleIds: number[] = [];
    if (String(userRoleIdsFormLocal).includes("-")) {
      const ids: string[] = String(userRoleIdsFormLocal).split("-");
      ids.forEach((val: string) => {
        oneOfRoleId = Number(val);
        if (extraUserRoleIdsLocal.includes(oneOfRoleId)) {
          extraUserRoleIds.push(oneOfRoleId);
        }
      });
    } else {
      oneOfRoleId = Number(userRoleIdsFormLocal);
      if (extraUserRoleIdsLocal.includes(oneOfRoleId)) {
        extraUserRoleIds.push(oneOfRoleId);
      }
    }

    setValue('extraRoleIds', extraUserRoleIds);

    let roleName: string | undefined = userRoles?.find((option: UserRole) => Number(option.userRoleId) === oneOfRoleId)?.roleName;
    if (roleName) {
      if (agencyUsers.includes(roleName)) {
        setSelectedRoleEntity(AGENCY_ENTITY);
        const responseAgencies = dispatch(fetchAgenciesListUserSlice());
      } else if (manufacturerUsers.includes(roleName)) {
        setSelectedRoleEntity(MANUFACTURER_ENTITY);
        const responseManufacturers = dispatch(fetchManufacturersListUserSlice());
      } else if (practiceUsers.includes(roleName)) {
        setSelectedRoleEntity(PRACTICE_ENTITY);
        const responsePractices = dispatch(getPracticesByUserRoleUserSlice(false));
      } else if (billerUsers.includes(roleName)) {
        setSelectedRoleEntity(BILLER_ENTITY);
        const responseBillers = dispatch(fetchActiveBillersUserSlice());
      } else {
        setSelectedRoleEntity("");
      }
    } else {
      setSelectedRoleEntity("");
    }

  }, [watch("userRoleIdsForm")]);

  //Mount
  useEffect(() => {
    const fromBaa = location?.state?.fromBaa
    if(fromBaa) {
      notifyBaaAlert();
    };
    fetchInitialLists();
  }, []);

  const notifyBaaAlert = () => {
    let infoObj: SingleAlertInfo = {
      message: "Please upload the Business Association Agreement (subcontractor version) by selecting the Import BAA option in the action menu.",
      alertType: "info"
    }
    setBaaAlert({
      show: true,
      obj: infoObj
    });
  }

  const fetchInitialLists = async () => {
    dispatch(showHideLoadingDialog(true));
    //Don't remove below unused variables
    if (agencyUsers.includes(loggedInUserRole)) {
      setLoggedInRoleEntity(AGENCY_ENTITY);
      const responseAgencies = await dispatch(fetchAgenciesListUserSlice());
    } else if (manufacturerUsers.includes(loggedInUserRole)) {
      setLoggedInRoleEntity(MANUFACTURER_ENTITY);
      const responseManufacturers = await dispatch(fetchManufacturersListUserSlice());
    } else if (practiceUsers.includes(loggedInUserRole)) {
      setLoggedInRoleEntity(PRACTICE_ENTITY);
      const responsePractices = await dispatch(getPracticesByUserRoleUserSlice(false));
    } else if (billerUsers.includes(loggedInUserRole)) {
      setLoggedInRoleEntity(BILLER_ENTITY);
      const responseBillers = await dispatch(fetchActiveBillersUserSlice());
    }
    const responseAllUserRoles = await dispatch(fetchAllUserRoles());
    dispatch(showHideLoadingDialog(false));
  }

  //Unmount
  useEffect(() => () => {
    dispatch(resetUserState());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    if (userTypesList && userTypesList.length > 0 && onlyExtraRoleEntity && (onlyExtraRoleEntity === NONE_ENTITY || allExtraRoleEntityLoaded)) {
      let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.USER_MANAGEMENT + "SearchCriteria" + userInfo.userId);
      if ((location.state && location.state.userListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
        if (location.state && location.state.withSearchHistory) {
          //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
          navigate("/user_list", { state: { userListSearchCriteria: criteria } });
          return;
        }
        let searchCriteria: SearchUsers = {
          firstName: "",
          lastName: "",
          userRoleIdsForm: "",
          userName: "",
          entityId: undefined,
          extraEntityId: undefined,
          extraRoleIds: [],
          phone: "",
          userStatus: [],
          currentPage: 0,
          itemsPerPage: 0
        };
        if (location.state && location.state.userListSearchCriteria) {
          searchCriteria = location.state.userListSearchCriteria;
        } else {
          searchCriteria = criteria ? criteria : searchCriteria;
        }
        setSearchCriteria(searchCriteria);
        setValuesToCriteriaFields(searchCriteria);

        searchUsersList(searchCriteria);
      } else {
        loadInitialUserList(1);
      }
    }
  }, [location.state, userTypesList, onlyExtraRoleEntity, allExtraRoleEntityLoaded,  showBaaPopup?.show]);

  const setValuesToCriteriaFields = (searchCriteria: SearchUsers) => {
    setValue("firstName", searchCriteria.firstName);
    setValue("lastName", searchCriteria.lastName);
    setValue("userRoleIdsForm", searchCriteria.userRoleIdsForm);
    setValue("userName", searchCriteria.userName);
    setValue("extraEntityId", searchCriteria.extraEntityId);
    setValue("extraRoleIds", searchCriteria.extraRoleIds);
    setValue("entityId", searchCriteria.entityId);
    setValue("phone", searchCriteria.phone);
    setValue("userStatus", searchCriteria.userStatus);
  }

  const loadInitialUserList = (pNumber: number = 1) => {
    const data: SearchUsers = {
      firstName: "",
      lastName: "",
      userRoleIdsForm: userTypesList && userTypesList.length === 1 ? userTypesList[0].value : "",
      userName: "",
      extraEntityId: getValues("extraEntityId"),
      extraRoleIds: getValues("extraRoleIds"),
      entityId: getValues("entityId"),
      phone: "",
      userStatus: ["Active"],
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);

    searchUsersList(data);
  }

  const searchUsersList = (searchCriteria: SearchUsers) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.USER_MANAGEMENT + "SearchCriteria" + userInfo.userId, searchCriteria);

    let dataToSearch = {
      ...searchCriteria
    }

    if (!dataToSearch.userRoleIdsForm) {
      let arry: number[] = [];
      userTypesList.forEach((option: TypeOption) => {
        if (!String(option.value).includes("-")) {
          arry.push(Number(option.value));
        }
      });
      dataToSearch.userRoleIds = arry;
    } else {
      if (String(dataToSearch.userRoleIdsForm).includes("-")) {
        let arry: number[] = [];
        const ids: string[] = String(dataToSearch.userRoleIdsForm).split("-");
        ids.forEach((val: string) => {
          arry.push(Number(val));
        });
        dataToSearch.userRoleIds = arry;
      } else {
        dataToSearch.userRoleIds = [Number(dataToSearch.userRoleIdsForm)];
      }
    }

    if (extraUserRoleIdsLocal && extraUserRoleIdsLocal.length > 0) {
      let arryX: number[] = [];
      dataToSearch.userRoleIds.forEach((id: number) => {
        if (!extraUserRoleIdsLocal.includes(id)) {
          arryX.push(id);
        }
      });
      dataToSearch.userRoleIds = arryX;
    }

    if (dataToSearch.extraRoleIds && dataToSearch.extraRoleIds.length > 0) {
      if (!dataToSearch.extraEntityId || dataToSearch.extraEntityId === 0) {
        if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === MANUFACTURER_ENTITY) {
          dataToSearch.extraEntityIds = manufacturerInfoListUserSlice && manufacturerInfoListUserSlice.length > 0
            ? manufacturerInfoListUserSlice?.map(manufacturer => Number(manufacturer.manufacturerId)) : [];
        } else if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === AGENCY_ENTITY) {
          dataToSearch.extraEntityIds = agencyInfoListUserSlice && agencyInfoListUserSlice.length > 0
            ? agencyInfoListUserSlice?.map(agency => Number(agency.agencyId)) : [];
        } else if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === PRACTICE_ENTITY) {
          dataToSearch.extraEntityIds = practiceByUserRoleUserSlice && practiceByUserRoleUserSlice.length > 0
            ? practiceByUserRoleUserSlice?.map(practice => Number(practice.practiceId)) : [];
        } else if (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity && selectedRoleEntity === BILLER_ENTITY) {
          dataToSearch.extraEntityIds = billerListUserSlice && billerListUserSlice.length > 0
            ? billerListUserSlice?.map(biller => Number(biller.billerId)) : [];
        }
      } else {
        dataToSearch.extraEntityIds = [dataToSearch.extraEntityId];
      }
    }


    if (!dataToSearch.userStatus || dataToSearch.userStatus.length === 0) {
      dataToSearch.userStatus = statusOptions && statusOptions.length > 0 ? statusOptions?.map(status => status.value) : [];
    }

    dispatch(searchUserList(dataToSearch));
  }

  const onSubmit = (data: SearchUsers) => {
    const data2: SearchUsers = {
      firstName: data.firstName,
      lastName: data.lastName,
      userRoleIdsForm: data.userRoleIdsForm,
      userName: data.userName,
      extraEntityId: data.extraEntityId,
      extraRoleIds: data.extraRoleIds,
      entityId: data.entityId,
      phone: data.phone,
      userStatus: data.userStatus,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state, userTypesList, onlyExtraRoleEntity, allExtraRoleEntityLoaded] and search
    navigate("/user_list", { state: { userListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state, userTypesList, onlyExtraRoleEntity, allExtraRoleEntityLoaded] and search
    navigate("/user_list", { state: { userListSearchCriteria: data } });
  }

  const handleUserTypeSelectChange = (option: TypeOption, value: string) => {
    if (!onlyExtraRoleEntity) {
      setValue('extraEntityId', 0);
    }

    if (!loggedInRoleEntity) {
      setValue('entityId', 0);
    }
  }

  const clickShowPasswordResetPopup = () => {
    setShowPasswordResetPopup(true);
  }

  const handleActionEdit = (row: any) => {
    const { userName, userId, firstName, lastName, email, phone } = row;
    dispatch(fetchUserProfileById(parseInt(userId)));
    navigate("/user_create", {
      state: { user: { userName, userId, firstName, lastName, email, phone } },
    });
  }

  const handleActionActiveUser = (row: any) => {
    const { userId, userStatus } = row;
    setUserID(userId);
    setUserProfileStatus(userStatus);
    setShowInactivePopup(true);
  }

  const handleActionInactiveUser = (row: any) => {
    const { userId, userStatus } = row;
    setUserID(userId);
    setUserProfileStatus(userStatus);
    setShowInactivePopup(true);
  }

  const handleActionResetPassword = (row: any) => {
    const { userId, email } = row;
    dispatch(fetchUserProfileById(parseInt(userId)));
    setUserEmail(email);
    setUserID(userId);
    clickShowPasswordResetPopup();
  }

  const handleCloseInactivePopup = (reloadList: boolean) => {
    setShowInactivePopup(false);
    if (reloadList) {
      searchUsersList(searchCriteria);
    }
  }

  const handleImportBaa = (row: any) => {
    setShowBaaPopup({
      show: true,
      userEntity: "USER",
      details: row
    });
  }

  //CS-518 Passing the IVR details to audit log
  const handleClickUserAudit = (data: any) => {
    if (data && data.email) {
      const UserAuditData = {
        entityName: "USER",
        entityNumber: data?.email
      }

      const url = `/auditlog?entityName=${UserAuditData.entityName}&entityNumber=${UserAuditData.entityNumber}`;
      window.open(url, '_blank');
    //   // navigate("/auditlog", '_blank',{ state: { entityName: IVRAuditData.entityName, entityNumber: IVRAuditData.entityNumber } });
    }
  }
  //

  const actionComponent = (row: any): JSX.Element => {
    const { userStatus } = row;
    return (
      <>
        <CustomIconButton
          color="info"
          size="small"
          variant="outlined"
          tooltipMessage="Edit"
          tooltipPlacement="top"
          onClick={() => handleActionEdit(row)}
        >
          <ModeOutlined fontSize="small" />
        </CustomIconButton>

        <CustomIconButton
          color="warning"
          size="small"
          variant="outlined"
          tooltipMessage="Reset Password"
          tooltipPlacement="top"
          onClick={() => handleActionResetPassword(row)}
        >
          <VpnKey fontSize="small" />
        </CustomIconButton>

        {userStatus === "Active" && (
          <CustomIconButton
            color="error"
            size="small"
            variant="outlined"
            tooltipMessage="Inactivate User"
            tooltipPlacement="top"
            onClick={() => handleActionInactiveUser(row)}
          >
            <DoDisturbAltOutlined fontSize="small" />
          </CustomIconButton>
        )}
        {userStatus === "Inactive" && (
          <CustomIconButton
            color="success"
            size="small"
            variant="outlined"
            tooltipMessage="Activate User"
            tooltipPlacement="top"
            onClick={() => handleActionActiveUser(row)}
          >
            <CheckCircleOutline fontSize="small" />
          </CustomIconButton>
        )}
        {(userStatus === "Active" && row?.baaDetailIds?.length === 0) && (
          <CustomIconButton
            color="info"
            size="small"
            variant="outlined"
            tooltipMessage="Import BAA"
            tooltipPlacement="top"
            onClick={() => handleImportBaa(row)}
          >
            <AssignmentReturnedOutlined fontSize="small" />
          </CustomIconButton>
        )}
         <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="User Audit"
          color="warning"
          size="small"
          variant="outlined"
          style={{ display: `${hideElement(!canAuditUser)}` }}
          onClick={() => handleClickUserAudit(row) }
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>
      </>
    );
  };

  const userData: Row[] = useMemo(() => {
    let users: Row[] = [];
    if (searchUsers) {
      users = searchUsers.content.map((user: any) => ({
        email: user.email,
        userRole: user.userRole,
        userName: user.userName,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        phone: user.phone,
        createDate: formatDate(user.requestTimestamp),
        userStatus: user.userStatus,
        action: actionComponent(user),
        userId: user.userId,
      }));
    };
    return users;
  }, [searchUsers]);

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <CheckCircleOutline color="success" />, text: "Activate User" },
    { icon: <DoDisturbAltOutlined color="error" />, text: "Inactivate User" },
    { icon: <VpnKey color="warning" />, text: "Reset Password" },
  ];

  return (
    <>
      {showPasswordResetPopup &&
        <PasswordResetPopup
          open={showPasswordResetPopup}
          email={userEmail}
          userId={userID}
          onClose={() => {
            setShowPasswordResetPopup(false);
          }}
        />
      }
      {showInactivePopup &&
        <InactiveUserPopup
          open={showInactivePopup}
          userId={userID}
          userStatus={userProfileStatus}
          onClose={(reloadList: boolean) => {
            handleCloseInactivePopup(reloadList);
          }}
        />
      }
      {showBaaPopup?.show &&
        <UploadBaaPopup
          open={showBaaPopup.show}
          onClose={() => setShowBaaPopup({ show: false, userEntity: "USER", details: null })}
          userEntity={showBaaPopup.userEntity}
          details={showBaaPopup.details}
        />
      }
      <Box my="8px" ml="12px" mr="10px">
        <Header title="User List" subtitle="User Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField name="userRoleIdsForm" control={control} label="User Type" options={userTypesList}
                      onChangeSelect={handleUserTypeSelectChange} disabled={userTypesList && userTypesList.length === 1} />
                  </Grid>
                  {(selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity !== loggedInRoleEntity) ? (
                    <Grid item md={3} xs={12}>
                      {(selectedRoleEntity === AGENCY_ENTITY) && (
                        <CustomDropdownField name="extraEntityId" control={control} options={agencyList} label="Agency"
                          disabled={agencyList && agencyList.length === 1} />
                      )}
                      {(selectedRoleEntity === MANUFACTURER_ENTITY) && (
                        <CustomDropdownField name="extraEntityId" control={control} options={manufacturersList} label="Manufacturer"
                          disabled={manufacturersList && manufacturersList.length === 1} />
                      )}
                      {(selectedRoleEntity === PRACTICE_ENTITY) && (
                        <CustomDropdownField name="extraEntityId" control={control} options={practiceList} label="Practice"
                          disabled={practiceList && practiceList.length === 1} />
                      )}
                      {(selectedRoleEntity === BILLER_ENTITY) && (
                        <CustomDropdownField name="extraEntityId" control={control} options={billerUsersList} label="Biller"
                          disabled={billerUsersList && billerUsersList.length === 1} />
                      )}
                    </Grid>
                  ) : null}
                  {(((selectedRoleEntity && !loggedInRoleEntity) || (!selectedRoleEntity && loggedInRoleEntity))
                    || (selectedRoleEntity && loggedInRoleEntity && selectedRoleEntity === loggedInRoleEntity)) ? (
                    <Grid item md={3} xs={12}>
                      {(loggedInRoleEntity === AGENCY_ENTITY || selectedRoleEntity === AGENCY_ENTITY) && (
                        <CustomDropdownField name="entityId" control={control} options={agencyList} label="Agency"
                          disabled={agencyList && agencyList.length === 1} />
                      )}
                      {(loggedInRoleEntity === MANUFACTURER_ENTITY || selectedRoleEntity === MANUFACTURER_ENTITY) && (
                        <CustomDropdownField name="entityId" control={control} options={manufacturersList} label="Manufacturer"
                          disabled={manufacturersList && manufacturersList.length === 1} />
                      )}
                      {(loggedInRoleEntity === PRACTICE_ENTITY || selectedRoleEntity === PRACTICE_ENTITY) && (
                        <CustomDropdownField name="entityId" control={control} options={practiceList} label="Practice"
                          disabled={practiceList && practiceList.length === 1} />
                      )}
                      {(loggedInRoleEntity === BILLER_ENTITY || selectedRoleEntity === BILLER_ENTITY) && (
                        <CustomDropdownField name="entityId" control={control} options={billerUsersList} label="Biller"
                          disabled={billerUsersList && billerUsersList.length === 1} />
                      )}
                    </Grid>
                  ) : null}
                  {/* {((selectedRoleEntity || loggedInRoleEntity)) ? (
                    <Grid item md={3} xs={12}>
                      {((loggedInRoleEntity === AGENCY_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === AGENCY_ENTITY && !loggedInRoleEntity)
                      || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === AGENCY_ENTITY)) && (
                        <CustomDropdownField name="entityId" control={control} options={agencyList} label="Agency"
                          disabled={agencyList && agencyList.length === 1} />
                      )}
                      {((loggedInRoleEntity === MANUFACTURER_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === MANUFACTURER_ENTITY && !loggedInRoleEntity)
                      || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === MANUFACTURER_ENTITY)) && (
                        <CustomDropdownField name="entityId" control={control} options={manufacturersList} label="Manufacturer"
                          disabled={manufacturersList && manufacturersList.length === 1} />
                      )}
                      {((loggedInRoleEntity === PRACTICE_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === PRACTICE_ENTITY && !loggedInRoleEntity)
                      || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === PRACTICE_ENTITY)) && (
                        <CustomDropdownField name="entityId" control={control} options={practiceList} label="Practice"
                          disabled={practiceList && practiceList.length === 1} />
                      )}
                      {((loggedInRoleEntity === BILLER_ENTITY && !selectedRoleEntity) || (selectedRoleEntity === BILLER_ENTITY && !loggedInRoleEntity)
                      || (selectedRoleEntity && loggedInRoleEntity && loggedInRoleEntity === BILLER_ENTITY)) && (
                        <CustomDropdownField name="entityId" control={control} options={billerUsersList} label="Biller"
                          disabled={billerUsersList && billerUsersList.length === 1} />
                      )}
                    </Grid>
                  ) : null} */}
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown name="userStatus" control={control} label="User Status" options={statusOptions} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="userName" label="Email" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="firstName" label="First Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="lastName" label="Last Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomPatternTextField control={control} name="phone" label="Phone" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button fullWidth size="large" color="success" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2}>
              <Grid item xs={12} md>
                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button fullWidth color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/user_create")} >
                  Create User
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={filteredColumns}
              rows={userData}
              actionComponent={(row: any) => actionComponent(row)}
              totalPages={searchUsers?.totalPages}
              totalElementText={"Records: " + (searchUsers?.totalElements ? searchUsers?.totalElements : "0")}
              pageNumber={(thisPage: number) => handlePagination(thisPage)}
              emptyText="No User(s) Found"
              currentPageNumber={searchCriteria.currentPage - 1}
              showMenu={true}
            />
          </Card>
        </Box>
      </Box>
      {
        baaAlert?.show && 
        <CustomSingleAlert
        open={baaAlert?.show}
        singleAlertObj={baaAlert.obj}
        autoHideDuration={20000}
        onClose={() => setBaaAlert({show:false, obj:{message: "", alertType: "info" }})}
      />
      }
    </>
  );
}

export default UserList;