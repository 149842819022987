import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Table from "../../common/CustomTable";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { IvrListDTO,  searchIVRByPatientInfo } from "../../../redux/slice/ivr/createIVRSlice";
import { Column } from "../../../redux/slice/commonSlice";
import { IVRInfo } from "../ivr/IVRList";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { Cancel, ClassOutlined, SearchOutlined } from "@mui/icons-material";
import { ORDERTYPE } from "../../../constants/applicationConstants";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import { formatDate, fullName, hideDayMonthOfBOD, sortNames } from "../../../utils/Utility";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { PatientInfo, getPatientListByUserRole, resetPatientsByUserRole, usePatientReducer } from "../../../redux/slice/patient/patientSlice";
import CustomCheckbox from "../../common/CustomCheckBox";
import { getPracticeProductByPracticeProductId } from "../../../redux/slice/practiceSlice";
import dayjs from "dayjs";

interface CreateOrderIVRListPopupProps {
  open: boolean;
  onClose: () => void;
}


type Option = {
  value: string | number,
  label: string
};

interface IVRRow extends IVRInfo {
  selectCheckBox: JSX.Element;
}

const columns: Column[] = [
  { id: 'selectCheckBox', label: '' },
  { id: "ivrNumber", label: "IVR Number" },
  { id: "patient", label: "Patient", minWidth:130 },
  { id:'patientCode', label:"Patient Code", minWidth:130 },
  { id: 'provider', label: 'Provider', minWidth:130 },
  { id: "product", label: "Product" },
  { id: "dateCreated", label: "Date Created" },
  { id: "dateOfService", label: "Date of Service" },
  { id: "status", label: "Verification Status" },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CreateOrderIVRListPopup: React.FC<CreateOrderIVRListPopupProps> = ({
  open,
  onClose,
}) => {
  const { reset, handleSubmit, control, getValues, setValue, watch } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedIVR, setSelectedIVR] = useState<any>(null);
  const [isDisableCreateButton, setIsDisableCreateButton] = useState(true);
  const [ searchedIVRList, setSearchedIVRList ] = useState<IvrListDTO[] | null>(null);
  const { patientsByUserRole } = usePatientReducer();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    dispatch(getPatientListByUserRole());
  }, []);

  useEffect(() => {
    if (selectedIVR || getValues('IVRVerificationNotAvailable')) {
      setIsDisableCreateButton(false);
    } else {
      setIsDisableCreateButton(true);
    }
  }, [selectedIVR, watch('IVRVerificationNotAvailable')]);

  const checkboxComponent = (row: IVRInfo): JSX.Element => {
    return (
      <>
        <CustomCheckbox 
        control={control} 
        color="primary" 
        onChange={(e) => handleCheckbox(e, row)} checked={row.ivrId === selectedIVR?.ivrId}
        />
      </>
    );
};

const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
  const { checked } = e.target;
  if(checked) {
    setSelectedIVR({
      ...row,
      providerName : row.providerName.split(" ").slice(1).join(" ")
    });
  } else {
    setSelectedIVR(null)
  }
}

  const searchedIvrInfo: IVRRow[] = useMemo(() => {
    if(searchedIVRList) {
      return searchedIVRList?.map((ivr: any) => ({
        selectCheckBox: checkboxComponent(ivr),
        practice: ivr.practiceName,
        patient: fullName(ivr.title, ivr.patientFirstName, ivr.patientMiddleName, ivr.patientLastName),
        product: ivr.productName,
        provider: ivr.providerName,
        dateCreated: formatDate(ivr.auditTimestamp),
        createdDate: ivr.createdDate,
        dateOfService: ivr.dateOfService,
        status: ivr.verificationStatus,
        locationId: ivr.locationId,
        productId: ivr.productId,
        practiceId: ivr.practiceId,
        ivrId: ivr.ivrId,
        ivrNumber: ivr.ivrNumber,
        patientId: ivr.patientId,
        patientDOB: ivr.patientDOB,
        practiceProductId: ivr.practiceProductId,
        ivrDocumentId: ivr.ivrDocumentId,
        patientCode: ivr.patientCode
      }))
    }
    return [];
  }, [searchedIVRList, selectedIVR]);

  const onSubmit = async () => {
    const selectedPatient = patientsOptions.find(option => option.value === getValues('patient'));
    const selectedPatientInfo = patientsByUserRole.find(patient => patient.patientId === getValues('patient'));
    if(selectedPatient && selectedPatientInfo?.birthDate) {
      const response = await dispatch(searchIVRByPatientInfo(selectedPatient.label));
      if(response?.meta?.requestStatus === 'fulfilled') {
        if(response?.payload?.data?.length > 0) {
          setSearchedIVRList(response?.payload?.data)
        } else {
          let errObj: SingleAlertInfo = {
            message: `No IVR found`,
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errObj));
          setSearchedIVRList(null)
        }
      }
    } else {
      let errObj: SingleAlertInfo = {
        message: `Invalid Patient`,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errObj));
    }
  };

  const createOrder = async () => {
    if (selectedIVR) {
      navigate("/order", { state: { orderType: ORDERTYPE.DIRECT_ORDER, orderDetails: selectedIVR } })
    } else {
      navigate("/order", { state: { orderType: ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR } })
    }
  };

  useEffect(() => {
    return () => {
      setSearchedIVRList(null);
      setSelectedIVR(null);
      setIsDisableCreateButton(true);
      setValue('patient', '');
      dispatch(resetPatientsByUserRole());
    };
  }, [open]);

  const patientsOptions = useMemo(() => {
    const options: Option[] = [];
    const labelSet: Set<string> = new Set();
    if(patientsByUserRole) {
      const sortedPatientsByUserRole: PatientInfo[] = sortNames(patientsByUserRole, 'firstName');
      sortedPatientsByUserRole.map((patient : PatientInfo) => {
        let label : string = "";
        if(patient.birthDate) {
          label = fullName(null, patient.firstName, patient.middleName, patient.lastName) + " - " + patient.birthDate;
        } else {
          label = fullName(null, patient.firstName, patient.middleName, patient.lastName);
        };
        
        if(!labelSet.has(label)) {
          labelSet.add(label);
          options.push({
            value: patient.patientId,
            label: label
          })
        }
      });
    }
    return options;
  }, [patientsByUserRole]);

  const handleIVRNotAvailable = () => {
    if(getValues('IVRVerificationNotAvailable')) {
      setValue('patient', null);
      setSearchedIVRList(null);
      setSelectedIVR(null);
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      onClick={(event) => {
        event.stopPropagation();
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Find IVR</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomDropdownField
                    name="patient"
                    control={control}
                    options={patientsOptions}
                    setValues={setValue}
                    label="patient"
                    rules={{required: "Patient is required"}}
                    enableBlurHandler={true}
                    enableKeyDownHandler={true}
                    disabled={getValues('IVRVerificationNotAvailable')}
                  />
              </Grid>
              <Grid item xs={12} md={5}>
                <Button
                  startIcon={<SearchOutlined />}
                  fullWidth
                  style={{
                    padding: "15px",
                  }}
                  type="submit"
                  color="success"
                >
                  Search
                </Button>
              </Grid>
              <Grid item md={6}>
                <CustomCheckbox
                  control={control}
                  controllerName='IVRVerificationNotAvailable'
                  label='IVR verification NOT available in system'
                  onChange={handleIVRNotAvailable}
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Table
                    columns={columns}
                    rows={searchedIvrInfo}
                    uniqueValue="ivrId"
                    emptyText="No IVR(s) Found"
                    disablePagination={true}
                    showMenu = {true}
                    borderBox={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="flex-end"
          sx={{ flexWrap: "wrap" }}
          spacing={1}
        >
          <Grid item xs={12} sm="auto">
            <Button
              color="success"
              disabled={isDisableCreateButton}
              onClick={createOrder}
              startIcon={<ClassOutlined />}
              fullWidth
            >
              Create Order
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              color="error"
              onClick={onClose}
              startIcon={<Cancel />}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderIVRListPopup;
