import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "../../common/Card";
import Table from "../../common/CustomTable";
import Header from "../../common/Header";
import Button from "../../common/Button";
import {SearchOutlined} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../../common/CustomTextField';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { ACTION_TYPE, CAN_ACCESS_APPLICATION, ENTITY_TYPE, IVR_STATUS, IVR_STATUS_LIST, ORDERTYPE } from '../../../constants/applicationConstants';
import CustomMultipleDropDown from '../../common/CustomMultipleDropDown';
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from '../../../redux/slice/codetableSlice';
import CustomDateRange from '../../common/CustomDateRange';
import { DateObject } from 'react-multi-date-picker';
import CustomDropdownField from '../../common/CustomDropdownFiled';
import { auditLogInfo, fetchAuditLogList, resetAuditState, SearchAuditLog, useAuditLogSlice } from '../../../redux/slice/auditLog/auditLogSlice';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

type Option = {
  value: string,
  label: string
};

const ITEMS_PER_PAGE = 20;

const AuditLogList: React.FC = () => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { auditLogList } = useAuditLogSlice();
  const entityName = params.get('entityName');
  const entityNumber = params.get('entityNumber');

  const { control, handleSubmit, reset, setValue, getValues } = useForm<SearchAuditLog>();
  const [showCard, setShowCard] = useState(false);

  const [searchCriteria, setSearchCriteria] = useState<SearchAuditLog>({
    entityName: undefined,
    entityNumber: undefined,
    actionType: undefined,
    fromDate: undefined,
    toDate: undefined,
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE
  });

  const { allCodeTablesValuesMap } = useCodetablesSlice();


  const searchAuditLogList = useCallback(
    (currentPage: number, obj?: SearchAuditLog) => {
      let data: SearchAuditLog = {
        fromDate:obj?.fromDate ,
        toDate:obj?.toDate,
        entityNumber: obj?.entityNumber,
        entityName: obj?.entityName,
        actionType:obj?.actionType,
        currentPage: currentPage,
        itemsPerPage: ITEMS_PER_PAGE,
      };
      setValue("currentPage", currentPage);
      setSearchCriteria(data)
      dispatch(fetchAuditLogList(data));
    },
    []
  );

  //CS-518
  useEffect(() => {
    setShowCard(true)
    setValue("entityName", entityName);
    setValue("entityNumber", entityNumber);
    if(entityName&&entityNumber){
    searchAuditLogList(1, { ...getValues(), entityName: entityName, entityNumber: entityNumber })
    }
  }, [entityName,entityNumber])
  //
  const actionTypeOptions = useMemo(() => {
    let actions: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      actions = getCodeTableValuesByTableName(allCodeTablesValuesMap, ACTION_TYPE);
    }
    if (!actions || actions.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options:Option[] = [];
    actions?.map((actions) => {
      options.push({ label: actions.displayValue, value: actions.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const entityNameOptions = useMemo(() => {
    let entity: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      entity = getCodeTableValuesByTableName(allCodeTablesValuesMap, ENTITY_TYPE);
    }
    if (!entity || entity.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options:Option[] = [];
    entity?.map((entity) => {
      options.push({ label: entity.displayValue, value: entity.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  //Unmount
  useEffect(() => {
    dispatch(resetAuditState());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    if (location.state && location.state.auditLogListSeachCriteria) {
      console.log("%c Line:162 🥪 location", "color:#4fff4B", location);
      let searchCriteria : SearchAuditLog={
        entityName: 'Tesr',
        entityNumber: '',
        actionType:[],
        fromDate: '',
        toDate: '',
        currentPage: 1,
        itemsPerPage:0
      }

      searchCriteria = location.state.auditLogListSeachCriteria;
      setSearchCriteria(searchCriteria)
      setValue("entityName",searchCriteria?.entityName)
      setValue("entityNumber",searchCriteria?.entityNumber)
      searchAuditLogList(searchCriteria?.currentPage ,searchCriteria)
    }
  }, [location.state]);

  const onSubmit = (data: SearchAuditLog) => {
    const data1 : SearchAuditLog ={
      entityName:data?.entityName,
      entityNumber:data?.entityNumber,
      actionType:data?.actionType,
      fromDate:data?.fromDate,
      toDate:data?.toDate,
      currentPage:1,
      itemsPerPage:ITEMS_PER_PAGE
    }
    navigate("/auditlog", { state: { auditLogListSeachCriteria: data1 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state, productsByUserRoleCreateIVRSlice] and search
    navigate("/auditlog", { state: { auditLogListSeachCriteria: data } });
  } 

 const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;
    setValue("fromDate",fromDateString?fromDateString:"");
    setValue("toDate", toDateString?toDateString:"");
  };

  // Define columns and rows data
  const columns: Column[] = [
    { id: "entityName", label: "Audited Entity", minWidth: 100, align: "center" },
    { id: "entityNumber", label: "Entity #", minWidth: 100 , align: "center"},
    { id: "actionType", label: "Action Type", minWidth: 100 , align: "center"},
    { id: "userName", label: "User", minWidth: 100, align: "center" },
    { id: "auditMessage", label: "Audit Message", minWidth: 100 , align: "center"},
    { id: "clientIP", label: "User IP", minWidth: 100 , align: "center"},
    { id: "auditedOn", label: "Audited ON", minWidth: 100, align: "center" }
  ];

  const formattedAuditLogRows = useMemo(() => {
    let formatRows: auditLogInfo[] = [];
    auditLogList?.content?.map((row) => {
      let formatRow: auditLogInfo = {
        ...row,
        entityName:row?.entityName,
        entityNumber:row?.entityNumber,
        actionType:row?.actionType,
        auditMessage:row?.action,
        clientIP:row?.clientIP,
        userName:row?.userName,
        auditedOn:row?.auditTimestamp,
      };
      formatRows.push(formatRow);
    });
    return formatRows;
  }, [auditLogList, getValues()]);


  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Audit Log" subtitle="An Audit Log tracks and records user actions and system events for accountability and security" />
        <Collapse in={showCard}>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.5} mt={-3} mb={-1}>
                <Grid item xs={12} sm={3}>
                  <CustomDropdownField name="entityName" label="Audited Entity" options={entityNameOptions} control={control} 
                    onChangeSelect={(data) => {
                      if (data) {
                        setSearchCriteria(prevState => ({
                          ...prevState,
                          entityName: data.value,
                          entityNumber:null
                        }));
                        setValue('entityNumber','')
                        setValue('actionType',[])
                      } else {
                        setSearchCriteria(prevState => ({
                          ...prevState,
                          entityName: null,
                          entityNumber:null
                        }));
                        setValue('entityNumber',null)
                        setValue('actionType',[])
                      }
                    }} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomTextField controllerName="entityNumber" label="Entity Number" control={control} 
                  onChange={(data) => {
                      if (data?.target.value) {
                        setSearchCriteria(prevState => ({
                          ...prevState,
                          entityNumber: data?.target.value
                        }));
                      } 
                      else {
                        setSearchCriteria(prevState => ({
                          ...prevState,
                          entityNumber: null
                        }));
                      }
                    }}/>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomMultipleDropDown name="actionType" label="Action Type" options={actionTypeOptions} control={control} disabled={!searchCriteria?.entityName&&!searchCriteria?.entityNumber} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomDateRange
                    label="Update Date Range"
                    control={control}
                    controllerName="dateRange"
                    dateRangeMonths={!entityNumber ? 6 : 0}
                  onChange={handleDateRangeChange}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button fullWidth size="large" startIcon={<SearchOutlined />} disabled={!searchCriteria?.entityName&&!searchCriteria?.entityNumber} style={{ padding: "15px" }} type="submit" >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Collapse>
        <Box pt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            <Table columns={columns} rows={formattedAuditLogRows||[]} pageNumber={handlePagination}
              totalPages={auditLogList?.totalPages} totalElementText={"Records: " + (auditLogList?.totalElements ? auditLogList?.totalElements : "0")} // + (IVRListInfo?.totalElements ? IVRListInfo?.totalElements : "0")
              emptyText="No Log(s) Found" currentPageNumber={getValues("currentPage") ? getValues("currentPage") - 1 : 1} showMenu={true} />
          </Card>
        </Box>
      </Box>
    </>
  )
}

export default AuditLogList;